import React, { useEffect, useState } from "react"
import Container from "../../components/general/Container"
import Card from "../../components/members/Card"
import { useHistory } from "react-router-dom"
import TableComponent, { TableCell, TableRow } from "../../components/TableComponent"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import execute from "../../api/api"
import { format } from "date-fns"
import AddButton from "../../components/general/AddButton"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { Color } from "../../utils/Constants"
import Restricted from "../../components/Restricted"
import { getColorByLabel } from "../../utils/Changelog"
import { ActionIcon } from "../../components/ActionIcon"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import Chip from "../../components/Chip"
import { ChangelogType } from "../../components/changelog/ChangelogTypes"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"

const Changelog: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { role } = useStore(ContributorStore)
  const [refresh, setRefresh] = useState(0)
  const [data, setData] = useState<ChangelogType[]>([])
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const isSuperAdmin = role === "ROLE_SUPER_ADMIN"
  const cols = (t: TFunction) => [
    { label: t("changelog.forms.fields.date"), columnSpecifications: "w-1/6" },
    {
      label: t("changelog.forms.fields.version"),
      columnSpecifications: "w-1/6",
    },
    { label: t("changelog.forms.fields.changes") },
    {
      label: t("changelog.forms.fields.role"),
      columnSpecifications: `w-1/3 ${isSuperAdmin ? "" : "hidden"}`,
    },
    {
      label: t("generic.table.actions"),
      columnSpecifications: `text-center ${isSuperAdmin ? "" : "hidden"}`,
    },
  ]

  const saveLastChangeLog = (listChangeLog: ChangelogType[]) => {
    const listIdChangeLog: string[] = []
    const dateSevenDaysAgo = Date.now() - 60 * 60 * 24 * 1000 * 7

    listChangeLog.forEach((changeLog) => {
      if (Date.parse(changeLog.date) >= dateSevenDaysAgo && changeLog.id) {
        listIdChangeLog.push(changeLog.id.toString())
      }
    })
    localStorage.setItem("last_changelog", JSON.stringify(listIdChangeLog))
  }

  useEffect(() => {
    addActiveCalls()
    execute<ChangelogType[]>(`changeLogs`, "GET")
      .then((changelogs) => {
        // Les changelogs arrivent du plus ancien au plus récent. On veut les afficher dans l'autre sens.
        setData(changelogs.reverse())
        saveLastChangeLog(changelogs)
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  const editChangelog = (changelog: ChangelogType) => {
    history.push(`/changelog/${changelog.id}/edit`)
  }

  const deleteChangelog = (changelog: ChangelogType) => {
    execute(`changeLogs/${changelog.id}`, "DELETE")
      .then(() => {
        openSuccessSnackbar(
          `${t("changelog.snackbar.title")} ${t("generic.snackbar.success.delete")}`,
          snackbarDuration,
        )
        setRefresh(refresh + 1)
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
  }

  // Dynamically update browser tab title
  useTitle(`${t("menu.changelog")} | ${t("applicationName")}`)

  return (
    <>
      <div className={`w-screen h-2 ${Color.CHANGELOG}`} />
      <div className="pt-6">
        <Container>
          <Card>
            <h3>{t("changelog.table.header")}</h3>
            <Restricted right="changelogs.create">
              <AddButton classNames="w-min mb-4" onClick={() => history.push("/changelog/create")} />
            </Restricted>
            <TableComponent columnNames={cols(t)}>
              {data.map((el) => (
                <TableRow key={el.id}>
                  <TableCell>{format(new Date(el.date), t("generic.format.date"))}</TableCell>
                  <TableCell>{el.version}</TableCell>
                  <TableCell cellProp="list-disc">
                    <ul className="list-disc">
                      {el.value.map((change) => (
                        <li key={change}>
                          <span>{change}</span>
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                  {isSuperAdmin && (
                    <TableCell>
                      <div>
                        {el.roles.map((role) => (
                          <Chip
                            key={role.label + "-" + el.id}
                            title={role.label}
                            label={role.label}
                            color={getColorByLabel(role.label)}
                          />
                        ))}
                      </div>
                    </TableCell>
                  )}
                  {isSuperAdmin && (
                    <TableCell>
                      <div className="text-2xl px-1 py-2 flex flex-row justify-center items-center">
                        <Restricted right="changelogs.update">
                          <ActionIcon icon="icon-edit" iconColor="text-black" onClick={() => editChangelog(el)} />
                        </Restricted>
                        <Restricted right="changelogs.delete">
                          <ActionIcon icon="icon-delete" iconColor="text-red-500" onClick={() => deleteChangelog(el)} />
                        </Restricted>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableComponent>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Changelog
