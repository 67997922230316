import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PieCharts from "./PieCharts"
import { execute } from "../../../api/api"
import { useStore } from "effector-react"
import { customersLoyaltyPieResponse, dataPieType } from "../../../api/ChartObject"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { ColorGraphs } from "../../../utils/Constants"

const colors = [ColorGraphs.green, ColorGraphs.blue, ColorGraphs.red, ColorGraphs.black]

type CustomersLoyaltyPieChartProps = {
  id: string
}
const CustomersLoyaltyPieChart: React.FC<CustomersLoyaltyPieChartProps> = (props: CustomersLoyaltyPieChartProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<dataPieType[]>([])

  const storeFilters = useStore(FilterStore)
  const title = t("dashboard.pie.loyalty.title")

  useEffect(() => {
    setLoading(true)
    execute<customersLoyaltyPieResponse>(
      `dashboard/${id}/widget/cardType`,
      "GET",
      {},
      {},
      { filters: getDashboardFilters(storeFilters) },
    )
      .then((res) => {
        if (res) {
          setData([
            {
              name: t(`dashboard.pie.loyalty.bothCard`),
              y: res.bothCard,
            },
            {
              name: t(`dashboard.pie.loyalty.cardRequest`),
              y: res.cardRequest,
            },
            {
              name: t(`dashboard.pie.loyalty.digitalCard`),
              y: res.digitalCard,
            },
            {
              name: t(`dashboard.pie.loyalty.printedCard`),
              y: res.printedCard,
            },
          ])
        }
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters, id])

  return (
    <PieCharts
      title={title}
      data={data}
      bottomText={t("dashboard.pie.loyalty.footer")}
      colors={colors}
      isSecondaryWidget={true}
      loading={loading}
    />
  )
}

export default CustomersLoyaltyPieChart
