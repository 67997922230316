import Cookies from "universal-cookie"
import jwt_decode from "jwt-decode"
import { decodedJwtType } from "./Jwt"

const cookies = new Cookies()

const cookiesOptions = {
  secure: process.env.REACT_APP_LOCAL !== "true",
  path: "/", // accessible on all pages
}

const credentialsManager = () => {
  const apiGatewayUsername: string = process.env.REACT_APP_API_GATEWAY_USERNAME || ""
  const apiGatewayPassword: string = process.env.REACT_APP_API_GATEWAY_PASSWORD || ""
  const apiGatewayKey: string = process.env.REACT_APP_API_GATEWAY_KEY || ""
  let apiGatewayToken: string | null = null
  let dashboardAccessToken: string | null = null
  let dashboardRefreshToken: string | null = null
  let csrfToken: string | null = null

  const getApiGatewayUsername = (): string => apiGatewayUsername

  const getApiGatewayPassword = (): string => apiGatewayPassword

  const getApiGatewayKey = (): string => apiGatewayKey

  const getApiGatewayToken = (): string | null => apiGatewayToken || cookies.get("apiGatewayToken")

  const setApiGatewayToken = (token: string): boolean => {
    cookies.set("apiGatewayToken", token, cookiesOptions)
    apiGatewayToken = token
    return true
  }

  const getDashboardAccessToken = (): string | null => dashboardAccessToken || cookies.get("dashboardAccessToken")

  const setDashboardAccessToken = (token: string): boolean => {
    const decodedJwt: decodedJwtType = jwt_decode(token)

    const cookiesOptionsWithExpiration = {
      ...cookiesOptions,
      expires: new Date((decodedJwt.exp! - 1) * 1000), // minus 1 second for call delay
    }
    cookies.set("dashboardAccessToken", token, cookiesOptionsWithExpiration)
    dashboardAccessToken = token
    return true
  }

  const eraseDashboardAccessToken = (): boolean => {
    cookies.remove("dashboardAccessToken", cookiesOptions)
    dashboardAccessToken = null
    return true
  }

  const getDashboardRefreshToken = (): string | null => dashboardRefreshToken || cookies.get("dashboardRefreshToken")

  const setDashboardRefreshToken = (token: string): boolean => {
    cookies.set("dashboardRefreshToken", token, cookiesOptions)
    dashboardRefreshToken = token
    return true
  }

  const eraseDashboardRefreshToken = (): boolean => {
    cookies.remove("dashboardRefreshToken", cookiesOptions)
    dashboardRefreshToken = null
    return true
  }

  const getCsrfToken = (): string | null => csrfToken

  const setCsrfToken = (token: string): boolean => {
    csrfToken = token
    return true
  }

  return {
    getApiGatewayUsername,
    getApiGatewayPassword,
    getApiGatewayKey,
    getApiGatewayToken,
    setApiGatewayToken,
    getDashboardAccessToken,
    setDashboardAccessToken,
    eraseDashboardAccessToken,
    getDashboardRefreshToken,
    setDashboardRefreshToken,
    eraseDashboardRefreshToken,
    getCsrfToken,
    setCsrfToken,
  }
}

export default credentialsManager()
