import React, { FC } from "react"

type LoyaltyButtonProps = {
  buttonName: string
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

export const LoyaltyMemberButton: FC<LoyaltyButtonProps> = ({ buttonName, onClick }: LoyaltyButtonProps) => {
  return (
    <div className="w-full h-8 mx-2 px-2 py-6 text-lg cursor-pointer flex flex-col justify-center" onClick={onClick}>
      {buttonName}
    </div>
  )
}

export default LoyaltyMemberButton
