import React, { FC, CSSProperties } from "react"
import { useTranslation } from "react-i18next"
import { Field, Form, Formik, FormikValues } from "formik"
import { execute } from "../../api/api"
import credentialsManager from "../../utils/CredentialsManager"
import { setUser } from "../../store/ContributorStore"
import { LoginType, WSForgotPassword } from "../../api/LoginObject"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"
import { useHistory } from "react-router"
import { hidePartialString } from "../../utils/String"
import InputFieldLoginPassword from "../../components/general/InputFieldLoginPassword"

const Login: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  // Dynamically update browser tab title
  useTitle(`${t("login.buttons.signIn")} | ${t("applicationName")}`)

  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  const loginStyle: CSSProperties = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/login_background.jpg")`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: "center",
    backgroundSize: `cover`,
  }

  const handleSubmit = (values: FormikValues): Promise<void> => {
    addActiveCalls()
    const rememberMe: boolean = values.rememberMe
    delete values.rememberMe
    const loginWS = execute<LoginType>("login", "POST", {}, values)
    return loginWS
      .then((res: LoginType) => {
        if (res) {
          credentialsManager.setDashboardAccessToken(res.tokens.accessToken)
          if (rememberMe) {
            credentialsManager.setDashboardRefreshToken(res.tokens.refreshToken)
          }
          setUser(res.user)
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          openErrorSnackbar(t("login.errors.credentials"))
        }
      })
      .finally(subtractActiveCalls)
  }

  const handleResetPassword = (values: FormikValues) => {
    addActiveCalls()
    execute<WSForgotPassword>("forgotPassword", "POST", {}, { username: values.username })
      .then((res: WSForgotPassword) => {
        openSuccessSnackbar(t("login.snackbar.successSendResetPassword", { email: hidePartialString(res.email) }))
        history.push("/resetPassword", { username: values.username })
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) openErrorSnackbar(t("login.errors.sendPassword"))
          else openErrorSnackbar(t("login.errors.sendPassword"))
        }
      })
      .finally(subtractActiveCalls)
  }

  return (
    <div className="h-screen font-sans bg-cover" style={loginStyle}>
      <div className="container mx-auto h-full flex flex-1 justify-center items-center">
        <div className="w-full max-w-sm">
          <div className="leading-loose">
            <Formik initialValues={{ username: "", password: "", rememberMe: false }} onSubmit={handleSubmit}>
              {({ values }) => (
                <div className="m-4 p-3 bg-white rounded shadow-xl">
                  <Form>
                    <img className="m-auto" src={`${process.env.PUBLIC_URL}/images/urw_logo.png`} alt="logo" />
                    <div>
                      <Field
                        type="text"
                        id="username"
                        name="username"
                        placeholder={t("generic.forms.fields.username")}
                        className="w-full py-1 px-3 appearance-none rounded-none border-b-1 border-gray-500 focus:border-gray-700 mb-2 outline-none bg-transparent shadow-none"
                      />
                      <InputFieldLoginPassword
                        fieldData={{
                          formKey: "password",
                          label: t("generic.forms.fields.password"),
                          required: true,
                        }}
                      />
                      <div className="mt-2 items-center flex justify-between">
                        <label className="cursor-pointer">
                          <Field
                            type="checkBox"
                            id="rememberMe"
                            name="rememberMe"
                            value={t("login.buttons.rememberMe")}
                            className="w-4 h-4 mx-2 rounded-none"
                          />
                          {t("login.buttons.rememberMe")}
                        </label>
                        <button
                          className="px-3 py-1 text-white font-light tracking-wider bg-gray-900 hover:bg-gray-800 rounded"
                          type="submit"
                        >
                          {t("login.buttons.signIn")}
                        </button>
                      </div>
                    </div>
                  </Form>
                  <button
                    className="text-sm text-gray-600 hover:text-gray-900"
                    onClick={() => handleResetPassword(values)}
                  >
                    {t("login.buttons.resetPassword")}
                  </button>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
