import React, { useState, useEffect } from "react"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { Field, Form, Formik } from "formik"
import { useHistory } from "react-router-dom"
import { renderRoleLinkedContent } from "./RenderRoleLinkedContent"
import FormInputLayout from "../FormInputLayout"
import Card from "../members/Card"
import Container from "../general/Container"
import { contributorsFormFields, passwordContributorsFormFields, passwordValidations } from "./ContributorsFormConsts"
import { ContributorsFiltersTagItem } from "./ContributorsFiltersTags"
import FormActionButtons from "../general/FormActionButtons"
import InputField from "../general/InputField"
import { CenterTypeWS } from "../centers/CentersType"
import * as Yup from "yup"
import { CountriesWS } from "../../utils/Country"
import { UserRole } from "../../utils/Roles"
import { Color } from "../../utils/Constants"
import { SetFieldValueType } from "../../utils/Types"
import { useFetchRoles } from "../../utils/hooks/useFetchRoles"
import { useFetchCountries } from "../../utils/hooks/useFetchCountries"
import { ContributorFormikType } from "../../api/ContributorObject"
import InputFieldPassword from "../general/InputFieldPassword"
import { useFetchCentersInUserScope } from "../../utils/hooks/centers/useFetchCentersInUserScope"

const renderRoleFilter = (t: TFunction, roles: UserRole[], activeRole: string, onClick: (role: UserRole) => void) => (
  <Field name="role">
    {({ meta }: any) => (
      <div className="mb-10 p-2 flex flex-col">
        <div>
          {roles.map((role) =>
            ContributorsFiltersTagItem(t(role.label), activeRole === role.name, meta, () => onClick(role)),
          )}
        </div>
        {meta.touched && meta.error && <span className="text-red-500">{t(meta.error)}</span>}
      </div>
    )}
  </Field>
)

const getSpecificFieldFromRole = (t: TFunction, selectedRole: UserRole) => {
  switch (selectedRole.linkedTo) {
    case "center":
      return {
        fieldName: "assignedCenters",
        linkedContentLabel: t("contributors.forms.fields.center"),
      }

    case "centers":
      return {
        fieldName: "assignedCenters",
        linkedContentLabel: t("contributors.forms.fields.centers"),
      }

    case "countries":
      return {
        fieldName: "countries",
        linkedContentLabel: t("contributors.forms.fields.countries"),
      }
  }
}

type RoleSpecificInputProps = {
  selectedRole: UserRole
  centers: CenterTypeWS[]
  countries: CountriesWS[]
  setFieldValue: SetFieldValueType
}
const RoleSpecificInput = ({ selectedRole, centers, countries, setFieldValue }: RoleSpecificInputProps) => {
  const { t } = useTranslation()
  const specificField = getSpecificFieldFromRole(t, selectedRole)

  return specificField ? (
    <Field name={specificField.fieldName}>
      {({ field, meta }: any) => (
        <FormInputLayout required label={specificField.linkedContentLabel} meta={meta}>
          {renderRoleLinkedContent(t, selectedRole, setFieldValue, meta, field.value, centers, countries)}
        </FormInputLayout>
      )}
    </Field>
  ) : (
    <div />
  )
}

type ContributorsFormProps = {
  isCreate?: boolean
  initialValues: ContributorFormikType
  validationSchema: (selectedRole?: UserRole) => { [key: string]: any }
  onSubmit: (values: ContributorFormikType, selectedRole?: UserRole) => void
}

const ContributorsForm: React.FC<ContributorsFormProps> = ({
  isCreate,
  validationSchema,
  initialValues,
  onSubmit,
}: ContributorsFormProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const roles = useFetchRoles()
  // We filter them so that at each render, roles doesn't change and doesn't trigger again
  // the useEffect below (the one which sets the selectedRole).
  const filteredRoles = roles.filter((role) => role.name !== "ROLE_SUPER_ADMIN")
  // We need to extract the selectedRole from the form to pass it to the validation schema because there are
  // rules that depend on that field (linkedTo : property)
  const [selectedRole, setSelectedRole] = useState<UserRole>()
  const countries = useFetchCountries()
  const { activeCentersInUserScope } = useFetchCentersInUserScope()

  useEffect(() => {
    if (initialValues.role && roles.length > 0) {
      const role = roles.find((role) => role.name === initialValues.role)
      setSelectedRole(role)
    }
  }, [roles, initialValues.role])

  return (
    <>
      <div className={`w-screen h-2 ${Color.CONTRIBUTORS}`} />
      <div className="pt-6">
        <Container>
          <Card>
            <Formik<ContributorFormikType>
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape(
                isCreate
                  ? {
                      ...validationSchema(selectedRole),
                      ...passwordValidations,
                    }
                  : validationSchema(selectedRole),
              )}
              onSubmit={(values) => {
                const selectedRole = roles.find((role) => role.name === values.role)
                onSubmit(values, selectedRole)
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <Form>
                    {renderRoleFilter(t, filteredRoles, values.role, (role) => {
                      setSelectedRole(roles.find((roleWS) => roleWS.name === role.name))
                      setFieldValue("role", role.name)
                    })}

                    {contributorsFormFields.map((fieldData) => (
                      <InputField key={fieldData.formKey} fieldData={fieldData} />
                    ))}

                    {isCreate &&
                      passwordContributorsFormFields.map((fieldData) => (
                        <InputFieldPassword fieldData={fieldData} key={fieldData.formKey} />
                      ))}

                    {selectedRole?.linkedTo && (
                      <RoleSpecificInput
                        selectedRole={selectedRole}
                        centers={activeCentersInUserScope}
                        countries={countries}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <FormActionButtons cancelOnClick={() => history.push("/contributors")} />
                  </Form>
                )
              }}
            </Formik>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default ContributorsForm
