import React, { useState, useEffect } from "react"
import CheckInForm from "./CheckInForm"
import execute from "../../api/api"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { FormikValues } from "formik"
import { Checkin, CheckinTargetType, CheckinThemeType, CheckinWS } from "../../components/checkin/CheckInType"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { getDays } from "./CheckInFormConsts"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"

const CheckInEdit: React.FC = () => {
  const { t } = useTranslation()
  const { checkinId } = useParams()
  const [centerId, setCenterId] = useState(-1)
  const [data, setData] = useState<Checkin>()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  // Dynamically update browser tab title
  useTitle(`${t("menu.checkIns")} | ${t("applicationName")}`)

  useEffect(() => {
    // Les données stockées en BDD des clés, mais celles renvoyées sont les objets totaux avec plein de valeurs.
    // On cherche à ne garder que les clés pour ensuite récupérer les labels qui peuvent être traduits côté front.
    const daysOptions = getDays(t)

    addActiveCalls()
    execute<CheckinWS>(`checkins/${checkinId}`, "GET")
      .then((checkIn) => {
        const values = {
          ...checkIn,
          freqQuantity: (checkIn.useRate && parseInt(checkIn.useRate[0])) || 1,
          freqPeriod: (checkIn.useRate && checkIn.useRate[2].toLowerCase()) || "u",
          stores:
            checkIn.stores.map((store) => {
              return {
                ...store,
                key: store.storeId,
                label: store.storeName,
              }
            }) || [],
          isPremium: checkIn.isPremium || false,
          daysOfUse: daysOptions.filter((day) => checkIn.daysOfUse?.includes(day.key)),
          themes: checkIn.themes.map((theme) => {
            return {
              ...theme,
              key: theme.value,
              label: t(`checkins.forms.values.themes.${theme.value}`),
            }
          }),
          targets: checkIn.targets.map((target) => {
            return {
              ...target,
              key: target.value,
              label: t(`checkins.forms.values.targets.${target.value}`),
            }
          }),
          startDate: checkIn.startDate ? format(new Date(checkIn.startDate), "yyyy-MM-dd") : "",
          endDate: checkIn.endDate ? format(new Date(checkIn.endDate), "yyyy-MM-dd") : "",
        }
        setCenterId(checkIn.centerId)
        setData(values)
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkinId])

  if (!data) return <div />
  return (
    <CheckInForm
      title={t("checkins.forms.titles.edit")}
      initialValues={data}
      centerId={centerId}
      onSubmit={(formData: FormikValues) => {
        addActiveCalls()
        execute(
          `checkins/${checkinId}`,
          "PUT",
          {},
          {
            ...formData,
            centerId,
            daysOfUse: formData.daysOfUse.map((day: { key: number; label: string }) => day.key),
            themes: formData.themes.map((theme: CheckinThemeType) => theme.value),
            targets: formData.targets.map((target: CheckinTargetType) => target.value),
          },
        )
          .then(() => {
            openSuccessSnackbar(
              `${t("checkins.snackbar.title")} ${t("generic.snackbar.success.edit")}`,
              snackbarDuration,
            )
          })
          .catch(() => {
            openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
          })
          .finally(subtractActiveCalls)
      }}
    />
  )
}

export default CheckInEdit
