import React, { FC, useEffect, useState } from "react"
import NavBarParameters from "../NavBarParameters"
import MenuItem from "./MenuItem"
import { useLocation } from "react-router-dom"
import { useMenu } from "../../../utils/hooks/useMenu"
import execute from "../../../api/api"
import { ChangelogType } from "../../changelog/ChangelogTypes"

const NavBarDesktop: FC = () => {
  const location = useLocation()
  const permittedMenu = useMenu()
  const [lastChangeLog, setLastChangeLog] = useState<ChangelogType[]>([])

  let savedLastChangeLog = localStorage.getItem("last_changelog")
  savedLastChangeLog = savedLastChangeLog && JSON.parse(savedLastChangeLog)

  useEffect(() => {
    execute<ChangelogType[]>(`changeLogs`, "GET", {}, {}, { onlyLastChangelogs: true }).then(
      (changelogs: ChangelogType[]) => {
        setLastChangeLog(changelogs)
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <nav className="bg-blueGray-700 w-full mx-auto px-4 sm:px-6 lg:px-8 xl:px-2 flex items-center">
      <div>
        {permittedMenu.map((menuItem) => (
          <MenuItem
            menuItem={menuItem}
            isSelected={location.pathname.includes(menuItem.basePath ? menuItem.basePath : menuItem.path)}
            key={`navItem${menuItem.label}`}
            badgeDisplay={
              menuItem.path === "/changelog"
                ? lastChangeLog.filter(
                    (changeLog) => changeLog?.id && !savedLastChangeLog?.includes(changeLog.id.toString()),
                  ).length
                : 0
            }
          />
        ))}
      </div>
      <div className="flex flex-row-reverse flex-grow">
        <NavBarParameters />
      </div>
    </nav>
  )
}

export default NavBarDesktop
