import { Form } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import FormActionButtons from "../general/FormActionButtons"
import Header from "../Header"

type ClaimCancelValidationProps = {
  closeCancelModal: () => void
  closeModal: () => void
}

const ClaimCancelValidation: React.FC<ClaimCancelValidationProps> = ({
  closeCancelModal,
  closeModal,
}: ClaimCancelValidationProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Header>{t("claim.titles.cancel")}</Header>
      <hr />
      <Form>
        <FormActionButtons
          cancelOnClick={closeCancelModal}
          validateOnClick={() => {
            closeCancelModal()
            closeModal()
          }}
          validateButtonLabel="generic.forms.fields.yes"
          cancelButtonLabel="generic.forms.fields.no"
        />
      </Form>
    </>
  )
}

export default ClaimCancelValidation
