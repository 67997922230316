import * as Yup from "yup"
import { getFormikApisValues } from "../../../utils/ApiKeys"

export const apiKeysInitialValues = {
  name: "",
  apikeyValue: null,
  apiAccess: getFormikApisValues(),
}

export const apiKeysValidation = Yup.object().shape({
  name: Yup.string().required("generic.forms.errors.required"),
  apikeyValue: Yup.string()
    .min(20, "generic.forms.errors.apiKeyLength")
    .max(128, "generic.forms.errors.apiKeyLength")
    .nullable(),
})
