import * as React from "react"
import { useTranslation } from "react-i18next"
import { Field } from "formik"
import FormInputLayout from "../../FormInputLayout"
import { genderFormFields } from "./MembersFormConsts"
import RadioOption from "../../general/RadioOption"
import { SetFieldValueType } from "../../../utils/Types"

type GenderFieldProps = {
  setFieldValue: SetFieldValueType
  selectedGender: string
  isUKMember: boolean
}
export const GenderField: React.FC<GenderFieldProps> = ({
  setFieldValue,
  selectedGender,
  isUKMember,
}: GenderFieldProps) => {
  const { t } = useTranslation()
  return (
    <>
      {!isUKMember ? (
        <Field name="gender">
          {({ meta }: any) => (
            <FormInputLayout label="generic.forms.fields.gender" required meta={meta}>
              <div className="h-10 w-full flex flex-row space-x-4">
                {genderFormFields.map((option) => (
                  <RadioOption
                    key={option.key}
                    label={t(option.label)}
                    isActive={option.key === selectedGender}
                    onClick={() => setFieldValue("gender", option.key)}
                    meta={meta}
                  />
                ))}
              </div>
            </FormInputLayout>
          )}
        </Field>
      ) : (
        <FormInputLayout label="generic.forms.fields.gender" key="gender_value">
          <Field name="gender">{({ field }: any) => <p>{field.value}</p>}</Field>
        </FormInputLayout>
      )}
    </>
  )
}
