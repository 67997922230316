import React, { useEffect, useState } from "react"
import { cockpitColumnsHeaderLabels, initialCockpitTotal } from "./CockpitConstant"
import { useTranslation } from "react-i18next"
import { execute } from "../../api/api"
import { ClassicCockpitObjectiveType, CockpitCenterObjectiveType, CockpitCountryObjectiveType } from "./CockpitType"
import { Color } from "../../utils/Constants"
import { ContributorType } from "../../api/ContributorObject"
import { useStore } from "effector-react"
import { FilterStore } from "../../store/FilterStore"
import { Cell, CenterLine, CountryLine, TableLineType, TotalLine } from "../../components/dashboard/CockpitTable"
import BreadcrumbCountries from "../../components/dashboard/BreadcrumbCountries"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { UserInitial } from "../../store/ContributorStore"
import CockpitDateFilter from "./CockpitDateFilter"
import Restricted from "../../components/Restricted"
import { useFetchAllCenters } from "../../utils/hooks/centers/useFetchAllCenters"

const getTotal = (centersByCountry: CockpitCountryObjectiveType<ClassicCockpitObjectiveType>[]) => {
  return centersByCountry.reduce(
    (acc: ClassicCockpitObjectiveType & { periodCustomers: number }, curr) => {
      Object.keys(acc).forEach(
        (key) => (acc[key as keyof ClassicCockpitObjectiveType] += curr[key as keyof ClassicCockpitObjectiveType]),
      )
      return acc
    },
    { ...initialCockpitTotal, periodCustomers: 0 },
  )
}

const toggleFavoriteCenter = (userId: number, centerId: number, isFavorite: boolean, refresh: () => void) => {
  const method = isFavorite ? "DELETE" : "POST"
  execute<ContributorType>(`profile/favoriteCenter/${centerId}`, method).then(refresh)
}

const Cockpit: React.FC = () => {
  const { t } = useTranslation()
  const [centersByCountries, setCentersByCountries] = useState<
    CockpitCountryObjectiveType<ClassicCockpitObjectiveType>[]
  >([])
  const [me, setMe] = useState<ContributorType>(UserInitial)
  const [favoriteCenters, setFavoriteCenters] = useState<CockpitCenterObjectiveType<ClassicCockpitObjectiveType>[]>([])
  const [total, setTotal] = useState<ClassicCockpitObjectiveType>()
  const [refresh, setRefresh] = useState(0)
  const { allActiveCentersByCountry } = useFetchAllCenters()
  const filters = useStore(FilterStore)

  useEffect(() => {
    addActiveCalls()
    execute<CockpitCountryObjectiveType<ClassicCockpitObjectiveType>[]>(
      `cockpit`,
      "GET",
      {},
      {},
      {
        filters: {
          bool: {
            must: [
              {
                range: {
                  centerAssociationCreatedAt: {
                    gte: filters.date.fromDateFilter,
                    lte: filters.date.toDateFilter,
                  },
                },
              },
            ],
            mustNot: [],
            should: [],
          },
        },
      },
    )
      .then((cockpitCountries) => {
        if (cockpitCountries) {
          setCentersByCountries(
            cockpitCountries.sort((a, b) => {
              return t(`country.${a.country}`).localeCompare(t(`country.${b.country}`))
            }),
          )
          setTotal(getTotal(cockpitCountries))
        }
      })
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.date.fromDateFilter, filters.date.toDateFilter, refresh])

  useEffect(() => {
    if (setCentersByCountries.length > 0) {
      addActiveCalls()
      execute<ContributorType>("profile", "GET")
        .then((me) => {
          setMe(me)
          const favoriteCenters: CockpitCenterObjectiveType<ClassicCockpitObjectiveType>[] = []
          centersByCountries.forEach((centersByCountry) => {
            centersByCountry.centers.forEach((center) => {
              if (me.favoriteCenters.some((favoriteCenter) => favoriteCenter.id === center.id)) {
                favoriteCenters.push(center)
              }
            })
          })
          setFavoriteCenters(favoriteCenters)
        })
        .finally(subtractActiveCalls)
    }
  }, [centersByCountries, refresh])

  return (
    <>
      <div className={`w-full h-2 ${Color.DASHBOARD}`} />
      <div className="flex-auto h-full flex flex-col">
        <Restricted right="dashboard.read">
          <BreadcrumbCountries countries={allActiveCentersByCountry} />
        </Restricted>
        <CockpitDateFilter />
        {centersByCountries.length > 0 && (
          <div className="flex-auto overflow-y-auto relative mx-3 my-3 bg-white">
            <table className="table-auto table-striped relative bg-white rounded shadow-xl">
              <thead className="sticky top-0 text-center text-blueGray-700 font-bold tracking-wider ">
                {[...Array(7)].map((cell, index) => {
                  if (index === 4) {
                    return (
                      <th className="bg-blueGray-900 text-white font-bold" colSpan={4}>
                        {t("dashboard.table.onlyLoyaltyMembers")}
                      </th>
                    )
                  }
                  return <th key={index} className={"bg-white"} />
                })}
                <tr className="bg-white">
                  {cockpitColumnsHeaderLabels.map((cockpitColumnHeaderLabel, index) => (
                    <Cell
                      key={cockpitColumnHeaderLabel}
                      value={t(cockpitColumnHeaderLabel) as string}
                      index={index}
                      type={TableLineType.HEADER}
                    />
                  ))}
                </tr>
                {total && <TotalLine total={total} />}
              </thead>
              <tbody>
                {favoriteCenters.length > 0 && (
                  <>
                    <tr className="bg-blueGray-900 text-white font-bold">
                      <Cell value={t("dashboard.table.favoriteCenters") as string} type={TableLineType.SEPARATOR} />
                    </tr>
                    {favoriteCenters.map((center, lineIndex) => (
                      <CenterLine
                        key={`favorite_${center.id}`}
                        center={center}
                        lineIndex={lineIndex}
                        isFavorite
                        onFavoriteIconClick={(isFavorite) =>
                          toggleFavoriteCenter(me.id, center.id, isFavorite, () => setRefresh(refresh + 1))
                        }
                      />
                    ))}
                  </>
                )}
                <tr className="bg-blueGray-900 text-white font-bold">
                  <Cell value={t("dashboard.table.allCenters") as string} type={TableLineType.SEPARATOR} />
                </tr>
                {centersByCountries.map((centersByCountry) => (
                  <React.Fragment key={centersByCountry.country}>
                    <CountryLine centersByCountry={centersByCountry} />
                    {centersByCountry.centers.map((center, lineIndex) => (
                      <CenterLine
                        key={center.id}
                        center={center}
                        lineIndex={lineIndex}
                        isFavorite={favoriteCenters.some((favoriteCenter) => favoriteCenter.id === center.id)}
                        onFavoriteIconClick={(isFavorite) =>
                          toggleFavoriteCenter(me.id, center.id, isFavorite, () => setRefresh(refresh + 1))
                        }
                      />
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}

export default Cockpit
