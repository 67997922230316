import React, { FC } from "react"
import FormSubmitButton, { FormCancelButton } from "../profile/FormSubmitButton"
import { useTranslation } from "react-i18next"
import { ButtonType } from "./Button"

type FormActionButtonsProps = {
  cancelOnClick?: () => void
  validateOnClick?: () => void
  validateButtonLabel?: string
  cancelButtonLabel?: string
}

const FormActionButtons: FC<FormActionButtonsProps> = ({
  cancelOnClick,
  validateOnClick,
  validateButtonLabel,
  cancelButtonLabel,
}: FormActionButtonsProps) => {
  const { t } = useTranslation()

  return (
    <div className="mt-1 md:mt-4 flex flex-row justify-center">
      {cancelOnClick && (
        <div className="md:px-6 mr-1">
          <FormCancelButton onClick={cancelOnClick}>
            {t(cancelButtonLabel || "generic.buttons.cancel")}
          </FormCancelButton>
        </div>
      )}
      <div className="md:px-6 ml-1">
        <FormSubmitButton type={cancelOnClick ? ButtonType.SUCCESS : ButtonType.PRIMARY} onClick={validateOnClick}>
          {t(validateButtonLabel || "generic.buttons.confirm")}
        </FormSubmitButton>
      </div>
    </div>
  )
}

export default FormActionButtons
