export const successSnackbarStyle = {
  backgroundColor: "#3A9960",
  border: "2px solid #17BF57",
  color: "#eee",
  fontSize: "1rem",
  textAlign: "center",
}

export const errorSnackbarStyle = {
  backgroundColor: "#B61827",
  border: "2px solid #EF5350",
  color: "#eee",
  fontSize: "1rem",
  textAlign: "center",
}

export const snackbarDuration = 3000

export const snackbarErrorMessage = "generic.snackbar.error.unknown"
