import React, { useEffect, useState } from "react"
import LineCharts from "./LineCharts"
import { useTranslation } from "react-i18next"
import { EmailWS, HighChartEmailSeries } from "./EmailValidityConstant"
import { TFunction } from "i18next"
import { useStore } from "effector-react"
import execute from "../../../api/api"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { ColorGraphs } from "../../../utils/Constants"

const POINT_INTERVAL = 24 * 3600 * 1000 //One day

const xAxis = {
  type: "datetime",
  labels: {
    format: "{value:%e %b}",
    align: "center",
  },
}

const tooltip = {
  shared: true,
  crosshairs: true,
  xDateFormat: "%A, %b %e, %Y",
  valueDecimals: 2,
  valueSuffix: "%",
}

const getSerie = (t: TFunction, series: EmailWS[]) => {
  let apps: number[][] = []
  let web: number[][] = []
  let onSite: number[][] = []
  let partner: number[][] = []
  let wifi: number[][] = []
  let directories: number[][] = []

  const test = series[0] ? series[0].date : new Date().getTime()
  const pointStart = new Date(test).getTime()

  series.map((obj) => {
    const objDate = new Date().setTime(parseInt(obj.date))
    apps = [...apps, [objDate, obj.mobile.percent]]
    web = [...web, [objDate, obj.web.percent]]
    onSite = [...onSite, [objDate, obj.onSite.percent]]
    partner = [...partner, [objDate, obj.Partner.percent]]
    wifi = [...wifi, [objDate, obj.Wifi.percent]]
    directories = [...directories, [objDate, obj.Directories.percent]]
    return obj
  })

  return [
    {
      name: t("dashboard.line.email.onSite"),
      color: ColorGraphs.red,
      marker: {
        enabled: false,
      },
      pointInterval: POINT_INTERVAL,
      pointStart: pointStart,
      data: onSite,
    },
    {
      name: t("dashboard.line.email.web"),
      color: ColorGraphs.blue,
      marker: {
        enabled: false,
      },
      pointInterval: POINT_INTERVAL,
      pointStart: pointStart,
      data: web,
    },
    {
      name: t("dashboard.line.email.mobileApp"),
      color: ColorGraphs.green,
      marker: {
        enabled: false,
      },
      pointInterval: POINT_INTERVAL,
      pointStart: pointStart,
      data: apps,
    },
    {
      name: t("dashboard.line.email.wifi"),
      color: ColorGraphs.purple,
      marker: {
        enabled: false,
      },
      pointInterval: POINT_INTERVAL,
      pointStart: pointStart,
      data: wifi,
    },
    {
      name: t("dashboard.line.email.partner"),
      color: ColorGraphs.orange,
      marker: {
        enabled: false,
      },
      pointInterval: POINT_INTERVAL,
      pointStart: pointStart,
      data: partner,
    },
    {
      name: t("dashboard.line.email.directories"),
      color: ColorGraphs.teal,
      marker: {
        enabled: false,
      },
      pointInterval: POINT_INTERVAL,
      pointStart: pointStart,
      data: directories,
    },
  ]
}

type EmailValidityLineChartProps = {
  id: string
}
const EmailValidityLineChart: React.FC<EmailValidityLineChartProps> = (props: EmailValidityLineChartProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [series, setSeries] = useState<HighChartEmailSeries[]>([])

  const storeFilters = useStore(FilterStore)
  const yAxis = {
    title: {
      text: t("dashboard.line.objective.yAxis"),
    },
    lineWidth: 1,
    labels: {
      x: -10,
      format: "{value}%",
    },
    max: 100,
  }

  useEffect(() => {
    setLoading(true)
    execute<EmailWS[]>(
      `dashboard/${id}/widget/emailEvolution`,
      "GET",
      {},
      {},
      { filters: getDashboardFilters(storeFilters) },
    )
      .then((res) => {
        setSeries(getSerie(t, res))
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters, id])

  return (
    <LineCharts
      title={t("dashboard.line.email.title")}
      series={series}
      xAxis={xAxis}
      yAxis={yAxis}
      tooltips={tooltip}
      loading={loading}
    />
  )
}

export default EmailValidityLineChart
