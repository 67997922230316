import React, { FC } from "react"
import { numberWithThousandsSeparator } from "../../../utils/Functions"
import { Color } from "../../../utils/Constants"
import { TDetailsProps } from "../TlpOffersModal"

const PointDetails: FC<TDetailsProps> = (props: TDetailsProps) => {
  const { offer, offerInformations, progressPercent, t } = props

  return (
    <div className="flex flex-row border-b border-blueGray-200">
      <div className="flex-grow p-3 text-center border-r border-blueGray-200 w-1/2">
        <h3 className="text-xl p-3">{t("tlpOffers.fields.distributedPoints")}</h3>
        <h2 className="text-3xl p-3">{`${numberWithThousandsSeparator(offerInformations?.totalAmountRewarded || 0)} ${t(
          "tlpOffers.fields.points",
        )}`}</h2>
      </div>
      <div className="w-1/2 flex p-3">
        <div className="flex m-auto">
          {offer.maxAttribution > 0 && (
            <div className="w-10 bg-gray-100 relative overflow-hidden rounded-md mr-5">
              <div
                style={{ height: `${progressPercent}%` }}
                className={`w-full ${Color.TLPOFFERS} absolute bottom-0`}
              />
            </div>
          )}
          <div className=" text-center">
            <h3 className="text-xl p-3">{t("tlpOffers.fields.attributedOffers")}</h3>
            <h2 className="text-3xl p-3">
              {offer.maxAttribution > 0
                ? `${offerInformations?.actualAttribution} / ${offer.maxAttribution}`
                : offerInformations?.actualAttribution}
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PointDetails
