import React, { useEffect, useState } from "react"
import execute from "../../api/api"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import { CheckInItem, CheckinWS } from "../../components/checkin/CheckInType"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { Color } from "../../utils/Constants"
import Container from "../../components/general/Container"
import Card from "../../components/members/Card"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import CheckinsBarcode from "../../components/checkin/CheckinsBarcode"
import CheckinInformations from "../../components/checkin/CheckinInformations"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"

const CheckInUse: React.FC = () => {
  const { t } = useTranslation()
  const { checkinId } = useParams()
  const location = useLocation()
  const [checkin, setCheckin] = useState<CheckinWS>()
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const locationState = location.state as { checkinItem: CheckInItem; type: "checkins" | "bookingbug" }
  // Les données stockées en BDD des clés, mais celles renvoyées sont les objets totaux avec plein de valeurs.
  // On cherche à ne garder que les clés pour ensuite récupérer les labels qui peuvent être traduits côté front.

  // Dynamically update browser tab title
  useTitle(`${t("menu.checkIns")} | ${t("applicationName")}`)

  useEffect(() => {
    if (locationState.type === "checkins") {
      addActiveCalls()
      execute<CheckinWS>(`checkins/${checkinId}`, "GET")
        .then((checkIn) => setCheckin(checkIn))
        .catch(() => {
          openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
        })
        .finally(subtractActiveCalls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkinId])

  return (
    <>
      <div className={`w-full h-2 ${Color.CHECKINS}`} />
      <div className="pt-6 pb-6">
        <Container>
          <Card>
            <h3 className="mb-4">{checkin?.name}</h3>
            {checkin && (
              <>
                <CheckinInformations checkin={checkin} />
                <hr className="my-8" />{" "}
              </>
            )}

            <CheckinsBarcode
              checkinId={checkinId}
              checkin={checkin}
              type={locationState.type}
              checkingBookingBug={locationState.checkinItem}
            />
          </Card>
        </Container>
      </div>
    </>
  )
}

export default CheckInUse
