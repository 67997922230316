import React, { FC, useState } from "react"
import { Field } from "formik"
import FormInputLayout from "../FormInputLayout"

type InputFieldProps = {
  fieldData: {
    formKey: string
    label: string
    required: boolean
  }
}

const InputFieldLoginPassword: FC<InputFieldProps> = ({ fieldData }: InputFieldProps) => {
  const [show, setShow] = useState(false)
  const togglePasswordVisiblity = () => {
    setShow((prev) => !prev)
  }

  return (
    <Field key={fieldData.formKey} name={fieldData.formKey}>
      {({ field, meta }: any) => (
        <FormInputLayout required={fieldData.required} meta={meta} large>
          <input
            className={
              "w-full px-3 appearance-none rounded-none border-b-1 border-gray-500 focus:border-gray-700 outline-none bg-transparent shadow-none" +
              (meta && meta.touched && meta.error ? " border-red-500 text-red-700" : " border-gray-200 text-gray-700")
            }
            type={show ? "text" : "password"}
            {...field}
            placeholder={fieldData.label}
          />
          <i className={`${show ? "icon-eye-off" : "icon-eye"} cursor-pointer`} onClick={togglePasswordVisiblity} />
        </FormInputLayout>
      )}
    </Field>
  )
}

export default InputFieldLoginPassword
