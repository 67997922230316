import React, { useState, useEffect } from "react"
import Search from "../../general/Search"
import TableComponent, { TableCell, TableRow } from "../../TableComponent"
import { apisInformation } from "../ApiGatewayConsts"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import execute from "../../../api/api"
import { ContributorStore } from "../../../store/ContributorStore"
import { ApiKeysTypeWS } from "./ApiKeysTypes"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import ManageLevelsModal from "./ManageLevelsModal"
import ApiKeyCreate from "./ApiKeyCreate"
import { IconWithConfirmation } from "../../modals/IconConfirmationModal"
import { useStore } from "effector-react"
import { ApiKeysStore, setApiKeys } from "./ApiKeysStore"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import Chip from "../../Chip"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"

const ApiKeysTab: React.FC = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>("")
  const { apiKeys } = useStore(ApiKeysStore)
  const [refresh, setRefresh] = useState(0)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const { id, email, assignedCenters } = useStore(ContributorStore)
  const cols = (t: TFunction) => [
    { label: t("generic.table.name"), columnSpecifications: "w-1/6" },
    { label: t("apiGateway.table.apikey") },
    { label: t("apiGateway.table.apiId") },
    { label: t("apiGateway.table.levels") },
    {
      label: t("generic.table.actions"),
      columnSpecifications: "text-center",
    },
  ]

  useEffect(() => {
    addActiveCalls()
    execute<ApiKeysTypeWS[]>("apiGateway/keys", "GET")
      .then((res) => setApiKeys(res))
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  const deleteApiKey = (apiKeyId: string) => {
    addActiveCalls()
    execute(`apiGateway/keys/${apiKeyId}`, "DELETE")
      .then(() => {
        execute<void>(
          "fid",
          "POST",
          {},
          {
            centerId: assignedCenters[0]?.id,
            code: "ApiKeyDeletion_Dashboard'",
            content: "Deleting ApiKey from dashboard",
            application: "dashboard",
            email: email,
            customerId: id,
          },
          {},
          "logger",
        )
        openSuccessSnackbar(`${t("apiGateway.titles.apiKey")} ${t("generic.snackbar.success.delete")}`)
        setRefresh(refresh + 1)
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
  }

  return (
    <div className="max-h-full flex flex-col items-center flex-grow-1 overflow-y-auto">
      <div className="p-8 w-full">
        <div className="flex flex-col md:flex-row items-center mb-4 gap-4">
          <ApiKeyCreate refresh={() => setRefresh(refresh + 1)} />
          <Search
            search={search}
            customStyle="md:w-auto flex-1"
            placeHolder={t("generic.forms.fields.searchPlaceholder")}
            onChangeAction={(event: any) => {
              setSearch(event.target.value?.toLowerCase() || "")
            }}
          />
        </div>
        <div>
          <TableComponent columnNames={cols(t)}>
            {apiKeys
              .filter((item) => item.name?.toLowerCase().includes(search) || item.value?.toLowerCase().includes(search))
              .map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.value}</TableCell>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>
                      <div className="flex flex-wrap content-start">
                        {Object.entries(item.apiAccess!).map(([api, level]) => {
                          // Retrieves api info (label + color)
                          const keyData = apisInformation[api as keyof typeof apisInformation]
                          const label = api === "partner" ? keyData.label + ` level${level}` : keyData.label
                          return level > 0 ? (
                            <Chip key={keyData.label} title={api} label={label} color={keyData.color} />
                          ) : null
                        })}
                      </div>
                    </TableCell>
                    <TableCell centered>
                      <ManageLevelsModal apiKey={item} refresh={() => setRefresh(refresh + 1)} />
                      <IconWithConfirmation
                        customStyle="text-3xl"
                        onConfirmationClick={() => {
                          deleteApiKey(item.id)
                        }}
                        icon="icon-delete"
                        title={t("generic.buttons.delete")}
                        description={t("modal.delete.description")}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableComponent>
        </div>
      </div>
    </div>
  )
}

export default ApiKeysTab
