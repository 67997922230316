import React, { useState } from "react"
import FormSubmitButton from "../profile/FormSubmitButton"
import { useTranslation } from "react-i18next"
import { IconModalValidation } from "./IconModalValidation"

type IconWithConfirmationProps = {
  iconColor?: string
  customStyle?: string
  onConfirmationClick: () => void
  description: string
  title?: string
  icon: string
}
export const IconWithConfirmation: React.FC<IconWithConfirmationProps> = ({
  iconColor = "text-red-500",
  customStyle,
  onConfirmationClick,
  description,
  title,
  icon,
}: IconWithConfirmationProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <IconModalValidation
      open={open}
      setOpen={setOpen}
      title={title}
      icon={icon}
      iconTitle={title}
      iconStyle={`${iconColor} ${customStyle}`}
      buttons={
        <FormSubmitButton
          onClick={() => {
            onConfirmationClick()
            setOpen(false)
          }}
          customStyle="ml-2 mt-2"
        >
          {t("modal.delete.validate")}
        </FormSubmitButton>
      }
    >
      <p className="mt-2 mb-8 text-center">{description}</p>
    </IconModalValidation>
  )
}
