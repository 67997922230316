import * as React from "react"
import Chip from "../Chip"
import { Color } from "../../utils/Constants"
import { ContributorType } from "../../api/ContributorObject"
import { useTranslation } from "react-i18next"
import Restricted from "../Restricted"
import { ActionIcon } from "../ActionIcon"
import { FC } from "react"
import { TFunction } from "i18next"
import { IconWithConfirmation } from "../../components/modals/IconConfirmationModal"

type ContributorCellProps = {
  label: string
  className?: string
}
export const ContributorCell: FC<ContributorCellProps> = (props: ContributorCellProps) => {
  const { label, className } = props

  return (
    <td className={`border-t border-gray-200 ${className || ""}`}>
      <span title={label} className="px-6 py-2 w-full items-center inline-block truncate cursor-default">
        {label}
      </span>
    </td>
  )
}

type ContributorsCountryTagsProps = {
  contributor: ContributorType
}

export function getArrayUniqueValueCountry(element: ContributorType, t: TFunction): Array<string> {
  const arrayCompleteOne = element.countries.map((country) => {
    return t(`country.${country.code}`)
  })
  const arrayCompleteTwo = element.assignedCenters.map((center) => {
    return t(`country.${center.countryCode}`)
  })

  return Array.from(new Set([...arrayCompleteTwo, ...arrayCompleteOne]).values())
}

export const ContributorsCountryTags: FC<ContributorsCountryTagsProps> = (props: ContributorsCountryTagsProps) => {
  const { contributor } = props
  const { t } = useTranslation()

  return (
    <td className="border-t border-gray-200 text-left hidden xl:table-cell">
      {getArrayUniqueValueCountry(contributor, t)
        .sort()
        .map((country) => (
          <Chip key={`${contributor.id}-country-${country}`} label={country} color={Color.CONTRIBUTORS} />
        ))}
    </td>
  )
}

type ContributorsCentersTagsProps = {
  contributor: ContributorType
  countryToFilter: string
}
export const ContributorsCentersTags: FC<ContributorsCentersTagsProps> = (props: ContributorsCentersTagsProps) => {
  const { contributor, countryToFilter } = props
  const { t } = useTranslation()

  return (
    <td className="border-t border-gray-200 text-left hidden xl:table-cell">
      {contributor.assignedCenters
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((center) => !countryToFilter.length || countryToFilter === t(`country.${center.countryCode}`))
        .map((center, index) => (
          <Chip key={`${contributor.id}-centers-${index}`} label={center.name} color={Color.CONTRIBUTORS} />
        ))}
    </td>
  )
}

type ContributorsActionsProps = {
  contributor: ContributorType
  actionsDropdown: {
    title?: string
    icon: string
    iconColor?: string
    right: string
    action: () => void
  }[]
}
export const ContributorsActions: FC<ContributorsActionsProps> = (props: ContributorsActionsProps) => {
  const { actionsDropdown } = props
  const { t } = useTranslation()

  return (
    <div className="text-2xl px-1 py-2 flex flex-col md:flex-row md:items-center md:justify-center">
      {actionsDropdown.map((action, index) => (
        <Restricted key={`action-${index}`} right={action.right}>
          <div className="hidden md:table-cell">
            {action.icon === "icon-delete" ? (
              <IconWithConfirmation
                icon={action.icon}
                description={t("contributors.modal.confirmation.description")}
                iconColor={action.iconColor}
                title={action.title}
                onConfirmationClick={() => action.action()}
              />
            ) : (
              <ActionIcon
                icon={action.icon}
                iconColor={action.iconColor}
                title={action.title}
                onClick={() => action.action()}
              />
            )}
          </div>
        </Restricted>
      ))}
    </div>
  )
}
