import { getButton, myButtons } from "../Utils"
import { Field, Form, Formik } from "formik"
import execute from "../../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import FormActionButtons from "../../general/FormActionButtons"
import React, { Dispatch, FC, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import CustomModal from "../../CustomModal"
import Header from "../../Header"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"

type ChartCommentWS = {
  chartComment: string
}

type CustomersStockDirectiveActionsProps = {
  id: string
  clickFunction: (label: string) => void
  dateSelector: string
  setDateSelector: Dispatch<SetStateAction<string>>
}

const CustomersStockDirectiveActions: FC<CustomersStockDirectiveActionsProps> = (
  props: CustomersStockDirectiveActionsProps,
) => {
  const { clickFunction, dateSelector, setDateSelector, id } = props
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [chartComment, setChartComment] = useState("")
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  return (
    <div className="flex flex-row justify-end items-center mr-4">
      {myButtons(clickFunction, setDateSelector, t).map((item) =>
        getButton(item.click, item.label, item.key === dateSelector),
      )}

      {id !== "all" && (
        <i
          className="icon-edit cursor-pointer ml-1 text-xl"
          title={t("dashboard.fields.cardOwner.chartComment.icon")}
          onClick={() => setOpen(true)}
        />
      )}
      <CustomModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        onAfterOpen={() => {
          addActiveCalls()
          execute<ChartCommentWS>(`dashboard/${id}/chartComment`, "GET")
            .then((res) => {
              setChartComment(res.chartComment)
            })
            .catch(() => {
              openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
              setOpen(false)
            })
            .finally(subtractActiveCalls)
        }}
      >
        <Formik
          initialValues={{ message: chartComment }}
          enableReinitialize
          onSubmit={(values) => {
            execute(`dashboard/${id}/chartComment`, "POST", {}, values)
              .then(() => {
                openSuccessSnackbar(
                  `${t("dashboard.fields.cardOwner.chartComment.title")} ${t("generic.snackbar.success.edit")}`,
                  snackbarDuration,
                )
                setOpen(false)
              })
              .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
          }}
        >
          <Form className="h-full flex flex-col justify-between">
            <div>
              <Header>{t("dashboard.fields.cardOwner.chartComment.title")}</Header>
              <Field name="message" type="textarea">
                {({ field }: any) => (
                  <textarea
                    className="h-64 autoexpand tracking-wide py-2 px-4 mb-3 leading-relaxed appearance-none block w-full
                                  bg-gray-200 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                    {...field}
                  />
                )}
              </Field>
            </div>

            <FormActionButtons cancelOnClick={() => setOpen(false)} />
          </Form>
        </Formik>
      </CustomModal>
    </div>
  )
}

export default CustomersStockDirectiveActions
