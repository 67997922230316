import * as Yup from "yup"
import { phoneNumberRegex } from "../../utils/Regex"
import { ClaimType } from "./ClaimType"

export const TicketsType = {
  ACCOUNT: "account",
  POINTS: "points",
  CASHBACK: "cashback",
  SCAN_ISSUE: "scanIssue",
  TECHNICAL_ISSUE: "technicalIssue",
  OTHER: "other",
}

export const types = [
  { value: TicketsType.ACCOUNT, label: "claim.forms.values.account" },
  { value: TicketsType.POINTS, label: "claim.forms.values.points" },
  { value: TicketsType.CASHBACK, label: "claim.forms.values.cashback" },
  { value: TicketsType.SCAN_ISSUE, label: "claim.forms.values.scanIssue" },
  { value: TicketsType.TECHNICAL_ISSUE, label: "claim.forms.values.technicalIssue" },
  { value: TicketsType.OTHER, label: "claim.forms.values.other" },
]

export const initialValues: ClaimType = {
  scope: TicketsType.ACCOUNT,
  message: "",
}

export const claimFormValidationSchema = Yup.object().shape({
  scope: Yup.string().required("generic.forms.errors.required"),
  message: Yup.string().required("generic.forms.errors.required"),
  phone: Yup.string().matches(phoneNumberRegex, "generic.forms.errors.invalidPhone"),
})
