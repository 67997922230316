import React from "react"

type InputProps = {
  placeholder: string
  border?: boolean
  value?: string
  onChange: (event: any) => void
}

const Input: React.FC<InputProps> = ({ placeholder, border, value, onChange }: InputProps) => {
  return (
    <input
      type="search"
      value={value}
      placeholder={placeholder}
      className={
        "bg-white h-10 pl-4 pr-2 text-sm focus:outline-none truncate rounded border-black w-full" +
        (border ? " border-1 border-black rounded" : "")
      }
      onChange={onChange}
    />
  )
}

export default Input
