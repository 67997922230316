import { Color } from "./Constants"

const colors = [
  Color.CHANGELOG,
  Color.APIGATEWAY,
  Color.CENTERS,
  Color.CHECKINS,
  Color.CIA,
  Color.COMPANIES,
  Color.CONTRIBUTORS,
  Color.DASHBOARD,
  Color.MEMBERS,
  Color.PREMIUM,
  Color.PROFILE,
  Color.RIGHTS,
]

// On récupère une couleur au hasard pour les badges des changelogs (les roles n'ont pas de couleur associée donc on leur en donne une de cette manière)
export const getColorByLabel = (label: string): string => colors[label.length % colors.length]
