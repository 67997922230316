import React, { FC, ReactNode } from "react"
import { useTranslation } from "react-i18next"

export type ColumnType = {
  label: string
  columnSpecifications?: string
}

type TableComponentProps = {
  columnNames: ColumnType[]
  children: ReactNode
}

type TableRowProps = {
  onClick?: () => void
  children: ReactNode
}

type TableCellProps = {
  styleProp?: string
  cellProp?: string
  centered?: boolean
  displayCol?: boolean
  children: ReactNode
}

export const TableCell: FC<TableCellProps> = ({
  styleProp = "",
  cellProp = "",
  displayCol,
  centered,
  children,
}: TableCellProps) => (
  <td className={`border-gray-200 px-6 py-3 ${cellProp}`}>
    <div
      className={`flex ${displayCol ? "flex-col" : "flex-row"} ${
        centered ? "justify-center text-center" : "text-left"
      } ${styleProp}`}
    >
      {children}
    </div>
  </td>
)

export const TableRow: FC<TableRowProps> = ({ onClick, children }: TableRowProps) => {
  let style = "border-t-1 hover:bg-gray-300 hover:bg-opacity-30"
  if (onClick) {
    style += " cursor-pointer hover:bg-blueGray-200"
  }
  return (
    <tr className={style} onClick={onClick}>
      {children}
    </tr>
  )
}

const TableComponent: FC<TableComponentProps> = ({ columnNames, children }: TableComponentProps) => {
  const { t } = useTranslation()
  return (
    <div className="max-h-4/5">
      <table className="table-auto table-striped relative bg-white rounded shadow-md w-full h-full">
        <thead className="text-left text-white font-bold tracking-wider">
          <tr>
            {columnNames.map((column, index) => (
              <th
                key={index}
                className={`px-6 py-3 sticky bg-blueGray-700 ${column.columnSpecifications || ""}`}
                style={{ top: 0 }}
              >
                {t(column.label)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-700">{children}</tbody>
      </table>
    </div>
  )
}

export default TableComponent
