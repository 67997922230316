import * as React from "react"

type HeaderProps = {
  children: React.ReactNode
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { children } = props

  return (
    <h1 className="w-full my-2 text-xl md:text-3xl leading-tight font-bold text-gray-700 my-2 md:my-5 text-center">
      {children}
    </h1>
  )
}

export default Header
