import React from "react"
import { useTranslation } from "react-i18next"

type FormInputLayoutProps = {
  label?: string
  required?: boolean
  meta?: any
  largeLeft?: boolean
  largeRight?: boolean
  inline?: boolean
  children: React.ReactNode
}

const FormInputInline: React.FC<FormInputLayoutProps> = ({
  label,
  required,
  meta,
  largeLeft,
  largeRight,
  children,
}: FormInputLayoutProps) => {
  const { t } = useTranslation()
  return (
    <div className="mb-6 justify-center flex">
      <div
        className={`w-full ${
          largeLeft || largeRight ? "md:w-full" : "md:w-2/3"
        } justify-center items-center flex flex-col md:flex-row `}
      >
        <div className={`w-full md:${largeRight ? "w-1/5" : largeLeft ? "w-4/5" : "w-2/5"}`}>
          {label && (
            <label
              className={`block font-bold md:text-right mb-1 md:mb-0 md:pr-4 ${
                meta && meta.touched && meta.error ? "text-red-500" : "text-gray-500"
              }`}
            >
              {t(label)}
              <span className={`pl-2 whitespace-no-wrap ${required ? "" : "hidden md:invisible"}`}>*</span>
            </label>
          )}
        </div>

        <div
          className={`w-full md:${largeRight ? "w-4/5" : largeLeft ? "w-1/5" : "w-3/5"}
          flex justify-center md:justify-start`}
        >
          {children}
        </div>
      </div>

      <div className="flex flex-row justify-center md:justify-start">
        <div className="w-full hidden md:block" />
        {meta && meta.touched && meta.error && <div className="text-red-500">{t(meta.error)}</div>}
      </div>
    </div>
  )
}

export default FormInputInline
