import React, { FC, Fragment } from "react"
import { CenterTypeWS } from "../../../components/centers/CentersType"
import ReactTooltip from "react-tooltip"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

type ConversionDateProps = {
  center?: CenterTypeWS
}

const ConversionDate: FC<ConversionDateProps> = (props: ConversionDateProps) => {
  const { center } = props
  const { t } = useTranslation()
  const date =
    center && center.loyaltyProgramStartDate
      ? format(new Date(center.loyaltyProgramStartDate), t("generic.format.date"))
      : ""

  return center?.loyaltyProgramStartDate ? (
    <div className="col-span-1 lg:col-span-3 h-16 bg-white flex justify-center">
      <p className="my-auto">{t("premiumDashboard.info.conversionDate", { value: date })}</p>
      <ReactTooltip place="right" type="info" effect="solid" />
      <span
        className="icon icon-info text-blue-500 text-2xl cursor-pointer flex items-center"
        data-tip={`${t("generic.fields.dateFormatLabel")}  ${t("generic.format.date")}`}
      />
    </div>
  ) : (
    <Fragment />
  )
}

export default ConversionDate
