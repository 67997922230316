import React from "react"

type DateInputProps = {
  action: (newDate: string) => void
  value: string
  max?: string
  min?: string
  className?: string
}

const DateInput: React.FC<DateInputProps> = (props: DateInputProps) => {
  const { action, value, max, min, className } = props
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value) {
      action(event.target.value.toLowerCase())
    }
  }

  return (
    <input
      type="date"
      value={value}
      className={className || "border-b border-blueGray-100 text-blueGray-500 p-2 rounded"}
      onChange={(e) => handleChange(e)}
      max={max}
      min={min}
    />
  )
}

export default DateInput
