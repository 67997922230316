import React, { FC, useRef, useState } from "react"
import { Transition } from "@tailwindui/react"
import { useTranslation } from "react-i18next"
import { CentersWS, CenterTypeWS } from "./CentersType"
import FlagIcon from "../../styles/customIcons/FlagIcon"
import { useOutsideAlerter } from "../../utils/Components"

type PropsType = {
  centersByCountry: CentersWS[]
  selectedCenters: CentersWS
  setSelectedCenters: (center: CentersWS) => void
  defaultValue?: string
}

const CentersByCountryDesktopDropdown: FC<PropsType> = ({
  centersByCountry,
  selectedCenters,
  setSelectedCenters,
  defaultValue,
}: PropsType) => {
  const [countryCenters, setCountryCenters] = useState<CenterTypeWS[]>([])
  const [isOpen, setOpen] = useState(false)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const { t } = useTranslation()
  const refElement = useRef(null)
  const refDropdown = useRef(null)
  useOutsideAlerter(refElement, setOpen, "mousedown")
  useOutsideAlerter(refDropdown, setIsSubMenuOpen, "mousedown")

  return (
    <div className="origin-left relative cursor-pointer" ref={refElement}>
      <div className={`py-1 px-1 w-64 bg-blueGray-50 rounded bg-white border-2 border-gray-200 py-2 hidden md:block`}>
        <div onClick={() => setOpen((prev) => !prev)} className="flex flex-row font-medium items-center m-auto">
          <div className="w-full text-left pl-3">
            {selectedCenters.country.length > 0 && <FlagIcon country={selectedCenters.country} />}
            {selectedCenters.centers.length === 0
              ? t(defaultValue || "dashboard.filters.country.all")
              : selectedCenters.country.length > 0
              ? t(defaultValue || "generic.forms.fields.allCenters")
              : selectedCenters.centers[0].name}
          </div>
          <div className="text-right">
            <i className="icon-down cursor-pointer m-auto text-black text-lg" />
          </div>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="origin-top-left absolute left-0 mt-2 w-full rounded shadow-md z-20">
          <div className="py-1 rounded-md bg-white shadow-xs" role="menu">
            <div key="all" className="ml-3 md-3 relative z-20 cursor-pointer text-left" ref={refDropdown}>
              <div>
                <div
                  onClick={() => {
                    setOpen(!isOpen)
                    setSelectedCenters({ country: "", centers: [] })
                  }}
                  className="block py-2 pl-6 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  role="menuitem"
                  key="all"
                  onMouseOver={() => {
                    setIsSubMenuOpen(false)
                  }}
                >
                  {t(defaultValue || "dashboard.filters.country.all")}
                </div>
              </div>
            </div>
            {centersByCountry.map((centerByCountry) => (
              <div
                key={centerByCountry.country}
                className="ml-3 md-3 relative z-20 cursor-pointer hover:bg-gray-100 text-left"
                ref={refDropdown}
                onMouseOver={() => {
                  if (isOpen) {
                    setCountryCenters(centerByCountry.centers)
                    setIsSubMenuOpen(true)
                  }
                }}
              >
                <div className="flex flex-row justify-between">
                  <div className="block px-4 py-2 text-gray-700" role="menuitem" key={centerByCountry.country}>
                    <FlagIcon country={centerByCountry.country} />
                    {t(`country.${centerByCountry.country}`)}
                  </div>
                  <i className="icon-right cursor-pointer text-black text-lg" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Transition>
      <div className="transform translate-x-full scale-1 bg-green-200 origin-right absolute right-48 mt-2 w-48 rounded-md shadow-md z-20">
        <Transition
          show={isSubMenuOpen}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="origin-top-right absolute left-48 mt-2 w-48 rounded-md shadow-md z-20">
            <div className="py-1 rounded-md bg-white shadow-xs" role="menu">
              <div
                key="all"
                onClick={() => {
                  setSelectedCenters({ country: countryCenters[0]?.countryCode, centers: countryCenters })
                  setOpen(false)
                }}
              >
                <div
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                  role="menuitem"
                  key="all"
                >
                  <FlagIcon country={countryCenters[0]?.countryCode} />
                  {t(defaultValue || "generic.forms.fields.allCenters")}
                </div>
              </div>
              {countryCenters.map((countryCenter) => (
                <div
                  key={countryCenter.name}
                  onClick={() => {
                    setSelectedCenters({ country: "", centers: [countryCenter] })
                    setOpen(false)
                  }}
                >
                  <div
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                    role="menuitem"
                    key={countryCenter.name}
                  >
                    {countryCenter.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default CentersByCountryDesktopDropdown
