import * as Yup from "yup"
import { passwordRegex, phoneNumberRegex } from "../../utils/Regex"

export const profileInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
}

export const changePasswordFields = [
  {
    formKey: "oldPassword",
    label: "profile.forms.fields.oldPassword",
    type: "password",
    required: true,
  },
  {
    formKey: "newPassword1",
    label: "profile.forms.fields.newPassword",
    type: "password",
    required: true,
  },
  {
    formKey: "newPassword2",
    label: "profile.forms.fields.passwordConfirmation",
    type: "password",
    required: true,
  },
]

export const passwordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("generic.forms.errors.required"),
  newPassword1: Yup.string()
    .required("generic.forms.errors.required")
    .matches(passwordRegex, "generic.forms.errors.passwordValidation"),
  newPassword2: Yup.string()
    .oneOf([Yup.ref("newPassword1"), undefined], "generic.forms.errors.matchPassword")
    .required("generic.forms.errors.required"),
})

export const profileFields = [
  {
    formKey: "firstName",
    label: "generic.forms.fields.firstname",
    required: true,
  },
  {
    formKey: "lastName",
    label: "generic.forms.fields.lastname",
    required: true,
  },
  {
    formKey: "email",
    label: "generic.forms.fields.email",
    required: true,
  },
  {
    formKey: "phone",
    label: "generic.forms.fields.phone",
    required: false,
  },
]

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "generic.forms.errors.min")
    .max(50, "generic.forms.errors.max")
    .required("generic.forms.errors.required"),
  lastName: Yup.string()
    .min(2, "generic.forms.errors.min")
    .max(50, "generic.forms.errors.max")
    .required("generic.forms.errors.required"),
  email: Yup.string().email("generic.forms.errors.invalidEmail").required("generic.forms.errors.required"),
  phone: Yup.string().matches(phoneNumberRegex, "generic.forms.errors.invalidPhone").nullable(),
})
