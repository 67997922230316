import {
  AgeFilterType,
  ConvertedMembersFilterType,
  DigitalFilterType,
  MembersFilterType,
  OptinFilterType,
  PartnersFilterType,
  TLPOriginFilterType,
  TLPPaymentMethodType,
} from "../../../store/FilterStore"

export type CustomizableOptionType<T> = { key: T; label: string }[]

// ------------------------------------------ Classic Dashboard --------------------------------------------------

export const membersCreationFilters: CustomizableOptionType<MembersFilterType> = [
  {
    key: "mobile",
    label: "dashboard.filters.members.mobile",
  },
  {
    key: "formWeb",
    label: "dashboard.filters.members.web",
  },
  {
    key: "onSite",
    label: "dashboard.filters.members.dashboard",
  },
  {
    key: "Partner",
    label: "dashboard.filters.members.partner",
  },
  {
    key: "Wifi",
    label: "dashboard.filters.members.wifi",
  },
  {
    key: "Directories",
    label: "dashboard.filters.members.directories",
  },
]

export const partnersFilters: CustomizableOptionType<PartnersFilterType> = [
  {
    key: "Westfield",
    label: "dashboard.filters.partners.westfield",
  },
  {
    key: "Qualifio",
    label: "dashboard.filters.partners.qualifio",
  },
  {
    key: "Captain Wallet",
    label: "dashboard.filters.partners.captainwallet",
  },
  {
    key: "Cardiweb",
    label: "dashboard.filters.partners.cardiweb",
  },
  {
    key: "GOODIE",
    label: "dashboard.filters.partners.goodie",
  },
  {
    key: "Maestro",
    label: "dashboard.filters.partners.maestro",
  },
  {
    key: "Openevents",
    label: "dashboard.filters.partners.openevents",
  },
  {
    key: "Stocard",
    label: "dashboard.filters.partners.stocard",
  },
  {
    key: "ilogs",
    label: "dashboard.filters.partners.ilogs",
  },
  {
    key: "Relatia",
    label: "dashboard.filters.partners.relatia",
  },
]

export const optinFilters: CustomizableOptionType<OptinFilterType> = [
  {
    key: "commercial",
    label: "dashboard.filters.optin.commercial",
  },
  {
    key: "sms",
    label: "dashboard.filters.optin.sms",
  },
  {
    key: "both",
    label: "dashboard.filters.optin.both",
  },
  {
    key: "no",
    label: "dashboard.filters.optin.no",
  },
]

export const digitalizationFilters: CustomizableOptionType<DigitalFilterType> = [
  {
    key: "digitalPrinted",
    label: "dashboard.filters.digital.digitalPrinted",
  },
  {
    key: "digital",
    label: "dashboard.filters.digital.digital",
  },
  {
    key: "printed",
    label: "dashboard.filters.digital.printed",
  },
  {
    key: "none",
    label: "dashboard.filters.digital.none",
  },
]

export const agesFilters: CustomizableOptionType<AgeFilterType> = [
  {
    key: "20",
    label: "dashboard.filters.ages.20Years",
  },
  {
    key: "20-30",
    label: "dashboard.filters.ages.20to30Years",
  },
  {
    key: "30-50",
    label: "dashboard.filters.ages.30to50Years",
  },
  {
    key: "50",
    label: "dashboard.filters.ages.50Years",
  },
]

// --------------------------------------------- TLP Dashboard ----------------------------------------------------

export const convertedMembersOptions: CustomizableOptionType<ConvertedMembersFilterType> = [
  {
    key: "",
    label: "premiumDashboard.pie.converted.all",
  },
  {
    key: 0,
    label: "premiumDashboard.pie.converted.new",
  },
  {
    key: 1,
    label: "premiumDashboard.pie.converted.converted",
  },
]

export const originUpgraded: CustomizableOptionType<TLPOriginFilterType> = [
  {
    key: "mobile",
    label: "premiumDashboard.pie.origin.mobile",
  },
  {
    key: "formWeb",
    label: "premiumDashboard.pie.origin.web",
  },
  {
    key: "Partner",
    label: "premiumDashboard.pie.origin.partner",
  },
  {
    key: "unknown",
    label: "premiumDashboard.pie.origin.others",
  },
]

export const paymentMethodOptions: CustomizableOptionType<TLPPaymentMethodType> = [
  {
    key: "",
    label: "premiumDashboard.pie.payment.all",
  },
  {
    key: "bank",
    label: "premiumDashboard.pie.payment.bank",
  },
  {
    key: "tr",
    label: "premiumDashboard.pie.payment.tr",
  },
  {
    key: "scan",
    label: "premiumDashboard.pie.payment.scan",
  },
  {
    key: "bankAndTR",
    label: "premiumDashboard.pie.payment.both",
  },
]
