import React from "react"
import Select from "../Select"
import { Field, Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import InputField from "../general/InputField"
import { UserRole } from "../../utils/Roles"
import { useHistory } from "react-router-dom"
import FormInputLayout from "../FormInputLayout"
import FormActionButtons from "../general/FormActionButtons"
import TableComponent, { TableCell, TableRow } from "../TableComponent"
import { cols, rightsCols, roleValidationSchema } from "./RoleFormConsts"
import Card from "../members/Card"
import Container from "../general/Container"
import Header from "../Header"
import { SetFieldValueType } from "../../utils/Types"

type SpecificFieldSelectorProps = {
  isCreate?: boolean
  value: "center" | "centers" | "countries" | ""
  setFieldValue: SetFieldValueType
}

const roleValues = [
  { value: "", name: "roles.forms.values.none" },
  { value: "center", name: "roles.forms.values.center" },
  { value: "centers", name: "roles.forms.values.centers" },
  { value: "countries", name: "roles.forms.values.countries" },
]

const SpecificFieldSelector: React.FC<SpecificFieldSelectorProps> = ({
  isCreate,
  setFieldValue,
  value,
}: SpecificFieldSelectorProps) => {
  const { t } = useTranslation()

  return (
    <Field name="linkedTo">
      {({ field, meta }: any) => (
        <FormInputLayout label="roles.forms.fields.linkedTo" required meta={meta}>
          {isCreate ? (
            <Select
              meta={meta}
              value={value}
              onChange={(event) => setFieldValue("linkedTo", event.target.value)}
              values={roleValues}
              keyRef="value"
              labelRef="name"
              defaultValueDisabled
              large
            />
          ) : (
            <p>{!field.value ? t("roles.forms.values.none") : t("roles.forms.values." + field.value)}</p>
          )}
        </FormInputLayout>
      )}
    </Field>
  )
}

type CheckboxProps = {
  name: string
  disabled: boolean
}
const Checkbox: React.FC<CheckboxProps> = ({ name, disabled }: CheckboxProps) => (
  <Field
    type="checkbox"
    disabled={disabled}
    name={name}
    className="bg-white cursor-pointer border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center focus-within:border-blue-500"
  />
)

const profileKey = "profile"

type RoleFormProps = {
  initialValues: UserRole
  isCreate: boolean
  onSubmit: (values: UserRole) => void
  title: string
}

const RoleForm: React.FC<RoleFormProps> = ({ initialValues, isCreate, onSubmit, title }: RoleFormProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const columnNames = rightsCols(t)
  const roles = Object.keys(initialValues.permissions).map((key) => {
    return { key: key, name: key }
  })

  return (
    <Formik<UserRole> initialValues={initialValues} validationSchema={roleValidationSchema} onSubmit={onSubmit}>
      {({ setFieldValue, values }) => (
        <Form>
          <Container>
            <Card>
              <Header>{title}</Header>
              <InputField
                fieldData={{
                  formKey: "name",
                  label: "generic.forms.fields.roleKey",
                  required: true,
                }}
              />
              <InputField
                fieldData={{
                  formKey: "label",
                  label: "generic.forms.fields.name",
                  required: true,
                }}
              />
              <SpecificFieldSelector isCreate={isCreate} value={values.linkedTo} setFieldValue={setFieldValue} />
              <br />
              <hr />
              <br />
              <TableComponent columnNames={columnNames}>
                {roles.map(
                  (item, index) =>
                    item.key !== profileKey && (
                      <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        {cols.map((right) => {
                          const permissions = values.permissions[item.key]
                          const permissionsKey = right as keyof typeof permissions
                          return (
                            <TableCell key={`${item.key}.${right}`} centered>
                              {!(
                                values.permissions[item.key] &&
                                values.permissions[item.key][permissionsKey] === undefined
                              ) && (
                                <Checkbox
                                  key={`permissions.${item.key}.${right}`}
                                  name={`permissions.${item.key}.${right}`}
                                  disabled={
                                    values.permissions[item.key] &&
                                    values.permissions[item.key][permissionsKey] === undefined
                                  }
                                />
                              )}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ),
                )}
              </TableComponent>

              <div className="pt-4">
                <FormActionButtons cancelOnClick={() => history.push("/roles")} />
              </div>
            </Card>
          </Container>
        </Form>
      )}
    </Formik>
  )
}

export default RoleForm
