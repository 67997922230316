import React, { Dispatch, FC, ReactElement, SetStateAction } from "react"
import { UserRole } from "../../utils/Roles"
import Select from "../Select"
import MultipleSelect from "../MultipleSelect"
import { useTranslation } from "react-i18next"
import { SetFieldValueType } from "../../utils/Types"

type PropsType = {
  roles: UserRole[]
  roleFilter: Array<UserRole>
  setRoleFilter: Dispatch<SetStateAction<UserRole[]>>
  countryList: Array<{ label: string; name: string }>
  countriesFilter: string
  centerList: Array<{ label: string; name: string }>
  centersFilter: string
  setCountryFilter: Dispatch<SetStateAction<string>>
  setCenterFilter: Dispatch<SetStateAction<string>>
  setShowOnlyActiveProfile: Dispatch<SetStateAction<boolean>>
}

export const ContributorsFiltersTagItem: (
  label: string,
  isActive: boolean,
  meta: any,
  fctClick: () => void,
) => ReactElement = (label, isActive, meta, fctClick) => {
  return (
    <div
      className={`border cursor-pointer ${
        isActive
          ? "bg-blueGray-700 border-transparent opacity-100 text-white "
          : "border-blueGray-700 bg-white text-blueGray-700 "
      } inline-block rounded-lg px-4 py-1 text-base font-semibold mr-2 mb-2
      ${meta.touched && meta.error ? "text-red-500" : ""}`}
      title={label}
      onClick={fctClick}
      key={label}
    >
      {label}
    </div>
  )
}

const ContributorsFiltersTags: FC<PropsType> = (props: PropsType) => {
  const {
    roles,
    roleFilter,
    setRoleFilter,
    countryList,
    countriesFilter,
    setCountryFilter,
    centersFilter,
    setCenterFilter,
    centerList,
    setShowOnlyActiveProfile,
  } = props
  const { t } = useTranslation()

  const handleChangeRole: SetFieldValueType = (event, newValue) => {
    setRoleFilter(newValue)
  }

  const handleChangeCountry = (event: any) => {
    const key = event.target.value
    const isActive = countriesFilter === key

    if (!key.length) {
      setCountryFilter("")
    } else if (isActive) {
      setCountryFilter("")
    } else {
      setCountryFilter(key)
    }
  }

  const handleChangeCenter = (event: any) => {
    const key = event.target.value
    const isActive = centersFilter === key

    if (!key.length) {
      setCenterFilter("")
    } else if (isActive) {
      setCenterFilter("")
    } else {
      setCenterFilter(key)
    }
  }

  const handleCheckbox = () => setShowOnlyActiveProfile((prevState: boolean) => !prevState)

  return (
    <div className="py-4 flex flex-row items-center justify-center flex-wrap gap-4">
      <div className="w-full md:w-64 relative">
        <p className="text-xs text-left pl-2 pb-1 cursor-default">{t("contributors.contributorsFiltersTag.roles")}</p>
        <MultipleSelect
          values={roleFilter}
          options={roles}
          setFieldValue={handleChangeRole}
          keyRef="name"
          field="Role"
          iconRef="icon"
          labelRef="label"
          placeholder={t("contributors.contributorsFiltersTag.all")}
          className="bg-white border-2"
        />
      </div>

      <div className="w-full md:w-64">
        <p className="text-xs text-left pl-2 pb-1 cursor-default">
          {t("contributors.contributorsFiltersTag.countries")}
        </p>
        <Select
          value={countriesFilter}
          onChange={handleChangeCountry}
          values={countryList || []}
          keyRef="name"
          labelRef="label"
          defaultValue={t("contributors.contributorsFiltersTag.all")}
          large
        />
      </div>

      <div className="w-full md:w-64">
        <p className="text-xs text-left pl-2 pb-1 cursor-default">{t("contributors.contributorsFiltersTag.centers")}</p>
        <Select
          value={centersFilter}
          onChange={handleChangeCenter}
          values={centerList || []}
          keyRef="name"
          labelRef="label"
          defaultValue={t("contributors.contributorsFiltersTag.all")}
          large
        />
      </div>

      <div className="w-full md:w-64 text-left">
        <div className="flex items-center justify-start mt-3.5">
          <p className="text-xs">{t("contributors.contributorsFiltersTag.userActive")}</p>
          <input className="text-xs cursor-default ml-3" type="checkbox" onClick={handleCheckbox} />
        </div>
      </div>
    </div>
  )
}

export default ContributorsFiltersTags
