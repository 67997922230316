import React from "react"
import MultipleSelect from "../MultipleSelect"
import Select from "../Select"
import { UserRole } from "../../utils/Roles"
import { SetFieldValueType } from "../../utils/Types"
import { TFunction } from "i18next"
import { CountriesWS } from "../../utils/Country"
import { CenterTypeWS } from "../centers/CentersType"

export const renderRoleLinkedContent: (
  t: TFunction,
  role: UserRole,
  setFieldValue: SetFieldValueType,
  meta: any,
  values: any,
  centers: CenterTypeWS[],
  countries: CountriesWS[],
) => React.ReactElement | undefined = (t: TFunction, role, setFieldValue, meta, values, centers, countries) => {
  switch (role.linkedTo) {
    case "center":
      return (
        <Select
          value={values && values.length !== 0 ? values[0].id : ""}
          onChange={(event) => {
            setFieldValue("assignedCenters", [centers.find((center) => center.id.toString() === event.target.value)])
          }}
          large
          values={centers}
          keyRef="id"
          labelRef="name"
          defaultValue={t("contributors.forms.values.defaultSelectCenter")}
          defaultValueDisabled
        />
      )

    case "centers":
      return (
        <MultipleSelect
          key={role.linkedTo}
          keyRef="id"
          labelRef="name"
          options={centers}
          values={values}
          field="assignedCenters"
          setFieldValue={setFieldValue}
          meta={meta}
          large
        />
      )

    case "countries":
      return (
        <MultipleSelect
          key={role.linkedTo}
          keyRef="code"
          labelRef="name"
          options={countries}
          values={values}
          field="countries"
          setFieldValue={setFieldValue}
          meta={meta}
          large
        />
      )

    default:
      return undefined
  }
}
