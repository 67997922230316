import React from "react"
import { MemberObject } from "../../api/MemberObject"
import Button, { ButtonType } from "./Button"

type GoToLpeButtonProps = {
  member: MemberObject
}

export const GoToLpeButton: React.FC<GoToLpeButtonProps> = (props: GoToLpeButtonProps) => {
  const { member } = props
  const urlToLPE = process.env.REACT_APP_URL_TO_LPE

  return (
    <Button
      classNames="mt-2 ml-2"
      label="Go to LPE"
      type={ButtonType.CUSTOM}
      onClick={() => window.open(`${urlToLPE}/${member.id}`)}
    />
  )
}
