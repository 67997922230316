import { apisInformation } from "../components/apigateway/ApiGatewayConsts"
import { ApiKeysTypeWS } from "../components/apigateway/apiKeys/ApiKeysTypes"

export type ApiKeysAccess = {
  key: string
  label: string
  checked: boolean
  value: number
}
export const getFormikApisValues = (apiKey?: ApiKeysTypeWS): ApiKeysAccess[] =>
  Object.keys(apisInformation).map((key) => {
    return {
      key: key,
      label: apisInformation[key as keyof typeof apisInformation].label,
      checked: !!apiKey && apiKey.apiAccess[key] > 0,
      value: apiKey?.apiAccess[key] || 1,
    }
  })

export const convertToRequestFormat = (acc: Record<string, number>, curr: ApiKeysAccess): Record<string, number> => {
  return {
    ...acc,
    [curr.key]: curr.checked ? curr.value : 0,
  }
}
