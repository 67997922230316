import React, { FC } from "react"
import { menuElement } from "../utils/Types"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

type PropsType = {
  menuItem: menuElement
}

const HomeStyleItem: FC<PropsType> = (props: PropsType) => {
  const { menuItem } = props
  const { t } = useTranslation()

  return (
    <div className="mx-auto">
      <div className={`icon ${menuItem.icon} text-5xl`} />
      <h3 className=" text-2xl">{t(`${menuItem.label}`)}</h3>
    </div>
  )
}

const HomeItem: FC<PropsType> = (props: PropsType) => {
  const { menuItem } = props

  return menuItem.path.startsWith("https") ? (
    <div
      className={`h-48 ${menuItem.color} ng-scope rounded-lg shadow-md hover:shadow-xl text-white text-center flex items-center`}
    >
      <a
        href={menuItem.path}
        className="welcome-btn section-owners mx-auto w-full h-full items-center flex text-center"
        target="_blank"
        rel="noopener noreferrer"
      >
        <HomeStyleItem menuItem={menuItem} />
      </a>
    </div>
  ) : (
    <div
      className={`h-48 ${menuItem.color} ng-scope rounded-lg shadow-md hover:shadow-xl text-white text-center flex items-center`}
    >
      <NavLink
        to={menuItem.path}
        href={menuItem.path}
        className="welcome-btn section-owners mx-auto w-full h-full items-center flex text-center"
      >
        <HomeStyleItem menuItem={menuItem} />
      </NavLink>
    </div>
  )
}

export default HomeItem
