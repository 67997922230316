import React, { FC, useEffect } from "react"
import { Field, useField, useFormikContext } from "formik"
import FormInputLayout from "../FormInputLayout"
import { dateMiniForm } from "../../utils/Constants"

type InputFieldProps = {
  fieldData: {
    formKey: string
    label?: string
    required?: boolean
    disabled?: boolean
    type?: string
    maxDate?: string
    min?: string
    initValue?: string | boolean
  }
  isAutoFocus?: boolean
  large?: boolean
  inline?: boolean
}

const InputField: FC<InputFieldProps> = ({ fieldData, isAutoFocus, large, inline }: InputFieldProps) => {
  const [field] = useField(fieldData.formKey)
  const formikContext = useFormikContext()

  useEffect(() => {
    if (!field.value && fieldData.initValue && formikContext.setFieldValue) {
      formikContext.setFieldValue(fieldData.formKey, fieldData.initValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Field key={fieldData.formKey} name={fieldData.formKey}>
      {({ field, meta }: any) => (
        <FormInputLayout
          label={fieldData.label}
          required={fieldData.required}
          meta={meta}
          large={large}
          inline={inline}
        >
          <input
            autoFocus={isAutoFocus}
            className={
              "appearance-none border-2 rounded w-full py-3 px-4 leading-tight focus:outline-none focus:border-blueGray-600" +
              (meta && meta.touched && meta.error ? " border-red-500 text-red-700" : " border-gray-200 text-gray-700")
            }
            type={fieldData.type || "text"}
            min={fieldData.min || dateMiniForm}
            max={fieldData.maxDate || ""}
            {...field}
          />
        </FormInputLayout>
      )}
    </Field>
  )
}

export default InputField
