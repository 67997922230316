import React, { FC } from "react"
import { numberWithThousandsSeparator } from "../../../utils/Functions"
import { Color, ColorDetailsTOE } from "../../../utils/Constants"
import { TDetailsProps } from "../TlpOffersModal"
import ReactTooltip from "react-tooltip"

const CashbackDetails: FC<TDetailsProps> = (props: TDetailsProps) => {
  const { offer, offerInformations, progressPercent, t } = props

  const getColor = (id: string): string => {
    switch (id) {
      case "received":
        return ColorDetailsTOE.red
      case "created":
        return ColorDetailsTOE.teal
      case "requested":
        return ColorDetailsTOE.purple
      default:
        return ColorDetailsTOE.default
    }
  }

  return (
    <div className="border-b border-blueGray-200 px-6">
      <div className="flex">
        <div className="flex items-center m-auto">
          <h3 className="text-2xl p-3 text-center ">{t("tlpOffers.fields.attributedOffers")}</h3>
          <ReactTooltip place="bottom" type="light" effect="solid" />
          <span
            className="icon icon-info text-xl cursor-pointer"
            data-tip={t("tlpOffers.fields.attributedOffersDescription")}
          />
        </div>
      </div>
      <div className="flex-grow text-center">
        <h2 className="text-2xl p-3">
          {offer.maxAttribution > 0
            ? `${offerInformations?.actualAttribution} / ${offer.maxAttribution}`
            : offerInformations?.actualAttribution}
        </h2>
      </div>
      {offer.maxAttribution > 0 && (
        <div className="h-8 my-1 rounded-md bg-gray-100 overflow-hidden">
          <div style={{ width: `${progressPercent}%` }} className={`h-full ${Color.TLPOFFERS}`} />
        </div>
      )}
      <div className={`grid grid-cols-${offerInformations?.details.length} gap-4`}>
        {offerInformations?.details.map((detail) => {
          return (
            <div key={detail.id} className="w-full flex flex-col items-center">
              <div className="flex items-center">
                <p className="uppercase font-semibold py-3 pr-1 text-sm">{detail.name}</p>
                <ReactTooltip place="bottom" type="light" effect="solid" />
                <span className="icon icon-info text-xs cursor-pointer" data-tip={detail.description} />
              </div>
              <div className={`${getColor(detail.id)} rounded py-3 w-full text-center`}>
                <p className="text-xl text-white">{numberWithThousandsSeparator(detail.cashbackNumber || 0)}</p>
              </div>
              <p className="text-sm pt-1 pb-3 w-full text-center">{`${numberWithThousandsSeparator(
                detail.cashbackAmount,
              )} ${offer.currency}`}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default CashbackDetails
