import React, { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import RoleForm from "../../components/roles/RoleForm"
import { rightsInitialValues } from "../../components/roles/RoleFormConsts"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { useHistory } from "react-router-dom"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { Color } from "../../utils/Constants"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { UserRole } from "../../utils/Roles"

const RoleCreate: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  useEffect(() => {
    addActiveCalls()
    // Get dynamically the default permissions from what exists in the database
    execute<UserRole>(`users/roles/ROLE_SUPER_ADMIN`, "GET")
      .then((res) => {
        //Set all the permissions to false
        for (const [resource, value] of Object.entries(res.permissions)) {
          Object.keys(value).forEach((permission) => {
            res.permissions[resource][permission as "create" | "update" | "read" | "delete"] = false
          })
        }
        rightsInitialValues.permissions = res.permissions
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className={`w-screen h-2 ${Color.RIGHTS}`} />
      <div className="pt-6">
        <RoleForm
          title={t("roles.forms.titles.create")}
          initialValues={rightsInitialValues}
          isCreate={true}
          onSubmit={(values) => {
            addActiveCalls()
            const formData = {
              name: values.name,
              label: values.label,
              linkedTo: values.linkedTo || null,
              permissions: values.permissions,
            }
            execute("users/roles", "POST", {}, formData)
              .then(() => {
                openSuccessSnackbar(
                  `${t("roles.snackbar.title")} ${t("generic.snackbar.success.create")}`,
                  snackbarDuration,
                )
                history.push("/roles")
              })
              .catch(() => {
                openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
              })
              .finally(subtractActiveCalls)
          }}
        />
      </div>
    </div>
  )
}

export default RoleCreate
