import React, { FC, useState } from "react"
import Restricted from "../Restricted"

type PropsType = {
  icon: string
  items: {
    title?: string
    icon: string
    iconColor?: string
    right: string
    action: () => void
  }[]
}

const IconDropdown: FC<PropsType> = (props: PropsType) => {
  const { icon, items } = props
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div className="relative inline-block text-left">
        <div onClick={() => setIsOpen(!isOpen)}>
          <i className={`${icon} cursor-pointer`} />
        </div>
      </div>

      <div
        className={`${
          isOpen ? "show" : "hidden"
        } origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-md z-40`}
      >
        <div className="rounded-md bg-blueGray-50 shadow-xl">
          <div className="py-1 block item-center">
            {items.map((item) => (
              <Restricted key={item.icon} right={item.right}>
                <div
                  className="p-2 flex flex-row justify-start text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 cursor-pointer"
                  role="menuitem"
                  onClick={item.action}
                >
                  {item.icon && <i className={`${item.icon} ${item.iconColor || ""}text-right pr-4`} />}
                  <div className="text-left">{item.title}</div>
                </div>
              </Restricted>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default IconDropdown
