import React from "react"
import { TFunction } from "i18next"

export type CustomersStockPeriodButton = {
  click: () => void
  label: string
  key: string
}

export const myButtons = (
  clickFunction: (label: string) => void,
  setDateSelector: React.Dispatch<React.SetStateAction<string>>,
  t: TFunction,
): CustomersStockPeriodButton[] => [
  {
    click: () => {
      setDateSelector("day")
      clickFunction("day")
    },
    label: t("dashboard.fields.day"),
    key: "day",
  },
  {
    click: () => {
      setDateSelector("week")
      clickFunction("week")
    },
    label: t("dashboard.fields.week"),
    key: "week",
  },
  {
    click: () => {
      setDateSelector("month")
      clickFunction("month")
    },
    label: t("dashboard.fields.month"),
    key: "month",
  },
]

export const getButton = (onClick: () => void, label: string, isSelected: boolean): JSX.Element => {
  return (
    <button
      key={label}
      name="day"
      className={`border border-blueGray-500 rounded m-2 px-2 cursor-pointer ${isSelected ? "bg-blueGray-200" : ""}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}
