import React, { FC } from "react"
import FormInputLayout from "../FormInputLayout"
import MultipleSelect from "../MultipleSelect"
import { Field } from "formik"
import { getDays } from "../../views/checkins/CheckInFormConsts"
import { useTranslation } from "react-i18next"
import { SetFieldValueType } from "../../utils/Types"

type DaysMultipleSelectProps = {
  setFieldValue: SetFieldValueType
  label: string
  required?: boolean
  formKey: string
}

const DaysMultipleSelect: FC<DaysMultipleSelectProps> = ({
  setFieldValue,
  label,
  required = false,
  formKey,
}: DaysMultipleSelectProps) => {
  const { t } = useTranslation()

  return (
    <Field name={formKey}>
      {({ field, meta }: any) => (
        <FormInputLayout required={required} meta={meta} label={label}>
          <MultipleSelect
            options={getDays(t)}
            values={field.value}
            field={formKey}
            setFieldValue={setFieldValue}
            meta={meta}
            large
          />
        </FormInputLayout>
      )}
    </Field>
  )
}

export default DaysMultipleSelect
