import React, { FC } from "react"
import { Form, Formik } from "formik"
import InputField from "../general/InputField"
import FormActionButtons from "../general/FormActionButtons"
import execute from "../../api/api"
import { useTranslation } from "react-i18next"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { newCompanyInitialValues, newCompanyValidationSchema } from "./CompaniesConsts"
import { Company } from "./CompaniesType"
import CustomModal from "../CustomModal"
import Header from "../Header"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"

type Props = {
  centerId: number
  refresh: () => void
  open: boolean
  setOpen: (newValue: boolean) => void
}

const CompaniesModal: FC<Props> = ({ centerId, refresh, open, setOpen }: Props) => {
  const { t } = useTranslation()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <div>
      <CustomModal isOpen={open} onRequestClose={closeModal}>
        <Header>{t("companies.forms.titles.creation")}</Header>
        <hr />
        <Formik
          initialValues={newCompanyInitialValues}
          validationSchema={newCompanyValidationSchema}
          onSubmit={(values) => {
            execute<Company>(`companies/${centerId}`, "POST", {}, values)
              .then(() => {
                openSuccessSnackbar(
                  `${t("companies.snackbar.title")} ${t("generic.snackbar.success.create")}`,
                  snackbarDuration,
                )
                setOpen(false)
                refresh()
              })
              .catch((err) => {
                let errorMessage = snackbarErrorMessage
                if (err.response && err.response.status === 409) {
                  errorMessage = "companies.snackbar.errors.duplicate"
                }
                openErrorSnackbar(t(errorMessage), snackbarDuration)
              })
          }}
        >
          <Form className="mt-8 text-left">
            <InputField
              isAutoFocus={true}
              fieldData={{
                formKey: "name",
                label: "generic.forms.fields.name",
                required: false,
              }}
            />
            <FormActionButtons cancelOnClick={closeModal} />
          </Form>
        </Formik>
      </CustomModal>
    </div>
  )
}

export default CompaniesModal
