import { useStore } from "effector-react"
import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import execute from "../../api/api"
import { FullContributorType } from "../../api/ContributorObject"
import { MemberObject } from "../../api/MemberObject"
import { ContributorStore } from "../../store/ContributorStore"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import CustomModal from "../CustomModal"
import FormInputLayout from "../FormInputLayout"
import FormActionButtons from "../general/FormActionButtons"
import InputField from "../general/InputField"
import TextArea from "../general/TextArea"
import Header from "../Header"
import Select from "../Select"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import ClaimCancelValidation from "./ClaimCancelValidation"
import { claimFormValidationSchema, types } from "./ClaimFormConsts"
import { ClaimType } from "./ClaimType"

type ClaimFormProps = {
  initialValues: ClaimType
  member: MemberObject
  closeModal: () => void
}

const ClaimForm: React.FC<ClaimFormProps> = ({ initialValues, member, closeModal }: ClaimFormProps) => {
  const { t } = useTranslation()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const user = useStore<FullContributorType>(ContributorStore)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const openCancelModal = () => {
    setOpenModal(true)
  }

  const closeCancelModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Header>{t("claim.titles.basic")}</Header>
      <hr />
      <Formik
        initialValues={initialValues}
        validationSchema={claimFormValidationSchema}
        onSubmit={(values) => {
          addActiveCalls()
          execute(
            "tlpSupport",
            "POST",
            {},
            {
              ...values,
              phone: values.phone ? values.phone : undefined,
              customerId: member.id,
              centerId: member.centerId,
              hasPremiumScan: member.hasPremiumScan ? true : false,
              email: member.email,
              dashboardUserEmail: user.email,
              dashboardUserUsername: user.username,
            },
          )
            .then(() => {
              closeModal()
              openSuccessSnackbar(
                `${t("claim.snackbar.title")} ${t("generic.snackbar.success.create")}`,
                snackbarDuration,
              )
            })
            .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
            .finally(subtractActiveCalls)
        }}
      >
        {({ setFieldValue, values }) => (
          <Form className="pt-6">
            <Field name="scope">
              {({ meta }: any) => (
                <FormInputLayout label="claim.forms.fields.scope" required meta={meta} large>
                  <Select
                    onChange={(event) => setFieldValue("scope", event.target.value)}
                    meta={meta}
                    value={values.scope}
                    large
                    values={types}
                    keyRef="value"
                    labelRef="label"
                  />
                </FormInputLayout>
              )}
            </Field>

            <TextArea
              rows="10"
              fieldData={{
                formKey: "message",
                label: "claim.forms.fields.message",
                required: true,
              }}
              large
            />

            <InputField
              fieldData={{
                type: "number",
                formKey: "phone",
                label: "claim.forms.fields.phone",
                required: false,
              }}
              large
            />

            <FormActionButtons cancelOnClick={openCancelModal} validateButtonLabel="generic.buttons.validate" />

            {openModal && (
              <CustomModal isOpen={openModal}>
                <ClaimCancelValidation closeCancelModal={closeCancelModal} closeModal={closeModal} />
              </CustomModal>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ClaimForm
