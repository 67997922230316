import React from "react"
import "../App.css"
import "../styles/css/fontello.css"
import Modal from "react-modal"

type CustomModalProps = Record<string, any> & {
  isOpen: boolean
  onRequestClose?: () => void
  className?: string
  overlayClassName?: string
  children: React.ReactNode
  scrollable?: boolean
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onRequestClose,
  className = "",
  overlayClassName = "",
  children,
  scrollable = true,
  ...rest
}: CustomModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={`absolute border-1 border-blueGray-300 rounded-md bg-white p-5 outline-none shadow-lg min-w-1/3
                  max-h-4/5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-9/10 md:max-w-4/5
                  ${scrollable && "overflow-auto overflow-x-hidden"}
                  ${className}`}
      overlayClassName={`fixed inset-0 bg-white bg-opacity-75 ${overlayClassName}`}
      bodyOpenClassName="overflow-hidden"
      {...rest}
    >
      {children}
    </Modal>
  )
}

export default CustomModal
