import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { CentersWS } from "../centers/CentersType"
import FlagIcon from "../../styles/customIcons/FlagIcon"

function useOutsideAlert(ref: any, setOpen: Dispatch<SetStateAction<boolean>>) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, setOpen])
}

type ElementBreadCrumbProps = {
  isFirstNode?: boolean
  onClick: (value: FormatDataBreadcrumb) => void
  currentElement: FormatDataBreadcrumb
  icon?: string
  values?: FormatDataBreadcrumb[]
  isCountry?: boolean
}

type FormatDataBreadcrumb = {
  id: string
  name?: string
}

const ElementBreadcrumb: FC<ElementBreadCrumbProps> = (props: ElementBreadCrumbProps) => {
  const { t } = useTranslation()
  const { onClick, icon, values, currentElement, isFirstNode, isCountry } = props
  const [isOpen, setIsOpen] = useState(false)
  const refSelector = useRef(null)

  useOutsideAlert(refSelector, setIsOpen)

  return currentElement.name !== undefined ? (
    <div className="flex flex-col relative cursor-pointer" ref={refSelector}>
      <span
        className="no-wrap inline-block rounded-lg p-3 font-semibold hover:bg-blueGray-200 whitespace-nowrap"
        key={currentElement.id}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {icon && <i className={`mx-auto mr-1 text-sm ${icon}`} />}
        {currentElement.name !== "all" && isCountry && <FlagIcon country={currentElement.id} />}
        {currentElement.name === "all" ? t("dashboard.filters.country.all") : currentElement.name}
      </span>
      <div className="relative">
        {isOpen && (
          <div className="absolute ml-3 py-2 px-2 rounded-md shadow-md z-40 bg-white w-48 text-left ml-2 divide-y">
            {!isFirstNode && (
              <div
                className="py-2 font-semibold pl-2 hover:bg-blueGray-100 rounded"
                key="all"
                onClick={() => {
                  setIsOpen(false)
                  onClick({ id: "", name: "" })
                }}
              >
                {t("dashboard.filters.country.all")}
              </div>
            )}

            {values &&
              values.map((element) => {
                return (
                  element.id !== currentElement.id && (
                    <div
                      className="py-2 font-semibold pl-2 hover:bg-blueGray-100 rounded"
                      key={element.name}
                      onClick={() => {
                        setIsOpen(false)
                        onClick(element)
                      }}
                    >
                      {isCountry && <FlagIcon country={element.id} />}
                      {element.name}
                    </div>
                  )
                )
              })}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div />
  )
}

type BreadCrumbProps = {
  premium?: boolean
  countryCode?: string
  countries?: CentersWS[]
  centerId?: string
}
const BreadcrumbCountries: FC<BreadCrumbProps> = (props: BreadCrumbProps) => {
  const { premium, countries, countryCode = "", centerId = "" } = props
  const basePath = premium ? "premiumDashboard" : "dashboard"

  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const dashboardElement = {
    id: "DASHBOARD",
    name: "Dashboard",
  }
  const cockpitElement = {
    id: "COCKPIT",
    name: "Cockpit",
  }

  const viewArray = [cockpitElement, dashboardElement]
  const countryName = countryCode ? t(`country.${countryCode}`) : "all"
  const [currentView, setCurrentView] = useState<FormatDataBreadcrumb>(dashboardElement)
  const [listCountries, setListCountries] = useState<FormatDataBreadcrumb[]>([])
  const [listCenters, setListCenters] = useState<FormatDataBreadcrumb[]>([])
  const [selectedCenter, setSelectedCenter] = useState<FormatDataBreadcrumb>()

  const handleChangeFirstNode = (value: FormatDataBreadcrumb) => {
    setCurrentView(value)
    switch (value.id) {
      case "DASHBOARD":
        history.push(`/${basePath}`)
        break
      case "COCKPIT":
        history.push(`/${basePath}/cockpit`)
        break
    }
  }

  const handleChangeCountry = (value: FormatDataBreadcrumb) => {
    history.push(`/${basePath}/${value.id}`)
  }

  const handleChangeCenter = (value: FormatDataBreadcrumb) => {
    if (value.id === "") {
      history.push(`/${basePath}/${countryCode}`)
    } else {
      history.push(`/${basePath}/${countryCode}/${value.id}`)
    }
  }

  useEffect(() => {
    const centersByCountry = countries?.find((element) => element.country === countryCode)
    if (centersByCountry) {
      const listCentersByCountry: FormatDataBreadcrumb[] = centersByCountry.centers
        .sort((a, b) => t(`country.${a.name}`).localeCompare(t(`country.${b.name}`)))
        .map((center) => {
          return { id: center.id.toString(), name: center.name }
        })
      setListCenters(listCentersByCountry)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, countries])

  useEffect(() => {
    if (countries) {
      const listCountries: FormatDataBreadcrumb[] = countries
        ?.sort((a, b) => t(`country.${a.country}`).localeCompare(t(`country.${b.country}`)))
        .map((country) => {
          return { id: country.country, name: t(`country.${country.country}`) }
        })
      setListCountries(listCountries)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, centerId, countries])

  useEffect(() => {
    if (listCenters) {
      const newCenter = listCenters.find((center) => center.id === centerId)
      if (newCenter !== undefined) setSelectedCenter(newCenter)
      else setSelectedCenter({ id: "all", name: "all" })
    }
  }, [listCenters, centerId])

  useEffect(() => {
    if (location.pathname !== undefined) {
      const splitArray = location.pathname.split("/")
      switch (splitArray[splitArray.length - 1]) {
        case "dashboard":
          setCurrentView(dashboardElement)
          break
        case "cockpit":
          setCurrentView(cockpitElement)
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div className="flex flex-row my-2 mx-5 py-2 items-center">
      <ElementBreadcrumb
        isFirstNode={true}
        onClick={handleChangeFirstNode}
        values={viewArray}
        currentElement={currentView}
      />
      <i className="icon-right-open" />
      {currentView.id === "DASHBOARD" && (
        <>
          <ElementBreadcrumb
            onClick={handleChangeCountry}
            values={listCountries}
            currentElement={{
              id: countryCode,
              name: countryName,
            }}
            isCountry
          />
          <i className="icon-right-open" />
          {countryCode && (
            <ElementBreadcrumb
              onClick={handleChangeCenter}
              values={listCenters}
              currentElement={{
                id: centerId,
                name: selectedCenter?.name,
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

export default BreadcrumbCountries
