import { FastField, Field } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import FormInputLayout from "../FormInputLayout"
import { DataType, ObjectiveDataType } from "./CentersType"
import { numberWithThousandsSeparator, numberWithoutThousandsSeparator } from "../../utils/Functions"
import { SetFieldValueType } from "../../utils/Types"

const periods: {
  key: keyof DataType
  label: string
}[] = [
  { key: "Q1", label: "centers.forms.fields.objectives.q1" },
  { key: "Q2", label: "centers.forms.fields.objectives.q2" },
  { key: "Q3", label: "centers.forms.fields.objectives.q3" },
  { key: "Q4", label: "centers.forms.fields.objectives.q4" },
  { key: "year", label: "centers.forms.fields.objectives.year" },
]

type PeriodProps = {
  editable: boolean
  name: string
  setFieldValue: SetFieldValueType
}

const Period: React.FC<PeriodProps> = ({ editable, name, setFieldValue }: PeriodProps) =>
  editable ? (
    <FastField
      type="string"
      key={name}
      name={name}
      onChange={(event: any) =>
        setFieldValue(name, numberWithoutThousandsSeparator(numberWithThousandsSeparator(event.target.value)))
      }
      className="appearance-none border-2 rounded w-1/6 py-3 px-4 leading-tight focus:outline-none focus-border-blueGray-800"
    />
  ) : (
    <Field name={name}>
      {({ field }: any) => <div className="w-1/6 py-2 px-4 text-left border-2 bg-gray-200">{field.value}</div>}
    </Field>
  )

type ObjectiveProps = {
  title: string
  name: string
  values: DataType | undefined
  setFieldValue: SetFieldValueType
}

const Objective: React.FC<ObjectiveProps> = ({ title, name, values, setFieldValue }: ObjectiveProps) => {
  if (values) {
    // Calculates the total in real time
    values.year = 0
    for (const [period, value] of Object.entries(values)) {
      if (period.includes("Q")) {
        values.year += numberWithoutThousandsSeparator(value.toString()) || 0
      }
    }

    // Converts number to string (with thousands comma separators)
    for (const [period, value] of Object.entries(values)) {
      if (typeof value === "number") {
        values[period as keyof DataType] = numberWithThousandsSeparator(value)
      }
    }
  }

  return (
    <FormInputLayout label={title} large>
      <div className="w-full flex flex-row justify-between">
        {periods.map((period) => (
          <Period
            key={period.key}
            editable={!name.includes("archive") && period.key !== "year"}
            name={`${name}.${period.key}`}
            setFieldValue={setFieldValue}
          />
        ))}
      </div>
    </FormInputLayout>
  )
}

type ObjectivesProps = {
  name: string
  values: ObjectiveDataType
  isPremium: boolean
  setFieldValue: SetFieldValueType
}

export const Objectives: React.FC<ObjectivesProps> = ({ name, values, isPremium, setFieldValue }: ObjectivesProps) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const currentYear = new Date().getFullYear()

  return (
    <div className="mt-4">
      <h3 className="my-4">{t("centers.fields.headerObjectives")}</h3>
      <div className="text-gray-700">
        <FormInputLayout large>
          <div className="w-full flex flex-row justify-between">
            {periods.map((period) => (
              <div key={period.key} className="w-1/6 px-4">
                {t(period.label)}
              </div>
            ))}
          </div>
        </FormInputLayout>
        <div>
          <Objective
            title={`${currentYear} ${t("menu.customers")}`}
            name={`${name}.customers`}
            values={values.customers}
            setFieldValue={setFieldValue}
          />
          {isPremium && (
            <Objective
              title={`${currentYear} ${t("members.tags.premium.label")}`}
              name={`${name}.premium`}
              values={values.premium}
              setFieldValue={setFieldValue}
            />
          )}
          {values.archive && values.archive.length > 0 && (
            <>
              <FormInputLayout large>
                <div
                  className="px-4 h-10 w-full bg-blueGray-400 rounded cursor-pointer flex flex-row justify-between items-center text-white"
                  onClick={() => setShow(!show)}
                >
                  <span>{t("centers.fields.archivesObjectives")}</span>
                  <i className={`icon-${show ? "up" : "down"} text-xl`} />
                </div>
              </FormInputLayout>
              <div className={`${show ? "" : "hidden"}`}>
                {values.archive.map((archive, index) => {
                  let title = `${archive.year} `
                  title += archive.type === "customers" ? t("menu.customers") : t("members.tags.premium.label")
                  return (
                    <Objective
                      key={title}
                      title={title}
                      name={`${name}.archive.${index}.data`}
                      values={archive.data}
                      setFieldValue={setFieldValue}
                    />
                  )
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Objectives
