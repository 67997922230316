import React, { Dispatch, FC, SetStateAction } from "react"
import Select from "../Select"
import { CenterTypeWS } from "../centers/CentersType"

type CenterSearchProps = {
  centerId?: number
  centers: CenterTypeWS[]
  setCenterId: Dispatch<SetStateAction<number>>
}

const CenterSearch: FC<CenterSearchProps> = ({ centerId, centers, setCenterId }: CenterSearchProps) => {
  return (
    <div className="xl:hidden my-6 md:w-1/2 md:mx-auto">
      <div className="px-2 md:px-0">
        <Select
          value={centerId || ""}
          onChange={(event) => setCenterId(event.target.value)}
          values={centers}
          keyRef="id"
          labelRef="name"
          iconCountry="countryCode"
          large
        />
      </div>
    </div>
  )
}

export default CenterSearch
