import React, { Dispatch, FC, SetStateAction } from "react"
import { menuElement } from "../../../utils/Types"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

type PropsType = {
  menuItem: menuElement
  setMenuOpened: Dispatch<SetStateAction<boolean>>
}

const MenuItemMobile: FC<PropsType> = (props: PropsType) => {
  const { menuItem, setMenuOpened } = props
  const { t } = useTranslation()

  return (
    <NavLink
      to={menuItem.path}
      href={menuItem.path}
      onClick={() => setMenuOpened(false)}
      className={`block px-3 py-2 cursor-pointer rounded-md text-base font-medium text-gray-8080 focus:outline-none focus:text-white hover:text-white hover:${menuItem.color}`}
    >
      {t(menuItem.label)}
    </NavLink>
  )
}

export default MenuItemMobile
