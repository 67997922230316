import { ClassicCockpitObjectiveType, KeyOfObjectiveType, PremiumCockpitObjectiveType } from "./CockpitType"

export const cockpitColumnsHeaderLabels = [
  "dashboard.table.center",
  "dashboard.table.totalCustomers",
  "dashboard.table.loyaltyMembers",
  "dashboard.table.newsletterSubscribers",
  "dashboard.table.periodMembers",
  "dashboard.table.objective",
  "dashboard.table.gap",
  "dashboard.table.gapPercentage",
  "dashboard.table.email",
  "dashboard.table.commercial",
]

export const cockpitColumns: KeyOfObjectiveType[] = [
  {
    key: "totalCustomers",
  },
  {
    key: "totalLoyaltyMembers",
  },
  {
    key: "totalNewsletterSubscribers",
  },
  {
    key: "periodLoyaltyMembers",
  },
  {
    key: "currentObjective",
  },
  {
    key: "currentGap",
  },
  {
    key: "quarterCustomers",
    percent: true,
  },
  {
    key: "validEmailCustomers",
    percent: true,
  },
  {
    key: "commercialOptinCustomers",
    percent: true,
  },
]

export const initialCockpitTotal: ClassicCockpitObjectiveType = {
  totalCustomers: 0,
  totalLoyaltyMembers: 0,
  totalNewsletterSubscribers: 0,
  periodLoyaltyMembers: 0,
  currentObjective: 0,
  currentGap: 0,
  quarterCustomers: 0,
  validEmailCustomers: 0,
  commercialOptinCustomers: 0,
}

export const premiumCockpitColumnsHeaderLabels = [
  "dashboard.table.center",
  "dashboard.table.premiumMembers",
  "dashboard.table.premiumPeriodMembers",
  "dashboard.table.premiumObjective",
  "dashboard.table.premiumGap",
  "dashboard.table.premiumConvertedMembers",
]

export const premiumCockpitColumns: KeyOfObjectiveType[] = [
  {
    key: "totalCustomers",
  },
  {
    key: "periodCustomers",
  },
  {
    key: "currentObjective",
  },
  {
    key: "currentGap",
  },
  {
    key: "convertedCustomers",
  },
]

export const initialPremiumCockpitTotal: PremiumCockpitObjectiveType = {
  totalCustomers: 0,
  periodCustomers: 0,
  currentObjective: 0,
  currentGap: 0,
  convertedCustomers: 0,
}
