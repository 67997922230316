import { ActionIcon } from "../../ActionIcon"
import React, { useState } from "react"
import { CentersWS } from "../../centers/CentersType"
import { CognitoUserObject } from "../../../api/CognitoUserObject"
import { Form, Formik } from "formik"
import execute from "../../../api/api"
import { useStore } from "effector-react"
import { ContributorStore } from "../../../store/ContributorStore"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import { useTranslation } from "react-i18next"
import CheckboxRow from "./CheckboxRow"
import FormSubmitButton from "../../profile/FormSubmitButton"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import CustomModal from "../../CustomModal"
import Header from "../../Header"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"

type CognitoUserCentersWS = {
  userName: string
  centerIds: number[]
}

type CheckedCountries = {
  country: string
  centers: {
    id: number
    name: string
    isChecked: boolean
  }[]
}

type EditCognitoUserCentersProps = {
  cognitoUser?: CognitoUserObject
  centers?: CentersWS[]
}

const EditCognitoUserCenters: React.FC<EditCognitoUserCentersProps> = (props: EditCognitoUserCentersProps) => {
  const { cognitoUser, centers } = props
  const { t } = useTranslation()
  const [cognitoUserCenters, setCognitoUserCenters] = useState<CheckedCountries[]>([])
  const [open, setOpen] = useState(false)
  const { id, email, assignedCenters } = useStore(ContributorStore)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  const fetchCognitoUserCentersWS = () => {
    addActiveCalls()
    execute<CognitoUserCentersWS>(`apiGateway/partners/${cognitoUser?.username}/centers`, "GET")
      .then((res) => {
        const mappedCenters = centers?.map((countryData) => {
          return {
            country: countryData.country,
            centers: countryData.centers.map((center) => {
              return {
                id: center.id,
                name: center.name,
                isChecked: res.centerIds.includes(center.id),
              }
            }),
          }
        })

        setCognitoUserCenters(mappedCenters || [])
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
  }

  return (
    <>
      <ActionIcon icon={`icon-edit`} onClick={() => setOpen(true)} title={t("apiGateway.fields.tooltipsSettings")} />
      {open && (
        <CustomModal isOpen={open} onRequestClose={() => setOpen(false)} onAfterOpen={fetchCognitoUserCentersWS}>
          <div className="overflow-y-auto pr-2">
            <Formik
              enableReinitialize
              initialValues={{ countries: cognitoUserCenters }}
              onSubmit={(values) => {
                // On récupère tous les id des centres checked
                const checkedCenters = values.countries.reduce((acc: number[], curr) => {
                  return [
                    ...acc,
                    ...curr.centers.reduce((accumulator: number[], center) => {
                      return center.isChecked ? [...accumulator, center.id] : accumulator
                    }, []),
                  ]
                }, [])

                execute(
                  `apiGateway/partners/${cognitoUser?.username}/centers`,
                  "PATCH",
                  {},
                  { centerIds: checkedCenters },
                )
                  .then(() => {
                    execute<void>(
                      "fid",
                      "POST",
                      {},
                      {
                        centerId: assignedCenters[0]?.id,
                        code: "PartnerModify_Dashboard'",
                        content: "Modifying partner centers from dashboard",
                        application: "dashboard",
                        email: email,
                        customerId: id,
                      },
                      {},
                      "logger",
                    )
                    openSuccessSnackbar(
                      `${t("apiGateway.titles.managedCenters")} ${t([
                        "generic.snackbar.success.editPlural",
                        "generic.snackbar.success.edit",
                      ])}`,
                      snackbarDuration,
                    )
                    setOpen(false)
                  })
                  .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
              }}
            >
              {({ values, setFieldValue }) => {
                const isAllChecked = !values.countries.some((countryData) =>
                  countryData.centers.some((center) => !center.isChecked),
                )

                return (
                  <Form>
                    <FormSubmitButton customStyle="w-full">{t("generic.buttons.validate")}</FormSubmitButton>
                    <Header>{t("modal.apiGateway.cognito.center")}</Header>
                    <hr />
                    <div className="my-4 font-semibold">
                      <CheckboxRow
                        label={t("dashboard.filters.country.all")}
                        checked={isAllChecked}
                        onClick={() => {
                          const allValues = values.countries.map((countryData) => {
                            return {
                              country: countryData.country,
                              centers: countryData.centers.map((center) => {
                                return {
                                  ...center,
                                  isChecked: !isAllChecked,
                                }
                              }),
                            }
                          })
                          setFieldValue("countries", allValues)
                        }}
                      />
                      <div className="ml-4 lg:ml-8">
                        {values.countries.map((countryData, index) => {
                          const isChecked = !countryData.centers.some((center) => !center.isChecked)
                          return (
                            <div key={countryData.country}>
                              <CheckboxRow
                                label={t(`country.${countryData.country}`)}
                                checked={isChecked}
                                onClick={() => {
                                  const newCenters = values.countries[index].centers.map((center) => {
                                    return {
                                      ...center,
                                      isChecked: !isChecked,
                                    }
                                  })
                                  setFieldValue(`countries[${index}].centers`, newCenters)
                                }}
                              />
                              <div className="ml-4 lg:ml-8 grid grid-cols-1 lg:grid-cols-2">
                                {countryData.centers.map((center, idx) => (
                                  <CheckboxRow
                                    key={center.name}
                                    label={center.name}
                                    checked={center.isChecked}
                                    onClick={() => {
                                      setFieldValue(`countries[${index}].centers[${idx}]`, {
                                        ...center,
                                        isChecked: !center.isChecked,
                                      })
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>

                    <FormSubmitButton customStyle="w-full">{t("generic.buttons.validate")}</FormSubmitButton>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </CustomModal>
      )}
    </>
  )
}

export default EditCognitoUserCenters
