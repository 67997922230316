import React, { FC } from "react"
import InformationLine from "./InformationLine"
import MembersTags from "./MembersTags"
import { useTranslation } from "react-i18next"
import { MemberObject } from "../../api/MemberObject"
import { useHistory } from "react-router-dom"
import { format } from "date-fns"
import Restricted from "../Restricted"
import { snackbarDuration } from "../snackbarStyles"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import MemberCheckInsModal from "./modal/MemberCheckInsModal"
import MemberLogsModal from "./modal/MemberLogsModal"
import { IconWithConfirmation } from "../modals/IconConfirmationModal"
import MemberPremiumModal from "./modal/MemberPremiumModal"
import { MemberResendEmailModal } from "./modal/MemberResendEmailModal"
import { ClaimModal } from "../claim/ClaimModal"
import { CenterTypeWS } from "../centers/CentersType"
import execute from "../../api/api"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { hasRight } from "../../utils/Roles"

type PropsType = {
  member: MemberObject
  centers: CenterTypeWS[]
  refreshData: (deletedCustomerId: number) => void
}

const MembersCard: FC<PropsType> = (props: PropsType) => {
  const { member, centers, refreshData } = props
  const { t } = useTranslation()
  const history = useHistory()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const { id, email, assignedCenters, permissions } = useStore(ContributorStore)

  const memberCenter = centers.find((center) => center.id === member.centerId)
  const isUKMember = memberCenter && memberCenter.countryCode.toLowerCase() === "uk"
  const isPartialMember = member && member.type.toLowerCase() === "partial"
  const isFRTLPMember =
    memberCenter && memberCenter.countryCode.toLowerCase() === "fr" && memberCenter.loyaltyProgramStartDate

  const deleteCustomer = () => {
    execute(`customers/${member.id}`, "DELETE", {}, {})
      .then(() => {
        execute<void>(
          "fid",
          "POST",
          {},
          {
            centerId: assignedCenters[0]?.id,
            code: "DB-DeleteCustomer",
            content: "Deleting customer from dashboard",
            application: "dashboard",
            email: email,
            customerId: id,
          },
          {},
          "logger",
        )
        refreshData(member.id)
        openSuccessSnackbar(`${t("members.snackbar.title")} ${t("generic.snackbar.success.delete")}`, snackbarDuration)
      })
      .catch(() => {
        openErrorSnackbar(`${t("members.snackbar.title")} ${t("generic.snackbar.error.unknown")}`, snackbarDuration)
      })
  }

  return (
    <div className="flex flex-col justify-between max-w-smd rounded overflow-hidden shadow-md text-left bg-white border-1 border-gray-100 px-4 py-4">
      <div className="divide-y divide-gray-200">
        <div className="py-2">
          <div className="font-bold text-xl mb-2">
            {/* We use dangerouslySetInnerHTML to highlight the fields search (which are strings) as HTML in a span. */}
            <span
              dangerouslySetInnerHTML={{
                __html: `${member.highlight?.firstName?.[0] || (member.firstName ? member.firstName : "")} ${
                  member.highlight?.lastName?.[0] || (member.lastName ? member.lastName : "")
                }`,
              }}
            />
          </div>
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: member.highlight?.email?.[0] || member.email,
              }}
            />
            <i className={member.hasValidEmail ? "text-green-500 icon-check" : "text-red-500 icon-cancel"} />
          </p>
          {member.birthDate ? (
            <InformationLine
              itemLabel={t("members.fields.birthdate")}
              itemValue={format(new Date(member.birthDate), t("generic.format.date"))}
            />
          ) : (
            ""
          )}
        </div>
        <div className="py-2">
          <InformationLine itemLabel={t("members.fields.cardNumber")} itemValue={member.barcode} />
          <InformationLine
            itemLabel={t("members.fields.creationDate")}
            itemValue={format(new Date(member.accountCreatedAt), t("generic.format.dateTime"))}
          />
          <InformationLine
            itemLabel={t("members.fields.lastChange")}
            itemValue={format(new Date(member.accountUpdatedAt), t("generic.format.dateTime"))}
          />
          <InformationLine itemLabel={t("members.fields.company")} itemValue={member.companyName} />
          <InformationLine itemLabel={t("members.fields.phone")} itemValue={member.phone} />
        </div>
        <div className="py-2">
          <div className="flex flex-row items-center">
            <InformationLine
              itemLabel={t("members.fields.centers")}
              itemValue={`${memberCenter?.name} [${member.centerId}]`}
              flag={memberCenter?.countryCode}
            />
          </div>
          <InformationLine itemLabel={t("members.fields.customerId")} itemValue={member.id.toString()} />
          <InformationLine itemLabel={t("members.fields.pasKey")} itemValue={member.pasKey} />
          <InformationLine itemLabel={t("members.fields.cognitoId")} itemValue={member.cognitoId} />
          <InformationLine itemLabel={t("members.fields.crmId")} itemValue={member.crmId} />
        </div>
        {!!member.isPremium && (
          <div className="py-2">
            {member.premiumStartDate && (
              <InformationLine
                itemLabel={t("members.fields.premiumRegistration")}
                itemValue={format(new Date(member.premiumStartDate), t("generic.format.dateTime"))}
              />
            )}
            {member.premiumBalance !== null && member.premiumBalance !== undefined && (
              <InformationLine
                itemLabel={t("members.fields.premiumBalance")}
                itemValue={`${member.premiumBalance} / ${member.premiumThreshold}`}
              />
            )}
          </div>
        )}
      </div>
      <div>
        <div className="py-2">
          <MembersTags member={member} />
        </div>
        {!member.pasKey && (
          <div className="text-3xl pt-2 flex justify-end flex-wrap">
            <Restricted right="tlpsupport.create">{isFRTLPMember ? <ClaimModal member={member} /> : ""}</Restricted>
            <Restricted right="customers.update">
              <i
                title={t("members.actions.edit")}
                className="text-menu-brown icon-edit cursor-pointer"
                onClick={() => history.push(`/members/${member.id}/edit`)}
              />
              {hasRight(permissions, "customerslogs.read") && <MemberLogsModal member={member} />}
              <MemberResendEmailModal member={member} />
            </Restricted>
            {!isPartialMember ? <MemberCheckInsModal member={member} /> : ""}
            {member.isPremium ? <MemberPremiumModal member={member} /> : ""}
            <Restricted right="customers.delete">
              <IconWithConfirmation
                customStyle="text-3xl"
                onConfirmationClick={() => {
                  deleteCustomer()
                }}
                icon="icon-delete"
                title={t("generic.buttons.delete")}
                description={t("modal.delete.description")}
              />
            </Restricted>
          </div>
        )}
        {isUKMember && member.isPremium && (
          <div className="text-3xl pt-2 flex justify-end">
            <MemberPremiumModal member={member} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MembersCard
