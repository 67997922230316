import * as Yup from "yup"

export const initialValues = {
  generalSettings: {
    centerId: 0,
    name: "",
  },
  offerSettings: {
    offerType: "",
    startDate: "",
    endDate: "",
    storesList: [],
    allStores: true,
    recurringDays: [0, 1, 2, 3, 4, 5, 6],
  },
  rewardSettings: {
    maxAttributionInfinite: true,
    maxAttributionCustomerInfinite: true,
  },
}

export const generalSettingsValidationSchema = Yup.object().shape({
  centerId: Yup.number().min(1, "generic.forms.errors.required"),
  name: Yup.string().required("generic.forms.errors.required"),
})

export const offerSettingsValidationSchema = Yup.object().shape(
  {
    offerType: Yup.string().required("generic.forms.errors.required"),
    startDate: Yup.date()
      .required("generic.forms.errors.required")
      .when("endDate", {
        is: (date) => date, // !undefined
        then: Yup.date().max(Yup.ref("endDate"), "generic.forms.errors.startDate"),
      }),
    endDate: Yup.date().when("startDate", {
      is: (date) => date, // !undefined
      then: Yup.date().min(Yup.ref("startDate"), "generic.forms.errors.endDate"),
    }),
    storesList: Yup.array().when("allStores", {
      is: false,
      then: Yup.array().min(1, "generic.forms.errors.minValue"),
    }),
  },
  [["startDate", "endDate"]],
)

export const getMinDate = (): string => {
  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)
  return minDate.toISOString().substring(0, 10)
}

export enum Status {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
