import React, { FC } from "react"
import Card from "../Card"
import FormSubmitButton, { FormCancelButton } from "../../profile/FormSubmitButton"
import Container from "../../general/Container"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

type TConfirmationSection = {
  isUKMember: boolean
}
const ConfirmationSection: FC<TConfirmationSection> = (props: TConfirmationSection) => {
  const { isUKMember } = props
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <>
      <Container>
        <Card>
          {!isUKMember && <p className="mb-4">{t("members.fields.confirmation")}</p>}
          <div className="w-4/5 m-auto sm:w-full sm:m-0 sm:flex sm:flex-row sm:justify-center">
            {isUKMember ? (
              <FormCancelButton
                customStyle="w-full flex justify-center space-x-4"
                onClick={() => history.push("/members")}
              >
                {t("generic.buttons.back")}
              </FormCancelButton>
            ) : (
              <>
                <div>
                  <FormCancelButton
                    customStyle="w-full justify-center mb-4 sm:mb-0 sm:mr-6"
                    onClick={() => history.push("/members")}
                  >
                    {t("generic.buttons.cancelChanges")}
                  </FormCancelButton>
                </div>
                <div>
                  <FormSubmitButton customStyle="w-full justify-center sm:ml-6">
                    {t("generic.buttons.saveChanges")}
                  </FormSubmitButton>
                </div>
              </>
            )}
          </div>
        </Card>
      </Container>
    </>
  )
}

export default ConfirmationSection
