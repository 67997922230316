import {
  getActiveCenters,
  getActiveCentersByCountry,
  getActiveEuropeanCenters,
  getActivePremiumCenters,
  getActivePremiumCentersByCountry,
  getCentersList,
  getVipCenters,
} from "../../Centers"
import { useEffect, useState } from "react"
import { CentersWS, CenterTypeWS } from "../../../components/centers/CentersType"
import { useTranslation } from "react-i18next"
import { useCustomSnackBar } from "../useCustomSnackBar"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import { execute } from "../../../api/api"
import { CountryObject } from "../../../api/CenterObject"
import { snackbarDuration, snackbarErrorMessage } from "../../../components/snackbarStyles"

export const useFetchCentersInUserScope = (): {
  centersInUserScope: CenterTypeWS[]
  centersInUserScopeByCountry: CentersWS[]
  activeCentersInUserScope: CenterTypeWS[]
  activeCentersInUserScopeByCountry: CentersWS[]
  activeEuropeanCentersInUserScope: CenterTypeWS[]
  activePremiumCentersInUserScope: CenterTypeWS[]
  activePremiumCentersInUserScopeByCountry: CentersWS[]
  vipCentersInUserScope: CenterTypeWS[]
} => {
  const { t } = useTranslation()
  const [openErrorSnackbar] = useCustomSnackBar("error")

  const [centersInUserScope, setCentersInUserScope] = useState<CenterTypeWS[]>([])
  const [centersInUserScopeByCountry, setCentersInUserScopeByCountry] = useState<CentersWS[]>([])
  const [activeCentersInUserScope, setActiveCentersInUserScope] = useState<CenterTypeWS[]>([])
  const [activeCentersInUserScopeByCountry, setActiveCentersInUserScopeByCountry] = useState<CentersWS[]>([])
  const [activeEuropeanCentersInUserScope, setActiveEuropeanCentersInUserScope] = useState<CenterTypeWS[]>([])
  const [activePremiumCentersInUserScope, setActivePremiumCentersInUserScope] = useState<CenterTypeWS[]>([])
  const [activePremiumCentersInUserScopeByCountry, setActivePremiumCentersInUserScopeByCountry] = useState<CentersWS[]>(
    [],
  )
  const [vipCentersInUserScope, setVipCentersInUserScope] = useState<CenterTypeWS[]>([])

  useEffect(() => {
    addActiveCalls()
    execute<CountryObject[]>("centers", "GET")
      .then((res) => {
        if (res) {
          setCentersInUserScope(getCentersList(res).sort((a, b) => a.countryCode.localeCompare(b.countryCode)))
          setCentersInUserScopeByCountry(res)
          setActiveCentersInUserScope(getActiveCenters(res))
          setActiveCentersInUserScopeByCountry(getActiveCentersByCountry(res))
          setActiveEuropeanCentersInUserScope(getActiveEuropeanCenters(res))
          setActivePremiumCentersInUserScope(getActivePremiumCenters(res))
          setActivePremiumCentersInUserScopeByCountry(getActivePremiumCentersByCountry(res))
          setVipCentersInUserScope(getVipCenters(res))
        }
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    centersInUserScope,
    centersInUserScopeByCountry,
    activeCentersInUserScope,
    activeCentersInUserScopeByCountry,
    activeEuropeanCentersInUserScope,
    activePremiumCentersInUserScope,
    activePremiumCentersInUserScopeByCountry,
    vipCentersInUserScope,
  }
}
