import React, { useEffect, useState } from "react"
import { quarterType, serieType } from "./ObjectiveChartConstant"
import LineCharts from "./LineCharts"
import { useTranslation } from "react-i18next"
import { useStore } from "effector-react"
import execute from "../../../api/api"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { TFunction } from "i18next"
import { ColorGraphs } from "../../../utils/Constants"

const POINT_INTERVAL = 24 * 3600 * 1000 //One day

const tooltip = {
  shared: true,
  crosshairs: true,
  xDateFormat: "%A, %b %e, %Y",
}

const getSerie = (data: quarterType[], t: TFunction) => {
  let customer: number[] = []
  let objective: number[] = []
  let date: number[] = []

  data.forEach((obj) => {
    date = [...date, obj.date]
    customer = [...customer, obj.count]
    objective = [...objective, obj.objective]
  })

  const firstSerie = {
    name: t("dashboard.line.objective.current"),
    color: ColorGraphs.blue,
    pointStart: date[0],
    marker: {
      enabled: false,
    },
    data: customer,
    pointInterval: POINT_INTERVAL,
  }
  const secondSerie = {
    name: t("dashboard.line.objective.objective"),
    color: ColorGraphs.red,
    pointStart: date[0],
    marker: {
      enabled: false,
    },
    data: objective,
    pointInterval: POINT_INTERVAL,
  }
  return [firstSerie, secondSerie]
}

const xAxis = {
  type: "datetime",
  units: [["month", [1]]],
  labels: {
    format: "{value:%e %b}",
    align: "left",
  },
}

type ObjectiveLineChartProps = {
  id: string
}
const ObjectiveLineChart: React.FC<ObjectiveLineChartProps> = (props: ObjectiveLineChartProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [series, setSeries] = useState<serieType[]>([])

  const storeFilters = useStore(FilterStore)
  const yAxis = {
    title: {
      text: t("dashboard.line.objective.yAxis"),
    },
    lineWidth: 1,
  }

  useEffect(() => {
    setLoading(true)
    execute(`dashboard/${id}/widget/quarterObjective`, "GET", {}, {}, { filters: getDashboardFilters(storeFilters) })
      .then((res: any) => {
        if (res) {
          setSeries(getSerie(res, t))
        }
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters, id])

  return (
    <LineCharts
      title={t("dashboard.line.objective.title")}
      series={series}
      xAxis={xAxis}
      yAxis={yAxis}
      tooltips={tooltip}
      loading={loading}
    />
  )
}

export default ObjectiveLineChart
