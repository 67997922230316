import * as React from "react"

type CheckboxRowProps = {
  label: string
  checked: boolean
  onClick: () => void
}

const CheckboxRow: React.FC<CheckboxRowProps> = ({ label, checked, onClick }: CheckboxRowProps) => (
  <div className="pl-2 py-1 cursor-pointer hover:bg-blueGray-100" onClick={onClick}>
    {/* This onChange is added just to remove the error in the console */}
    <input onChange={onClick} className="mr-4 cursor-pointer" type="checkbox" checked={checked} />
    <span>{label}</span>
  </div>
)

export default CheckboxRow
