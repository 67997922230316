import React, { FC } from "react"
import { menuElement } from "../../../utils/Types"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { BadgeIcon } from "../../../styles/customIcons/BadgeIcon"

type PropsType = {
  menuItem: menuElement
  isSelected: boolean
  badgeDisplay?: number
}

const MenuStyleItem: FC<PropsType> = (props: PropsType) => {
  const { menuItem, isSelected, badgeDisplay = 0 } = props
  const { t } = useTranslation()

  return (
    <div
      className={`pt-3 pb-3 pl-2 pr-2 xl:px-2 ${isSelected ? menuItem.color : ""} 
      hover:${menuItem.color ? menuItem.color : ""} hover:text-white`}
    >
      <div className="relative">
        {badgeDisplay > 0 && <BadgeIcon value={badgeDisplay.toString()} />}
        <i className={`mx-auto text-5xl ${menuItem.icon}`} />
      </div>
      <div className="mx-auto mt-2 text-sm ">{t(menuItem.label)}</div>
    </div>
  )
}

const MenuItem: FC<PropsType> = (props: PropsType) => {
  const { menuItem, isSelected } = props

  return menuItem.path.startsWith("https") ? (
    <a
      href={menuItem.path}
      className={`${isSelected ? "text-white" : "text-blueGray-100"} text-center font-medium float-left`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <MenuStyleItem menuItem={menuItem} isSelected={isSelected} />
    </a>
  ) : (
    <NavLink
      to={menuItem.path}
      href={menuItem.path}
      className={`${isSelected ? "text-white" : "text-blueGray-100"} text-center font-medium float-left`}
    >
      <MenuStyleItem menuItem={menuItem} isSelected={isSelected} />
    </NavLink>
  )
}

export default MenuItem
