import React from "react"
import { Form, Formik } from "formik"
import FormSubmitButton from "./FormSubmitButton"
import InputFieldPassword from "../general/InputFieldPassword"
import InputField from "../general/InputField"

type FieldType = {
  formKey: string
  label: string
  type?: string
  required: boolean
}

type DashboardFormProps = {
  formTitle: string
  initialValues: { [key: string]: string | number }
  validationSchema: any
  fields: FieldType[]
  buttonLabel: string
  onSubmit: (formData: any) => void
}

const ProfileForm: React.FC<DashboardFormProps> = (props: DashboardFormProps) => {
  const { formTitle, initialValues, validationSchema, fields, buttonLabel, onSubmit } = props
  return (
    <div>
      <h3 className="py-3 mb-6">{formTitle}</h3>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          {fields.map((fieldData) =>
            fieldData.type === "password" ? (
              <InputFieldPassword fieldData={fieldData} />
            ) : (
              <InputField fieldData={fieldData} />
            ),
          )}
          <div className="flex items-center justify-end">
            <FormSubmitButton>{buttonLabel}</FormSubmitButton>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default ProfileForm
