import React, { useEffect, useState } from "react"
import CenterSearch from "../../components/companies/CenterSearch"
import { Tabs } from "../../components/Tabs"
import CheckInTable from "../../components/checkin/CheckInTable"
import { CentersSideMenu } from "../../components/general/SideMenu"
import { CentersInfoCount } from "../../components/centers/CentersType"
import execute from "../../api/api"
import { useTranslation } from "react-i18next"
import { Color } from "../../utils/Constants"
import { CheckinWS } from "../../components/checkin/CheckInType"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useTitle } from "../../utils/hooks/useTitle"
import { CheckinsTabId, checkinsTabs } from "../../components/checkin/CheckinsConst"
import { useFetchCentersInUserScope } from "../../utils/hooks/centers/useFetchCentersInUserScope"

const CheckIns: React.FC = () => {
  const { t } = useTranslation()
  const { activeEuropeanCentersInUserScope } = useFetchCentersInUserScope()

  const [searchCenter, setSearchCenter] = useState<string>()
  const [selectedTab, setSelectedTab] = useState<string>(CheckinsTabId.CHECKINS)
  const [centerId, setCenterId] = useState(-1)
  const [centersCheckinsCount, setCentersCheckinsCount] = useState<CentersInfoCount>({})
  const [refresh, setRefresh] = useState(0)

  // Dynamically update browser tab title
  useTitle(`${t("menu.checkIns")} | ${t("applicationName")}`)

  // Get all centers for centreSideBar
  useEffect(() => {
    // We set the first center as the selected center when we fetch them
    if (activeEuropeanCentersInUserScope.length > 0) {
      setCenterId(activeEuropeanCentersInUserScope[0].id)
    }
  }, [activeEuropeanCentersInUserScope])

  // Get number event for centerSideBar
  useEffect(() => {
    if (selectedTab === CheckinsTabId.CHECKINS) {
      execute<CheckinWS[]>(`checkins`, "GET")
        .then((checkins: CheckinWS[]) => {
          const centersCheckinsCount: CentersInfoCount = {}
          checkins.forEach((checkin) => {
            if (centersCheckinsCount[checkin.centerId]) {
              centersCheckinsCount[checkin.centerId]++
            } else {
              centersCheckinsCount[checkin.centerId] = 1
            }
          })
          setCentersCheckinsCount(centersCheckinsCount)
        })
        .finally(subtractActiveCalls)
    } else {
      Promise.all([
        execute<CentersInfoCount>(`bookingbug/services`, "GET"),
        execute<CentersInfoCount>(`bookingbug/events`, "GET"),
      ])
        .then(([servicesResults, eventResults]: [CentersInfoCount, CentersInfoCount]) => {
          // This reduce permit to additional the result count together
          const newServicesResults: CentersInfoCount = Object.entries(servicesResults).reduce((acc, curr) => {
            const value = acc[curr[0]] || 0
            const key = curr[0]
            return {
              ...acc,
              [key]: value + curr[1],
            }
          }, eventResults)

          setCentersCheckinsCount(newServicesResults)
        })
        .finally(subtractActiveCalls)
    }
    addActiveCalls()
  }, [selectedTab])

  return (
    <>
      <div className={`w-full h-2 ${Color.CHECKINS}`} />
      <div className="flex flex-col xl:flex-row h-auto">
        {activeEuropeanCentersInUserScope.length > 1 && (
          <CentersSideMenu
            centerId={centerId}
            centers={activeEuropeanCentersInUserScope}
            centersInfoCount={centersCheckinsCount}
            searchCenter={searchCenter}
            setSearchCenter={setSearchCenter}
            setCenterId={setCenterId}
          />
        )}
        <div className="flex flex-col w-full px-2 py-4 bg-white rounded m-2 h-full">
          <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={checkinsTabs} />
          {activeEuropeanCentersInUserScope.length > 1 && (
            <CenterSearch centers={activeEuropeanCentersInUserScope} centerId={centerId} setCenterId={setCenterId} />
          )}
          <CheckInTable activeTab={selectedTab} centerId={centerId} refresh={refresh} setRefresh={setRefresh} />
        </div>
      </div>
    </>
  )
}

export default CheckIns
