import { TabsValue } from "../Tabs"

export enum CheckinsTabId {
  CHECKINS = "checkins",
  BOOKINGBUG = "bookingbug",
}

export const checkinsTabs: TabsValue[] = [
  { name: "checkins.fields.tabsServicesEvents", id: CheckinsTabId.CHECKINS, order: 0 },
  { name: "checkins.fields.bookingBug", id: CheckinsTabId.BOOKINGBUG, order: 1 },
]

export const tableColumnsCheckins = [
  {
    label: "generic.table.name",
    columnSpecifications: "w-1/3",
  },
  {
    label: "checkins.table.category",
    columnSpecifications: "text-center",
  },
  {
    label: "checkins.table.bookings",
    columnSpecifications: "text-center",
  },
  {
    label: "checkins.table.useRate",
    columnSpecifications: "text-center",
    tabs: CheckinsTabId.CHECKINS,
  },
  {
    label: "generic.table.actions",
    columnSpecifications: "text-center",
    tabs: CheckinsTabId.CHECKINS,
  },
  {
    label: "checkins.table.datetime",
    columnSpecifications: "text-center",
    tabs: CheckinsTabId.BOOKINGBUG,
  },
  {
    label: "checkins.table.participations",
    columnSpecifications: "text-center",
    tabs: CheckinsTabId.BOOKINGBUG,
  },
]
