import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { CenterDataType, CenterType } from "./CentersType"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import CentersConfigurationForm from "./CentersConfigurationForm"
import { centersInitialValues } from "./CenterConfigurationConsts"
import Container from "../general/Container"
import Card from "../members/Card"
import { format } from "date-fns"
import { CountryObjectWS } from "../../api/CountryObject"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"

const CenterConfiguration: React.FC = () => {
  const { t } = useTranslation()
  const { centerId } = useParams()
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const [countries, setCountries] = useState<CountryObjectWS[]>([])
  const [center, setCenter] = useState<CenterDataType>()

  useEffect(() => {
    addActiveCalls()
    execute<CountryObjectWS[]>(`countries`, "GET")
      .then((countriesResponse) => setCountries(countriesResponse))
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)

    if (centerId) {
      addActiveCalls()
      execute<CenterType>(`centers/${centerId}`, "GET")
        .then((CenterResponse) =>
          setCenter({
            ...CenterResponse.content,
            // Converts loyaltyProgramVersion to a boolean for the check box
            isPremium: CenterResponse.content.loyaltyProgramVersion === 2,
            // Converts string to date
            launchDate: CenterResponse.content.launchDate
              ? format(new Date(CenterResponse.content.launchDate), "yyyy-MM-dd")
              : undefined,
            loyaltyProgramStartDate: CenterResponse.content.loyaltyProgramStartDate
              ? format(new Date(CenterResponse.content.loyaltyProgramStartDate), "yyyy-MM-dd")
              : undefined,
          }),
        )
        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
        .finally(subtractActiveCalls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerId])

  return (
    <Container>
      <Card>
        <CentersConfigurationForm
          countries={countries}
          centerId={centerId}
          initialValues={center ? center : centersInitialValues}
        />
      </Card>
    </Container>
  )
}

export default CenterConfiguration
