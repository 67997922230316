import React from "react"
import Search from "./general/Search"
import Restricted from "./Restricted"

type PropsType = {
  resource: string
  barColor: string
  icon?: string
  iconColor?: string
  addAction?: () => void
  searchAction?: (value: string) => void
  shouldShowSearchIcon?: boolean
}

const SearchBar: React.FC<PropsType> = (props: PropsType) => {
  const { resource, barColor, icon, iconColor, addAction, searchAction, shouldShowSearchIcon = false } = props
  let timer: any
  let valueForAction = "" //Pas le top mais en passant par le state on a "un tour de retard"

  const handleChange = (event: any) => {
    valueForAction = event.target.value.toLowerCase()
    clearTimeout(timer)
    timer = setTimeout(trigger, 500)
  }

  const trigger = () => {
    if (searchAction) {
      searchAction(valueForAction)
    }
  }

  return (
    <div className={`${barColor} w-full text-gray-600 h-full flex flex-row justify-center`}>
      {searchAction && (
        <>
          <div className="self-center w-1/8 py-2">
            {addAction && (
              <Restricted right={`${resource}.create`}>
                <i
                  className={`text-3xl cursor-pointer
                  ${iconColor ? iconColor : "text-white"}
                  ${icon ? icon : "icon-add-user"}`}
                  onClick={addAction}
                />
              </Restricted>
            )}
          </div>
          <Search
            customStyle="self-center w-4/6 px-5 rounded-lg text-sm focus:outline-none md:w-8/12"
            onChangeAction={(event: any) => {
              valueForAction = event.target.value.toLowerCase()
              handleChange(event)
            }}
            onResetAction={() => searchAction("")}
          />
          {shouldShowSearchIcon && (
            <div className="w-1/8 self-center">
              <i
                className={`text-4xl cursor-pointer ${iconColor} icon-search`}
                onClick={() => searchAction(valueForAction)}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SearchBar
