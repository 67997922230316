import React, { useEffect, useState } from "react"
import Card from "../../components/members/Card"
import Container from "../../components/general/Container"
import { useTranslation } from "react-i18next"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { FullContributorType } from "../../api/ContributorObject"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import ProfileEdit from "./ProfileEdit"
import PasswordEdit from "./PasswordEdit"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"

type ProfileCentersProps = {
  centers: { id: number; name: string; countryCode: string }[]
}
const ProfileCenters = ({ centers }: ProfileCentersProps) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Card>
        <h3>{t("profile.fields.centers")}</h3>
        {centers.length === 0 && <p className="text-md pt-4">{t("profile.fields.noCenter")}</p>}
        {centers.length !== 0 && (
          <div className="flex flex-row justify-center">
            <div className="m-4 w-5/6 text-left grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
              {centers.map((center) => (
                <li key={center.id}>{center.name}</li>
              ))}
            </div>
          </div>
        )}
      </Card>
    </Container>
  )
}

const Profile: React.FC = () => {
  const { t } = useTranslation()
  const [user, setUser] = useState<FullContributorType>()
  const [openErrorSnackbar] = useCustomSnackBar("error")

  useTitle(`${t("menu.profile")} | ${t("applicationName")}`)

  useEffect(() => {
    addActiveCalls()
    execute<FullContributorType>("profile", "GET")
      .then((response) => {
        setUser(response)
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="pb-8">
      <header className="text-white px-3 pb-3 mb-4 block bg-gray-8080 min-h-2.5">
        <h3>{user ? `${user.username} - ${user.userRole.label}` : ""}</h3>
      </header>
      {user && (
        <>
          <ProfileEdit profileId={user!.id} profile={user} />
          <ProfileCenters centers={user!.assignedCenters} />
          <PasswordEdit profileId={user!.id} />
        </>
      )}
    </div>
  )
}

export default Profile
