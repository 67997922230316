import React, { FC } from "react"
import { Color } from "../../utils/Constants"

type BlocTitleProps = {
  title: string
}

const BlocTitle: FC<BlocTitleProps> = ({ title }: BlocTitleProps) => (
  <h3 className={`pb-4 ${Color.TLPOFFERS} text-white`}>{title}</h3>
)

export default BlocTitle
