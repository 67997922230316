import React, { useRef, useState } from "react"
import { Transition } from "@tailwindui/react"
import { CollapseMenuItem } from "./NavBarParameters"
import { useOutsideAlerter } from "../../utils/Components"

type PropsType = {
  name: string
  icon: string
  menuList: CollapseMenuItem[]
}

const NavBarCollapseItem: React.FC<PropsType> = (props: PropsType) => {
  const [isOpen, setOpen] = useState(false)
  const { name, icon, menuList } = props
  const myRef = useRef(null)
  useOutsideAlerter(myRef, setOpen, "mousedown")

  return (
    <div className="ml-3 md-3 relative hover:text-white cursor-pointer py-2" ref={myRef}>
      <div>
        <div onClick={() => setOpen(!isOpen)} className={`text-center font-medium`}>
          <i className={`mx-auto text-5xl  ${icon}`} />
          <div className="mx-auto mt-2 text-sm hidden md:block">{name}</div>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-md z-10">
          <div
            className="py-1 rounded-md bg-white shadow-xs"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            {menuList.map((element) => (
              <div
                onClick={() => {
                  setOpen(!isOpen)
                  element.action()
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                role="menuitem"
                key={element.label}
              >
                {element.label}
              </div>
            ))}
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default NavBarCollapseItem
