import { partialString } from "./Regex"

export const hidePartialString = (string: string): string => {
  return string.replace(partialString, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += "*"
    }
    return gp2
  })
}
