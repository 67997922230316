import React, { FC } from "react"
import FormInputLayout from "../FormInputLayout"
import { Field } from "formik"
import { useTranslation } from "react-i18next"

type DateInputProps = {
  fieldData: {
    formKey: string
    label: string
    required: boolean
    placeholder?: string
    minDate?: string
  }
}

const FormDateInput: FC<DateInputProps> = ({ fieldData }: DateInputProps) => {
  const { t } = useTranslation()
  return (
    <Field name={fieldData.formKey}>
      {({ field, meta }: any) => (
        <FormInputLayout label={fieldData.label} required={fieldData.required} meta={meta}>
          <input
            className={
              "appearance-none border-2 rounded w-full py-3 px-4 leading-tight focus:outline-none focus:border-blueGray-800" +
              (meta && meta.touched && meta.error ? " border-red-500 text-red-700" : " border-gray-200 text-gray-700")
            }
            type="date"
            placeholder={fieldData.placeholder || t("format.date")}
            {...field}
            min={fieldData.minDate ? fieldData.minDate : ""}
          />
        </FormInputLayout>
      )}
    </Field>
  )
}

export default FormDateInput
