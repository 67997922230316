import React from "react"

type DisabledIconProps = {
  icon: string
  iconColor?: string
  title?: string
}

export const DisabledIcon: React.FC<DisabledIconProps> = ({ icon, iconColor, title }: DisabledIconProps) => {
  return (
    <i
      title={title}
      className={`mx-1 ${icon} text-2xl ${iconColor || ""} cursor-not-allowed`}
      onClick={(event) => {
        event.stopPropagation()
      }}
    />
  )
}
