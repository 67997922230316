import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import execute from "../../../api/api"
import { MemberObject } from "../../../api/MemberObject"
import Restricted from "../../Restricted"
import { LPECustomerMessageType, LPECustomerMessageWSType } from "../MembersType"
import { IconModalValidation } from "../../modals/IconModalValidation"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"
import { SeeMoreButton } from "../../general/SeeMoreButton"
import { originMemberEdit } from "../../../utils/Constants"
import { GoToLpeButton } from "../../general/GoToLpeButton"

type MemberPremiumModalProps = {
  member: MemberObject
}

const MemberPremiumModal: React.FC<MemberPremiumModalProps> = (props: MemberPremiumModalProps) => {
  const { member } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [customerMessage, setCustomerMessage] = useState<LPECustomerMessageType>()
  const [openErrorSnackbar] = useCustomSnackBar("error")

  const fetchCustomerMessage = () => {
    setCustomerMessage(undefined)
    addActiveCalls()
    execute<LPECustomerMessageWSType>(
      `partner/customer/${member.id}/messages`,
      "GET",
      {},
      {},
      { language: "en", centerId: member.centerId },
      "lpe",
    )
      .then((res) => setCustomerMessage(res.result))
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(() => {
        setLoaded(true)
        subtractActiveCalls()
      })
  }

  return (
    <div>
      <IconModalValidation
        open={open}
        setOpen={setOpen}
        icon="icon-credit-card"
        title={t("modal.premium.title", {
          user: `${member.firstName} ${member.lastName}`,
        })}
        iconTitle={t("members.actions.tlp")}
        iconStyle="text-3xl"
        buttons={
          <>
            <Restricted right="customers.update">
              <SeeMoreButton member={member} origin={originMemberEdit.TLP} />
            </Restricted>
            <Restricted right="serverlpe.read">
              <GoToLpeButton member={member} />
            </Restricted>
          </>
        }
        onAfterOpen={fetchCustomerMessage}
      >
        <div className="lg:pl-8 py-4 h-full overflow-auto">
          {!loaded ? (
            <div />
          ) : !customerMessage ? (
            <span>{t("modal.premium.noInfo")}</span>
          ) : (
            <div>
              <p>
                {t("modal.premium.clientId.label")}
                {member.id || t("modal.premium.clientId.noId")}
              </p>
              <p>
                {t("modal.premium.transactionConnectId.label")}
                {customerMessage.customer.spaycialId || t("modal.premium.transactionConnectId.noId")}
              </p>
              <p>
                {t("modal.premium.paymentMethod.title")}
                {member.hasPremiumScan
                  ? t("modal.premium.premiumBalance.scan")
                  : member.hasPremiumBank
                  ? t("modal.premium.premiumBalance.bank")
                  : member.hasPremiumTicketRestaurant
                  ? t("modal.premium.premiumBalance.tk")
                  : t("modal.premium.premiumBalance.other")}
              </p>
              <p>
                {t("modal.premium.totalPoint")}
                {customerMessage.customer.totalPoint}
              </p>
              <p>
                {t("modal.premium.premiumBalance.title")}
                {customerMessage.customer.currentBalance}/{customerMessage.customer.threshold}
              </p>
              <p>
                {t("modal.premium.cashbackNumber")}
                {customerMessage.customer.currentMaxCashback}/{customerMessage.customer.maxCashback}
              </p>
              <div>
                <p>{t("modal.premium.issues")}</p>
                <ul className="list-disc ml-8">
                  {customerMessage.customer.messages.length === 0 ? (
                    <span>{t("modal.premium.issuesNone")}</span>
                  ) : (
                    customerMessage.messages.map((message, index) => <li key={index}>{message.title}</li>)
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </IconModalValidation>
    </div>
  )
}

export default MemberPremiumModal
