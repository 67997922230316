// Transforms a number into a string with thousands separators
// convert 12345678 to "12 345 678", 80 to "80" and "1 234" to "1 234", undefined to ""
export const numberWithThousandsSeparator = (x: number, separator = " "): string =>
  x !== undefined
    ? x
        .toString() //convert to string
        .replace(new RegExp(separator), "") //remove eventual commas
        .replace(/\B(?=(\d{3})+(?!\d))/g, separator) // add thousands comma separators
    : ""

// Transforms a number into a string with thousands separators
// convert 12345678 to "12,345,678", 80 to "80" and "1,234" to "1,234", undefined to ""
export const numberWithoutThousandsSeparator = (x: string, separator = " "): number | void => {
  x = x.replace(new RegExp(separator), "") //remove eventual separator
  const y = parseInt(x, 10)
  return isNaN(y) ? undefined : y
}

export const hasNumber = (myString?: string): boolean => {
  if (myString) return /\d/.test(myString)
  return false
}

export const getDateFormatInt = (date: number, dateSelector: string): number => {
  const myDate = new Date(new Date().setTime(date))
  const y = myDate.getFullYear()
  const m = myDate.getMonth()
  let dateToPrint

  if (dateSelector === "week") {
    const firstDay = myDate.getDate() - myDate.getDay() + (myDate.getDay() === 0 ? -6 : 1)
    dateToPrint = myDate.setDate(firstDay)
  } else if (dateSelector === "month") {
    dateToPrint = myDate.setFullYear(y, m, 1)
  } else {
    dateToPrint = new Date(myDate).getTime()
  }
  return dateToPrint
}
