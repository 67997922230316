import React from "react"
import { useTranslation } from "react-i18next"
import { CheckinWS } from "./CheckInType"
import FormInputLayout from "../FormInputLayout"
import { format } from "date-fns"
import { getDays, getPeriods } from "../../views/checkins/CheckInFormConsts"

type CenterSearchProps = {
  checkin: CheckinWS
}

const CheckinInformations: React.FC<CenterSearchProps> = ({ checkin }: CenterSearchProps) => {
  const { t } = useTranslation()
  const daysOptions = getDays(t)
  const currentDate = new Date()
  const currentDay = currentDate.getDay()
  const freqQuantity = (checkin.useRate && parseInt(checkin.useRate[0])) || 1
  const freqPeriod = (checkin.useRate && checkin.useRate[2].toLowerCase()) || "u"
  const freqPeriodLabel = getPeriods(t).find((p) => p.key === freqPeriod)

  return (
    <div>
      <FormInputLayout label="checkins.forms.fields.freq">
        <div className="h-6 text-left">
          <p>
            {freqPeriod !== "u" && freqPeriodLabel
              ? `${freqQuantity} / ${t(freqPeriodLabel.label)}`
              : t("checkins.forms.values.unlimited")}
          </p>
        </div>
      </FormInputLayout>
      <FormInputLayout label="checkins.forms.fields.days">
        <div className="h-6 text-left">
          <p className="flex flex-wrap">
            {daysOptions.map((day) => {
              let style = "pr-2"
              if (day.key === currentDay) {
                style += " font-extrabold"
              }
              if (checkin.daysOfUse?.includes(day.key)) {
                style += " text-green-600"
              } else {
                style += " text-red-600"
              }
              return (
                <span key={day.key} className={style}>
                  {t(day.label)}
                </span>
              )
            })}
          </p>
        </div>
      </FormInputLayout>
      <FormInputLayout label="checkins.forms.fields.isPremium">
        <div className="h-6 text-left">
          <p>{checkin.isPremium ? t("generic.forms.fields.yes") : t("generic.forms.fields.no")}</p>
        </div>
      </FormInputLayout>
      <FormInputLayout label="checkins.forms.fields.from">
        <div className="h-6 text-left">
          {checkin.startDate ? (
            <p className={new Date(checkin.startDate) < new Date() ? "text-green-600" : "text-red-600"}>
              {format(new Date(checkin.startDate), t("generic.format.date"))}
            </p>
          ) : (
            <p className="text-green-600">{t("generic.forms.fields.none")}</p>
          )}
        </div>
      </FormInputLayout>
      <FormInputLayout label="checkins.forms.fields.to">
        <div className="h-6 text-left">
          {checkin.endDate ? (
            <p className={new Date(checkin.endDate) > new Date() ? "text-green-600" : "text-red-600"}>
              {format(new Date(checkin.endDate), t("generic.format.date"))}
            </p>
          ) : (
            <p className="text-green-600">{t("generic.forms.fields.none")}</p>
          )}
        </div>
      </FormInputLayout>
    </div>
  )
}

export default CheckinInformations
