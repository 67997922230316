import { TFunction } from "i18next"
import { ColumnType } from "../TableComponent"
import { UserRole } from "../../utils/Roles"
import * as Yup from "yup"

export const rightsCols: (t: TFunction) => ColumnType[] = (t) => [
  { label: t("generic.table.name") },
  { label: t("roles.table.create"), columnSpecifications: "text-center" },
  { label: t("roles.table.read"), columnSpecifications: "text-center" },
  { label: t("roles.table.update"), columnSpecifications: "text-center" },
  { label: t("roles.table.delete"), columnSpecifications: "text-center" },
]

export const rightsInitialValues: UserRole = {
  name: "",
  order: 0,
  label: "",
  linkedTo: "",
  permissions: {},
}

export const roleValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required("generic.forms.errors.required"),
  label: Yup.string().trim().required("generic.forms.errors.required"),
})

export const cols = ["create", "read", "update", "delete"]

export const newRightInitialValue = {
  name: "",
}

export const newRightValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required("generic.forms.errors.required"),
})
