import { useTranslation } from "react-i18next"
import PieCharts from "./PieCharts"
import React, { useEffect, useState } from "react"
import { execute } from "../../../api/api"
import { useStore } from "effector-react"
import { dataPieType, optinResponse } from "../../../api/ChartObject"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"

type OptinChartDirectiveProps = {
  id: string
}
const OptinChartDirective: React.FC<OptinChartDirectiveProps> = (props: OptinChartDirectiveProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [data, setData] = useState<dataPieType[]>([])
  const [loading, setLoading] = useState(false)

  const storeFilters = useStore(FilterStore)

  useEffect(() => {
    setLoading(true)
    execute<optinResponse>(
      `dashboard/${id}/widget/optinStat`,
      "GET",
      {},
      {},
      { filters: getDashboardFilters(storeFilters) },
    )
      .then((res) => {
        if (res) {
          setData([
            {
              name: t(`dashboard.pie.optin.bothOptin`),
              y: res.bothOptin,
            },
            {
              name: t(`dashboard.pie.optin.optinCommercial`) + "*",
              y: res.optinCommercial,
            },
            {
              name: t(`dashboard.pie.optin.optinSms`),
              y: res.optinSms,
            },
            {
              name: t(`dashboard.pie.optin.noOptin`),
              y: res.noOptin,
            },
          ])
        }
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters, id])

  const title = t("dashboard.pie.optin.title")

  return (
    <PieCharts
      title={title}
      data={data}
      bottomText={t("dashboard.pie.optin.footer")}
      isSecondaryWidget={true}
      loading={loading}
    />
  )
}

export default OptinChartDirective
