import React from "react"
import { HighchartSerie } from "./ConstPremiumEvolution"

type LegendProps = {
  data: HighchartSerie[]
  colors: string[]
}
const Legend: React.FC<LegendProps> = (props: LegendProps) => {
  const { data, colors } = props
  return (
    <div className="flex flex-row space-x-4">
      {Object.values(data).map((option: HighchartSerie, index) => (
        <div className="flex flex-row items-center" key={index}>
          <div className="h-3 w-3 rounded-full mr-2" style={{ backgroundColor: colors[index] }} />
          <label>{option.name}</label>
        </div>
      ))}
    </div>
  )
}

export default Legend
