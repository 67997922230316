import React from "react"
import FormInputLayout from "../FormInputLayout"
import { SetFieldValueType } from "../../utils/Types"

type ColorPickerProps = {
  meta?: any
  onChange?: (event: any) => void
  setFieldValue?: SetFieldValueType
  value?: string
}

const ColorPicker: React.FC<ColorPickerProps> = (props: ColorPickerProps) => {
  const { setFieldValue } = props
  let timer: any
  let valueForAction = ""

  const handleChange = (event: any) => {
    valueForAction = event.target.value
    clearTimeout(timer)
    timer = setTimeout(trigger, 300)
  }

  const trigger = () => {
    if (setFieldValue) {
      setFieldValue("colorCode", valueForAction)
    }
  }

  return (
    <FormInputLayout required meta={props.meta} label="checkins.forms.fields.color">
      <div className="w-20 h-8">
        <input
          type="color"
          className={`appearance-none border-2 rounded px-2 w-full h-full leading-tight focus:outline-none bg-white focus:border-blueGray-800
                            ${
                              props.meta && props.meta.touched && props.meta.error
                                ? "border-red-500 text-red-700"
                                : "border-gray-200 text-gray-700"
                            }`}
          value={props.value}
          onChange={handleChange}
        />
      </div>
    </FormInputLayout>
  )
}

export default ColorPicker
