import React, { useRef, useState } from "react"
import { TFunction } from "i18next"
import { useTranslation } from "react-i18next"
import { useOutsideAlerter } from "../utils/Components"
import FlagIcon from "../styles/customIcons/FlagIcon"

type OptionProps = {
  t: TFunction
  option: any
  keyRef: string
  labelRef: string
  valueRef: string
  onClick: (event: any) => void
  selected: boolean
  lastElement?: boolean
  complementTranslation?: string
  iconCountry?: any
  translate?: boolean
}
const Option: React.FC<OptionProps> = ({
  option,
  t,
  keyRef,
  labelRef,
  valueRef,
  onClick,
  selected,
  lastElement,
  complementTranslation,
  iconCountry,
  translate = true,
}: OptionProps) => {
  return (
    <button
      type="button"
      value={option[valueRef]}
      key={option[keyRef]}
      className={`cursor-pointer w-full ${
        !lastElement ? "border-gray-100  border-b" : ""
      } rounded-t hover:bg-teal-100 focus:outline-none`}
      onClick={onClick}
    >
      <div className={`flex w-full p-2 hover:bg-blueGray-50 ${selected ? "bg-blueGray-50" : ""}`}>
        <div className="w-full items-center flex text-left">
          <div className="mx-2 leading-6 w-3/4" key={option[keyRef]}>
            {iconCountry && <FlagIcon country={option[iconCountry]} />}
            {
              // Sometimes we want to display the value without translating it (from an API)
              // and if the key exists in the translation files we can have wrong string
              translate ? t(`${complementTranslation}${option[labelRef]}`) : option[labelRef]
            }
          </div>
          {selected && <i className="icon-check w-1/4 text-right" />}
        </div>
      </div>
    </button>
  )
}

const defaultValueElement = (title: string, value: string, onClick: (event: any) => void, selected: boolean) => {
  return (
    <button
      type="button"
      value={value}
      key={value}
      className="cursor-pointer w-full border-gray-100  border-b border-gray-100  border-b rounded-t focus:outline-none"
      onClick={onClick}
    >
      <div className={`flex w-full p-2 ${selected ? "bg-blueGray-50" : ""}`}>
        <div className="w-full items-center flex text-left">
          <div className="mx-2 leading-6 w-3/4" key={value}>
            {title}
          </div>
          {selected && <i className="icon-check w-1/4 text-right" />}
        </div>
      </div>
    </button>
  )
}

type SelectProps = {
  value?: string | number
  large?: boolean
  disabled?: boolean
  onChange: (value: any) => void
  meta?: any
  values: any[]
  keyRef?: string
  labelRef?: string
  valueRef?: string
  defaultValue?: string
  defaultValueDisabled?: boolean
  complementTranslation?: string
  isInput?: boolean
  iconCountry?: any
  inline?: boolean
  translate?: boolean
}

const Select: React.FC<SelectProps> = ({
  value,
  large,
  disabled,
  onChange,
  meta,
  values,
  keyRef = "key",
  labelRef = "label",
  valueRef = keyRef,
  defaultValue = "",
  defaultValueDisabled = false,
  complementTranslation = "",
  isInput = false,
  iconCountry,
  inline,
  translate = true,
}: SelectProps) => {
  const { t } = useTranslation()
  const [openSelector, setOpenSelector] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const refInput = useRef(null)
  const refDropdown = useRef(null)

  const valueFind = values.find((valueList) => valueList[valueRef].toString() === value?.toString())

  const handleOpenSelector = () => {
    setOpenSelector((prev) => !prev)
  }

  useOutsideAlerter(refInput, setOpenSelector, "mousedown", refDropdown)

  return (
    <div className={`relative ${inline ? " w-max inline-block" : !large ? " w-full md:w-64" : " w-full"}`}>
      <div
        className={` h-full rounded flex items-center border-2 bg-white ${
          meta && meta.touched && meta.error ? "border-red-400" : "border-gray-200"
        }`}
      >
        <input
          onClick={handleOpenSelector}
          readOnly={!isInput || valueFind}
          ref={refInput}
          type="text"
          className={`h-11 w-full cursor-pointer bg-white px-3 text-left cursor-default sm:text-sm focus:outline-none rounded`}
          value={
            searchValue !== ""
              ? searchValue
              : valueFind
              ? translate
                ? `${t(valueFind![labelRef])}`
                : valueFind![labelRef]
              : defaultValue
          }
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <div onClick={disabled ? undefined : handleOpenSelector}>
          <i className="icon-down cursor-pointer text-black text-lg" />
        </div>
      </div>
      {openSelector && (
        <div
          className="absolute rounded bg-white shadow-sm w-full py-2 px-2 border max-h-48 border-gray-200 text-left overflow-y-scroll z-50"
          ref={refDropdown}
        >
          {!defaultValueDisabled &&
            defaultValue !== "" &&
            defaultValueElement(
              defaultValue,
              "",
              (event) => {
                onChange({ ...event, target: { value: event.currentTarget.value } })
              },
              value === defaultValue,
            )}
          {values
            .filter((element) => element[labelRef].includes(searchValue))
            .map((element, index) => {
              return (
                <Option
                  key={element + index}
                  t={t}
                  option={element}
                  keyRef={keyRef}
                  labelRef={labelRef}
                  valueRef={valueRef}
                  onClick={(event) => {
                    if (event.currentTarget.value === value) {
                      onChange({ ...event, target: { value: "" }, currentTarget: { value: "" } })
                    } else {
                      onChange({ ...event, target: { value: event.currentTarget.value } })
                    }
                    handleOpenSelector()
                    setSearchValue("")
                  }}
                  selected={element[keyRef].toString() === value?.toString()}
                  lastElement={index === values.length - 1}
                  complementTranslation={complementTranslation}
                  iconCountry={iconCountry}
                  translate={translate}
                />
              )
            })}
        </div>
      )}
    </div>
  )
}

export default Select
