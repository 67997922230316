import React from "react"

type BadgeIconProps = {
  value: string
}
export const BadgeIcon: React.FC<BadgeIconProps> = ({ value }: BadgeIconProps) => {
  return (
    <div className="h-5 w-5 bg-red-600 rounded-full flex items-center justify-center mx-1 absolute top-0 right-0 mr-2">
      <label>{value}</label>
    </div>
  )
}
