import React, { FC, useEffect, useRef, useState } from "react"
import HighchartsReact from "highcharts-react-official"
import Highcharts, { Tooltip } from "highcharts"
import { Loader } from "../../Loader"

const getChartConfig = (
  title: string,
  series: any,
  subtitle?: string,
  xAxis?: any,
  yAxis?: any,
  tooltips?: Tooltip | any,
) => {
  return {
    chart: {
      height: "50%",
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subtitle,
    },
    xAxis: xAxis,
    yAxis: yAxis,
    legend: {
      enabled: true,
      align: "center",
      layout: "horizontal",
      verticalAlign: "bottom",
    },
    credits: {
      enabled: false,
    },
    tooltip: tooltips,
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    series: series,
  }
}

type PropsType = {
  title: string
  series: any
  subtitle?: string
  xAxis?: any
  yAxis?: any
  tooltips?: Tooltip | any
  bottomText?: string
  loading: boolean
}

const LineCharts: FC<PropsType> = (props: PropsType) => {
  const { title, series, subtitle, xAxis, yAxis, tooltips, loading } = props
  const chartRef = useRef<any>(null)
  const [chartOptions, setChartOptions] = useState(getChartConfig(title, series, subtitle, xAxis, yAxis, tooltips))

  useEffect(() => {
    setChartOptions(getChartConfig(title, series, subtitle, xAxis, yAxis, tooltips))
    if (chartRef.current) {
      chartRef.current.chart.reflow()
    }
  }, [title, series, subtitle, xAxis, yAxis, tooltips])

  return (
    <div className="bg-white">
      {loading ? <Loader /> : <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />}
    </div>
  )
}

export default LineCharts
