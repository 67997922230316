import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import BarCharts from "./BarCharts"
import { execute } from "../../../api/api"
import { useStore } from "effector-react"
import { dataPieType } from "../../../api/ChartObject"
import { formatDataWithSlider } from "./UtilsBarChart"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { Loader } from "../../Loader"

type MembersBarChartProps = {
  id: string
}
const MembersBarChart: FC<MembersBarChartProps> = (props: MembersBarChartProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [value, setValue] = useState(5)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<dataPieType[]>([])
  const [dataWithSlider, setDataWithSlider] = useState<dataPieType[]>([])

  const storeFilters = useStore(FilterStore)
  const inputProps = {
    value: value,
    min: 1,
    max: 50,
    onChange: (e: any) => setValue(e.target.value),
  }

  useEffect(() => {
    setLoading(true)
    execute(`dashboard/${id}/widget/topCompanies`, "GET", {}, {}, { filters: getDashboardFilters(storeFilters) })
      .then((res: any) => {
        if (res) {
          setData(
            res.map((element: any) => {
              return { name: element.key, y: element.doc_count }
            }),
          )
        }
      })
      .finally(() => setLoading(false))
  }, [storeFilters, id])

  useEffect(() => {
    setDataWithSlider(formatDataWithSlider(data, value, t))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, value]) //data as dependency because the first time we have no info in graph

  return loading ? (
    <Loader />
  ) : (
    <BarCharts
      title={t("dashboard.bar.members.title")}
      data={dataWithSlider}
      xAxisLabel={t("dashboard.bar.members.xAxis")}
      yAxisLabel={t("dashboard.bar.members.yAxis")}
      inputProps={inputProps}
    />
  )
}

export default MembersBarChart
