import * as React from "react"
import { CenterSelectorType } from "./CentersType"
import { useTranslation } from "react-i18next"
import { Field } from "formik"
import FormInputLayout from "../FormInputLayout"
import Select from "../Select"
import { FC } from "react"

type CenterSelectorProps = {
  isCreate?: boolean
  canUpdate?: boolean
  centers: CenterSelectorType[]
  value?: number
  onChange: (event: any) => void
  fieldName: string
  label?: string
}
export const CenterSelector: FC<CenterSelectorProps> = ({
  isCreate,
  canUpdate,
  centers,
  value,
  onChange,
  fieldName,
  label,
}: CenterSelectorProps) => {
  const { t } = useTranslation()

  return (
    <Field name={fieldName}>
      {({ meta }: any) => (
        <FormInputLayout required meta={meta} label={label || "generic.fields.center"}>
          {isCreate || canUpdate ? (
            <Select
              disabled={centers.length <= 1}
              value={centers.length === 1 ? centers[0].id : value}
              onChange={onChange}
              meta={meta}
              values={centers}
              keyRef="id"
              labelRef="name"
              defaultValue={t("generic.forms.selectDefaultValue")}
              defaultValueDisabled
              large
            />
          ) : (
            <p>{centers.find((center) => center.id === value)?.name || t("members.forms.values.noLinkedCenter")}</p>
          )}
        </FormInputLayout>
      )}
    </Field>
  )
}
