import React, { useEffect, useState, useRef } from "react"
import ContributorsFiltersTags from "../../components/contributors/ContributorsFiltersTags"
import IconDropdown from "../../components/general/IconDropdown"
import { useTranslation } from "react-i18next"
import SearchBar from "../../components/SearchBar"
import { useHistory } from "react-router-dom"
import { execute } from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { ContributorType } from "../../api/ContributorObject"
import { Color } from "../../utils/Constants"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useFetchRoles } from "../../utils/hooks/useFetchRoles"
import { UserRole } from "../../utils/Roles"
import {
  ContributorCell,
  ContributorsActions,
  ContributorsCentersTags,
  ContributorsCountryTags,
  getArrayUniqueValueCountry,
} from "../../components/contributors/ContributorsComponents"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"

const TableHead = () => {
  const { t } = useTranslation()
  return (
    <thead className="text-center bg-blueGray-700 sticky top-0 border-b border-gray-200 text-white font-bold tracking-wider">
      <tr>
        <th className="px-6 py-3 w-full md:w-auto text-center md:text-left">{t("contributors.table.username")}</th>
        <th className="px-6 py-3 hidden md:table-cell">{t("generic.table.email")}</th>
        <th className="px-6 py-3 hidden sm:table-cell">{t("contributors.table.role")}</th>
        <th className="px-6 py-3 hidden xl:table-cell">{t("contributors.table.countries")}</th>
        <th className="px-6 py-3 hidden xl:table-cell">{t("contributors.table.centers")}</th>
        <th className="px-1 py-3 hidden md:table-cell">{t("generic.table.actions")}</th>
      </tr>
    </thead>
  )
}

const getContributors = (setTmpList: React.Dispatch<React.SetStateAction<ContributorType[]>>) => {
  addActiveCalls()
  const ContributorsWS = execute<ContributorType[]>("users", "GET", {}, {})
  ContributorsWS.then((res) => {
    if (res) {
      setTmpList(res)
    }
  }).finally(subtractActiveCalls)
}

const Contributors: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const roles = useFetchRoles()
  const [roleFilter, setRoleFilter] = useState<Array<UserRole>>([])
  const [filterContributors, setFilterContributors] = useState("")
  const [contributors, setContributors] = useState<ContributorType[]>([])
  const [countryList, setCountryList] = useState<Array<{ label: string; name: string }>>([])
  const [countriesFilter, setCountryFilter] = useState("")
  const [centersFilter, setCenterFilter] = useState("")
  const [centerList, setCenterList] = useState<Array<{ label: string; name: string; country: string }>>([])
  const immutableCenterList = useRef<Array<{ label: string; name: string; country: string }>>([])
  const [showOnlyActiveProfile, setShowOnlyActiveProfile] = useState<boolean>(false)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const myRole = useStore(ContributorStore).role
  // Dynamically update browser tab title
  useTitle(`${t("menu.users")} | ${t("applicationName")}`)

  useEffect(() => {
    getContributors(setContributors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const countries = contributors.flatMap((contributor) => getArrayUniqueValueCountry(contributor, t))
    const withoutDoublon = countries
      .filter((value, index) => countries.indexOf(value) === index)
      .map((country) => ({ label: country, name: country }))
    withoutDoublon.sort((a, b) => a.label.localeCompare(b.label))
    setCountryList(withoutDoublon)
  }, [contributors])

  useEffect(() => {
    const centers = contributors
      .flatMap(({ assignedCenters }) => assignedCenters.map(({ name, countryCode }) => ({ name, countryCode })))
      .map((center) => ({ label: center.name, name: center.name, country: t(`country.${center.countryCode}`) }))

    const uniqueArray = centers.filter((value, index) => {
      const _value = JSON.stringify(value)
      return (
        index ===
        centers.findIndex((obj) => {
          return JSON.stringify(obj) === _value
        })
      )
    })

    uniqueArray.sort((a, b) => a.label.localeCompare(b.label))

    setCenterList(uniqueArray)

    immutableCenterList.current = uniqueArray
  }, [contributors])

  useEffect(() => {
    if (countriesFilter.length > 0) {
      setCenterList([...immutableCenterList.current.filter(({ country }) => country === countriesFilter)])
    } else {
      setCenterList(immutableCenterList.current)
    }
  }, [countriesFilter])

  function filterContains(item: ContributorType) {
    return (
      item.username.toLowerCase().includes(filterContributors.toLowerCase()) || item.email.includes(filterContributors)
    )
  }

  const editUser = (id: number) => {
    history.push(`/contributors/${id}/edit`)
  }

  const disableUser = (id: number) => {
    addActiveCalls()
    execute(`users/${id}/deactivate`, "PATCH")
      .then(() => {
        getContributors(setContributors)
        openSuccessSnackbar(
          `${t("contributors.snackbar.title")} ${t("generic.snackbar.success.disable")}`,
          snackbarDuration,
        )
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
  }

  const enableUser = (id: number) => {
    addActiveCalls()
    execute(`users/${id}/reactivate`, "PATCH")
      .then(() => {
        getContributors(setContributors)
        openSuccessSnackbar(
          `${t("contributors.snackbar.title")} ${t("generic.snackbar.success.enable")}`,
          snackbarDuration,
        )
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
  }

  const deleteUser = (id: number) => {
    addActiveCalls()
    execute(`users/${id}`, "DELETE")
      .then(() => {
        getContributors(setContributors)
        openSuccessSnackbar(
          `${t("contributors.snackbar.title")} ${t("generic.snackbar.success.delete")}`,
          snackbarDuration,
        )
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
  }

  const dropdownActions = (isActive: boolean, id: number) => [
    {
      title: t("contributors.buttons.edit"),
      icon: "icon-edit",
      right: "users.update",
      action: () => editUser(id),
    },
    {
      title: isActive ? t("contributors.buttons.disable") : t("contributors.buttons.enable"),
      icon: isActive ? "icon-delete-user" : "icon-add-user",
      right: "users.update",
      action: () => {
        if (isActive) {
          disableUser(id)
        } else {
          enableUser(id)
        }
      },
    },
    {
      title: t("generic.buttons.delete"),
      icon: "icon-delete",
      iconColor: "text-red-500",
      right: "users.delete",
      action: () => deleteUser(id),
    },
  ]

  const filterByCountry = (element: ContributorType) =>
    !countriesFilter.length ||
    element.countries.some((country) => countriesFilter === country.name) ||
    element.assignedCenters.some((country) => countriesFilter === t(`country.${country.countryCode}`))

  const filterByRole = (element: ContributorType) =>
    !roleFilter.length || roleFilter.some((item) => item.name === element.role)

  const filterByCenter = (element: ContributorType) =>
    !centersFilter.length || element.assignedCenters.some((element) => centersFilter === element.name)

  const filterByActiveProfile = (element: ContributorType) => !showOnlyActiveProfile || element.isActivated

  return (
    <>
      <div className="w-full h-16">
        <SearchBar
          resource="users"
          barColor={Color.CONTRIBUTORS}
          searchAction={(value) => setFilterContributors(value)}
          addAction={() => history.push("/contributors/create")}
        />
      </div>
      <div className="m-8 max-h-full">
        <ContributorsFiltersTags
          roles={roles}
          countryList={countryList}
          roleFilter={roleFilter}
          setRoleFilter={setRoleFilter}
          countriesFilter={countriesFilter}
          setCountryFilter={setCountryFilter}
          setCenterFilter={setCenterFilter}
          centerList={centerList}
          centersFilter={centersFilter}
          setShowOnlyActiveProfile={setShowOnlyActiveProfile}
        />
        <div className="mt-4 pb-4">
          <table className="border-collapse border-1 border-blueGray-100 table-fixed w-full whitespace-no-wrap bg-white relative rounded shadow-xl">
            <TableHead />
            <tbody>
              {contributors
                .filter(filterContains)
                .sort((a, b) => a.username.localeCompare(b.username))
                .filter(filterByRole)
                .filter(filterByCountry)
                .filter(filterByCenter)
                .filter(filterByActiveProfile)
                .map((element) => (
                  <tr
                    key={element.username}
                    className={`${
                      element.isActivated
                        ? "text-gray-700 hover:bg-gray-300 hover:bg-opacity-30"
                        : "bg-blueGray-300 hover:bg-blueGray-500 text-white"
                    }`}
                  >
                    <td className="border-t border-gray-200 w-full flex justify-between md:table-cell md:text-left py-2 px-2">
                      <div className="overflow-x-hidden flex-grow-1">
                        <span>{element.username}</span>
                      </div>
                      {element.role !== "ROLE_SUPER_ADMIN" && (
                        <div className="text-right flex-grow-0 md:hidden">
                          <IconDropdown
                            icon="icon-mobile-menu"
                            items={dropdownActions(element.isActivated, element.id)}
                          />
                        </div>
                      )}
                    </td>
                    <ContributorCell
                      label={element.email}
                      className="hidden md:table-cell overflow-x-hidden text-left"
                    />

                    <ContributorCell
                      label={roles.find((key) => key.name === element.role)?.label || ""}
                      className="hidden sm:table-cell overflow-x-hidden"
                    />
                    <ContributorsCountryTags contributor={element} />
                    <ContributorsCentersTags contributor={element} countryToFilter={countriesFilter} />
                    <td className="border-t border-gray-200 overflow-x-hidden hidden md:table-cell">
                      {element.role !== "ROLE_SUPER_ADMIN" && element.role !== myRole && (
                        <ContributorsActions
                          contributor={element}
                          actionsDropdown={dropdownActions(element.isActivated, element.id)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Contributors
