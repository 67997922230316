import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Color } from "../../../utils/Constants"
import BreadcrumbCountries from "../../../components/dashboard/BreadcrumbCountries"
import CockpitDateFilter from "../CockpitDateFilter"
import { execute } from "../../../api/api"
import { getTLPDashboardFilters } from "../ChartsUtils"
import { useStore } from "effector-react"
import { FilterStore } from "../../../store/FilterStore"
import { Loader } from "../../../components/Loader"
import { Cell, CenterLine, CountryLine, TableLineType, TotalLine } from "../../../components/dashboard/CockpitTable"
import { initialPremiumCockpitTotal, premiumCockpitColumnsHeaderLabels } from "../CockpitConstant"
import { CockpitCenterObjectiveType, CockpitCountryObjectiveType, PremiumCockpitObjectiveType } from "../CockpitType"
import { useTitle } from "../../../utils/hooks/useTitle"
import Restricted from "../../../components/Restricted"
import { useFetchAllCenters } from "../../../utils/hooks/centers/useFetchAllCenters"
import { ContributorType } from "../../../api/ContributorObject"
import { UserInitial } from "../../../store/ContributorStore"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"

const getTotal = (centersByCountry: CockpitCountryObjectiveType<PremiumCockpitObjectiveType>[]) => {
  return centersByCountry.reduce(
    (acc: PremiumCockpitObjectiveType, curr) => {
      Object.keys(acc).forEach(
        (key) => (acc[key as keyof PremiumCockpitObjectiveType] += curr[key as keyof PremiumCockpitObjectiveType]),
      )
      return acc
    },
    { ...initialPremiumCockpitTotal },
  )
}

const toggleFavoriteCenter = (userId: number, centerId: number, isFavorite: boolean, refresh: () => void) => {
  const method = isFavorite ? "DELETE" : "POST"
  execute<ContributorType>(`profile/favoriteCenter/${centerId}`, method).then(refresh)
}

const PremiumDashboard: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [centersByCountries, setCentersByCountries] = useState<
    CockpitCountryObjectiveType<PremiumCockpitObjectiveType>[]
  >([])
  const [total, setTotal] = useState<PremiumCockpitObjectiveType>()
  const { allActivePremiumCentersByCountry } = useFetchAllCenters()
  const [favoriteCenters, setFavoriteCenters] = useState<CockpitCenterObjectiveType<PremiumCockpitObjectiveType>[]>([])
  const [me, setMe] = useState<ContributorType>(UserInitial)
  const [refresh, setRefresh] = useState(0)
  const storeFilters = useStore(FilterStore)

  // Dynamically update browser tab title
  useTitle(`${t("menu.premiumDashboard")} | ${t("applicationName")}`)

  useEffect(() => {
    setLoading(true)
    execute<CockpitCountryObjectiveType<PremiumCockpitObjectiveType>[]>(
      "cockpit/premium",
      "GET",
      {},
      {},
      { filters: getTLPDashboardFilters(storeFilters) },
    )
      .then((cockpitCountries) => {
        setCentersByCountries(cockpitCountries)
        setTotal(getTotal(cockpitCountries))
      })
      .finally(() => setLoading(false))
  }, [storeFilters])

  useEffect(() => {
    if (setCentersByCountries.length > 0) {
      addActiveCalls()
      execute<ContributorType>("profile", "GET")
        .then((me) => {
          setMe(me)
          const favoriteCenters: CockpitCenterObjectiveType<PremiumCockpitObjectiveType>[] = []
          centersByCountries.forEach((centersByCountry) => {
            centersByCountry.centers.forEach((center) => {
              if (me.favoriteCenters.some((favoriteCenter) => favoriteCenter.id === center.id)) {
                favoriteCenters.push(center)
              }
            })
          })
          setFavoriteCenters(favoriteCenters)
        })
        .finally(subtractActiveCalls)
    }
  }, [centersByCountries, refresh])

  return (
    <div className="bg-blueGray-50">
      <div className={`w-full h-2 ${Color.PREMIUM}`} />
      <div className="flex-auto h-full flex flex-col">
        <Restricted right="dashboard.read">
          <BreadcrumbCountries premium countries={allActivePremiumCentersByCountry} />
        </Restricted>
        <CockpitDateFilter />
        {centersByCountries.length > 0 && (
          <div className="flex-auto overflow-y-auto relative m-3 bg-white">
            <div className="lg:col-span-2 row-span-2">
              <div className="overflow-y-auto relative bg-white">
                {loading ? (
                  <Loader />
                ) : (
                  <table className="table-auto table-striped relative bg-white rounded shadow-xl">
                    <thead className="sticky top-0 text-center text-blueGray-700 font-bold tracking-wider ">
                      <tr className="bg-white">
                        {premiumCockpitColumnsHeaderLabels.map((premiumCockpitColumnHeaderLabel, index) => (
                          <Cell
                            key={premiumCockpitColumnHeaderLabel}
                            value={t(premiumCockpitColumnHeaderLabel) as string}
                            index={index}
                            type={TableLineType.HEADER}
                          />
                        ))}
                      </tr>
                      {total && <TotalLine total={total} premium />}
                    </thead>
                    <tbody>
                      {favoriteCenters.length > 0 && (
                        <>
                          <tr className="bg-blueGray-900 text-white font-bold">
                            <Cell
                              value={t("dashboard.table.favoriteCenters") as string}
                              type={TableLineType.SEPARATOR}
                            />
                          </tr>
                          {favoriteCenters.map((center, lineIndex) => (
                            <CenterLine
                              key={`favorite_${center.id}`}
                              center={center}
                              lineIndex={lineIndex}
                              isFavorite
                              onFavoriteIconClick={(isFavorite) =>
                                toggleFavoriteCenter(me.id, center.id, isFavorite, () => setRefresh(refresh + 1))
                              }
                              premium
                            />
                          ))}
                        </>
                      )}
                      <tr className="bg-blueGray-900 text-white font-bold">
                        <Cell value={t("dashboard.table.allCenters") as string} type={TableLineType.SEPARATOR} />
                      </tr>
                      {centersByCountries.map((centersByCountry) => (
                        <React.Fragment key={centersByCountry.country}>
                          <CountryLine centersByCountry={centersByCountry} premium />
                          {centersByCountry.centers.map((center, lineIndex) => (
                            <CenterLine
                              key={center.id}
                              center={center}
                              lineIndex={lineIndex}
                              isFavorite={favoriteCenters.some((favoriteCenter) => favoriteCenter.id === center.id)}
                              onFavoriteIconClick={(isFavorite) =>
                                toggleFavoriteCenter(me.id, center.id, isFavorite, () => setRefresh(refresh + 1))
                              }
                              premium
                            />
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PremiumDashboard
