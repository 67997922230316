import React, { FC, ReactChild } from "react"

type ContainerProps = {
  children: ReactChild
}

const Container: FC<ContainerProps> = (props: ContainerProps) => {
  const { children } = props

  return <div className="px-4 mx-auto md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">{children}</div>
}

export default Container
