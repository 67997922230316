import React, { FC, useState } from "react"
import Select from "../Select"
import { CentersWS } from "./CentersType"
import { useTranslation } from "react-i18next"
import CentersByCountryDesktopDropdown from "./CentersByCountryDesktopDropdown"
import CentersByCountryMobileDropDown from "./CentersByCountryMobileDropDown"

type CentersByCountryDropdownType = {
  centersByCountry: CentersWS[]
  selectedCenters: CentersWS
  setSelectedCenters: (center: CentersWS) => void
  label?: string
  defaultValue?: string
}

const CentersByCountryDropdown: FC<CentersByCountryDropdownType> = ({
  centersByCountry,
  selectedCenters,
  setSelectedCenters,
  label,
  defaultValue,
}: CentersByCountryDropdownType) => {
  const { t } = useTranslation()
  const [filterCenter, setFilterCenter] = useState("")
  const centersByCountrySorted = centersByCountry.sort((a, b) =>
    t(`country.${a.country}`).localeCompare(t(`country.${b.country}`)),
  )

  const handleChangeCenter = (event: any) => {
    setFilterCenter(event.target.value)
    setSelectedCenters(event.target.value)
  }

  if (
    !centersByCountry ||
    centersByCountry.length === 0 ||
    (centersByCountry.length === 1 && centersByCountry[0].centers.length === 1)
  ) {
    return null
  } else {
    return (
      <div className="w-full md:w-64">
        <p className="text-xs text-left pl-2 pb-1 cursor-default">{t(label || "members.tags.titleGroup.centers")}</p>

        {centersByCountry.length > 1 && (
          <>
            <CentersByCountryDesktopDropdown
              centersByCountry={centersByCountrySorted}
              selectedCenters={selectedCenters}
              setSelectedCenters={setSelectedCenters}
              defaultValue={defaultValue}
            />
            <CentersByCountryMobileDropDown
              centersByCountry={centersByCountrySorted}
              selectedCenters={selectedCenters}
              setSelectedCenters={setSelectedCenters}
            />
          </>
        )}

        {centersByCountrySorted.length === 1 && centersByCountrySorted[0].centers.length > 1 && (
          <Select
            value={filterCenter}
            onChange={handleChangeCenter}
            values={centersByCountrySorted[0].centers}
            keyRef="id"
            labelRef="name"
            defaultValue={t(defaultValue || "generic.forms.fields.allCenters")}
            large
          />
        )}
      </div>
    )
  }
}

export default CentersByCountryDropdown
