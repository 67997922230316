import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PieCharts from "../pieCharts/PieCharts"
import { execute } from "../../../api/api"
import { useStore } from "effector-react"
import { DataType, PremiumPaymentObject } from "../../../api/PremiumObject"
import { FilterStore } from "../../../store/FilterStore"
import { getTLPDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { ColorGraphs } from "../../../utils/Constants"

type PaymentMethodGraphProps = {
  id: string
}
const PaymentMethodGraph: FC<PaymentMethodGraphProps> = (props: PaymentMethodGraphProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<DataType[]>([])

  const filterStore = useStore(FilterStore)

  useEffect(() => {
    setLoading(true)

    execute<PremiumPaymentObject>(
      `dashboard/${id}/widget/premium/paymentMethod`,
      "GET",
      {},
      {},
      { filters: getTLPDashboardFilters(filterStore) },
    )
      .then((res) => {
        setData([
          { name: t("premiumDashboard.pie.payment.bank"), y: res.paymentBank },
          {
            name: t("premiumDashboard.pie.payment.tr"),
            y: res.paymentTicketRestaurant,
          },
          { name: t("premiumDashboard.pie.payment.scan"), y: res.paymentScan },
          { name: t("premiumDashboard.pie.payment.both"), y: res.paymentBoth },
        ])
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore, id])

  return (
    <PieCharts
      title={t("premiumDashboard.pie.payment.title")}
      data={data}
      isSecondaryWidget={false}
      loading={loading}
      colors={[ColorGraphs.blue, ColorGraphs.red, ColorGraphs.green, ColorGraphs.orange]}
    />
  )
}

export default PaymentMethodGraph
