import React, { FC } from "react"
import InputField from "../general/InputField"
import { Field, FormikValues, useFormikContext } from "formik"
import FormInputLayout from "../FormInputLayout"
import Select from "../Select"
import { formValuesType, ItemConditionType, RewardSettingsItemType } from "./TlpOffersTypes"
import { useTranslation } from "react-i18next"
import CheckboxInput from "../general/CheckboxInput"

const displayItem = (formValues: formValuesType, conditions: ItemConditionType[]) =>
  !conditions ||
  !conditions.some(
    (condition) =>
      formValues[condition.conditionKey] === undefined ||
      formValues[condition.conditionKey].toString() !== condition.conditionValue.toString(),
  )

type RewardSettingsProps = {
  rewardSettingsItems: RewardSettingsItemType[]
  values: FormikValues
  showHiddenParams?: boolean
}

const RewardSettings: FC<RewardSettingsProps> = ({
  rewardSettingsItems,
  values,
  showHiddenParams = false,
}: RewardSettingsProps) => {
  const { t } = useTranslation()
  const formikContext = useFormikContext()

  return (
    <div className="text-left flex flex-row flex-wrap items-center gap-3">
      {rewardSettingsItems
        .filter((item) => item.hidden === showHiddenParams)
        .map((item, index) => {
          const key = (showHiddenParams ? "hidden-" : "") + index
          if (displayItem(values, item.conditions)) {
            switch (item.type) {
              case "Text":
                return (
                  <p key={key} className="inline">
                    {item.values[0].label}
                  </p>
                )
              case "NumberPicker":
                return (
                  <InputField
                    key={key}
                    fieldData={{
                      formKey: item.fieldKey,
                      type: "number",
                      initValue: item.initialValue || "",
                      min: item.minimumValue || "0",
                    }}
                    inline
                  />
                )
              case "Selector":
                return (
                  <Field key={key} name={item.fieldKey}>
                    {({ meta }: any) => (
                      <FormInputLayout required meta={meta} inline>
                        <Select
                          onChange={(event) => formikContext.setFieldValue(item.fieldKey, event.target.value)}
                          meta={meta}
                          value={values[item.fieldKey]}
                          large
                          values={item.values}
                          keyRef="fieldValue"
                          defaultValue={t("generic.forms.selectDefaultValue")}
                          defaultValueDisabled
                          inline
                        />
                      </FormInputLayout>
                    )}
                  </Field>
                )
              case "Checkbox":
                return (
                  <CheckboxInput
                    key={key}
                    fieldData={{
                      formKey: item.fieldKey,
                      required: false,
                      defaultValue: item.initialValue || false,
                    }}
                    inline
                  />
                )
              default:
                return <div key={key} className="w-full h-4" />
            }
          } else {
            return null
          }
        })}
    </div>
  )
}

export default RewardSettings
