import * as React from "react"
import { Field } from "formik"
import FormInputLayout from "../../FormInputLayout"
import { membersFormFields } from "./MembersFormConsts"
import InputField from "../../general/InputField"

export const getMemberFormFields = (
  isCreate: boolean,
  isUKMembers: boolean,
  isPartialMember: boolean,
): React.ReactElement => {
  return (
    <div>
      {membersFormFields.map((formField, index) => {
        return isUKMembers || (!isCreate && formField.disabled) ? (
          <FormInputLayout label={formField.label} key={index}>
            <Field name={formField.formKey}>{({ field }: any) => <p>{field.value}</p>}</Field>
          </FormInputLayout>
        ) : (
          <InputField
            key={formField.formKey}
            fieldData={{
              ...formField,
              required:
                isPartialMember && formField.requiredPartial !== undefined
                  ? formField.requiredPartial
                  : formField.required,
            }}
          />
        )
      })}
    </div>
  )
}
