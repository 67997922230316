import React, { useState } from "react"
import TableComponent, { TableCell, TableRow } from "../../TableComponent"
import { cashbackHistoryCols, membersCheckInsCols, membersLogsCols } from "./MembersFormConsts"
import { useTranslation } from "react-i18next"
import { TransactionStatusType, TransactionsType, LogsType } from "../../../api/MemberObject"
import { format } from "date-fns"
import { CheckinUse } from "../../checkin/CheckInType"
import { ActionIcon } from "../../ActionIcon"
import DisplayLogModal from "../modal/DisplayLogModal"

type CheckInHistoryTableProps = {
  checkinsUses: CheckinUse[]
}

export const CheckInHistoryTable: React.FC<CheckInHistoryTableProps> = ({ checkinsUses }: CheckInHistoryTableProps) => {
  const { t } = useTranslation()
  return (
    <div className="mx-auto w-full md:w-1/2">
      <TableComponent columnNames={membersCheckInsCols(t)}>
        {checkinsUses.map((el, index) => (
          <TableRow key={index}>
            <TableCell>{el.name}</TableCell>
            <TableCell centered>{format(new Date(el.createdAt), t("generic.format.dateMinuteTime"))}</TableCell>
          </TableRow>
        ))}
      </TableComponent>
    </div>
  )
}

const getStatusColor = (status: TransactionStatusType): string => {
  switch (status) {
    case "received":
    case "validated":
      return "text-green-500"
    case "refused":
    case "error":
    case "deleted":
    case "rejected":
    case "retryable":
    case "expired":
    case "pending":
      return "text-red-500"
    default:
      return "text-black"
  }
}

type CashbackHistoryTableProps = {
  transactions: TransactionsType[]
}
export const CashbackHistoryTable: React.FC<CashbackHistoryTableProps> = ({
  transactions,
}: CashbackHistoryTableProps) => {
  const { t } = useTranslation()
  const [seeMore, setSeeMore] = useState(false)

  return (
    <div>
      <TableComponent columnNames={cashbackHistoryCols(t)}>
        {transactions.map(
          (el, index) =>
            (seeMore || index < 5) && (
              <TableRow key={index}>
                <TableCell>{format(new Date(el.date), t("generic.format.date"))}</TableCell>
                <TableCell displayCol>
                  <span className="capitalize text-left">
                    {el.EarnCashback === "earn" ? t("members.table.transaction") : t("members.table.cashback")}
                  </span>
                  {el.EarnCashback === "cashback" && (
                    <span className="whitespace-no-wrap pl-2">{t("members.table.cashbackOffer")}</span>
                  )}
                </TableCell>
                <TableCell centered>
                  {el.amount} {el.currency}
                </TableCell>
                <TableCell centered>
                  {el.EarnCashback === "cashback" ? (
                    <span className={getStatusColor(el.status)}>{el.status}</span>
                  ) : el.status === "rejected" ? (
                    <span className={getStatusColor(el.status)}>{el.status}</span>
                  ) : (
                    <span className={el.points > 0 ? "text-green-500" : "text-red-500"}>+{el.points}pts</span>
                  )}
                </TableCell>
                <TableCell styleProp="text-left">{el.comment}</TableCell>
              </TableRow>
            ),
        )}
      </TableComponent>
      {transactions.length > 5 && !seeMore && (
        <button
          type="button"
          className="mt-4 px-2 py-1 rounded border-1 border-blueGray-500"
          onClick={() => setSeeMore(true)}
        >
          {t("generic.buttons.more")}
        </button>
      )}
    </div>
  )
}

type LogsHistoryTableProps = {
  logs: LogsType[]
}
export const LogsHistoryTable: React.FC<LogsHistoryTableProps> = ({ logs }: LogsHistoryTableProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <div className="mx-auto w-full">
      <TableComponent columnNames={membersLogsCols(t)}>
        {logs.map((element, index) => (
          <>
            <DisplayLogModal log={element} open={open} setOpen={setOpen} />
            <TableRow key={index}>
              <TableCell>{format(new Date(element.date), t("generic.format.dateMinuteTime"))}</TableCell>
              <TableCell>{element.origin}</TableCell>
              <TableCell>{element.log_code}</TableCell>
              <TableCell>{element.ip_address}</TableCell>
              <TableCell>{element.user_agent}</TableCell>
              <TableCell>{element.log_summary}</TableCell>
              <TableCell>
                <ActionIcon icon="icon-eye" onClick={() => setOpen(true)} />
              </TableCell>
            </TableRow>
          </>
        ))}
      </TableComponent>
    </div>
  )
}
