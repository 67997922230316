import React, { FC, useEffect, useRef, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { ColorGraphs } from "../../../utils/Constants"

const getChartConfig = (
  title: string,
  data: { name?: string; y: number }[],
  format?: string,
  subtitle?: string,
  colors?: string[],
  xAxisLabel?: string,
  yAxisLabel?: string,
) => {
  return {
    chart: {
      type: "bar",
      backgroundColor: "transparent",
      colorByPoint: true,
      height: "300px",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: yAxisLabel,
      },
    },
    xAxis: {
      title: {
        text: xAxisLabel,
      },
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subtitle,
    },
    colors: colors
      ? colors
      : [
          ColorGraphs.blue,
          "#434343",
          ColorGraphs.green,
          ColorGraphs.orange,
          ColorGraphs.purple,
          ColorGraphs.teal,
          ColorGraphs.red,
        ], //default color
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y}",
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "<div>{point.name} : <b>{point.y}</b><br/></div>",
    },
    series: [
      {
        data: data,
        colorByPoint: true,
      },
    ],
  }
}

export type inputRangeChart = {
  value: number
  min: number
  max: number
  onChange: (e: any) => void
}

type PropsType = {
  title: string
  subtitle?: string
  format?: string
  data: { name?: string; y: number }[]
  colors?: string[]
  headerText?: string
  xAxisLabel?: string
  yAxisLabel?: string
  inputProps?: inputRangeChart
}

const BarCharts: FC<PropsType> = (props: PropsType) => {
  const { title, subtitle, format, data, colors, headerText, xAxisLabel, yAxisLabel, inputProps } = props
  const chartRef = useRef<any>(null)
  const [chartOptions, setChartOptions] = useState(
    getChartConfig(title, data, format, subtitle, colors, yAxisLabel, xAxisLabel),
  )

  useEffect(() => {
    setChartOptions(getChartConfig(title, data, format, subtitle, colors, yAxisLabel, xAxisLabel))
  }, [title, data, format, subtitle, colors, yAxisLabel, xAxisLabel])

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.reflow()
    }
  }, [data])

  return (
    <div className="bg-lightBlue-50 flex flex-col h-full">
      {headerText && <div className="bg-blueGray-100">{headerText}</div>}
      <HighchartsReact
        classeName={`${headerText ? "h-full" : ""}`}
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
      {inputProps && (
        <div className="flex flex-row align-left ml-4 mb-2 font-bold">
          <div className="w-5"> {inputProps.value}</div>
          <input
            className="ml-2"
            type="range"
            min={inputProps.min}
            max={inputProps.max}
            value={inputProps.value}
            onChange={inputProps.onChange}
          />
        </div>
      )}
    </div>
  )
}

export default BarCharts
