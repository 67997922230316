import * as Yup from "yup"
import { UserRole } from "../../utils/Roles"
import { passwordRegex, phoneNumberRegex } from "../../utils/Regex"

export const initValues = {
  role: "",
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  password2: "",
  assignedCenters: [],
  center: [],
  countries: [],
}

export const contributorsFormFields = [
  {
    formKey: "username",
    label: "contributors.forms.fields.username",
    required: true,
  },
  {
    formKey: "firstName",
    label: "generic.forms.fields.firstname",
    required: true,
  },
  {
    formKey: "lastName",
    label: "generic.forms.fields.lastname",
    required: true,
  },
  {
    formKey: "email",
    label: "generic.forms.fields.email",
    required: true,
  },
  {
    formKey: "phone",
    label: "generic.forms.fields.phone",
    required: false,
  },
]

export const passwordContributorsFormFields = [
  {
    formKey: "password",
    label: "generic.forms.fields.password",
    type: "password",
    required: true,
  },
  {
    formKey: "password2",
    label: "contributors.forms.fields.password2",
    type: "password",
    required: true,
  },
]

export const mainContributorsFormValidations = (selectedRole?: UserRole): { [key: string]: any } => {
  return {
    role: Yup.string().required("generic.forms.errors.required"),
    username: Yup.string()
      .min(2, "generic.forms.errors.min")
      .max(50, "generic.forms.errors.max")
      .required("generic.forms.errors.required"),
    firstName: Yup.string()
      .min(2, "generic.forms.errors.min")
      .max(50, "generic.forms.errors.max")
      .required("generic.forms.errors.required"),
    lastName: Yup.string()
      .min(2, "generic.forms.errors.min")
      .max(50, "generic.forms.errors.max")
      .required("generic.forms.errors.required"),
    email: Yup.string().email("generic.forms.errors.invalidEmail").required("generic.forms.errors.required"),
    phone: Yup.string().matches(phoneNumberRegex, "generic.forms.errors.invalidPhone").nullable(),
    assignedCenters:
      selectedRole?.linkedTo === "centers" || selectedRole?.linkedTo === "center"
        ? Yup.array().min(1, "generic.forms.errors.minValue")
        : undefined,
    countries: selectedRole?.linkedTo === "countries" ? Yup.array().min(1, "generic.forms.errors.minValue") : undefined,
  }
}
export const passwordValidations = {
  password: Yup.string()
    .required("generic.forms.errors.required")
    .matches(passwordRegex, "generic.forms.errors.passwordValidation"),
  password2: Yup.string()
    .required("generic.forms.errors.required")
    .oneOf([Yup.ref("password")], "generic.forms.errors.matchPassword"),
}
