import React, { useState } from "react"
import { MemberCheckinsUses, MemberObject } from "../../../api/MemberObject"
import { useTranslation } from "react-i18next"
import execute from "../../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import Restricted from "../../Restricted"
import { CheckinUse } from "../../checkin/CheckInType"
import { IconModalValidation } from "../../modals/IconModalValidation"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"
import { SeeMoreButton } from "../../general/SeeMoreButton"
import { originMemberEdit } from "../../../utils/Constants"

type MemberCheckInsModalProps = {
  member: MemberObject
}

const MemberCheckInsModal: React.FC<MemberCheckInsModalProps> = (props: MemberCheckInsModalProps) => {
  const { member } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [servicesUses, setServicesUses] = useState<CheckinUse[]>()
  const [openErrorSnackbar] = useCustomSnackBar("error")

  const fetchCheckInUses = () => {
    addActiveCalls()
    execute<MemberCheckinsUses>(`customers/${member.id}/checkinsUses`, "GET")
      .then((res) => setServicesUses(res.services))
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
  }

  return (
    <IconModalValidation
      open={open}
      setOpen={setOpen}
      icon="icon-loyalty-card"
      title={t("modal.checkins.title", {
        user: `${member.firstName} ${member.lastName}`,
      })}
      iconTitle={t("checkins.snackbar.title")}
      iconStyle="text-3xl"
      buttons={
        <Restricted right="customers.update">
          <SeeMoreButton member={member} origin={originMemberEdit.CHECKINS} />
        </Restricted>
      }
      onAfterOpen={fetchCheckInUses}
    >
      <div className="lg:pl-8 pt-4 pb-8 h-full overflow-auto">
        {!servicesUses ? (
          <div />
        ) : servicesUses.length === 0 ? (
          <p>{t("modal.checkins.description")}</p>
        ) : (
          <ul>
            {servicesUses.map((element) => (
              <li key={element.name} className="py-1">
                {element.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </IconModalValidation>
  )
}

export default MemberCheckInsModal
