import React, { FC, useEffect, useState } from "react"
import TableComponent, { TableCell, TableRow } from "../../components/TableComponent"
import { useTranslation } from "react-i18next"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { TlpOffersWSType, TlpOfferType } from "../../components/tlpOffers/TlpOffersTypes"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"
import { Color } from "../../utils/Constants"
import Restricted from "../../components/Restricted"
import AddButton from "../../components/general/AddButton"
import { useHistory } from "react-router-dom"
import { ActionIcon } from "../../components/ActionIcon"
import TlpOffersModal from "../../components/tlpOffers/TlpOffersModal"
import FlagIcon from "../../styles/customIcons/FlagIcon"
import Search from "../../components/general/Search"
import TlpOffersEnumFilter from "../../components/tlpOffers/TlpOffersEnumFilter"
import CentersByCountryDropdown from "../../components/centers/CentersByCountryDropdown"
import { CentersWS } from "../../components/centers/CentersType"
import { useFetchCentersInUserScope } from "../../utils/hooks/centers/useFetchCentersInUserScope"
import { Status } from "../../components/tlpOffers/TlpOfferFormConst"
import { format } from "date-fns"
import { IconWithConfirmation } from "../../components/modals/IconConfirmationModal"

const TlpOffers: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { activePremiumCentersInUserScope, activePremiumCentersInUserScopeByCountry } = useFetchCentersInUserScope()
  const [tlpOffers, setTlpOffers] = useState<TlpOfferType[]>([])
  const [filteredTlpOffers, setFilteredTlpOffers] = useState<TlpOfferType[]>([])
  const [selectedCenters, setSelectedCenters] = useState<CentersWS>({ country: "", centers: [] })
  const [search, setSearch] = useState<string>("")
  const [offerType, setOfferType] = useState<string>("")
  const [rewardType, setRewardType] = useState<string>("")
  const [offerStatus, setOfferStatus] = useState<string>("")
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const [refresh, setRefresh] = useState<number>(0)
  const cols = [
    {
      label: t("tlpOffers.fields.country"),
      columnSpecifications: "w-1/8",
    },
    {
      label: t("tlpOffers.fields.center"),
      columnSpecifications: "w-2/8",
    },
    {
      label: t("tlpOffers.fields.offerType"),
      columnSpecifications: "w-1/8 text-center",
    },
    {
      label: t("tlpOffers.fields.offerName"),
      columnSpecifications: "w-2/8",
    },
    {
      label: t("tlpOffers.fields.startDate"),
      columnSpecifications: "w-1/8 text-center",
    },
    {
      label: t("tlpOffers.fields.endDate"),
      columnSpecifications: "w-1/8 text-center",
    },
    {
      label: t("tlpOffers.fields.rewardType"),
      columnSpecifications: "w-1/8 text-center",
    },
    {
      label: t("tlpOffers.fields.status"),
      columnSpecifications: "w-1/8 text-center",
    },
    {
      label: t("tlpOffers.fields.actions"),
      columnSpecifications: "w-1/8 text-center",
    },
  ]

  const updateStatus = (offerId: string, status: string, successMessage: string) => {
    addActiveCalls()
    execute(`offers/${offerId}/status`, "POST", {}, { status }, {}, "lpe")
      .then(() => {
        openSuccessSnackbar(`${t("tlpOffers.snackbar.title")} ${successMessage}`, snackbarDuration)
        setRefresh(refresh + 1)
      })
      .catch(() => {
        openErrorSnackbar(t("tlpOffers.snackbar.errorMessage"), snackbarDuration)
      })
      .finally(subtractActiveCalls)
  }

  useEffect(() => {
    if (activePremiumCentersInUserScope.length > 0) {
      addActiveCalls()
      execute<TlpOffersWSType>(`offers`, "GET", {}, {}, { origin: "fid" }, "lpe")
        .then((res) => {
          const offers = res.result.offers
            .reduce((offers: TlpOfferType[], offer) => {
              const center = activePremiumCentersInUserScope.find((center) => center.id.toString() === offer.centerId)
              if (center) {
                offer.countryCode = center.countryCode
                offer.centerName = center.name
                offers.push(offer)
              }
              return offers
            }, [])
            .sort(
              (a, b) =>
                t(`country.${a.countryCode}`).localeCompare(t(`country.${b.countryCode}`)) * 10 +
                a.centerName.localeCompare(b.centerName),
            )
          setTlpOffers(offers)
          setFilteredTlpOffers(offers)
        })
        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
        .finally(subtractActiveCalls)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePremiumCentersInUserScope, refresh])

  useEffect(() => {
    let offers = tlpOffers
    if (selectedCenters.centers.length > 0) {
      offers = offers.filter((tlpOffer) =>
        selectedCenters.centers.map((center) => center.id.toString()).includes(tlpOffer.centerId),
      )
    }
    if (search?.length > 0) {
      offers = offers.filter((tlpOffer) => new RegExp(search, "i").test(tlpOffer.name))
    }
    if (offerType.length > 0) {
      offers = offers.filter((tlpOffer) => tlpOffer.offerType === offerType)
    }
    if (rewardType.length > 0) {
      offers = offers.filter((tlpOffer) => tlpOffer.rewardType === rewardType)
    }
    if (offerStatus.length > 0) {
      offers = offers.filter((tlpOffer) => tlpOffer.status === offerStatus)
    }
    setFilteredTlpOffers(offers)
  }, [tlpOffers, selectedCenters, search, offerType, rewardType, offerStatus])

  // Dynamically update browser tab title
  useTitle(`${t("menu.tlpOffers")} | ${t("applicationName")}`)

  return (
    <>
      <div className={`w-full h-2 ${Color.TLPOFFERS}`} />
      <div className="mx-4 my-2 px-8 pb-8 flex flex-col flex-grow-1 bg-white rounded overflow-y-auto">
        <h3>{t("tlpOffers.title")}</h3>
        <div className="flex flex-col">
          <div className="py-4 flex flex-row items-center justify-center flex-wrap gap-4">
            <CentersByCountryDropdown
              centersByCountry={activePremiumCentersInUserScopeByCountry}
              selectedCenters={selectedCenters}
              setSelectedCenters={setSelectedCenters}
              label="tlpOffers.fields.centers"
              defaultValue="tlpOffers.forms.selectDefaultValue"
            />
            <div className="w-full md:w-64">
              <p className="text-xs text-left pl-2 pb-1 cursor-default">{t("tlpOffers.fields.offerName")}</p>
              <Search
                search={search}
                placeHolder={t("tlpOffers.forms.searchPlaceholder")}
                onChangeAction={(event: any) => {
                  setSearch(event.target.value)
                }}
              />
            </div>
            <TlpOffersEnumFilter
              enumType="OfferItemTypeEnum"
              label="tlpOffers.fields.offerType"
              value={offerType}
              setValue={setOfferType}
            />
            <TlpOffersEnumFilter
              enumType="OfferItemRewardTypeEnum"
              label="tlpOffers.fields.rewardType"
              value={rewardType}
              setValue={setRewardType}
            />
            <TlpOffersEnumFilter
              enumType="OfferItemStatusEnum"
              label="tlpOffers.fields.status"
              value={offerStatus}
              setValue={setOfferStatus}
            />
          </div>
          {activePremiumCentersInUserScope.length > 0 && (
            <Restricted right="tlpoffers.create">
              <div className="mb-5">
                <AddButton
                  label={t("tlpOffers.buttons.add")}
                  classNames="w-auto mr-6 mt-4"
                  onClick={() => history.push("/tlpOffers/create")}
                />
              </div>
            </Restricted>
          )}
        </div>
        <div className="w-full h-4/5 2xl:h-9/12">
          <div className="pb-2 h-full overflow-y-auto overscroll-auto">
            <TableComponent columnNames={cols}>
              {filteredTlpOffers.map((item, index) => (
                <TableRow key={index}>
                  <TableCell styleProp="items-center" cellProp="text-left">
                    {item.countryCode && <FlagIcon country={item.countryCode} />}
                    <p>{t(`country.${item.countryCode}`)}</p>
                  </TableCell>
                  <TableCell>{item.centerName}</TableCell>
                  <TableCell centered>{item.offerType}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell centered>
                    {item.startDate ? format(new Date(item.startDate), "dd MMM yyyy") : "-"}
                  </TableCell>
                  <TableCell centered>{item.endDate ? format(new Date(item.endDate), "dd MMM yyyy") : "-"}</TableCell>
                  <TableCell centered>{item.rewardType}</TableCell>
                  <TableCell centered>{item.status}</TableCell>
                  <TableCell centered>
                    <Restricted right="tlpoffers.update">
                      {item.canUpdate && (
                        <ActionIcon
                          icon="icon-edit"
                          iconColor="text-black"
                          title={t("tlpOffers.actions.edit")}
                          onClick={() => history.push(`/tlpOffers/${item.id}/edit`)}
                        />
                      )}
                      {item.canActivate ? (
                        <IconWithConfirmation
                          onConfirmationClick={() =>
                            updateStatus(item.id, Status.ACTIVE, t("generic.snackbar.success.activate"))
                          }
                          iconColor="text-gray-500"
                          title={t("tlpOffers.actions.activate")}
                          description={t("tlpOffers.validate.message.activate")}
                          icon={"icon-switch-off"}
                        />
                      ) : (
                        <IconWithConfirmation
                          onConfirmationClick={() =>
                            updateStatus(item.id, Status.INACTIVE, t("generic.snackbar.success.deactivate"))
                          }
                          iconColor="text-green-700"
                          title={t("tlpOffers.actions.deactivate")}
                          description={t("tlpOffers.validate.message.deactivate")}
                          icon={"icon-switch-on"}
                        />
                      )}
                    </Restricted>
                    <TlpOffersModal offer={item} />
                    {item.canDelete && (
                      <Restricted right="tlpoffers.delete">
                        <ActionIcon
                          icon="icon-delete"
                          iconColor="text-red-500"
                          title={t("tlpOffers.actions.delete")}
                          onClick={() => {
                            addActiveCalls()
                            execute(`offers/${item.id}`, "DELETE", {}, {}, {}, "lpe")
                              .then(() => {
                                openSuccessSnackbar(
                                  `${t("tlpOffers.snackbar.title")} ${t("generic.snackbar.success.delete")}`,
                                  snackbarDuration,
                                )
                                setRefresh(refresh + 1)
                              })
                              .catch(() => {
                                openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
                              })
                              .finally(subtractActiveCalls)
                          }}
                        />
                      </Restricted>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          </div>
        </div>
      </div>
    </>
  )
}

export default TlpOffers
