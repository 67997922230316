import React from "react"
import { Route, RouteProps } from "react-router-dom"
import { useStore } from "effector-react"
import { hasRight } from "../utils/Roles"
import NotFound from "../views/NotFound"
import { ContributorStore } from "../store/ContributorStore"
import { useLoyaltyApp } from "../utils/hooks/useLoyaltyApp"

type PropsType = RouteProps & {
  right: string
  inLoyaltyApp?: boolean
}

const ProtectedRoute: React.FC<PropsType> = (props: PropsType) => {
  const { right, children, inLoyaltyApp } = props
  const { permissions } = useStore(ContributorStore)
  const isLoyaltyApp = useLoyaltyApp()

  // Displays only if storedRights not empty
  return permissions.profile ? (
    <Route {...props}>
      {hasRight(permissions, right) && (!isLoyaltyApp || inLoyaltyApp) ? children : <NotFound />}
    </Route>
  ) : null
}

export default ProtectedRoute
