import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useTitle } from "../../utils/hooks/useTitle"
import HomeItem from "../../components/HomeItem"
import { useMenu } from "../../utils/hooks/useMenu"

const Home: FC = () => {
  const { t } = useTranslation()
  const permittedMenu = useMenu()

  // Dynamically update browser tab title
  useTitle(`${t("menu.home")} | ${t("applicationName")}`)

  return (
    <div>
      <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 grid-flow-row gap-5 ">
        {permittedMenu.map((menuItem) =>
          menuItem.shouldShowInHome ? <HomeItem key={menuItem.label} menuItem={menuItem} /> : null,
        )}
      </div>
    </div>
  )
}

export default Home
