import React, { FC } from "react"
import { useTranslation } from "react-i18next"

type ShowMoreButtonProps = {
  showMore: boolean
  setShowMore: (value: boolean) => void
  fullSize?: boolean
}

const ShowMoreButton: FC<ShowMoreButtonProps> = ({ showMore, setShowMore, fullSize = false }: ShowMoreButtonProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={`cursor-pointer w-full ${
        !fullSize && "md:w-1/2"
      } items-center m-auto bg-gray-100 p-2 mb-6 mt-8 rounded`}
      onClick={() => {
        setShowMore(!showMore)
      }}
    >
      <i className={`m-2 ${showMore ? "icon-up-arrow" : "icon-down-arrow"}`} />
      {showMore ? t("tlpOffers.buttons.showLess") : t("tlpOffers.buttons.showMore")}
    </div>
  )
}

export default ShowMoreButton
