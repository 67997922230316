import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { tags } from "../../utils/Constants"
import { MemberObject } from "../../api/MemberObject"
import Chip from "../Chip"

type PropsType = {
  member: MemberObject
}

const MembersTags: FC<PropsType> = (props: PropsType) => {
  const { member } = props
  const { t } = useTranslation()

  return (
    <div>
      {Object.values(tags).map((element) => {
        let shouldShow
        switch (element.key) {
          case "pending":
            shouldShow = member.type === "pending"
            break
          case "partial":
            shouldShow = member.type === "partial"
            break
          case "full":
            shouldShow = member.type === "full"
            break
          case "physical":
            shouldShow = member.isDigital === 0
            break
          case "digital":
            shouldShow = member.isDigital
            break
          case "TLP":
            shouldShow = member.isPremium
            break
          case "loyaltyMember":
            shouldShow =
              (member.type != "partial" && member.pasKey === null) || (member.pasKey != null && member.isPremium)
            break
          case "newsletterSubscriber":
            shouldShow = member.type === "partial" || (member.pasKey != null && !member.isPremium)
            break
          case "printed":
            shouldShow = member.isPrinted
            break
          case "pro":
            shouldShow = member.isVIP
            break
          case "hostess":
            shouldShow = member.createdFrom === "onSite"
            break
          case "web":
            shouldShow = member.createdFrom === "formWeb"
            break
          case "iphone":
            shouldShow = member.createdFrom === "appiPhone"
            break
          case "android":
            shouldShow = member.createdFrom === "appAndroid"
            break
          case "wifi":
            shouldShow = member.createdFrom === "Wifi"
            break
          case "partner":
            shouldShow = member.createdFrom === "Partner"
            break
          case "directories":
            shouldShow = member.createdFrom === "Directories"
            break
          case "email":
            shouldShow = member.cognitoId
            break
          case "google":
            shouldShow = member.googleId
            break
          case "facebook":
            shouldShow = member.facebookId
            break
          case "unknown":
            shouldShow = member.isDigital && member.cognitoId + member.googleId + member.facebookId === null
            break
          default:
            shouldShow = false
        }

        return shouldShow ? (
          <Chip
            key={element.key}
            title={t(element.title)}
            label={t(element.label)}
            color={element.color}
            icon={element.icon}
          />
        ) : (
          ""
        )
      })}
    </div>
  )
}

export default MembersTags
