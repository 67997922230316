import React, { FC } from "react"

export enum ButtonType {
  PRIMARY = "PRIMARY",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  NEUTRAL = "NEUTRAL",
  CUSTOM = "CUSTOM",
}

type Props = {
  onClick: (event?: any) => void
  label?: string
  type?: ButtonType
  buttonType?: "button" | "submit" | "reset"
  icon?: string
  classNames?: string
}

const Button: FC<Props> = ({
  onClick,
  label,
  type = ButtonType.PRIMARY,
  buttonType = "button",
  icon,
  classNames = "",
}: Props) => {
  let color
  switch (type) {
    case ButtonType.PRIMARY:
      color = "blueGray"
      break
    case ButtonType.SUCCESS:
      color = "green"
      break
    case ButtonType.ERROR:
      color = "red"
      break
    case ButtonType.WARNING:
      color = "yellow"
      break
    case ButtonType.INFO:
      color = "indigo"
      break
    case ButtonType.NEUTRAL:
      color = "gray"
      break
    case ButtonType.CUSTOM:
      color = "green"
      break
  }
  return (
    <button
      type={buttonType}
      className={`flex items-center w-auto cursor-pointer border border-${color}-500 bg-${color}-500 text-white rounded-md px-4 py-2 transition duration-500 ease select-none hover:bg-${color}-600 focus:outline-none focus:shadow-outline ${classNames}`}
      onClick={onClick}
    >
      {icon && <i className={`${icon} text-xl self-center mr-1`} />}
      {label && <p>{label}</p>}
    </button>
  )
}

export default Button
