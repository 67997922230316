import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import execute from "../../api/api"
import { useHistory } from "react-router-dom"
import { UserRole } from "../../utils/Roles"
import TableComponent, { TableCell, TableRow } from "../../components/TableComponent"
import AddButton from "../../components/general/AddButton"
import { ButtonType } from "../../components/general/Button"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { Color } from "../../utils/Constants"
import { ActionIcon } from "../../components/ActionIcon"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useFetchRoles } from "../../utils/hooks/useFetchRoles"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"
import RoleAddRightModal from "../../components/roles/RoleAddRightModal"

const sortRoleByOrder = (role1: UserRole, role2: UserRole) => (role1.order > role2.order ? 1 : -1)

const RoleView: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const roles = useFetchRoles(sortRoleByOrder)
  const [refresh, setRefresh] = useState(0)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const [openRoleAddRightModal, setOpenRoleAddRightModal] = useState<boolean>(false)
  const cols = [
    {
      label: t("generic.table.name"),
      columnSpecifications: "w-5/6",
    },
    {
      label: t("generic.table.actions"),
      columnSpecifications: "w-1/6",
    },
  ]

  // Dynamically update browser tab title
  useTitle(`${t("menu.roles")} | ${t("applicationName")}`)

  const moveRole = (roleName: string, moveUp: boolean) => {
    addActiveCalls()

    execute(`users/roles/${roleName}/order?increase=${moveUp}`, "PATCH")
      .then(() => {
        openSuccessSnackbar(`${t("roles.snackbar.title")} ${t("generic.snackbar.success.move")}`, snackbarDuration)
        setRefresh(refresh + 1)
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
  }

  return (
    <>
      <div className={`h-2 ${Color.RIGHTS}`} />
      <div className="w-2/3 mx-auto px-8 pb-8 pt-4">
        <RoleAddRightModal open={openRoleAddRightModal} setOpen={setOpenRoleAddRightModal} />
        <div className="flex flex-row gap-4">
          <AddButton
            label={t("roles.buttons.add")}
            onClick={() => history.push("/roles/create")}
            type={ButtonType.PRIMARY}
            classNames="mb-4"
          />
          <AddButton
            label={t("roles.buttons.addRight")}
            onClick={() => setOpenRoleAddRightModal(true)}
            type={ButtonType.PRIMARY}
            classNames="mb-4"
          />
        </div>

        <TableComponent columnNames={cols}>
          {roles.map((role, index) => (
            <TableRow key={role.order}>
              <TableCell>{role.label}</TableCell>
              <TableCell>
                <ActionIcon
                  icon={`icon-arrow-up ${index <= 3 ? "invisible" : ""}`}
                  onClick={() => moveRole(role.name, false)}
                />
                <ActionIcon
                  icon={`icon-arrow-down ${index <= 2 || index === roles.length - 1 ? "invisible" : ""}`}
                  onClick={() => moveRole(role.name, true)}
                />
                <ActionIcon
                  icon={`icon-edit ${index === 0 ? "invisible" : ""}`}
                  iconColor="text-black"
                  onClick={() => history.push(`/roles/${role.name}/edit`)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableComponent>
      </div>
    </>
  )
}

export default RoleView
