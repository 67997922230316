import React from "react"
import Button, { ButtonType } from "../general/Button"

type FormButtonProps = {
  type?: ButtonType
  customStyle?: string
  onClick?: () => void
  children: string
}

const FormSubmitButton: React.FC<FormButtonProps> = ({
  type = ButtonType.SUCCESS,
  customStyle,
  onClick,
  children,
}: FormButtonProps) => {
  return (
    <Button
      label={children}
      type={type}
      buttonType="submit"
      classNames={customStyle}
      onClick={(event) => {
        event.stopPropagation()
        onClick && onClick()
      }}
    />
  )
}

export default FormSubmitButton

export const FormCancelButton: React.FC<FormButtonProps> = ({ customStyle, onClick, children }: FormButtonProps) => {
  return (
    <Button
      label={children}
      type={ButtonType.ERROR}
      classNames={customStyle}
      onClick={(event) => {
        event.stopPropagation()
        onClick && onClick()
      }}
    />
  )
}
