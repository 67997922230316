import React, { FC } from "react"
import ChangelogForm from "./ChangelogForm"
import { changelogInitialValues } from "./ChangelogFormConsts"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { ChangelogTypeWS } from "./ChangelogTypes"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useFetchRoles } from "../../utils/hooks/useFetchRoles"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import packageJson from "../../../package.json"

const ChangelogCreate: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const version = packageJson["version"]
  const roles = useFetchRoles()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  const submitCreate = (formData: ChangelogTypeWS) => {
    addActiveCalls()
    execute<ChangelogTypeWS>(`changeLogs`, "POST", {}, formData)
      .then((changelog) => {
        openSuccessSnackbar(
          `${t("changelog.snackbar.title")} ${t("generic.snackbar.success.create")}`,
          snackbarDuration,
        )
        history.push(`/changelog/${changelog.id}/edit`)
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
  }

  return (
    <ChangelogForm
      header={t("changelog.forms.titles.create")}
      values={version ? { ...changelogInitialValues, version } : changelogInitialValues}
      roles={roles}
      onSubmit={submitCreate}
    />
  )
}

export default ChangelogCreate
