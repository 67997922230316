import React, { Dispatch, FC, SetStateAction, useState } from "react"
import Input from "../Input"
import { useTranslation } from "react-i18next"
import Search from "../general/Search"
import CompaniesModal from "./CompaniesModal"
import CompaniesActions from "./CompaniesActions"
import { Company } from "./CompaniesType"
import Restricted from "../Restricted"
import AddButton from "../general/AddButton"
import TableComponent, { TableCell, TableRow } from "../TableComponent"
import { TFunction } from "i18next"

const NameField = ({
  company,
  editedCompanyId,
  newCompanyName,
  setNewCompanyName,
}: {
  company: Company
  editedCompanyId?: number
  newCompanyName: string
  setNewCompanyName: Dispatch<SetStateAction<string>>
}) => {
  return company.id === editedCompanyId ? (
    <div className="flex flex-row items-start">
      <Input
        border
        value={newCompanyName}
        placeholder={""}
        onChange={(event) => {
          setNewCompanyName(event.target.value)
        }}
      />
    </div>
  ) : (
    <span className="flex items-center">{company.name}</span>
  )
}

const cols = (t: TFunction) => [
  { label: t("companies.table.companies"), columnSpecifications: "w-5/6" },
  { label: t("generic.table.actions"), columnSpecifications: "w-1/6 text-center" },
]

type CompaniesTableProps = {
  companies: Company[]
  centerId: number
  refresh: () => void
}

const CompaniesTable: FC<CompaniesTableProps> = ({ companies, centerId, refresh }: CompaniesTableProps) => {
  const { t } = useTranslation()
  const [searchCompany, setSearchCompany] = useState<string>("")
  const [newCompanyName, setNewCompanyName] = useState<string>("")
  const [editedCompany, setEditedCompany] = useState<Company>()
  const [openAddCompaniesModal, setOpenAddCompaniesModal] = useState<boolean>(false)

  return (
    <div className="mb-10 w-full max-h-full flex flex-row justify-center">
      <div className="w-full flex flex-col flex-grow-1 bg-white m-2 p-2 rounded">
        <CompaniesModal
          centerId={centerId}
          refresh={refresh}
          open={openAddCompaniesModal}
          setOpen={setOpenAddCompaniesModal}
        />
        <>
          <div className="flex flex-row items-center mb-4 mx-2 mt-2">
            <>
              <Restricted right="companies.create">
                <AddButton
                  label={t("companies.forms.titles.creation")}
                  onClick={() => setOpenAddCompaniesModal(true)}
                  classNames="w-auto ml-4 mr-6"
                />
              </Restricted>
              {companies.length > 0 && (
                <Search
                  search={searchCompany}
                  customStyle="w-1/4"
                  placeHolder={t("generic.forms.fields.searchPlaceholder")}
                  onChangeAction={(event: any) => {
                    setSearchCompany(event.target.value || "")
                  }}
                />
              )}
            </>
          </div>
          <div className="mx-6 my-2 shadow-md">
            <TableComponent columnNames={cols(t)}>
              {companies
                .filter((company) => company.name.toLowerCase().includes(searchCompany.toLowerCase()))
                .map((company) => {
                  return (
                    <TableRow key={company.id}>
                      <TableCell>
                        <NameField
                          company={company}
                          editedCompanyId={editedCompany?.id}
                          newCompanyName={newCompanyName}
                          setNewCompanyName={setNewCompanyName}
                        />
                      </TableCell>
                      <TableCell centered>
                        <CompaniesActions
                          centerId={centerId}
                          editedCompanyId={editedCompany?.id}
                          company={company}
                          newCompanyName={newCompanyName}
                          onEdit={() => {
                            setEditedCompany(company)
                            setNewCompanyName(company.name)
                          }}
                          onCancel={() => setEditedCompany(undefined)}
                          refresh={refresh}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableComponent>
          </div>
        </>
      </div>
    </div>
  )
}

export default CompaniesTable
