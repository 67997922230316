import React, { FC } from "react"
import { Color } from "../../utils/Constants"

type RadioOptionProps = {
  label: string
  isActive: boolean
  isDisabled?: boolean
  onClick: () => void
  meta?: any
}

const RadioOption: FC<RadioOptionProps> = ({
  label,
  isActive,
  isDisabled = false,
  onClick,
  meta,
}: RadioOptionProps) => {
  return (
    <div
      onClick={onClick}
      className={`px-4 h-full rounded-md flex justify-center items-center text-blueGray-500 text-bold cursor-pointer ${
        meta && meta.error && meta.touched
          ? "border-red-500 border-2"
          : isActive
          ? `${Color.MEMBERS} text-white`
          : isDisabled
          ? "text-gray-400 bg-gray-200 cursor-not-allowed"
          : "border-blueGray-500 border-1"
      }`}
    >
      {label}
    </div>
  )
}

export default RadioOption
