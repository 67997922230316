import * as React from "react"
import { CenterTypeWS } from "../../components/centers/CentersType"
import execute from "../../api/api"
import Search from "../../components/general/Search"
import { useTranslation } from "react-i18next"
import { ActionIcon } from "../../components/ActionIcon"
import TableComponent, { TableCell, TableRow } from "../../components/TableComponent"
import { useHistory } from "react-router-dom"
import Restricted from "../../components/Restricted"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { format } from "date-fns"
import AddButton from "../../components/general/AddButton"
import { centerConfigFields } from "../../components/centers/CenterConfigurationConsts"
import { Color } from "../../utils/Constants"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"
import FlagIcon from "../../styles/customIcons/FlagIcon"
import { FC, useState } from "react"
import { useFetchCentersInUserScope } from "../../utils/hooks/centers/useFetchCentersInUserScope"
import { IconWithConfirmation } from "../../components/modals/IconConfirmationModal"

const Centers: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { centersInUserScope } = useFetchCentersInUserScope()
  const [searchCenter, setSearchCenter] = useState("")
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const [refresh, setRefresh] = useState(0)
  const cols = [
    {
      label: t("generic.fields.country"),
      columnSpecifications: "w-2/12",
    },
    {
      label: t("generic.table.name"),
      columnSpecifications: "w-3/12",
    },
    {
      label: "ID",
      columnSpecifications: "w-3/12",
    },
    {
      label: t("centers.forms.fields.launchDate"),
      columnSpecifications: "w-2/12 text-center",
    },
    {
      label: t("centers.forms.fields.loyaltyProgramStartDate"),
      columnSpecifications: "w-2/12",
    },
    {
      label: t("generic.fields.options"),
      columnSpecifications: "w-2/12",
    },
    {
      label: t("generic.table.actions"),
      columnSpecifications: "w-1/12",
    },
  ]

  const deleteCenter = (id: number) => {
    execute(`centers/${id}`, "DELETE")
      .then(() => {
        openSuccessSnackbar(
          t(`${t("centers.snackbar.title")} ${t("generic.snackbar.success.delete")}`),
          snackbarDuration,
        )
        setRefresh(refresh + 1)
        setSearchCenter("")
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
  }

  // Dynamically update browser tab title
  useTitle(`${t("menu.centers")} | ${t("applicationName")}`)

  return (
    <>
      <div className={`w-full h-2 ${Color.CENTERS}`} />
      <div className="w-full px-8 h-screen">
        <div className="py-4 flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0">
          <Restricted right="centers.create">
            <AddButton
              label={t("centers.forms.creation")}
              classNames="w-auto md:mr-6"
              onClick={() => history.push("/centers/create")}
            />
          </Restricted>
          <Search
            search={searchCenter}
            customStyle="w-full md:w-1/4 flex flex-row justify-start"
            placeHolder={t("generic.forms.fields.searchPlaceholder")}
            onChangeAction={(event: any) => {
              setSearchCenter(event.target.value)
            }}
          />
        </div>
        <div className="w-full h-4/5 2xl:h-9/12">
          <div className="pb-2">
            <TableComponent columnNames={cols}>
              {centersInUserScope.map(
                (center) =>
                  (!searchCenter || center.name.toLowerCase().includes(searchCenter.toLowerCase())) && (
                    <TableRow key={center.id}>
                      <TableCell styleProp="items-center" cellProp="text-left">
                        <FlagIcon country={center.countryCode} />
                        <p>{t(`country.${center.countryCode}`)}</p>
                      </TableCell>
                      <TableCell cellProp="text-left">{center.name}</TableCell>
                      <TableCell>{center.id}</TableCell>
                      <TableCell centered>
                        {center.launchDate ? format(new Date(center.launchDate), t("generic.format.date")) : "-"}
                      </TableCell>
                      <TableCell centered>
                        {center.loyaltyProgramVersion > 1 && center.loyaltyProgramStartDate
                          ? format(new Date(center.loyaltyProgramStartDate), t("generic.format.date"))
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {centerConfigFields.map((field) =>
                          center[field.formKey as keyof CenterTypeWS] ? (
                            <span
                              className={`${Color.CENTERS} inline-block rounded-lg px-3 py-1 text-sm font-semibold text-white mr-2 mb-2`}
                              key={field.formKey}
                              title={t(field.label)}
                            >
                              {t(field.tag)}
                            </span>
                          ) : null,
                        )}
                      </TableCell>
                      <TableCell centered>
                        <Restricted right="centers.update">
                          <ActionIcon
                            icon="icon-edit"
                            iconColor="text-black"
                            onClick={() => history.push(`/centers/${center.id}/edit`)}
                          />
                        </Restricted>
                        <Restricted right="centers.delete">
                          <IconWithConfirmation
                            customStyle="text-3xl"
                            onConfirmationClick={() => {
                              deleteCenter(center.id)
                            }}
                            icon="icon-delete"
                            title={t("generic.buttons.delete")}
                            description={t("modal.delete.description")}
                          />
                        </Restricted>
                      </TableCell>
                    </TableRow>
                  ),
              )}
            </TableComponent>
          </div>
        </div>
      </div>
    </>
  )
}

export default Centers
