import { createEvent, createStore } from "effector"
import { ContributorType, FullContributorType } from "../api/ContributorObject"
import { resetFiltersStore } from "./FilterStore"
import { resetCredentialsStore } from "./CredentialsStore"

const resetAllStore = () => {
  resetFiltersStore()
  resetCredentialsStore()
}

export const UserInitial: FullContributorType = {
  assignedCenters: [],
  favoriteCenters: [],
  countries: [],
  id: 0,
  username: "",
  role: "",
  isActivated: false,
  createdAt: "",
  createdBy: 0,
  updatedAt: "",
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  lastLogin: "",
  deletedBy: 0,
  userRole: { name: "", label: "" },
  permissions: {},
}

export const setFullUser = createEvent<FullContributorType>("SET_FULL_USER")
export const setUser = createEvent<ContributorType>("SET_USER")
export const unsetUser = createEvent<void>("UNSET_USER")

export const ContributorStore = createStore<FullContributorType>(UserInitial)
  .on(setFullUser, (state: FullContributorType, payload: FullContributorType) => {
    return { ...payload }
  })
  .on(setUser, (state: FullContributorType, payload: ContributorType) => {
    return Object.assign({}, state, payload)
  })
  .on(unsetUser, () => {
    resetAllStore()
    return UserInitial
  })
