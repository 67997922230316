import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IconModalValidation } from "../modals/IconModalValidation"
import { SettingsInfoWSType, TlpOfferType, TOfferInformations, TSettingsInfo } from "./TlpOffersTypes"
import { differenceInDays, format, isAfter, isBefore } from "date-fns"
import { Color } from "../../utils/Constants"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { TFunction } from "i18next"
import CashbackDetails from "./TlpOfferModalDetails/CashbackDetails"
import PointDetails from "./TlpOfferModalDetails/PointDetails"

type TlpOffersModalProps = {
  offer: TlpOfferType
}

export type TDetailsProps = {
  offer: TlpOfferType
  offerInformations?: TOfferInformations
  progressPercent: number
  t: TFunction
}

const TlpOffersModal: FC<TlpOffersModalProps> = (props: TlpOffersModalProps) => {
  const { offer } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const [offerInformations, setOfferInformations] = useState<TOfferInformations>()

  const dateProgressPercent =
    offer.startDate && offer.endDate
      ? Math.max(
          0,
          (differenceInDays(new Date(offer.startDate), new Date()) /
            differenceInDays(new Date(offer.startDate), new Date(offer.endDate))) *
            100,
        )
      : 0
  const dateStyle = { width: `${dateProgressPercent}%` }

  const attributionProgressPercent =
    offer.maxAttribution > 0 && offerInformations?.actualAttribution
      ? (offerInformations.actualAttribution / offer.maxAttribution) * 100
      : 0

  useEffect(() => {
    if (open) {
      addActiveCalls()
      execute<SettingsInfoWSType>(`offers/${offer.id}/modal`, "GET", {}, {}, {}, "lpe")
        .then((res) => {
          if (res?.result?.settings) {
            setOfferInformations(res.result)
          }
        })
        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
        .finally(subtractActiveCalls)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <div>
      <IconModalValidation
        open={open}
        setOpen={setOpen}
        icon="icon-chart"
        title={offer.name}
        iconTitle={t("tlpOffers.actions.info")}
        iconStyle="text-3xl"
        buttons={<></>}
      >
        <div className="h-full overflow-auto">
          <div className="flex flex-col">
            {offer.startDate && offer.endDate ? (
              <div className="flex flex-col p-3 border-b border-blueGray-200">
                <div className="flex flex-row justify-between">
                  <p className="text-sm">{t("checkins.forms.fields.from")}</p>
                  <p className="text-sm">{t("checkins.forms.fields.to")}</p>
                </div>
                <div className="h-8 my-1 rounded-md bg-gray-100 overflow-hidden">
                  <div style={dateStyle} className={`h-full ${Color.TLPOFFERS}`} />
                </div>
                <div className="flex flex-row justify-between">
                  <p className="text-sm">{format(new Date(offer.startDate), t("generic.format.date"))}</p>
                  <p className="text-sm">{format(new Date(offer.endDate), t("generic.format.date"))}</p>
                </div>
              </div>
            ) : offer.startDate ? (
              <h2
                className={`text-2xl ${
                  isBefore(new Date(), new Date(offer.startDate)) ? "text-red-500" : "text-green-500"
                } p-3 text-center border-b border-blueGray-200`}
              >{`${t("checkins.forms.fields.from")}: ${format(
                new Date(offer.startDate),
                t("generic.format.date"),
              )}`}</h2>
            ) : offer.endDate ? (
              <h2
                className={`text-2xl ${
                  isAfter(new Date(), new Date(offer.endDate)) ? "text-red-500" : "text-green-500"
                } p-3 text-center border-b border-blueGray-200`}
              >{`${t("checkins.forms.fields.to")}: ${format(new Date(offer.endDate), t("generic.format.date"))}`}</h2>
            ) : null}
            {offer.rewardType === "Cashback" ? (
              <CashbackDetails
                offer={offer}
                progressPercent={attributionProgressPercent}
                t={t}
                offerInformations={offerInformations}
              />
            ) : (
              <PointDetails
                offer={offer}
                progressPercent={attributionProgressPercent}
                t={t}
                offerInformations={offerInformations}
              />
            )}
            <h3 className="text-2xl p-3 text-center">{t("tlpOffers.headers.settings")}</h3>
            {offerInformations?.settings?.map((item: TSettingsInfo, index: number) => (
              <p className="m-1" key={index}>
                <span className="font-bold text-gray-500">{item.name} :</span> {item.value}
              </p>
            ))}
          </div>
        </div>
      </IconModalValidation>
    </div>
  )
}

export default TlpOffersModal
