import React, { FC } from "react"
import NavBarCollapseItem from "./NavBarCollapseItem"
import { language } from "../../utils/i18n"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { useHistory } from "react-router-dom"
import { execute } from "../../api/api"
import { LoginType } from "../../api/LoginObject"
import credentialsManager from "../../utils/CredentialsManager"
import { ContributorStore, unsetUser } from "../../store/ContributorStore"
import { useStore } from "effector-react"

export type CollapseMenuItem = {
  label: string
  action: () => void | Promise<TFunction>
}

const NavBarParameters: FC = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const userStore = useStore(ContributorStore)
  const username = userStore.username

  const collapseNavbarMenu = () => [
    {
      label: t("menu.collapseMenu.profile"),
      action: () => history.push("/profile"),
    },
    {
      label: t("menu.collapseMenu.logout"),
      action: () => {
        const logoutWS = execute<LoginType>(`logout`, "POST", {})
        return logoutWS.then(() => {
          credentialsManager.eraseDashboardAccessToken()
          credentialsManager.eraseDashboardRefreshToken()
          unsetUser()
          history.push("/home")
        })
      },
    },
  ]

  const getMenuListLang = () => {
    return language.map((lng) => ({
      label: t(lng.label),
      action: () => {
        localStorage.setItem("lang", lng.code)
        i18n.changeLanguage(lng.code)
      },
    }))
  }

  return (
    <div style={{ lineHeight: "1" }}>
      <nav className="bg-blueGray-700 max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center text-blueGray-100">
            <NavBarCollapseItem name={t("menu.lang")} icon="icon-language" menuList={getMenuListLang()} />
            <NavBarCollapseItem name={username} icon="icon-user" menuList={collapseNavbarMenu()} />
          </div>
        </div>
      </nav>
    </div>
  )
}

export default NavBarParameters
