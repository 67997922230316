import { CountriesWS } from "../Country"
import execute from "../../api/api"
import { subtractActiveCalls } from "../../store/LoadingStore"
import { useEffect, useState } from "react"

export const useFetchCountries = (): CountriesWS[] => {
  const [countries, setCountries] = useState<CountriesWS[]>([])

  useEffect(() => {
    execute<CountriesWS[]>("countries", "GET")
      .then((res) => setCountries(res))
      .finally(subtractActiveCalls)
  }, [])

  return countries
}
