import { MemberObject } from "../../api/MemberObject"
import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import Button, { ButtonType } from "./Button"

type SeeMoreButtonProps = {
  member: MemberObject
  origin?: string
}

export const SeeMoreButton: React.FC<SeeMoreButtonProps> = (props: SeeMoreButtonProps) => {
  const { member, origin } = props
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Button
      classNames="mt-2 ml-2"
      label={t("generic.buttons.more")}
      type={ButtonType.INFO}
      onClick={() => history.push(`members/${member.id}/edit`, { origin: origin })}
    />
  )
}
