import { createEvent, createStore } from "effector"
import { format } from "date-fns"

export type FilterDate = {
  fromDateFilter: string
  toDateFilter: string
}
export type GenderFilterType = "M" | "F" | "U"
export type DigitalFilterType = "" | "digitalPrinted" | "digital" | "printed" | "none"

export type MembersFilterType = "mobile" | "formWeb" | "onSite" | "Partner" | "Wifi" | "Directories"
export type OptinFilterType = "" | "commercial" | "sms" | "both" | "no"
export type AgeFilterType = "20" | "20-30" | "30-50" | "50"
export type MemberType = "Loyalty" | "Newsletter"

export type ConvertedMembersFilterType = "" | 0 | 1
export type TLPOriginFilterType = "mobile" | "formWeb" | "Partner" | "unknown"
export type TLPPaymentMethodType = "" | "bank" | "tr" | "scan" | "bankAndTR" | "both"

export type PartnersFilterType =
  | ""
  | "Westfield"
  | "Openevents"
  | "Relatia"
  | "Qualifio"
  | "Maestro"
  | "Stocard"
  | "Captain Wallet"
  | "GOODIE"
  | "ilogs"
  | "Cardiweb"

export type FilterStore = {
  // Common filters
  date: FilterDate
  genderFilter: GenderFilterType[]
  digitalFilter: DigitalFilterType

  // Classic dashboard filters
  isVip: boolean
  isPro: boolean
  membersFilter: MembersFilterType[]
  optinFilter: OptinFilterType
  agesFilter: AgeFilterType[]
  memberTypeFilter: MemberType

  // TLP dashboard filters
  premium: {
    convertedMembers: ConvertedMembersFilterType
    originFilter: TLPOriginFilterType[]
    paymentMethod: TLPPaymentMethodType
  }

  //Pie chart filter
  partnersFilter: PartnersFilterType
}

export const FilterDateInitial: FilterDate = {
  fromDateFilter: format(new Date(`01/01/${new Date().getFullYear()}`), "yyyy-MM-dd"),
  toDateFilter: format(Date.now(), "yyyy-MM-dd"),
}

export const FilterStoreInitial: FilterStore = {
  // Common filters
  date: FilterDateInitial,
  genderFilter: [], // this filter contains the gender we want to exclude
  digitalFilter: "",

  // Clasic dashboard filters
  // isVip: true,
  // isPro: true,
  isVip: false,
  isPro: false,
  membersFilter: [],
  optinFilter: "",
  agesFilter: [],
  memberTypeFilter: "Loyalty",

  // TLP dashboard filters
  premium: {
    convertedMembers: "",
    originFilter: [],
    paymentMethod: "",
  },

  //Pie chart filter
  partnersFilter: "",
}

export const setFromDate = createEvent<string>("SET_FROM_DATE")
export const setToDate = createEvent<string>("SET_TO_DATE")
export const setAllDate = createEvent<FilterDate>("SET_ALL_DATE")
export const setIsVip = createEvent<boolean>("SET_IS_VIP")
export const setIsPro = createEvent<boolean>("SET_IS_PRO")
export const setMemberType = createEvent<MemberType>("SET_MEMBER_TYPE")

export const setGender = createEvent<GenderFilterType[]>("SET_GENDER")
export const setMembers = createEvent<MembersFilterType[]>("SET_MEMBERS")
export const setOptin = createEvent<OptinFilterType>("SET_OPTIN")
export const setDigital = createEvent<DigitalFilterType>("SET_DIGITAL")
export const setAges = createEvent<AgeFilterType[]>("SET_AGES")
export const setPaymentMethods = createEvent<TLPPaymentMethodType>("SET_PAYMENT_METHODS")
export const setConvertedMembers = createEvent<ConvertedMembersFilterType>("SET_CONVERTED_MEMBERS")
export const setPremiumOrigin = createEvent<TLPOriginFilterType[]>("SET_PREMIUM_ORIGIN")
export const addGender = createEvent<GenderFilterType>("ADD_MEMBERS")
export const addMember = createEvent<MembersFilterType>("ADD_MEMBERS")
export const addAges = createEvent<AgeFilterType>("ADD_AGES")
export const removeGender = createEvent<GenderFilterType>("REMOVE_MEMBERS")
export const setPartner = createEvent<PartnersFilterType>("SET_PARTNER")

export const resetFiltersStore = createEvent("RESET")

export const FilterStore = createStore<FilterStore>(FilterStoreInitial)
  .on(setFromDate, (state: FilterStore, payload: string) => {
    return { ...state, date: { ...state.date, fromDateFilter: payload } }
  })
  .on(setToDate, (state: FilterStore, payload: string) => {
    return { ...state, date: { ...state.date, toDateFilter: payload } }
  })
  .on(setAllDate, (state: FilterStore, payload: FilterDate) => {
    return { ...state, date: payload }
  })
  .on(setGender, (state: FilterStore, payload: GenderFilterType[]) => {
    return { ...state, genderFilter: payload }
  })
  .on(setDigital, (state: FilterStore, payload: DigitalFilterType) => {
    return { ...state, digitalFilter: payload }
  })
  .on(setIsVip, (state: FilterStore, payload: boolean) => {
    return { ...state, isVip: payload }
  })
  .on(setIsPro, (state: FilterStore, payload: boolean) => {
    return { ...state, isPro: payload }
  })
  .on(setMemberType, (state: FilterStore, payload: MemberType) => {
    return { ...state, memberTypeFilter: payload }
  })
  .on(setMembers, (state: FilterStore, payload: MembersFilterType[]) => {
    return { ...state, membersFilter: payload }
  })
  .on(setOptin, (state: FilterStore, payload: OptinFilterType) => {
    return { ...state, optinFilter: payload }
  })
  .on(setAges, (state: FilterStore, payload: AgeFilterType[]) => {
    return { ...state, agesFilter: payload }
  })
  .on(setConvertedMembers, (state: FilterStore, payload: ConvertedMembersFilterType) => {
    return {
      ...state,
      premium: { ...state.premium, convertedMembers: payload },
    }
  })
  .on(setPremiumOrigin, (state: FilterStore, payload: TLPOriginFilterType[]) => {
    return { ...state, premium: { ...state.premium, originFilter: payload } }
  })
  .on(setPaymentMethods, (state: FilterStore, payload: TLPPaymentMethodType) => {
    return { ...state, premium: { ...state.premium, paymentMethod: payload } }
  })

  .on(addGender, (state: FilterStore, payload: GenderFilterType) => {
    return { ...state, genderFilter: [...state.genderFilter, payload] }
  })
  .on(addMember, (state: FilterStore, payload: MembersFilterType) => {
    return { ...state, membersFilter: [...state.membersFilter, payload] }
  })
  .on(addAges, (state: FilterStore, payload: AgeFilterType) => {
    return { ...state, agesFilter: [...state.agesFilter, payload] }
  })
  .on(removeGender, (state: FilterStore, payload: GenderFilterType) => {
    return {
      ...state,
      genderFilter: state.genderFilter.filter((item) => item !== payload),
    }
  })
  .on(setPartner, (state: FilterStore, payload: PartnersFilterType) => {
    return { ...state, partnersFilter: payload }
  })
  .reset(resetFiltersStore)
