import { RightsType } from "../api/ContributorObject"

export type UserRole = {
  name: string
  order: number
  label: string
  linkedTo: "center" | "centers" | "countries" | ""
  permissions: RightsType
}

// Checks if the right (i.e. "customers.create") exists or not in the effector stored logged in user
export const hasRight = (rights: RightsType, right: string): boolean =>
  right.split(".").reduce((r: any, i: string) => r && r[i], rights)
