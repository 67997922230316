import React, { FC } from "react"
import { convertToPercent } from "../../utils/Numbers"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { numberWithThousandsSeparator } from "../../utils/Functions"
import { cockpitColumns, premiumCockpitColumns } from "../../views/dashboard/CockpitConstant"
import {
  CockpitCenterObjectiveType,
  CockpitCountryObjectiveType,
  MixedCockpitObjectiveType,
} from "../../views/dashboard/CockpitType"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import { hasRight } from "../../utils/Roles"
import FlagIcon from "../../styles/customIcons/FlagIcon"

export enum TableLineType {
  HEADER = "header",
  TOTAL = "total",
  SEPARATOR = "separator",
  COUNTRY = "country",
  CENTER = "center",
}

type CellProps = {
  value: number | string
  type: TableLineType
  index?: number
  isFavorite?: boolean
  countryCode?: string
  percent?: boolean
  total?: number
  className?: string
  onFavoriteIconClick?: (isFavorite: boolean) => void
}

export const Cell: FC<CellProps> = ({
  value,
  type,
  index = -1,
  isFavorite = false,
  countryCode,
  percent = false,
  total,
  className,
  onFavoriteIconClick,
}: CellProps): React.ReactElement => {
  let cellStyle = `py-1 ${className}`
  // Makes the 1st column (name) bigger and left aligned
  if (index === 0) {
    cellStyle += " text-left pl-2  w-1/3 lg:w-2/12"
  } else {
    cellStyle += "  w-1/3 lg:w-1/12"
  }

  // Hides columns that are not:
  //  - collapsed separator (-1)
  //  - name (0)
  //  - customers on filtered period (2)
  //  - gap to current quarter/year objective (4)
  if (![-1, 0, 2, 4].includes(index)) {
    cellStyle += " hidden lg:table-cell"
  }

  // formats percent values
  if (percent && typeof value === "number") {
    if (!total) {
      value = "-"
    } else if (value <= total) {
      value = convertToPercent(value, total)
      cellStyle += parseInt(value) > 90 ? " text-green-500" : value !== "NaN" ? " text-yellow-500" : ""
      value = value !== "NaN" ? `${value}%` : "-"
    } else {
      value = "-"
    }
  }
  // Adds thousands separators
  if (typeof value === "number") {
    value = numberWithThousandsSeparator(value)
  }

  return type === TableLineType.HEADER ? (
    <th className={cellStyle}>{value}</th>
  ) : (
    <td className={cellStyle} colSpan={type === TableLineType.SEPARATOR ? 12 : 1}>
      {type === TableLineType.CENTER && index === 0 && onFavoriteIconClick && (
        <i
          className={`${isFavorite ? "icon-star-filled" : "icon-star"} cursor-pointer`}
          onClick={(event) => {
            event.stopPropagation()
            if (onFavoriteIconClick) onFavoriteIconClick(isFavorite)
          }}
        />
      )}
      {type === TableLineType.COUNTRY && index === 0 && countryCode && <FlagIcon country={countryCode} />}
      {value}
    </td>
  )
}

type TotalLineProps = {
  total: MixedCockpitObjectiveType
  premium?: boolean
}

export const TotalLine: FC<TotalLineProps> = ({ total, premium = false }: TotalLineProps): React.ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const { permissions } = useStore(ContributorStore)

  const columns = premium ? premiumCockpitColumns : cockpitColumns
  let style = "bg-lightBlue-900 text-white"
  style += hasRight(permissions, "dashboard.read") ? " hover:bg-bluGray-400 tabLine cursor-pointer" : ""

  return (
    <tr
      className={style}
      onClick={() =>
        hasRight(permissions, "dashboard.read") && history.push(`/${premium ? "premiumDashboard" : "dashboard"}`)
      }
    >
      <Cell value={t("dashboard.fields.cardOwner.total") as string} index={0} type={TableLineType.TOTAL} />

      {columns.map((column, index) => {
        const newTotal = column.key === "quarterCustomers" ? total.currentObjective : total.periodCustomers
        return (
          <Cell
            key={`total_${column.key}`}
            value={total[column.key]!}
            index={index + 1}
            type={TableLineType.TOTAL}
            percent={column.percent}
            total={newTotal}
          />
        )
      })}
    </tr>
  )
}

type CountryLineProps = {
  centersByCountry: CockpitCountryObjectiveType<MixedCockpitObjectiveType>
  premium?: boolean
}

export const CountryLine: FC<CountryLineProps> = ({
  centersByCountry,
  premium = false,
}: CountryLineProps): React.ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const { permissions } = useStore(ContributorStore)

  const columns = premium ? premiumCockpitColumns : cockpitColumns
  let style = "bg-lightBlue-900 text-white"
  style += hasRight(permissions, "dashboard.read") ? " hover:bg-bluGray-400 tabLine cursor-pointer" : ""

  return (
    <tr
      className={style}
      onClick={() =>
        hasRight(permissions, "dashboard.read") &&
        history.push(`/${premium ? "premiumDashboard" : "dashboard"}/${centersByCountry.country}`)
      }
    >
      <Cell
        value={t(`country.${centersByCountry.country}`) as string}
        index={0}
        type={TableLineType.COUNTRY}
        countryCode={centersByCountry.country}
      />
      {columns.map((cockpitColumn, index) => {
        const countryTotal =
          cockpitColumn.key === "quarterCustomers"
            ? centersByCountry.currentObjective
            : centersByCountry.periodCustomers
        return (
          <Cell
            key={`country_${centersByCountry.country}_${cockpitColumn.key}`}
            value={centersByCountry[cockpitColumn.key]!}
            index={index + 1}
            type={TableLineType.COUNTRY}
            percent={cockpitColumn.percent}
            total={countryTotal}
          />
        )
      })}
    </tr>
  )
}

type CenterLineProps = {
  center: CockpitCenterObjectiveType<MixedCockpitObjectiveType>
  lineIndex: number
  isFavorite?: boolean
  onFavoriteIconClick?: (isFavorite: boolean) => void
  premium?: boolean
}

export const CenterLine: FC<CenterLineProps> = ({
  center,
  lineIndex,
  isFavorite = false,
  onFavoriteIconClick,
  premium = false,
}: CenterLineProps): React.ReactElement => {
  const history = useHistory()
  const { permissions } = useStore(ContributorStore)

  const columns = premium ? premiumCockpitColumns : cockpitColumns
  let style = "text-gray-700 tabLine"
  style += lineIndex % 2 === 0 ? " bg-white" : " bg-gray-200"
  style += hasRight(permissions, "dashboard.read") ? " hover:bg-blueGray-200 hover:text-white cursor-pointer" : ""

  return (
    <tr
      className={style}
      onClick={() =>
        hasRight(permissions, "dashboard.read") &&
        history.push(`/${premium ? "premiumDashboard" : "dashboard"}/${center.countryCode}/${center.id}`)
      }
    >
      <Cell
        value={center.name}
        index={0}
        type={TableLineType.CENTER}
        isFavorite={isFavorite}
        onFavoriteIconClick={onFavoriteIconClick}
      />
      {columns.map((cockpitColumn, index) => {
        const centersTotal = cockpitColumn.key === "quarterCustomers" ? center.currentObjective : center.periodCustomers
        return (
          <Cell
            key={`center_${center.id}_${cockpitColumn.key}`}
            value={center[cockpitColumn.key]!}
            index={index + 1}
            type={TableLineType.CENTER}
            percent={cockpitColumn.percent}
            total={centersTotal}
          />
        )
      })}
    </tr>
  )
}
