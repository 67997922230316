import React from "react"

type PropsType = {
  country: string
}

const FlagIcon: React.FC<PropsType> = (props: PropsType) => {
  const { country } = props

  return (
    <img
      src={`https://www.geonames.org/flags/x/${country}.gif`}
      alt={`${country}-flag`}
      className="inline h-6 w-8 p-1 mx-1 mb-1"
    />
  )
}

export default FlagIcon
