import React from "react"

type Props = {
  label: string
  title: string
  onClick: () => void
  value?: number
  isClicked?: boolean
}

const Icon: React.FC<Props> = ({ label, title, onClick, value, isClicked }: Props) => (
  <div
    className={`${isClicked ? "text-gray-800" : "text-gray-300"} m-3 cursor-pointer `}
    key={label}
    onClick={onClick}
    title={title}
  >
    <i className={label} />
    {value !== undefined && value !== null ? `${value}%` : ""}
  </div>
)

export default Icon
