import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"

type PropsType = {
  search?: string
  customStyle?: string
  placeHolder?: string
  onChangeAction: (event: any) => void
  onResetAction?: (event: any) => void
}

const Search: FC<PropsType> = (props: PropsType) => {
  const { search, customStyle, placeHolder, onChangeAction, onResetAction } = props
  const { t } = useTranslation()
  const [isChange, setIsChange] = useState<boolean>(false)

  return (
    <div className={`w-full md:w-64 ${customStyle}`}>
      <div className="self-center border-2 border-gray-200 rounded bg-white h-12 px-3 text-sm focus:outline-none flex flex-row items-center">
        <input
          value={search}
          className="focus:outline-none flex-grow w-full"
          type="text"
          name="search"
          id="searchBar"
          placeholder={placeHolder ? placeHolder : t("generic.forms.fields.searchPlaceholder")}
          onChange={(event) => {
            onChangeAction(event)
            setIsChange(event.target.value !== "")
          }}
        />
        {isChange && (
          <i
            className="icon-cancel text-xl cursor-pointer"
            onClick={(event) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              document.getElementById("searchBar").value = ""
              onResetAction ? onResetAction(event) : onChangeAction(event)
              setIsChange(false)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default Search
