import React from "react"
import { Field, Form, Formik, FormikValues } from "formik"
import { centerConfigFields, centersValidationSchema } from "./CenterConfigurationConsts"
import InputField from "../general/InputField"
import FormActionButtons from "../general/FormActionButtons"
import { useHistory } from "react-router-dom"
import FormDateInput from "../general/FormDateInput"
import Objectives from "./Objectives"
import execute from "../../api/api"
import { useTranslation } from "react-i18next"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { numberWithoutThousandsSeparator, numberWithThousandsSeparator } from "../../utils/Functions"
import { DataType } from "./CentersType"
import { CountrySelector } from "../countries/CountrySelector"
import { CountryObjectWS } from "../../api/CountryObject"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import FormInputInline from "../FormInputInline"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"

type CentersConfigurationForm = {
  countries: CountryObjectWS[]
  centerId?: number
  initialValues: FormikValues
}

const convertObjectivesValuesToNumberAgain = (objective: DataType): void => {
  if (objective) {
    Object.keys(objective).map(
      (k: string) =>
        (objective[k as keyof DataType] =
          numberWithoutThousandsSeparator(objective[k as keyof DataType] as string) || 0),
    )
  }
}

const CentersConfigurationForm: React.FC<CentersConfigurationForm> = ({
  countries,
  centerId,
  initialValues,
}: CentersConfigurationForm) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  // transform number to string in order to add thousands comma separators
  if (initialValues?.objectives?.archive?.length > 0 && initialValues.objectives.archive[0].data) {
    const currentObjectives = initialValues.objectives.archive[0].data
    Object.keys(currentObjectives).map(
      (key: string) => (currentObjectives[key] = numberWithThousandsSeparator(currentObjectives[key])),
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={centersValidationSchema}
      onSubmit={(values) => {
        addActiveCalls()

        // Converts objectives values from string to number
        convertObjectivesValuesToNumberAgain(values.objectives?.customers)
        if (values.isPremium) {
          convertObjectivesValuesToNumberAgain(values.objectives?.premium)
        }
        if (values.objectives?.archive) {
          values.objectives.archive.map((archive: any) => convertObjectivesValuesToNumberAgain(archive.data))
        }

        const formData = {
          ...values,
          launchDate: values.launchDate || null,
          latestTermsAndConditionsDate: values.latestTermsAndConditionsDate || null,
          loyaltyProgramVersion: values.isPremium ? 2 : 1,
          loyaltyProgramStartDate: values.isPremium ? values.loyaltyProgramStartDate || null : null,
          objectives: {
            ...values.objectives,
            premium: values.isPremium ? values.objectives?.premium : null,
          },
        }

        if (centerId) {
          execute(`centers/${centerId}`, "PATCH", {}, formData)
            .then(() => {
              openSuccessSnackbar(
                `${t("centers.snackbar.title")} ${t("generic.snackbar.success.edit")}`,
                snackbarDuration,
              )
            })
            .catch(() => {
              openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
            })
            .finally(subtractActiveCalls)
        } else {
          execute(`centers`, "POST", {}, formData)
            .then(() => {
              openSuccessSnackbar(
                `${t("centers.snackbar.title")} ${t("generic.snackbar.success.create")}`,
                snackbarDuration,
              )
            })
            .catch((err) => {
              let errorMessage = snackbarErrorMessage
              if (err.message && err.response.status === 409) {
                errorMessage = "centers.snackbar.errors.duplicate"
              }
              openErrorSnackbar(t(errorMessage), snackbarDuration)
            })
            .finally(subtractActiveCalls)
        }
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <div className="pb-8">
              <h3>
                {centerId
                  ? t("centers.forms.fields.header.update", {
                      center: initialValues.name,
                    })
                  : t("centers.forms.fields.header.create")}
              </h3>
            </div>
            <div>
              <InputField
                fieldData={{
                  formKey: "name",
                  label: "generic.forms.fields.name",
                  required: true,
                }}
              />
              <CountrySelector
                countries={countries}
                value={values.countryCode}
                onChange={(event) => {
                  const country = countries.find((country) => country.code === event.target.value)
                  if (country) {
                    setFieldValue("countryCode", event.target.value)
                  }
                }}
              />
              <FormDateInput
                fieldData={{
                  formKey: "launchDate",
                  label: "centers.forms.fields.launchDate",
                  required: false,
                }}
              />
              <FormDateInput
                fieldData={{
                  formKey: "latestTermsAndConditionsDate",
                  label: "centers.forms.fields.latestTermsAndConditionsDate",
                  required: false,
                }}
              />

              <label>
                <FormInputInline label="centers.forms.fields.loyaltyProgramPremium" required={false}>
                  <Field
                    name="isPremium"
                    type="checkbox"
                    className="bg-white cursor-pointer border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500"
                  />
                </FormInputInline>
              </label>

              {values.isPremium && (
                <FormDateInput
                  fieldData={{
                    formKey: "loyaltyProgramStartDate",
                    label: "centers.forms.fields.loyaltyProgramStartDate",
                    required: true,
                  }}
                />
              )}

              {centerConfigFields.map((field) => (
                <FormInputInline key={field.formKey} label={field.label} required={field.required}>
                  <Field
                    name={field.formKey}
                    type="checkbox"
                    className="bg-white cursor-pointer border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500"
                  />
                </FormInputInline>
              ))}

              <hr />
              {values.objectives && (
                <Objectives
                  name="objectives"
                  values={values.objectives}
                  isPremium={values.isPremium}
                  setFieldValue={setFieldValue}
                />
              )}
              <br />
            </div>
            <FormActionButtons cancelOnClick={() => history.push("/centers")} />
          </Form>
        )
      }}
    </Formik>
  )
}

export default CentersConfigurationForm
