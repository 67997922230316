import React, { useState } from "react"
import { MemberObject } from "../../../api/MemberObject"
import { useTranslation } from "react-i18next"
import execute from "../../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import Restricted from "../../Restricted"
import { ReturnLogsType, LogsType } from "../../../api/MemberObject"
import { IconModalValidation } from "../../modals/IconModalValidation"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"
import { LogsHistoryTable } from "../form/MembersFormTable"
import { SeeMoreButton } from "../../general/SeeMoreButton"
import { originMemberEdit } from "../../../utils/Constants"

type MemberLogsModalProps = {
  member: MemberObject
}

const MemberLogsModal: React.FC<MemberLogsModalProps> = (props: MemberLogsModalProps) => {
  const { member } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [memberLogs, setMemberLogs] = useState<LogsType[]>()
  const [openErrorSnackbar] = useCustomSnackBar("error")

  const fetchLogs = () => {
    addActiveCalls()
    execute<ReturnLogsType>(
      "get",
      "GET",
      {},
      {},
      { customer_id: member.id, customer_email: member.email, fid_center_id: member.centerId, limit: 5 },
      "logger",
    )
      .then((res) => setMemberLogs(res.logs))
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
  }

  return (
    <IconModalValidation
      open={open}
      setOpen={setOpen}
      icon="icon-doc"
      title={t("modal.logs.title", {
        user: `${member.firstName} ${member.lastName}`,
      })}
      iconTitle={t("logs.snackbar.title")}
      iconStyle="text-3xl"
      buttons={
        <Restricted right="customers.update">
          <SeeMoreButton member={member} origin={originMemberEdit.LOGS} />
        </Restricted>
      }
      onAfterOpen={fetchLogs}
    >
      <div className="pt-4 pb-8 h-full overflow-auto">
        {!memberLogs ? (
          <div />
        ) : memberLogs.length === 0 ? (
          <p>{t("modal.logs.empty")}</p>
        ) : (
          <LogsHistoryTable logs={memberLogs} />
        )}
      </div>
    </IconModalValidation>
  )
}

export default MemberLogsModal
