import React, { FC } from "react"
import FlagIcon from "../../styles/customIcons/FlagIcon"

type PropsType = {
  itemLabel: string
  itemValue: string
  flag?: string
  show?: boolean
}

const InformationLine: FC<PropsType> = (props: PropsType) => {
  const { itemLabel, itemValue, show = true, flag } = props

  return show && itemValue ? (
    <p>
      <span>{itemLabel}</span>
      {flag && <FlagIcon country={flag} />}
      <span>{itemValue}</span>
    </p>
  ) : null
}

export default InformationLine
