import React, { ChangeEvent, Dispatch, FC, ReactNode, SetStateAction } from "react"
import Search from "./Search"
import { CentersInfoCount, CenterTypeWS } from "../centers/CentersType"
import { useTranslation } from "react-i18next"

const getSideMenuItem = (key: string | number, label: string, isSelected: boolean, onClick: () => void) => (
  <div
    key={key}
    className={
      " shadow-md min-h-2.5 p-2 m-4 rounded cursor-pointer hover:bg-blue-200 " +
      (isSelected ? "bg-blueGray-700 text-white" : "bg-blueGray-100 bg-opacity-50")
    }
    onClick={onClick}
  >
    <span className="font-bold">{label}</span>
  </div>
)

type SideMenu = {
  placeholder?: string
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => void
  children: ReactNode
}

const SideMenu: FC<SideMenu> = ({ placeholder, onSearch, children }: SideMenu) => {
  return (
    <div className="w-1/6 pb-6 hidden xl:block shadow-xl text-gray-700 overflow-y-auto overscroll-auto bg-white m-2 rounded h-auto max-h-screen">
      {onSearch !== undefined && (
        <Search customStyle="flex flex-row justify-start" placeHolder={placeholder} onChangeAction={onSearch} />
      )}
      <div>{children}</div>
    </div>
  )
}

type CentersSideMenuProps = {
  centerId?: number
  centers: CenterTypeWS[]
  centersInfoCount: CentersInfoCount
  searchCenter?: string
  setSearchCenter: Dispatch<SetStateAction<string | undefined>>
  setCenterId: Dispatch<SetStateAction<number>>
}

export const CentersSideMenu: FC<CentersSideMenuProps> = ({
  centerId,
  centers,
  centersInfoCount,
  searchCenter,
  setCenterId,
}: CentersSideMenuProps) => {
  const { t } = useTranslation()
  return (
    <SideMenu>
      <p className="mt-4 font-bold text-xl">
        {t("generic.fields.centers")} ({centers.length})
      </p>
      <div>
        {centers.map(
          (center) =>
            (!searchCenter || center.name.toLowerCase().includes(searchCenter)) &&
            getSideMenuItem(
              center.id,
              `${center.name} (${centersInfoCount[center.id] || 0})`,
              centerId === center.id,
              () => setCenterId(center.id),
            ),
        )}
      </div>
    </SideMenu>
  )
}

export type Role = {
  id: number
  name: string
}

type RolesSideMenuProps = {
  roleId: number
  roles: Role[]
  searchRole?: string
  setSearchRole: Dispatch<SetStateAction<string | undefined>>
  setRoleId: Dispatch<SetStateAction<number>>
}

export const RolesSideMenu: FC<RolesSideMenuProps> = ({
  roleId,
  roles,
  searchRole,
  setSearchRole,
  setRoleId,
}: RolesSideMenuProps) => (
  <SideMenu onSearch={(event) => setSearchRole(event.target.value.toLowerCase())}>
    {roles.map(
      (role) =>
        (!searchRole || role.name.toLowerCase().includes(searchRole)) &&
        getSideMenuItem(role.id, role.name, roleId === role.id, () => setRoleId(role.id)),
    )}
  </SideMenu>
)
