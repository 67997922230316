import * as Yup from "yup"

export const centersInitialValues = {
  name: "",
  countryCode: "",
  launchDate: "",
  latestTermsAndConditionsDate: "",
  loyaltyProgramVersion: 1,
  loyaltyProgramStartDate: "",
  isPremium: false,
  hasVIP: false,
  hasParking: false,
  hasCustomerEnrichment: false,
  hasAddressField: false,
  objectives: {},
}

export const centersValidationSchema = Yup.object().shape({
  name: Yup.string().required("generic.forms.errors.required"),
  countryCode: Yup.string().required("generic.forms.errors.required"),
  isPremium: Yup.boolean(),
  loyaltyProgramStartDate: Yup.date().when("isPremium", {
    is: true,
    then: Yup.date().required("generic.forms.errors.required"),
  }),
  hasVIP: Yup.boolean(),
  hasParking: Yup.boolean(),
  hasCustomerEnrichment: Yup.boolean(),
  hasAddressField: Yup.boolean(),
})

export const centerConfigFields = [
  {
    type: "checkbox",
    formKey: "hasVIP",
    tag: "centers.tags.hasVIP",
    label: "centers.forms.fields.hasVIP",
    required: false,
  },
  {
    type: "checkbox",
    formKey: "hasParking",
    tag: "centers.tags.hasParking",
    label: "centers.forms.fields.hasParking",
    required: false,
  },
  {
    type: "checkbox",
    formKey: "hasCustomerEnrichment",
    tag: "centers.tags.hasCustomerEnrichment",
    label: "centers.forms.fields.hasCustomerEnrichment",
    required: false,
  },
  {
    type: "checkbox",
    formKey: "hasAddressField",
    tag: "centers.tags.hasAddressField",
    label: "centers.forms.fields.hasAddressField",
    required: false,
  },
]
