import { useLoyaltyApp } from "./useLoyaltyApp"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import { menu } from "../Constants"
import { hasRight } from "../Roles"
import { menuElement } from "../Types"

export const useMenu = (): menuElement[] => {
  const isLoyaltyApp = useLoyaltyApp()
  const { permissions } = useStore(ContributorStore)
  return isLoyaltyApp
    ? menu.filter(
        (item) =>
          (typeof item.right === "boolean" ? item.right : hasRight(permissions, item.right)) && item.inLoyaltyApp,
      )
    : menu.filter((item) => (typeof item.right === "boolean" ? item.right : hasRight(permissions, item.right)))
}
