import Select from "../Select"
import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { OptionType } from "../MultipleSelect"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { TlpOfferEnumWSType } from "./TlpOffersTypes"

type TlpOffersEnumFilterProps = {
  enumType: string
  label: string
  value: string
  setValue: (choice: string) => void
}

const TlpOffersEnumFilter: FC<TlpOffersEnumFilterProps> = ({
  enumType,
  label,
  value,
  setValue,
}: TlpOffersEnumFilterProps) => {
  const { t } = useTranslation()
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const [possibleValues, setPossibleValues] = useState<OptionType[]>([])

  // Get enums needed for filters
  useEffect(() => {
    addActiveCalls()
    execute<TlpOfferEnumWSType>(`ui/enums/${enumType}`, "GET", {}, {}, { origin: "fid" }, "lpe")
      .then((res) =>
        setPossibleValues(
          res.result.map((r) => {
            return { key: r.label, label: r.label }
          }),
        ),
      )
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return possibleValues?.length > 0 ? (
    <div className="w-full md:w-64">
      <p className="text-xs text-left pl-2 pb-1 cursor-default">{t(label)}</p>
      <Select
        value={value}
        onChange={(event) => setValue(event.target.value)}
        values={possibleValues}
        defaultValue={t("tlpOffers.forms.selectDefaultValue")}
        defaultValueDisabled
      />
    </div>
  ) : null
}

export default TlpOffersEnumFilter
