import * as React from "react"
import { CentersWS } from "../../centers/CentersType"
import EditCognitoUserCenters from "./EditCognitoUserCenters"
import { CognitoUserObject } from "../../../api/CognitoUserObject"
import { useStore } from "effector-react"
import { ContributorStore } from "../../../store/ContributorStore"
import execute from "../../../api/api"
import { useTranslation } from "react-i18next"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import SendEmailCognitoUser from "./SendEmailCognitoUserModal"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"
import { IconWithConfirmation } from "../../modals/IconConfirmationModal"

type CognitoUsersActionsProps = {
  cognitoUser: CognitoUserObject
  centers: CentersWS[]
  refresh: () => void
}
const CognitoUsersActions: React.FC<CognitoUsersActionsProps> = ({
  cognitoUser,
  centers,
  refresh,
}: CognitoUsersActionsProps) => {
  const { t } = useTranslation()
  const { id, email, assignedCenters } = useStore(ContributorStore)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  return (
    <>
      <EditCognitoUserCenters cognitoUser={cognitoUser} centers={centers} />
      <SendEmailCognitoUser cognitoUser={cognitoUser} />
      <IconWithConfirmation
        customStyle="text-3xl"
        onConfirmationClick={() => {
          addActiveCalls()
          execute(`apiGateway/partners/${cognitoUser.username}`, "DELETE")
            .then(() => {
              execute<void>(
                "fid",
                "POST",
                {},
                {
                  centerId: assignedCenters[0]?.id,
                  code: "PartnerDeletion_Dashboard'",
                  content: "Deleting partner from dashboard",
                  application: "dashboard",
                  email: email,
                  customerId: id,
                },
                {},
                "logger",
              )
              openSuccessSnackbar(
                `${t("apiGateway.titles.cognitoUser")} ${t("generic.snackbar.success.delete")}`,
                snackbarDuration,
              )
              refresh()
            })
            .catch(() => {
              openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
            })
            .finally(subtractActiveCalls)
        }}
        icon="icon-delete"
        title={t("generic.buttons.delete")}
        description={t("modal.delete.description")}
      />
    </>
  )
}

export default CognitoUsersActions
