import React, { FC, ReactNode } from "react"

type CardProps = {
  children: ReactNode
  removePadding?: boolean
}

const Card: FC<CardProps> = (props: CardProps) => {
  const { children, removePadding } = props
  return (
    <div className={"rounded shadow-md bg-white flex flex-col m-2 mb-6 " + (!removePadding && "p-6")}>{children}</div>
  )
}

export default Card
