import { TFunction } from "i18next"
import React, { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import HighchartsReact from "highcharts-react-official"
import Highcharts, { Tooltip, TooltipFormatterCallbackFunction } from "highcharts"
import {
  DataCustomersStockDirective,
  dataCustomersStockDirectiveInitialState,
  dataCustomersStockDirectiveInitialStatePartners,
  DataSeries,
  dataSubType,
  getSeriesForHighchart,
  getSeriesForHighchartPartners,
  initialDataSeries,
  initialDataSeriesPartners,
  objectKeyString,
  subGraphType,
  WSGraph,
} from "./ConstCustomer"
import { format } from "date-fns"
import { useStore } from "effector-react"
import execute from "../../../api/api"
import { HighchartSerie } from "../verticalBarChart/ConstPremiumEvolution"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { Loader } from "../../Loader"
import CustomersStockDirectiveActions from "./CustomersStockDirectiveActions"
import { getDateFormatInt } from "../../../utils/Functions"

// Config for Highchart components
const getChartConfig = (
  t: TFunction,
  data: any, //Series for hightcharts
  xAxis: number[],
  xAxisFormat: string,
  formatTooltip: TooltipFormatterCallbackFunction,
  partnersFilter: boolean,
) => {
  return {
    chart: {
      backgroundColor: "transparent",
      height: 580,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: partnersFilter ? t("dashboard.fields.cardOwner.titlePartners") : t("dashboard.fields.cardOwner.title"),
    },
    navigator: {
      enabled: false,
    },
    series: data,
    xAxis: {
      type: "datetime",
      labels: {
        format:
          xAxisFormat === "day" ? "{value: %e %b %Y}" : xAxisFormat === "week" ? "{value: %e %b %Y}" : "{value: %b %y}",
        align: "center",
        y: 30,
      },
      categories: xAxis,
      height: 454,
    },
    yAxis: [
      {
        title: {
          text:
            xAxisFormat === "day"
              ? t("dashboard.fields.cardOwner.firstYaxisByDay")
              : xAxisFormat === "week"
              ? t("dashboard.fields.cardOwner.firstYaxisByWeek")
              : t("dashboard.fields.cardOwner.firstYaxisByMonth"),
        },
        height: 150,
        lineWidth: 1,
      },
      {
        title: {
          text: t("dashboard.fields.cardOwner.secondYaxis"),
        },
        top: 250,
        height: 250,
        offset: 0,
        lineWidth: 1,
        labels: {
          x: -10,
          format: "{value} %",
        },
        showLastLabel: true,
      },
    ],
    plotOptions: {
      area: {
        stacking: "percent",
        lineColor: "#ffffff",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#ffffff",
        },
      },
      column: {
        stacking: "normal",
      },
    },
    tooltip: {
      shared: true,
      formatter: formatTooltip,
    },
    rangeSelector: {
      verticalAlign: "top",
      x: 0,
      y: 0,
    },
    dateRangeGrouping: true,
  }
}

type PropsType = {
  className: string
  id: string
}

//Add the value when week or month selected
const addValue = (cur: { customers: number; percentages: number }, obj: subGraphType) => {
  return {
    percentages: cur.percentages + obj.percent,
    customers: cur.customers + obj.value,
  }
}

const addValuePartner = (cur: { customers: number; percentages: number }, obj: subGraphType) => {
  if (!cur || isNaN(cur.customers) || isNaN(cur.percentages)) {
    cur = { percentages: 0, customers: 0 }
  }
  return {
    percentages: cur.percentages + obj.percent,
    customers: cur.customers + obj.value,
  }
}

//Divide percent when week or month
const dividePercentage = (cur: { customers: number; percentages: number }, nbItem: number) => {
  return { percentages: cur.percentages / nbItem, customers: cur.customers }
}

const updatePrecentage = (tab: any, nbItem: number) => {
  Object.keys(tab).forEach((key) => {
    if (key !== "day" && key !== "date") {
      tab[key] = {
        ...dividePercentage(tab[key], nbItem),
      }
    }
  })
  return { ...tab, date: tab.date }
}

//New format after ws for day
const getNewFormat = (cur: { percentages: number[]; customers: number[] }, obj: subGraphType) => {
  if (!cur) {
    cur = { percentages: [], customers: [] }
  }
  return {
    percentages: [...cur.percentages, obj.percent],
    customers: [...cur.customers, obj.value],
  }
}

//Format object for days
const getObjNewFormat = (tab: any, val: any) => {
  tab.date = [...tab.date, new Date().setTime(val.date)]
  Object.keys(val).forEach((key) => {
    if (key !== "date" && key !== "day") {
      tab[key] = {
        ...getNewFormat(tab[key], val[key]),
      }
    }
  })
  return { ...tab }
}

//New format for week and month
const getWeekMonthData = (cur: { percentages: number[]; customers: number[] }, obj: dataSubType) => {
  if (!cur) {
    cur = { percentages: [], customers: [] }
  }
  return {
    percentages: [...cur.percentages, obj.percentages],
    customers: [...cur.customers, obj.customers],
  }
}

//Format object for week and month
const getObjWeekMonthData = (tab: any, val: any) => {
  tab.date = [...tab.date, new Date(parseInt(val.date)).getTime()]
  Object.keys(val).forEach((key) => {
    if (key !== "date" && key !== "day") {
      tab[key] = {
        ...getWeekMonthData(tab[key], val[key]),
      }
    }
  })
  return { ...tab }
}

//Add value between tab and val for each key
const updateVar = (tab: DataCustomersStockDirective, val: WSGraph) => {
  if (tab.date !== "") {
    Object.keys(tab).forEach((key) => {
      if (key !== "day" && key !== "date") {
        tab[key as objectKeyString] = {
          ...addValue(tab[key as objectKeyString], val[key as objectKeyString]),
        }
      }
    })
    return { ...tab }
  }
  return {
    date: `${tab.date}`,
    mobile: { customers: val.mobile.value, percentages: val.mobile.percent },
    web: { customers: val.web.value, percentages: val.web.percent },
    onSite: { customers: val.onSite.value, percentages: val.onSite.percent },
    Partner: { customers: val.Partner.value, percentages: val.Partner.percent },
    Wifi: { customers: val.Wifi.value, percentages: val.Wifi.percent },
    Directories: {
      customers: val.Directories.value,
      percentages: val.Directories.percent,
    },
  } as DataCustomersStockDirective
}

const updateVarPartners = (tab: any, val: any) => {
  if (tab.date !== "") {
    Object.keys(val).forEach((key) => {
      if (key !== "day" && key !== "date") {
        tab[key] = {
          ...addValuePartner(tab[key], val[key]),
        }
      }
    })
    return { ...tab }
  }
  return {
    date: `${tab.date}`,
    ...val,
  }
}

const resetVar = () => {
  return { ...dataCustomersStockDirectiveInitialState }
}

//Format 2 decimal digit
const getFormatNumber = (myNumber: number) => {
  return Math.round(myNumber).toFixed(2)
}

//Update data in function of day or week or month
const changeDataWithTime = (delay: string, series: WSGraph[]): DataSeries => {
  const formatType = delay === "day" ? "d" : delay === "week" ? "w" : "M"
  let timeDate: string | null = null
  let currentTmpObj: DataCustomersStockDirective = {
    ...dataCustomersStockDirectiveInitialState,
  }
  let nbDataInSeries = 1

  const myNewSeries: DataSeries = series.reduce(
    (acc, currentVal) => {
      if (formatType === "d") {
        return { ...getObjNewFormat(acc, currentVal) }
      } else {
        const currentDate = format(new Date().setTime({ ...currentVal }.date), formatType, { weekStartsOn: 1 })
        if (timeDate === null) {
          timeDate = currentDate
          currentTmpObj = {
            ...updateVar(currentTmpObj, currentVal),
            date: `${getDateFormatInt(currentVal.date, delay)}`,
          }
        } else if (timeDate !== currentDate) {
          currentTmpObj = updatePrecentage(currentTmpObj, nbDataInSeries)
          acc = { ...getObjWeekMonthData(acc, currentTmpObj) }
          currentTmpObj = {
            ...dataCustomersStockDirectiveInitialState,
            date: `${getDateFormatInt(currentVal.date, delay)}`,
          }
          currentTmpObj = { ...updateVar(currentTmpObj, currentVal) }
          nbDataInSeries = 1
          timeDate = currentDate
          resetVar()
        } else {
          nbDataInSeries = nbDataInSeries + 1
          currentTmpObj = { ...updateVar(currentTmpObj, currentVal) }
        }
        if (series.indexOf(currentVal) === series.length - 1) {
          currentTmpObj = updatePrecentage(currentTmpObj, nbDataInSeries)
          acc = { ...getObjWeekMonthData(acc, currentTmpObj) }
        }
        return { ...acc }
      }
    },
    { ...initialDataSeries },
  )
  return myNewSeries as unknown as DataSeries
}

const changeDataWithTimePartners = (delay: string, series: []) => {
  const formatType = delay === "day" ? "d" : delay === "week" ? "w" : "M"
  let timeDate: string | null = null
  let currentTmpObj = {
    ...dataCustomersStockDirectiveInitialStatePartners,
  }
  let nbDataInSeries = 1

  const myNewSeries = series.reduce(
    (acc, currentVal) => {
      if (formatType === "d") {
        return { ...getObjNewFormat(acc, currentVal) }
      } else {
        const currentDate = format(new Date().setTime(currentVal["date"]), formatType, { weekStartsOn: 1 })
        if (timeDate === null) {
          timeDate = currentDate

          currentTmpObj = {
            ...updateVarPartners(currentTmpObj, currentVal),
            date: `${getDateFormatInt(currentVal["date"], delay)}`,
          }
        } else if (timeDate !== currentDate) {
          currentTmpObj = updatePrecentage(currentTmpObj, nbDataInSeries)
          acc = { ...getObjWeekMonthData(acc, currentTmpObj) }
          currentTmpObj = {
            ...dataCustomersStockDirectiveInitialStatePartners,
            date: `${getDateFormatInt(currentVal["date"], delay)}`,
          }
          currentTmpObj = { ...updateVarPartners(currentTmpObj, currentVal) }
          nbDataInSeries = 1
          timeDate = currentDate
          resetVar()
        } else {
          nbDataInSeries = nbDataInSeries + 1
          currentTmpObj = { ...updateVarPartners(currentTmpObj, currentVal) }
        }
        if (series.indexOf(currentVal) === series.length - 1) {
          currentTmpObj = updatePrecentage(currentTmpObj, nbDataInSeries)
          acc = { ...getObjWeekMonthData(acc, currentTmpObj) }
        }
        return { ...acc }
      }
    },
    { ...initialDataSeriesPartners },
  )
  return myNewSeries
}

//return the date format like jj/MM/yyyy if dateSelector = "day" for "week" : jj/MM/yyyy - jj/MM/yyyy month: "01/MM/yyyy
const getDateFormat = (date: string | number, dateSelector: string) => {
  let myDate: Date
  if (typeof date === "string") {
    myDate = new Date(date)
  } else {
    myDate = new Date(new Date().setTime(date))
  }
  const y = myDate.getFullYear()
  const m = myDate.getMonth()
  let dateToPrint

  if (dateSelector === "week") {
    const firstDay = myDate.getDate() - myDate.getDay() + (myDate.getDay() === 0 ? -6 : 1) // week start monday
    dateToPrint = `${format(myDate.setDate(firstDay), "dd/MM/yyyy")} - ${format(
      myDate.setDate(firstDay + 6),
      "dd/MM/yyyy",
    )} `
  } else if (dateSelector === "month") {
    dateToPrint = `${format(new Date(y, m, 1), "dd/MM/yyyy")}`
  } else {
    dateToPrint = format(new Date(myDate).getTime(), "dd/MM/yyyy")
  }

  return dateToPrint
}

const titleSeries = (t: TFunction) => [
  t("generic.fields.onSite"),
  t("generic.fields.mobileApp"),
  t("generic.fields.web"),
  t("generic.fields.partner"),
  t("generic.fields.wifi"),
  t("generic.fields.directories"),
]

const CustomerStockDirective: FC<PropsType> = (props: PropsType) => {
  const { className, id } = props
  const { t, i18n } = useTranslation()
  const storeFilters = useStore(FilterStore)
  const chartRef = useRef<any>(null)

  const [loading, setLoading] = useState(false)
  const [seriesFromWS, setSeriesFromWS] = useState<WSGraph[]>([])
  const [seriesFromWSPartners, setSeriesFromWSPartners] = useState<[]>([])
  const [seriesData, setSeriesData] = useState(initialDataSeries)
  const [seriesDataPartners, setSeriesDataPartners] = useState(initialDataSeriesPartners)
  const [seriesForHighChart, setSeriesForHighChart] = useState<HighchartSerie[]>([])
  const [dateSelector, setDateSelector] = useState("day")

  const ifPartnersFilter = (): boolean => {
    let isPartnerFilter = false
    getDashboardFilters(storeFilters)?.bool?.must.map((obj) => {
      if (obj.terms) {
        if (obj.terms["createdFrom.keyword"].length === 1 && obj.terms["createdFrom.keyword"][0] === "Partner") {
          isPartnerFilter = true
        }
      }
    })
    return isPartnerFilter
  }

  //Custom Tooltip
  const formatTooltip = (tooltip: Tooltip) => {
    let total = 0
    let digital = 0
    const points = tooltip.chart.axes[0].chart.hoverPoints
    if (points) {
      const size = points.length / 2
      let increment = 0
      const day = points[0].category
      let firstTooltip = ""
      let secondTooltip = ""
      points.map((obj: any) => {
        if (increment < size) {
          // ---------------------------------- First part => first graph
          firstTooltip = `${firstTooltip} <br /> <div style="color :${obj.series.color}" >\u25CF ${t(
            obj.series.name,
          )} :</div> <div style="color: #000000">${obj.options.y} </div>`
          total += obj.options.y
        } else {
          // ------------------------------------------- Second part => second Graph
          secondTooltip = `${secondTooltip} <br /> <div style="color :${obj.series.color}" >\u25CF ${t(
            obj.series.name,
          )} :</div> <div style="color: #000000">${getFormatNumber(obj.options.y)} %</div> `
          if (
            obj.series.name === t("generic.fields.mobileApp") ||
            obj.series.name === t("generic.fields.web") ||
            obj.series.name === t("generic.fields.wifi")
          ) {
            digital += obj.options.y
          }
        }
        increment = increment + 1
        return obj
      })

      const dateToPrint = getDateFormat(day, dateSelector)

      // --------------------------------------------------- Final part => all tooltip
      return `<div><b>${dateToPrint}</b> <br /><b>${t(
        "dashboard.fields.cardOwner.userCreated",
      )}</b>  <br />${firstTooltip} <br />
                <b>${t("dashboard.fields.cardOwner.total")}:</b> ${total} <br /><b>${t(
        "dashboard.fields.cardOwner.cumulative",
      )}</b> <br />${secondTooltip} <br />
                <b>${t("dashboard.fields.cardOwner.digital")}</b> ${getFormatNumber(digital)} %</div>`
    }
    return ""
  }
  const [chartConfig, setChartConfig] = useState(
    getChartConfig(
      t,
      seriesForHighChart,
      ifPartnersFilter() ? seriesDataPartners.date : seriesData.date,
      dateSelector,
      formatTooltip,
      false,
    ),
  )

  useEffect(() => {
    setLoading(true)
    execute<[]>(`dashboard/${id}/widget/customersStock`, "GET", {}, {}, { filters: getDashboardFilters(storeFilters) })
      .then((res) => {
        let tmpSeries: any
        if (ifPartnersFilter()) {
          tmpSeries = res.reduce(
            (acc, currentVal) => {
              //On transforme la serie dans le format dont on a besoin
              return { ...getObjNewFormat(acc, currentVal) }
            },
            { ...initialDataSeriesPartners },
          )
          setSeriesDataPartners(tmpSeries)
          const titleOfSeries = Object.keys(tmpSeries)
          const index = titleOfSeries.indexOf("date")
          if (index !== -1) {
            titleOfSeries.splice(index, 1)
          }
          setSeriesForHighChart(getSeriesForHighchartPartners(titleOfSeries, tmpSeries))
          setSeriesFromWSPartners(res)
        } else {
          tmpSeries = res.reduce(
            (acc, currentVal) => {
              //On transforme la serie dans le format dont on a besoin
              return { ...getObjNewFormat(acc, currentVal) }
            },
            { ...initialDataSeries },
          )
          setSeriesData(tmpSeries)
          setSeriesForHighChart(getSeriesForHighchart(titleSeries(t), tmpSeries))
          setSeriesFromWS(res)
        }
        setDateSelector("day")
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters, id])

  useEffect(() => {
    if (ifPartnersFilter()) {
      setChartConfig(getChartConfig(t, seriesForHighChart, seriesDataPartners.date, dateSelector, formatTooltip, true))
      if (chartRef.current) {
        chartRef.current.chart.reflow()
      }
    } else {
      setChartConfig(getChartConfig(t, seriesForHighChart, seriesData.date, dateSelector, formatTooltip, false))
      if (chartRef.current) {
        chartRef.current.chart.reflow()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, ifPartnersFilter() ? seriesDataPartners.date : seriesData.date, seriesForHighChart])

  const clickFunction = (label: string) => {
    if (ifPartnersFilter()) {
      const newSeries: any = changeDataWithTimePartners(label, seriesFromWSPartners)
      setSeriesDataPartners(newSeries)
      const titleOfSeries = Object.keys(newSeries)
      const index = titleOfSeries.indexOf("date")
      if (index !== -1) {
        titleOfSeries.splice(index, 1)
      }
      setSeriesForHighChart(getSeriesForHighchartPartners(titleOfSeries, newSeries))
    } else {
      const newSeries = changeDataWithTime(label, seriesFromWS)
      setSeriesData(newSeries)
      setSeriesForHighChart(getSeriesForHighchart(titleSeries(t), newSeries))
    }
  }

  return (
    <div className={`bg-white flex flex-col h-full ${className}`}>
      {loading ? (
        <div className="h-full col-span-2 row-span-2">
          <Loader />
        </div>
      ) : (
        <>
          <CustomersStockDirectiveActions
            id={id}
            clickFunction={clickFunction}
            dateSelector={dateSelector}
            setDateSelector={setDateSelector}
          />
          <HighchartsReact
            highcharts={Highcharts}
            options={chartConfig}
            allowChartUpdate={true}
            ref={chartRef}
            containerProps={{ style: { height: "100%" } }}
          />
        </>
      )}
    </div>
  )
}

export default CustomerStockDirective
