import { CentersWS, CenterTypeWS } from "../components/centers/CentersType"

export const getCentersList = (centersByCountry: CentersWS[]): CenterTypeWS[] => {
  const getCountryCenters = (accumulator: CenterTypeWS[], currentValue: CentersWS) => {
    return [...accumulator, ...currentValue.centers]
  }
  return centersByCountry.reduce(getCountryCenters, []).sort((a, b) => a.name.localeCompare(b.name))
}

export const getActiveCenters = (centersByCountry: CentersWS[]): CenterTypeWS[] => {
  return getCentersList(centersByCountry).filter((center) => center.launchDate)
}

export const getActiveCentersByCountry = (centersByCountry: CentersWS[]): CentersWS[] => {
  const filterActiveFunction = (acc: CentersWS[], curr: CentersWS) => {
    const activeCenters = curr.centers.filter((center) => center.launchDate)
    if (activeCenters.length !== 0) {
      acc.push({
        ...curr,
        centers: activeCenters,
      })
    }
    return acc
  }
  return centersByCountry.reduce(filterActiveFunction, [])
}

export const getActivePremiumCenters = (centersByCountry: CentersWS[]): CenterTypeWS[] => {
  return getCentersList(centersByCountry).filter((center) => center.launchDate && center.loyaltyProgramStartDate)
}

export const getActivePremiumCentersByCountry = (centersByCountry: CentersWS[]): CentersWS[] => {
  const filterPremiumFunction = (acc: CentersWS[], curr: CentersWS) => {
    const premiumCenters = curr.centers.filter((center) => center.launchDate && center.loyaltyProgramStartDate)
    if (premiumCenters.length !== 0) {
      acc.push({
        ...curr,
        centers: premiumCenters,
      })
    }
    return acc
  }
  return centersByCountry.reduce(filterPremiumFunction, [])
}

export const getVipCenters = (centersByCountry: CentersWS[]): CenterTypeWS[] => {
  return getCentersList(centersByCountry).filter((center) => center.hasVIP)
}

export const getEuropeanCenters = (centersByCountry: CenterTypeWS[]): CenterTypeWS[] => {
  return centersByCountry.filter((center) => isEuropean(center))
}

export const getUKCenters = (centersByCountry: CenterTypeWS[]): CenterTypeWS[] => {
  return centersByCountry.filter((center) => center.countryCode === "uk")
}

export const getActiveEuropeanCenters = (centersByCountry: CentersWS[]): CenterTypeWS[] => {
  return getCentersList(centersByCountry).filter((center) => center.launchDate && isEuropean(center))
}

const isEuropean = (center: CenterTypeWS): boolean => {
  return center.countryCode !== "uk" && center.countryCode !== "us"
}
