import React, { useEffect, useRef, useState } from "react"
import Highcharts, { Options } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { Loader } from "../../Loader"
import { ColorGraphs } from "../../../utils/Constants"

const getChartConfig = (
  title: string,
  data: { name?: string; y: number; key?: string }[],
  subtitle?: string,
  colors?: string[],
): Options => {
  return {
    chart: {
      backgroundColor: "transparent",
      height: "300px",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
    },
    subtitle: {
      text: subtitle,
    },
    colors: colors
      ? colors
      : [
          ColorGraphs.blue,
          ColorGraphs.black,
          ColorGraphs.green,
          ColorGraphs.orange,
          ColorGraphs.purple,
          ColorGraphs.teal,
          ColorGraphs.red,
        ], //default color
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.2f} %</b><br/>{point.y:,.Of}",
          color: "black",
          distance: 10,
        },
        showInLegend: true,
      },
    },
    tooltip: {
      pointFormat: "<b>{point.y}</b>",
    },
    series: [
      {
        type: "pie",
        data: data,
      },
    ],
  }
}

type PropsType = {
  title: string
  subtitle?: string
  isSecondaryWidget: boolean
  data: { name?: string; y: number; key?: string }[]
  colors?: string[]
  bottomText?: string
  loading: boolean
}

const PieCharts: React.FC<PropsType> = (props: PropsType) => {
  const { title, subtitle, isSecondaryWidget, data, colors, bottomText, loading } = props
  const [chartOptions, setChartOptions] = useState(getChartConfig(title, data, subtitle, colors))

  const chartRef = useRef<any>(null)

  useEffect(() => {
    setChartOptions(getChartConfig(title, data, subtitle, colors))
    if (chartRef.current) {
      chartRef.current.chart.reflow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, data, subtitle, colors])

  return (
    <div
      className={`${isSecondaryWidget ? "bg-lightBlue-50" : "bg-white"} flex flex-col justify-between h-full`}
      key={title}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <HighchartsReact
            classeName={`${bottomText ? "h-full" : ""}`}
            highcharts={Highcharts}
            options={chartOptions}
            ref={chartRef}
            allowChartUpdate
          />
          {bottomText ? (
            <div className="bg-blueGray-100">{bottomText}</div>
          ) : (
            <div className="bg-blueGray-100 invisible " />
          )}
        </>
      )}
    </div>
  )
}

export default PieCharts
