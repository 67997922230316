import { ColorGraphs } from "../../../utils/Constants"

export type objectKeyString = "date" | "apps" | "web" | "partner" | "total" | "others"

export type Customer = {
  value: number
  total: number
}
export type Customers = {
  customers: number[]
}
export type basedSeriesItem = {
  date: number
  day: string
  apps: Customer
  web: Customer
  Partner: Customer
  total: Customer
  others: Customer
}
export const basedSeriesItemInitial: basedSeriesItem = {
  date: 0,
  day: "",
  apps: { value: 0, total: 0 },
  web: { value: 0, total: 0 },
  Partner: { value: 0, total: 0 },
  total: { value: 0, total: 0 },
  others: { value: 0, total: 0 },
}

export type DataSeries = {
  date: string[]
  apps: Customers
  web: Customers
  partner: Customers
  total: Customers
  others: Customers
}

export const initialDataSeries: DataSeries = {
  date: [],
  apps: { customers: [] },
  web: { customers: [] },
  partner: { customers: [] },
  total: { customers: [] },
  others: { customers: [] },
}

export type HighchartSerie = {
  yAxis: number
  type: string
  name: string
  data: number[] | null
  marker: {
    enabled: boolean
  }
  shadow: boolean
  color: string
  showInLegend: boolean
  turboThreshold: number
}

export const getSeriesForHighchart = (titleSeries: string[], seriesData: DataSeries): HighchartSerie[] => {
  return [
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[0],
      data: seriesData.apps.customers,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.green,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[1],
      data: seriesData.web.customers,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.blue,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[2],
      data: seriesData.partner.customers,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.red,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[3],
      data: seriesData.others.customers,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.orange,
      showInLegend: false,
      turboThreshold: 0,
    },
  ]
}
