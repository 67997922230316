import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { CheckinWS } from "../checkin/CheckInType"
import { CenterOperation, CenterTypeWS } from "../centers/CentersType"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import { Field, Form, FormikProps } from "formik"
import FormInputLayout from "../FormInputLayout"
import Select from "../Select"
import {
  customersOrigins,
  exportOptInValues,
  exportsTypes,
  exportMemberTypeValues,
} from "../../views/exports/ExportsConst"
import FormDateInput from "../general/FormDateInput"
import MultipleSelect from "../MultipleSelect"
import FormActionButtons from "../general/FormActionButtons"
import { ExportType } from "../../views/exports/ExportsType"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"

function filterValuesByCenterId<T extends { centerId: number }>(values: T[] | undefined, centers: CenterTypeWS[]): T[] {
  return values ? values.filter((value) => centers.some((center) => value.centerId === center.id)) : []
}

type ExportsProps = FormikProps<ExportType> & {
  centers: CenterTypeWS[]
}

const ExportsForm: React.FC<ExportsProps> = ({ centers, values, setFieldValue }) => {
  const { t } = useTranslation()
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const [checkins, setCheckins] = useState<CheckinWS[]>([])
  const [centerOperations, setCenterOperations] = useState<CenterOperation[]>([])

  const isPeriodTooLong = (startDateValue: string, endDateValue: string) => {
    const startDate = new Date(startDateValue)
    const endDate = new Date(endDateValue)
    return endDate.getTime() - startDate.getTime() > 31556952000 // one year
  }
  useEffect(() => {
    if (values.centers.length > 0) {
      const centersId = JSON.stringify(values.centers.map((center) => center.id))
      if (values.type === "checkins") {
        addActiveCalls()
        execute<CheckinWS[]>(`checkins?centersId=${centersId}`, "GET")
          .then((checkins) => {
            setCheckins(checkins)

            // Filters the check-ins values to remove ones to old selected centers
            values.checkIns = filterValuesByCenterId<CheckinWS>(values.checkIns, values.centers)
          })
          .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
          .finally(subtractActiveCalls)
      } else if (values.type === "centerOperations") {
        addActiveCalls()
        execute<CenterOperation[]>(`centerOperations?centersId=${centersId}`, "GET")
          .then((centerOperations) => {
            setCenterOperations(centerOperations)

            // Filters the center operations values to remove ones related to old selected centers
            values.centerOperations = filterValuesByCenterId<CenterOperation>(values.centerOperations, values.centers)
          })
          .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
          .finally(subtractActiveCalls)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.centers, values.type])

  return (
    <Form>
      <div>
        <Field name="type">
          {({ meta }: any) => (
            <FormInputLayout label="exports.forms.fields.type" meta={meta} required>
              <Select
                onChange={(event) => setFieldValue("type", event.target.value)}
                large
                values={exportsTypes}
                value={values.type}
                defaultValueDisabled
              />
            </FormInputLayout>
          )}
        </Field>
        <FormDateInput
          fieldData={{
            formKey: "startDate",
            label: "checkins.forms.fields.from",
            placeholder: t("generic.format.datePlaceholder"),
            required: false,
          }}
        />
        <FormDateInput
          fieldData={{
            formKey: "endDate",
            label: "checkins.forms.fields.to",
            placeholder: t("generic.format.datePlaceholder"),
            required: false,
          }}
        />
        {centers && !["dashboard", "tlpDashboard"].includes(values.type) && (
          <Field name="centers">
            {({ field, meta }: any) => (
              <FormInputLayout label="menu.centers" required={values.type === "centerOperations"}>
                <MultipleSelect
                  options={centers}
                  values={field.value}
                  keyRef="id"
                  labelRef="name"
                  field={field.name}
                  setFieldValue={setFieldValue}
                  meta={meta}
                  large
                />
              </FormInputLayout>
            )}
          </Field>
        )}
        {values.type === "customers" && (
          <>
            <Field name="origins">
              {({ field, meta }: any) => (
                <FormInputLayout label="dashboard.pie.membersCreation.label">
                  <MultipleSelect
                    options={customersOrigins}
                    values={field.value}
                    keyRef="id"
                    labelRef="label"
                    field={field.name}
                    setFieldValue={setFieldValue}
                    meta={meta}
                    large
                  />
                </FormInputLayout>
              )}
            </Field>
            <Field name="memberType">
              {({ meta }: any) => {
                return (
                  <FormInputLayout label="exports.forms.fields.memberType" meta={meta} required>
                    <Select
                      onChange={(event) => setFieldValue("memberType", event.target.value)}
                      large
                      values={exportMemberTypeValues}
                      value={values.memberType}
                      defaultValueDisabled
                    />
                  </FormInputLayout>
                )
              }}
            </Field>
            <Field name="optinCommercial">
              {({ meta }: any) => (
                <FormInputLayout label="dashboard.pie.optin.optinCommercial" meta={meta} required>
                  <Select
                    onChange={(event) =>
                      setFieldValue(
                        "optinCommercial",
                        event.target.value === "all" ? undefined : event.target.value === "true",
                      )
                    }
                    values={exportOptInValues}
                    value={values.optinCommercial === undefined ? "all" : values.optinCommercial.toString()}
                    large
                    defaultValueDisabled
                  />
                </FormInputLayout>
              )}
            </Field>
            <Field name="optinSMS">
              {({ meta }: any) => (
                <FormInputLayout label="dashboard.pie.optin.optinSms" meta={meta} required>
                  <Select
                    onChange={(event) =>
                      setFieldValue(
                        "optinSMS",
                        event.target.value === "all" ? undefined : event.target.value === "true",
                      )
                    }
                    values={exportOptInValues}
                    value={values.optinSMS === undefined ? "all" : values.optinSMS.toString()}
                    large
                    defaultValueDisabled
                  />
                </FormInputLayout>
              )}
            </Field>
          </>
        )}
        {checkins && values.type === "checkins" && values.centers.length > 0 && (
          <Field name="checkIns">
            {({ field, meta }: any) => (
              <FormInputLayout label="menu.checkIns">
                <MultipleSelect
                  options={checkins}
                  values={field.value}
                  keyRef="id"
                  labelRef="name"
                  field={field.name}
                  setFieldValue={setFieldValue}
                  meta={meta}
                  large
                />
              </FormInputLayout>
            )}
          </Field>
        )}
        {centerOperations && values.type === "centerOperations" && values.centers.length > 0 && (
          <Field name="centerOperations">
            {({ field, meta }: any) => (
              <FormInputLayout label="centers.fields.centerOperations">
                <MultipleSelect
                  options={centerOperations}
                  values={field.value}
                  keyRef="gameName"
                  labelRef="gameName"
                  field={field.name}
                  setFieldValue={setFieldValue}
                  meta={meta}
                  large
                />
              </FormInputLayout>
            )}
          </Field>
        )}
        {isPeriodTooLong(values.startDate, values.endDate) && (
          <div className="bg-yellow-100 rounded p-1 mx-24 font-medium text-yellow-800">
            <label>{t("exports.fields.alertFailed")}</label>
          </div>
        )}
      </div>
      <FormActionButtons />
    </Form>
  )
}

export default ExportsForm
