import React from "react"
import { useTranslation } from "react-i18next"
import Button, { ButtonType } from "./Button"

type Props = {
  onClick: () => void
  label?: string
  type?: ButtonType
  classNames?: string
}

const AddButton: React.FC<Props> = ({ onClick, label, type, classNames }: Props) => {
  const { t } = useTranslation()
  if (!label) {
    label = t("generic.buttons.add")
  }
  return <Button onClick={onClick} label={label} icon="icon-plus" type={type} classNames={classNames} />
}

export default AddButton
