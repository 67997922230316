import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { execute } from "../../../api/api"
import { useStore } from "effector-react"
import { PremiumObjectiveInfoObject } from "../../../api/PremiumObject"
import { FilterStore } from "../../../store/FilterStore"
import { getTLPDashboardFilters } from "../ChartsUtils"
import { Loader } from "../../../components/Loader"
import { numberWithThousandsSeparator } from "../../../utils/Functions"

type InfoChartsProps = {
  id: string
}
const InfoCharts: React.FC<InfoChartsProps> = (props: InfoChartsProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<PremiumObjectiveInfoObject>({
    premiumObjective: 0,
    periodCustomers: 0,
  })

  const filterStore = useStore(FilterStore)

  let years
  const firstYear = new Date(filterStore.date.fromDateFilter).getFullYear()
  const secondYear = new Date(filterStore.date.toDateFilter).getFullYear()

  if (firstYear === secondYear) {
    years = `${firstYear}`
  } else {
    years = `${firstYear} - ${secondYear}`
  }

  useEffect(() => {
    setLoading(true)
    execute<PremiumObjectiveInfoObject>(
      `dashboard/${id}/widget/premium/objective`,
      "GET",
      {},
      {},
      { filters: getTLPDashboardFilters(filterStore) },
    )
      .then((res) => {
        if (res) {
          setData(res)
        }
      })
      .finally(() => setLoading(false))
  }, [id, filterStore])

  return (
    <div className="bg-white flex  h-full px-10 items-center justify-center">
      {loading ? (
        <Loader />
      ) : (
        <div className="space-y-2">
          <div className="text-4xl">{t("premiumDashboard.info.members.title")}</div>
          <div className="text-xl">
            {t("premiumDashboard.info.members.subtitle", {
              years: years,
              value: numberWithThousandsSeparator(data.premiumObjective),
            })}
          </div>
          <div className="text-2xl">
            {t("premiumDashboard.info.members.info", {
              value: numberWithThousandsSeparator(data.periodCustomers),
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default InfoCharts
