import { TFunction } from "i18next"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import HighchartsReact from "highcharts-react-official"
import Highcharts, { Tooltip, TooltipFormatterCallbackFunction } from "highcharts"
import {
  basedSeriesItem,
  basedSeriesItemInitial,
  Customer,
  DataSeries,
  getSeriesForHighchart,
  HighchartSerie,
  initialDataSeries,
} from "./ConstPremiumEvolution"
import { format } from "date-fns"
import { getButton, myButtons } from "../Utils"
import execute from "../../../api/api"
import { useStore } from "effector-react"
import { FilterStore } from "../../../store/FilterStore"
import { getTLPDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { Loader } from "../../Loader"
import Legend from "./Legend"
import { ColorGraphs } from "../../../utils/Constants"
import { getDateFormatInt } from "../../../utils/Functions"

const getChartConfig = (
  t: TFunction,
  data: any,
  xAxis: string[],
  xAxisFormat: string,
  formatTooltip: TooltipFormatterCallbackFunction,
) => {
  return {
    chart: {
      backgroundColor: "transparent",
      height: "20%",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: t("dashboard.fields.cardOwner.title"),
    },
    navigator: {
      enabled: false,
    },
    series: data,
    xAxis: {
      categories: xAxis,
    },
    yAxis: [
      {
        title: {
          text:
            xAxisFormat === "day"
              ? t("dashboard.fields.cardOwner.firstYaxisByDay")
              : xAxisFormat === "week"
              ? t("dashboard.fields.cardOwner.firstYaxisByWeek")
              : t("dashboard.fields.cardOwner.firstYaxisByMonth"),
        },
      },
    ],
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    tooltip: {
      shared: true,
      formatter: formatTooltip,
    },
    dateRangeGrouping: true,
  }
}

type PropsType = {
  className: string
  id: string
}

const addValue = (cur: Customer, obj: Customer) => {
  return {
    value: cur.value + obj.value,
    total: cur.total + obj.total,
  }
}

const getNewFormat = (cur: number[], obj: Customer) => {
  return {
    customers: [...cur, obj.value],
  }
}

const getObjNewFormat = (tab: DataSeries, val: basedSeriesItem) => {
  tab.date = [...tab.date, format(new Date(val.date), "dd MMM yyyy")]
  tab.apps = getNewFormat(tab.apps.customers, { ...val.apps })
  tab.web = getNewFormat(tab.web.customers, { ...val.web })
  tab.partner = getNewFormat(tab.partner.customers, { ...val.Partner })
  tab.others = getNewFormat(tab.others.customers, { ...val.others })
  tab.total = getNewFormat(tab.total.customers, { ...val.total })

  return { ...tab }
}

const updateVar = (tab: basedSeriesItem, val: basedSeriesItem) => {
  if (tab.day !== "") {
    tab.apps = addValue(tab.apps, val.apps)
    tab.web = addValue(tab.web, val.web)
    tab.Partner = addValue(tab.Partner, val.Partner)
    tab.others = addValue(tab.others, val.others)
    tab.total = addValue(tab.total, val.total)
    return { ...tab }
  }
  return { ...val, date: tab.date }
}

const resetVar = () => {
  return { ...basedSeriesItemInitial }
}

const changeData = (delay: string, series: basedSeriesItem[]): DataSeries => {
  let timeDate: string | null = null
  let tmpObj = { ...basedSeriesItemInitial }
  const formatType = delay === "day" ? "d" : delay === "week" ? "w" : "M"

  const tmp: DataSeries = series.reduce(
    (acc: DataSeries, currentVal: basedSeriesItem) => {
      if (formatType === "d") {
        return { ...getObjNewFormat(acc, currentVal) }
      } else {
        const tmpDate = format(new Date({ ...currentVal }.date), formatType, { weekStartsOn: 1 })

        if (timeDate === null) {
          timeDate = tmpDate
          tmpObj = { ...updateVar(tmpObj, currentVal), date: getDateFormatInt(currentVal.date, delay) }
        } else if (timeDate !== tmpDate) {
          acc = { ...getObjNewFormat(acc, tmpObj) }
          tmpObj = {
            ...basedSeriesItemInitial,
            date: getDateFormatInt(currentVal.date, delay),
          }
          tmpObj = { ...updateVar(tmpObj, currentVal) }
          timeDate = tmpDate
          resetVar()
        } else {
          tmpObj = { ...updateVar(tmpObj, currentVal) }
        }

        if (series.indexOf(currentVal) === series.length - 1) {
          acc = { ...getObjNewFormat(acc, tmpObj) }
        }
        return { ...acc }
      }
    },
    { ...initialDataSeries },
  )
  return tmp as unknown as DataSeries
}

const formatTooltip = (t: TFunction) => (tooltip: Tooltip) => {
  const points = tooltip.chart.axes[0].chart.hoverPoints
  if (points) {
    const day = points[0].category
    let firstTooltip = ""
    points.map((obj: any) => {
      firstTooltip = `${firstTooltip} <br /> <div style="color :${obj.series.color}" >\u25CF ${t(
        obj.series.name,
      )} :</div> <div style="color: #000000">${obj.options.y} </div>`
      return obj
    })
    return `<div><b>${day}</b> <br /> <br />${firstTooltip} <br /> 
    </div>`
  }
  return ""
}

const VerticalBarCharts: React.FC<PropsType> = (props: PropsType) => {
  const { className, id } = props
  const { t, i18n } = useTranslation()
  const chartRef = useRef<any>(null)
  const [loading, setLoading] = useState(false)
  const [seriesData, setSeriesData] = useState(initialDataSeries)
  const [dateSelector, setDateSelector] = useState("day")
  const [seriesFromWS, setSeriesFromWS] = useState<basedSeriesItem[]>([])
  const [seriesForHighChart, setSeriesForHighChart] = useState<HighchartSerie[]>([])
  const [chartConfig, setChartConfig] = useState(
    getChartConfig(t, seriesForHighChart, seriesData.date, dateSelector, formatTooltip(t)),
  )

  const storeFilters = useStore(FilterStore)
  /* WARNING the order is essential*/
  const titleSeries = (t: TFunction) => [
    t("premiumDashboard.pie.origin.mobile"),
    t("premiumDashboard.pie.origin.web"),
    t("premiumDashboard.pie.origin.partner"),
    t("premiumDashboard.pie.origin.others"),
  ]

  useEffect(() => {
    setChartConfig(getChartConfig(t, seriesForHighChart, seriesData.date, dateSelector, formatTooltip(t)))
    if (chartRef.current) {
      chartRef.current.chart.reflow()
      chartRef.current.chart.redraw()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesData.date, seriesForHighChart, i18n.language])

  useEffect(() => {
    setLoading(true)
    execute<basedSeriesItem[]>(
      `dashboard/${id}/widget/premium/customersStock`,
      "GET",
      {},
      {},
      { filters: getTLPDashboardFilters(storeFilters) },
    )
      .then((res) => {
        const dataConvert = res.reduce(
          (acc, currentVal) => {
            //On transforme la serie dans le format dont on a besoin
            return { ...getObjNewFormat(acc, currentVal) }
          },
          { ...initialDataSeries },
        )
        setSeriesFromWS(res)
        setSeriesData(dataConvert)
        setSeriesForHighChart(getSeriesForHighchart(titleSeries(t), dataConvert))
        setDateSelector("day")
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters, id])

  useEffect(() => {
    setChartConfig(getChartConfig(t, seriesForHighChart, seriesData.date, dateSelector, formatTooltip(t)))
    if (chartRef.current) {
      chartRef.current.chart.reflow()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesData.date, i18n.language, seriesForHighChart])

  const clickFunction = (label: string) => {
    // const newSeries = changeData(label, seriesFromWS)
    // const newRealSeries = getSeriesForHighchart(titleSeries, newSeries)
    // setChartConfig(getChartConfig(t, newRealSeries, newSeries.date, dateSelector, formatTooltip(t)))

    const newSeries = changeData(label, seriesFromWS)
    setSeriesData(newSeries)
    setSeriesForHighChart(getSeriesForHighchart(titleSeries(t), newSeries))
  }

  return (
    <div className={`bg-white flex flex-col h-full ${className}`}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-row justify-end items-center mr-4">
            {myButtons(clickFunction, setDateSelector, t).map((item) =>
              getButton(item.click, item.label, item.key === dateSelector),
            )}
          </div>
          <HighchartsReact highcharts={Highcharts} options={chartConfig} allowChartUpdate={true} ref={chartRef} />
          <div className="flex justify-center pb-4">
            <Legend
              data={seriesForHighChart}
              colors={[ColorGraphs.green, ColorGraphs.blue, ColorGraphs.red, ColorGraphs.orange]}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default VerticalBarCharts
