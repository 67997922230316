import React, { useState } from "react"
import { ApiKeysTypeWS } from "./ApiKeysTypes"
import { Form, Formik } from "formik"
import CheckboxRow from "../cognitoUsers/CheckboxRow"
import { useTranslation } from "react-i18next"
import { useStore } from "effector-react"
import { ContributorStore } from "../../../store/ContributorStore"
import execute from "../../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import { convertToRequestFormat, getFormikApisValues } from "../../../utils/ApiKeys"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import CustomModal from "../../CustomModal"
import FormActionButtons from "../../general/FormActionButtons"
import Header from "../../Header"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"

type ManageLevelsModalProps = {
  apiKey: ApiKeysTypeWS
  refresh: () => void
}

const ManageLevelsModal: React.FC<ManageLevelsModalProps> = (props: ManageLevelsModalProps) => {
  const { apiKey, refresh } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const { id, email, assignedCenters } = useStore(ContributorStore)

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <div>
      <i className="mx-1 icon-edit text-2xl text-black cursor-pointer" onClick={() => setOpen(true)} />
      {open && (
        <CustomModal isOpen={open} onRequestClose={closeModal}>
          <Formik
            initialValues={{
              apiAccess: getFormikApisValues(apiKey),
            }}
            onSubmit={(values) => {
              addActiveCalls()
              execute(
                `apiGateway/partnersLevels/${apiKey.id}`,
                "PUT",
                {},
                values.apiAccess.reduce(convertToRequestFormat, {}),
              )
                .then(() => {
                  execute<void>(
                    "fid",
                    "POST",
                    {},
                    {
                      centerId: assignedCenters[0]?.id,
                      code: "ApiKeyModification_Dashboard'",
                      content: "Modifying ApiKey from dashboard",
                      application: "dashboard",
                      email: email,
                      customerId: id,
                    },
                    {},
                    "logger",
                  )
                  refresh()
                  openSuccessSnackbar(
                    `${t("apiGateway.titles.managedLevels")} ${t([
                      "generic.snackbar.success.editPlural",
                      "generic.snackbar.success.edit",
                    ])}`,
                    snackbarDuration,
                  )
                  setOpen(false)
                })
                .catch(() => openErrorSnackbar(snackbarErrorMessage, snackbarDuration))
                .finally(subtractActiveCalls)
            }}
          >
            {({ values, setFieldValue }) => (
              <Form className="h-full flex flex-col justify-between">
                <div>
                  <Header>{t("apiGateway.titles.managedLevels")}</Header>
                  <hr />
                  <div>
                    {values.apiAccess.map((api, index) => (
                      <div className="flex flex-row items-center md:px-20" key={api.key}>
                        <CheckboxRow
                          key={api.key}
                          label={api.label}
                          checked={api.checked}
                          onClick={() => setFieldValue(`apiAccess[${index}].checked`, !api.checked)}
                        />
                        {api.key === "partner" && api.checked ? (
                          <label className="ml-10">
                            level
                            <input
                              className="w-12 ml-2 pl-1 border-1 border-solid border-grey-200"
                              type="number"
                              min="1"
                              max="4"
                              defaultValue={apiKey.apiAccess[api.key] || 1}
                              onChange={(event) =>
                                setFieldValue(`apiAccess[${index}].value`, parseInt(event.target.value))
                              }
                            />
                          </label>
                        ) : (
                          <br />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <FormActionButtons cancelOnClick={closeModal} />
              </Form>
            )}
          </Formik>
        </CustomModal>
      )}
    </div>
  )
}

export default ManageLevelsModal
