import * as React from "react"
import { useTranslation } from "react-i18next"
import CustomModal from "../CustomModal"
import Header from "../Header"
import { FormCancelButton } from "../profile/FormSubmitButton"

type ConfirmationModalProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  title?: string
  children: React.ReactElement
  buttons: React.ReactElement
  onAfterOpen?: () => void
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (props: ConfirmationModalProps) => {
  const { t } = useTranslation()
  const { isOpen, setIsOpen, title = t("modal.title"), children, buttons, onAfterOpen } = props

  return (
    <>
      {isOpen && (
        <CustomModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} onAfterOpen={onAfterOpen}>
          <div className="h-full flex flex-col justify-between">
            <div>
              <Header>{title}</Header>
              <div className="h-1 border-t-1 border-blueGray-200" />
              {children}
            </div>

            <div>
              <div className="h-1 border-b-1 border-blueGray-200" />
              <div className="flex flex-row justify-end">
                <FormCancelButton onClick={() => setIsOpen(false)} customStyle="mt-2">
                  {t("generic.buttons.cancel")}
                </FormCancelButton>
                {buttons}
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  )
}
