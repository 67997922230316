import React, { FC, useEffect } from "react"
import { Tabs } from "../../components/Tabs"
import CognitoUsersTab from "../../components/apigateway/cognitoUsers/CognitoUsersTab"
import ApiKeysTab from "../../components/apigateway/apiKeys/ApiKeysTab"
import SwaggerTab from "../../components/apigateway/SwaggerTab"
import { useTranslation } from "react-i18next"
import { Color } from "../../utils/Constants"
import { useHistory, useParams } from "react-router-dom"
import { ApiGatewayTabId, apiGatewayTabs } from "../../components/apigateway/ApiGatewayConsts"
import { useTitle } from "../../utils/hooks/useTitle"

const getTabView = (selectedTab: string) => {
  switch (selectedTab) {
    case ApiGatewayTabId.SWAGGER:
      return <SwaggerTab />
    case ApiGatewayTabId.COGNITO:
      return <CognitoUsersTab />
    case ApiGatewayTabId.API_KEYS:
      return <ApiKeysTab />
  }
}

const ApiGateway: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { tab } = useParams()
  const initialTab = ApiGatewayTabId.SWAGGER

  const handleChangeTab = (valueTab: string) => {
    history.push(`/apiGateway/${valueTab}`)
  }

  // Dynamically update browser tab title
  useTitle(`${t("menu.apiGateway")} | ${t("applicationName")}`)

  //Dynamically path URL
  useEffect(() => {
    if (!tab) {
      history.push(`/apiGateway/${initialTab}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <>
      <div className={`w-screen h-2 ${Color.APIGATEWAY}`} />
      <div className="flex flex-col flex-grow-1 pt-2">
        <Tabs selectedTab={tab} setSelectedTab={handleChangeTab} tabs={apiGatewayTabs} />
        {getTabView(tab)}
      </div>
    </>
  )
}

export default ApiGateway
