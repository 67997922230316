import React, { useEffect, useRef, useState } from "react"
import Card from "../Card"
import { Form, Formik } from "formik"
import Container from "../../general/Container"
import { idTabsMembersAction, membersActionTabs, membersFormValidationSchema } from "./MembersFormConsts"
import { Color, originMemberEdit } from "../../../utils/Constants"
import InputField from "../../general/InputField"
import { format } from "date-fns"
import { CenterTypeWS } from "../../centers/CentersType"
import { MemberType, TransactionsType } from "../../../api/MemberObject"
import { getMemberFormFields } from "./MembersFormComponents"
import { BarCodeSection } from "./BarcodeSection"
import { GenderField } from "./GenderField"
import { CenterSelector } from "../../centers/CenterSelector"
import { WorkNearMallSection } from "./WorkNearMallSection"
import { AgreementSection } from "./AgreementSection"
import { CheckinUse } from "../../checkin/CheckInType"
import { CountriesWS } from "../../../utils/Country"
import { getEuropeanCenters, getUKCenters } from "../../../utils/Centers"
import { Tabs } from "../../Tabs"
import { CheckInsTab, LogsTab, TransactionsTab } from "./MembersActionTabs"
import { useLocation } from "react-router-dom"
import ConfirmationSection from "./ConfirmationSection"
import { AgreementSectionPartialMember } from "./AgreementSectionPartialMember"

const getTabView = (
  selectedTab: string,
  checkinsUses?: CheckinUse[],
  transactions?: TransactionsType[],
  member?: MemberType,
) => {
  switch (selectedTab) {
    case idTabsMembersAction.CHECKINS:
      return <CheckInsTab checkinsUses={checkinsUses} />
    case idTabsMembersAction.TLP:
      return <TransactionsTab transactions={transactions} />
    case idTabsMembersAction.LOGS:
      return <LogsTab member={member} />
  }
}

type MembersFormProps = {
  isCreate?: boolean
  centers: CenterTypeWS[]
  countries: CountriesWS[]
  member: MemberType
  checkinsUses?: CheckinUse[]
  transactions?: TransactionsType[]
  customerId?: string
  onSubmit: (values: MemberType) => void
}

type LocationMemberForm = {
  origin: string
}

const MembersForm: React.FC<MembersFormProps> = ({
  isCreate,
  centers,
  countries,
  member,
  checkinsUses,
  transactions,
  onSubmit,
}: MembersFormProps) => {
  const location = useLocation<LocationMemberForm>()
  const containerMembersActionTabs = useRef<HTMLDivElement>(null)
  const [primaryCenter, setPrimaryCenter] = useState<CenterTypeWS>()
  const [zipAddressRegex, setZipAddressRegex] = useState("")
  const [tab, setTab] = useState<string>(idTabsMembersAction.CHECKINS)
  const isUKMember = member?.countryCode?.toLowerCase() === "uk"
  const isPartialMember = member?.type?.toLowerCase() === "partial"

  useEffect(() => {
    if (member.primaryCenterId) {
      setPrimaryCenter(centers.find((center) => center.id === member.primaryCenterId))
    }
  }, [member.primaryCenterId, centers])

  useEffect(() => {
    if (primaryCenter && countries.length) {
      const countryCode = centers.find((center) => center.id === primaryCenter.id)?.countryCode
      if (countryCode) {
        const regex = countries.find((country) => country.code === countryCode)

        if (regex) {
          setZipAddressRegex(regex.postalCodeRegex)
        }
      }
    }
  }, [centers, primaryCenter, countries])

  useEffect(() => {
    const origin = location?.state?.origin
    switch (origin) {
      case originMemberEdit.TLP:
      case originMemberEdit.CHECKINS:
      case originMemberEdit.LOGS:
        containerMembersActionTabs?.current?.scrollIntoView()
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const origin = location?.state?.origin
    switch (origin) {
      case originMemberEdit.TLP:
        setTab(idTabsMembersAction.TLP)
        break
      case originMemberEdit.CHECKINS:
        setTab(idTabsMembersAction.CHECKINS)
        break
      case originMemberEdit.LOGS:
        setTab(idTabsMembersAction.LOGS)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={`w-screen h-2 ${Color.MEMBERS}`} />
      <div className="pt-6">
        {member.barcode && !isUKMember && <BarCodeSection barcodeId={member.barcode} customerId={member.id} />}
        <Formik
          initialValues={member}
          validationSchema={membersFormValidationSchema(zipAddressRegex, isPartialMember)}
          onSubmit={(values) => {
            const formData = {
              ...values,
              firstName: values.firstName ? values.firstName : null,
              lastName: values.lastName ? values.lastName : null,
              optinCommercial: values.optinLoyalty ? values.optinCommercial : false,
              optinSMS: values.optinLoyalty ? values.optinSMS : false,
              birthDate: values.birthDate ? format(new Date(values.birthDate), "yyyy-MM-dd") : null,
              addressZipCode: values.addressZipCode || undefined,
              addressStreet: primaryCenter?.hasAddressField ? values.addressStreet : undefined,
              addressCity: primaryCenter?.hasAddressField ? values.addressCity : undefined,
              licencePlate: primaryCenter?.hasParking ? values.licencePlate : undefined,
              companyName: values.isVIP ? values.companyName : undefined,
            }

            onSubmit(formData)
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className="py-5">
              <Container>
                <Card>
                  <div className="pr-0 ">
                    <CenterSelector
                      isCreate={isCreate}
                      centers={isUKMember ? getUKCenters(centers) : getEuropeanCenters(centers)}
                      value={values.primaryCenterId}
                      fieldName="primaryCenterId"
                      onChange={(event) => {
                        const center = centers.find((center) => center.id.toString() === event.target.value)
                        if (center) {
                          setFieldValue("primaryCenterId", event.target.value)
                          // On stocke dans Formik les "hasVIP" & "hasParking" & "hasAddressField" du centre pour que Yup s'en serve pour la validation
                          setFieldValue("centerHasVIP", center.hasVIP)
                          setFieldValue("centerHasParking", center.hasParking)
                          setFieldValue("centerHasAddressField", center.hasAddressField)
                          setPrimaryCenter(center)
                        }
                      }}
                    />

                    <GenderField setFieldValue={setFieldValue} selectedGender={values.gender} isUKMember={isUKMember} />

                    {getMemberFormFields(!!isCreate, isUKMember, isPartialMember)}

                    {primaryCenter?.hasAddressField && (
                      <div>
                        <InputField
                          fieldData={{
                            formKey: "addressStreet",
                            label: "generic.forms.fields.street",
                            required: false,
                          }}
                        />
                        <InputField
                          fieldData={{
                            formKey: "addressCity",
                            label: "generic.forms.fields.city",
                            required: false,
                          }}
                        />
                      </div>
                    )}
                    {primaryCenter?.hasParking && (
                      <InputField
                        fieldData={{
                          formKey: "licencePlate",
                          label: "generic.forms.fields.licencePlate",
                          required: false,
                        }}
                      />
                    )}
                  </div>
                </Card>
              </Container>
              {primaryCenter && primaryCenter.hasVIP && !isUKMember && (
                <WorkNearMallSection primaryCenter={primaryCenter} isVIP={values.isVIP} setFieldValue={setFieldValue} />
              )}
              {!isUKMember && !isPartialMember && <AgreementSection setFieldValue={setFieldValue} values={values} />}
              {isPartialMember && <AgreementSectionPartialMember setFieldValue={setFieldValue} values={values} />}
              <ConfirmationSection isUKMember={isUKMember} />
              <Container>
                <Card>
                  <div ref={containerMembersActionTabs} className="flex justify-center mb-5">
                    <Tabs
                      selectedTab={tab}
                      setSelectedTab={setTab}
                      tabs={membersActionTabs}
                      className="h-16 w-full sm:w-2/3 flex flex-row"
                      isUKMember={isUKMember}
                      isPartialMember={isPartialMember}
                    />
                  </div>
                  {getTabView(tab, checkinsUses, transactions, member)}
                </Card>
              </Container>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default MembersForm
