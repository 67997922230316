import React from "react"
import "./ObjectiveCharts.css"
import { Loader } from "../../Loader"
import { hasNumber } from "../../../utils/Functions"

type PropsType = {
  title: string
  subtitle?: string
  membersDone?: string
  objective?: string
  objectivePercent?: string
  objective50Percent?: string
  objectiveTotalNumbers?: string
  valueObjectivePercent?: number
  loading: boolean
}

const ObjectiveCharts: React.FC<PropsType> = (props: PropsType) => {
  const {
    title,
    subtitle,
    membersDone,
    objective,
    objectivePercent,
    objective50Percent,
    objectiveTotalNumbers,
    valueObjectivePercent = 0,
    loading,
  } = props
  const percent = `${objectivePercent ? objectivePercent : 0}`

  return (
    <div className="bg-white flex flex-col justify-center p-5">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="text-4xl">{title}</div>
          {hasNumber(subtitle) && <div>{subtitle}</div>}
          <div>{membersDone}</div>
          {hasNumber(objectiveTotalNumbers) && (
            <div>
              <div className="text-2xl mt-4">{objective}</div>
              <div className="flex flex-row w-full items-center">
                <div className="text-sm w-full text-right">{objectiveTotalNumbers}</div>
              </div>
              <div className="relative h-6 mb-2">
                <div
                  className={`h-full w-full border-1 border-grayBlue-800 bg-gray-100 items-center flex  ${
                    valueObjectivePercent < 1 ? "rounded-r-lg" : "rounded-lg"
                  } absolute`}
                />
                <div className="h-full w-full absolute">
                  <div className="h-full w-full relative items-center flex">
                    <div
                      className={`${
                        valueObjectivePercent < 50
                          ? "bg-red-400"
                          : valueObjectivePercent < 90
                          ? "bg-yellow-500"
                          : "bg-green-500"
                      } rounded-lg h-full absolute`}
                      style={{ width: `${valueObjectivePercent > 100 ? 100 : valueObjectivePercent}%` }}
                    />
                    <div className="ml-2 absolute text-sm">{percent}</div>
                  </div>
                </div>
                <div className="h-full w-full items-center flex absolute">
                  <div className="w-1 h-8 rounded-sm bg-gray-900 bg-opacity-50" style={{ marginLeft: "49%" }} />
                </div>
              </div>
              <div className="text-sm">{objective50Percent}</div>
            </div>
          )}
          {!hasNumber(objectiveTotalNumbers) && <div>{percent}</div>}
        </div>
      )}
    </div>
  )
}

export default ObjectiveCharts
