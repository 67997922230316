import AgePieChart from "../../components/dashboard/pieCharts/AgePieChart"
import React, { useEffect, useState } from "react"
import MembersCreationPieChart from "../../components/dashboard/pieCharts/MembersCreationPieChart"
import CustomersLoyaltyPieChart from "../../components/dashboard/pieCharts/CustomersLoyaltyPieChart"
import MembersBarChart from "../../components/dashboard/barCharts/MembersBarChart"
import CheckInsBarChart from "../../components/dashboard/barCharts/CheckInsBarChart"
import ObjectiveLineChart from "../../components/dashboard/lineCharts/ObjectiveLineChart"
import EmailValidityLineChart from "../../components/dashboard/lineCharts/EmailValidityLineChart"
import { useTranslation } from "react-i18next"
import Highcharts from "highcharts"
import CustomerStockDirective from "../../components/dashboard/doubleCharts/CustomersStockDirective"
import { IconType } from "./DashboardConstant"
import EmailValidityPercent from "../../components/dashboard/percentCharts/EmailValidityPercent"
import MoonCharts from "../../components/dashboard/objectiveCharts/MoonCharts"
import { dashboardMultipleFilters, getChartOptions, getDashboardFilters, MultipleFilterType } from "./ChartsUtils"
import HeaderDateFilter from "./HeaderDateFilter"
import Icon from "../../components/dashboard/Icon"
import { execute } from "../../api/api"
import { useStore } from "effector-react"
import OptinChartDirective from "../../components/dashboard/pieCharts/OptinPieChart"
import { Color } from "../../utils/Constants"
import { TFunction } from "i18next"
import { GenderStatsType } from "./DashboardTypes"
import BreadcrumbCountries from "../../components/dashboard/BreadcrumbCountries"
import { useParams } from "react-router-dom"
import Select from "../../components/Select"
import {
  addGender,
  FilterStore,
  removeGender,
  resetFiltersStore,
  setAllDate,
  setIsPro,
  setIsVip,
  setMemberType,
} from "../../store/FilterStore"
import MultipleSelect from "../../components/MultipleSelect"
import { useFetchAllCenters } from "../../utils/hooks/centers/useFetchAllCenters"
import LoyaltyMemberButton from "../../components/dashboard/LoyaltyMemberButton"

export const getIconsFilters = (
  filters: FilterStore,
  resetFilter: () => void,
  hasCardIcons: boolean,
  genderStats?: GenderStatsType,
): IconType[] => {
  // If the total is 0 (meaning there are no records for the selected filters), the percentages should be 0 (to avoid the division by 0).
  const maleStats = !(genderStats?.total && genderStats.M) ? 0 : Math.floor((100 * genderStats.M) / genderStats.total)
  const femaleStats = !(genderStats?.total && genderStats.F) ? 0 : Math.floor((100 * genderStats.F) / genderStats.total)
  const undefinedStats = !(genderStats?.total && genderStats.U) ? 0 : 100 - maleStats - femaleStats

  const icons = [
    {
      label: "icon-male",
      title: "dashboard.filters.icons.male",
      action: () => (!filters.genderFilter.includes("M") ? addGender("M") : removeGender("M")),
      value: maleStats,
      isClicked: !filters.genderFilter.includes("M"),
    },
    {
      label: "icon-female",
      title: "dashboard.filters.icons.female",
      action: () => (!filters.genderFilter.includes("F") ? addGender("F") : removeGender("F")),
      value: femaleStats,
      isClicked: !filters.genderFilter.includes("F"),
    },
    {
      label: "icon-undefined",
      title: "dashboard.filters.icons.undefined",
      action: () => (!filters.genderFilter.includes("U") ? addGender("U") : removeGender("U")),
      value: undefinedStats,
      isClicked: !filters.genderFilter.includes("U"),
    },
    {
      label: "icon-card-pro",
      title: "dashboard.filters.icons.pro",
      action: () => {
        setIsPro(!filters.isPro)
        filters.isVip ? setIsVip(!filters.isVip) : setIsVip(filters.isVip)
      },
      isClicked: filters.isPro,
      // À n'afficher que sur le Dashboard
      isCardIcon: true,
    },
    {
      label: "icon-card-classic",
      title: "dashboard.filters.icons.classic",
      action: () => {
        setIsVip(!filters.isVip)
        filters.isPro ? setIsPro(!filters.isPro) : setIsPro(filters.isPro)
      },
      isClicked: filters.isVip,
      isCardIcon: true,
    },
    {
      label: "icon-loading",
      title: "dashboard.filters.icons.reset",
      action: resetFilter,
      isClicked: true,
    },
  ]

  return hasCardIcons ? icons : icons.filter((icon) => !icon.isCardIcon)
}

export const resetFilter = (): void => {
  resetFiltersStore()
}

type FiltersProps = {
  multipleFilter: MultipleFilterType
  t: TFunction
  storeFilters: FilterStore
}

export const Filter: React.FC<FiltersProps> = (props: FiltersProps) => {
  const { multipleFilter, t, storeFilters } = props
  const [currentValue, setCurrentValue] = useState<string>("")
  const [multiCurrentValue, setMultiCurrentValue] = useState<string[]>([])

  const transformValuesFilters = (oldValues: any) => {
    return oldValues.map((element: any) => {
      return element.key
    })
  }

  if (multipleFilter.key === "partner") {
    if (storeFilters.membersFilter.length !== 1 || !storeFilters.membersFilter.includes("Partner")) {
      if (currentValue) {
        setCurrentValue("")
      }
      return <></>
    }
  }

  return (
    <div className="lg:w-1/4 m-2 flex flex-col justify-center">
      <p className="text-xs text-left pl-2 pb-1 cursor-default">{t(multipleFilter.placeholder || "")}</p>
      {multipleFilter.isMultipleSelect ? (
        <MultipleSelect
          options={multipleFilter.values}
          placeholder={multipleFilter.defaultValue ? t(multipleFilter.defaultValue) : ""}
          setFieldValue={(event, newValues: any) => {
            setMultiCurrentValue(newValues)
            const transformValue = transformValuesFilters(newValues)
            multipleFilter.action(transformValue)
          }}
          values={multiCurrentValue}
          keyRef="key"
          labelRef="label"
          field={multipleFilter.value?.toString() || ""}
          className="bg-white border-2"
          large
        />
      ) : (
        <Select
          value={currentValue}
          onChange={(event) => {
            setCurrentValue(event.target.value)
            multipleFilter.action(event.target.value)
          }}
          values={multipleFilter.values}
          large
          defaultValue={t(multipleFilter?.defaultValue || "")}
          defaultValueDisabled
        />
      )}
    </div>
  )
}

const Dashboard: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { countryCode, centerId } = useParams<{ countryCode: string; centerId: string }>()
  const requestsId = centerId || countryCode || "all"
  const { allActiveCentersByCountry, allActiveCenters } = useFetchAllCenters()

  const [genderStats, setGenderStats] = useState<GenderStatsType>()
  const filtersStore = useStore(FilterStore)

  const [isCenterVip, setIsCenterVip] = useState(false)
  const iconFilters = getIconsFilters(filtersStore, () => resetFilter(), true, genderStats)

  // Fetching gender data
  useEffect(() => {
    execute<GenderStatsType>(
      `dashboard/${requestsId}/widget/gender`,
      "GET",
      {},
      {},
      { filters: getDashboardFilters(filtersStore) },
    ).then((res) => setGenderStats(res))
  }, [filtersStore, requestsId])

  useEffect(() => {
    Highcharts.setOptions(getChartOptions(t))
  }, [i18n.language, t])

  useEffect(() => {
    const dateFilter = filtersStore.date
    resetFiltersStore()
    setAllDate(dateFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const center = allActiveCenters.find((center) => center.id.toString() === requestsId)

    setIsCenterVip(center?.hasVIP || false)
  }, [requestsId, allActiveCenters])

  return (
    <div className="bg-blueGray-50">
      <div className={`w-full h-2 ${Color.DASHBOARD}`} />
      <BreadcrumbCountries centerId={centerId} countries={allActiveCentersByCountry} countryCode={countryCode} />
      <div className="flex flex-col justify-center bg-white py-2 mx-8 rounded-sm">
        <div className="flex flex-col lg:flex-row justify-center">
          <HeaderDateFilter />
          <div className="flex flex-row text-xl">
            {iconFilters.map((icon: IconType, index) => {
              return (
                <Icon
                  key={index}
                  label={icon.label}
                  title={t(icon.title)}
                  onClick={icon.action}
                  value={icon.value}
                  isClicked={icon.isClicked}
                />
              )
            })}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-center bg-white px-12">
          {dashboardMultipleFilters.map((element) => (
            <Filter multipleFilter={element} t={t} key={`dashboard_${element.key}`} storeFilters={filtersStore} />
          ))}
        </div>
      </div>
      <div className="w-screen h-2" />
      <div className="flex flex-col flex-grow-1">
        <div className="h-16 w-full flex flex-row">
          <div className="w-full">
            <div className="p-4 px-8 flex flex-col justify-around overflow-auto">
              <div
                className={
                  filtersStore.memberTypeFilter === "Newsletter"
                    ? "bg-blueGray-100 bg-opacity-50 text-blueGray-700 rounded"
                    : "bg-blueGray-700 text-white rounded"
                }
              >
                <LoyaltyMemberButton
                  buttonName={t("dashboard.table.loyaltyMembers")}
                  onClick={() => {
                    setMemberType("Loyalty")
                  }}
                />
              </div>
            </div>
          </div>
          <div className="h-16 w-full flex flex-row">
            <div className="w-full">
              <div className="p-4 px-8 flex flex-col justify-around overflow-auto">
                <div
                  className={
                    filtersStore.memberTypeFilter === "Loyalty"
                      ? "bg-blueGray-100 bg-opacity-50 text-blueGray-700 rounded"
                      : "bg-blueGray-700 text-white rounded"
                  }
                >
                  <LoyaltyMemberButton
                    buttonName={t("dashboard.table.newsletterSubscribers")}
                    onClick={() => {
                      setMemberType("Newsletter")
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 xl:grid lg:grid-cols-3 gap-8 p-8" key="highchartGraph">
        {filtersStore.memberTypeFilter === "Loyalty" && <MoonCharts id={requestsId} />}
        <CustomerStockDirective id={requestsId} className="lg:col-span-2 row-span-2" />
        {!filtersStore.partnersFilter && <MembersCreationPieChart id={requestsId} key="MembersCreationPieChart" />}
        {filtersStore.memberTypeFilter === "Loyalty" && <ObjectiveLineChart id={requestsId} />}
        <EmailValidityPercent id={requestsId} />
        <EmailValidityLineChart id={requestsId} />
        {filtersStore.memberTypeFilter === "Loyalty" && <CustomersLoyaltyPieChart id={requestsId} />}
        <OptinChartDirective id={requestsId} />
        {filtersStore.memberTypeFilter === "Loyalty" && <AgePieChart id={requestsId} />}
        {requestsId !== "all" && requestsId !== countryCode && isCenterVip && (
          <>
            {filtersStore.memberTypeFilter === "Loyalty" && <MembersBarChart id={requestsId} />}
            {filtersStore.memberTypeFilter === "Loyalty" && <CheckInsBarChart id={requestsId} />}
          </>
        )}
      </div>
    </div>
  )
}

export default Dashboard
