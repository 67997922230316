import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Color } from "../utils/Constants"
import execute from "../api/api"
import { CheckingAppLinks } from "../components/checkingapp/CheckingAppTypes"
import { useTitle } from "../utils/hooks/useTitle"

const CheckingApp: FC = () => {
  const { t } = useTranslation()
  const [checkingAppLinks, setCheckingAppLinks] = useState<CheckingAppLinks>()
  const classes = `md:w-1/3 m-4 mr-6 p-8 text-center text-white text-2xl shadow-md ${Color.CIA} rounded-lg`
  const apps = [
    {
      label: "checkingApp.android",
      icon: "icon-android",
      link: checkingAppLinks?.android,
    },
    {
      label: "checkingApp.ios",
      icon: "icon-apple",
      link: checkingAppLinks?.ios,
    },
  ]

  // Dynamically update browser tab title
  useTitle(`${t("menu.app")} | ${t("applicationName")}`)

  useEffect(() => {
    execute<CheckingAppLinks>("cia/links", "GET").then((resCheckingAppLinks) =>
      setCheckingAppLinks(resCheckingAppLinks),
    )
  }, [])

  return (
    <>
      <div className={`w-screen h-2 ${Color.CIA}`} />
      <div className="p-8 flex flex-col md:flex-row justify-center">
        {apps.map((app, index) => (
          <a key={index} className={classes} href={app.link}>
            <span>
              <i className={`${app.icon} mr-2`} />
              {t(app.label)}
            </span>
          </a>
        ))}
      </div>
      <div className="w-4/5 m-auto bg-white h-text rounded text-justify p-10">
        <p>{t("checkingApp.instructionsText")}</p>
      </div>
    </>
  )
}

export default CheckingApp
