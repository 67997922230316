import React from "react"
import { useTranslation } from "react-i18next"

type FormInputLayoutProps = {
  label?: string
  required?: boolean
  meta?: any
  large?: boolean
  inline?: boolean
  children: React.ReactNode
}

const FormInputLayout: React.FC<FormInputLayoutProps> = ({
  label,
  required,
  meta,
  large,
  inline,
  children,
}: FormInputLayoutProps) => {
  const { t } = useTranslation()
  return (
    <div className={`${inline ? "inline-block w-max" : "mb-6"}`}>
      <div className={`w-full ${large ? "md:w-full" : !inline && "md:w-1/2"} items-center flex flex-col m-auto`}>
        <div className={`w-full`}>
          {label && (
            <label
              className={`block font-bold text-left mb-1 md:mb-0 md:pr-4 ${
                meta && meta.touched && meta.error ? "text-red-500" : "text-gray-500"
              }`}
            >
              {t(label)}
              <span className={`pl-2 whitespace-no-wrap ${required ? "" : "hidden md:invisible"}`}>*</span>
            </label>
          )}
        </div>

        <div className={`w-full flex flex-row justify-center md:justify-start`}>{children}</div>
        {meta && meta.touched && meta.error && <div className="text-red-500 text-left w-full">{t(meta.error)}</div>}
      </div>
    </div>
  )
}

export default FormInputLayout
