import React from "react"
import Container from "../general/Container"
import Card from "../members/Card"
import { FastField, Field, FieldArray, Form, Formik } from "formik"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import FormInputLayout from "../FormInputLayout"
import { ChangelogType, ChangelogTypeWS } from "./ChangelogTypes"
import MultipleSelect from "../MultipleSelect"
import { UserRole } from "../../utils/Roles"
import FormActionButtons from "../general/FormActionButtons"
import { changeLogFormFields, changelogValidation } from "./ChangelogFormConsts"
import { Color } from "../../utils/Constants"
import { SetFieldValueType } from "../../utils/Types"
import InputField from "../general/InputField"
import { useTitle } from "../../utils/hooks/useTitle"

const ValuesArrayFields = ({ values }: { values: string[] }) => {
  const { t } = useTranslation()
  return (
    <FieldArray name="value">
      {({ remove, push }) => (
        <div className="mt-6">
          {values.map((val, index) => {
            return (
              <div key={index}>
                <FormInputLayout
                  label={`${t("changelog.forms.fields.changes", {
                    value: index + 1,
                  })}`}
                >
                  <div className="w-11/12">
                    <FastField
                      type="text"
                      name={`value.${index}`}
                      className="appearance-none border-2 rounded w-full py-3 px-4 leading-tight focus:outline-none focus-border-blueGray-800"
                    />
                  </div>
                  <div className="w-1/12">
                    {values.length > 1 && (
                      <i
                        className="icon-delete text-red-500 text-xl cursor-pointer ml-4 mt-1 text-left"
                        onClick={() => remove(index)}
                      />
                    )}
                  </div>
                </FormInputLayout>
              </div>
            )
          })}
          <FormInputLayout label={""}>
            <button type="button" className="bg-blueGray-500 text-white py-2 px-4 rounded" onClick={() => push("")}>
              {t("changelog.buttons.addValue")}
            </button>
          </FormInputLayout>
        </div>
      )}
    </FieldArray>
  )
}

const RoleCountrySelector = ({ roles, setFieldValue }: { roles: UserRole[]; setFieldValue: SetFieldValueType }) => {
  return (
    <>
      <Field name="roles">
        {({ field, meta }: any) => {
          return (
            <FormInputLayout label="changelog.forms.fields.role">
              <MultipleSelect
                options={roles}
                keyRef="name"
                labelRef="label"
                values={field.value}
                field="roles"
                setFieldValue={setFieldValue}
                meta={meta}
                large
              />
            </FormInputLayout>
          )
        }}
      </Field>
    </>
  )
}

interface ChangelogFormProps {
  header: string
  values: ChangelogType
  roles: UserRole[]
  onSubmit: (formData: ChangelogTypeWS) => void
}

const ChangelogForm: React.FC<ChangelogFormProps> = ({ header, values, roles, onSubmit }: ChangelogFormProps) => {
  const history = useHistory()
  const { t } = useTranslation()

  // Dynamically update browser tab title
  useTitle(`${t("menu.changelog")} | ${t("applicationName")}`)

  return (
    <>
      <div className={`w-full h-2 ${Color.CHANGELOG}`} />
      <div className="pt-6">
        <Container>
          <Card>
            <h3 className="pb-8">{header}</h3>
            <Formik
              initialValues={values}
              enableReinitialize
              validationSchema={changelogValidation}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  roles: values.roles.map((role: UserRole) => role.name),
                }
                onSubmit(formData)
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <Form>
                    <div>
                      {changeLogFormFields.map((fieldData) => (
                        <InputField key={fieldData.formKey} fieldData={fieldData} />
                      ))}
                      <RoleCountrySelector roles={roles} setFieldValue={setFieldValue} />
                      <ValuesArrayFields values={values.value} />
                    </div>

                    <div className="pt-6">
                      <FormActionButtons cancelOnClick={() => history.push("/changelog")} />
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default ChangelogForm
