import React, { useState } from "react"
import { MemberObject } from "../../../api/MemberObject"
import { IconModalValidation } from "../../modals/IconModalValidation"
import { useStore } from "effector-react"
import { ContributorStore } from "../../../store/ContributorStore"
import execute from "../../../api/api"
import { useTranslation } from "react-i18next"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import Button, { ButtonType } from "../../general/Button"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"

type MemberResendEmailModalProps = {
  member: MemberObject
}

export const MemberResendEmailModal: React.FC<MemberResendEmailModalProps> = (props: MemberResendEmailModalProps) => {
  const { member } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const { id, email, assignedCenters } = useStore(ContributorStore)

  const resendWelcomeEmail = (member: MemberObject) => {
    const values = {
      emailType: "hostess",
      ids: [member.id],
    }

    addActiveCalls()
    execute("customers/resendEmail", "POST", {}, values)
      .then(() => {
        execute<void>(
          "fid",
          "POST",
          {},
          {
            centerId: assignedCenters[0]?.id,
            code: "DB-WelcomeEmail",
            content: "Resending welcome email to customer from dashboard",
            application: "dashboard",
            email: email,
            customerId: id,
          },
          {},
          "logger",
        )
        openSuccessSnackbar(`${t("modal.email.validate")} ${t("generic.snackbar.success.send")}`, snackbarDuration)
        setOpen(false)
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
  }

  return (
    <IconModalValidation
      open={open}
      setOpen={setOpen}
      icon="icon-mail"
      iconStyle="text-menu-blue text-3xl"
      iconTitle={t("members.actions.send-mail")}
      title={t("modal.email.title")}
      buttons={
        <Button
          classNames="mt-2 ml-2"
          label={t("modal.email.validate")}
          type={ButtonType.INFO}
          onClick={() => resendWelcomeEmail(member)}
        />
      }
    >
      <p className="mt-2 mb-8">{t("modal.email.description", { email: member.email })}</p>
    </IconModalValidation>
  )
}
