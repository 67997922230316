import * as React from "react"
import MembersForm from "../../components/members/form/MembersForm"
import { membersInitialValues } from "../../components/members/form/MembersFormConsts"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import execute from "../../api/api"
import { format } from "date-fns"
import { MemberObject } from "../../api/MemberObject"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useFetchCountries } from "../../utils/hooks/useFetchCountries"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"
import { useFetchCentersInUserScope } from "../../utils/hooks/centers/useFetchCentersInUserScope"

const MemberCreate: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const member = {
    ...membersInitialValues,
    birthDate: membersInitialValues.birthDate ? format(new Date(membersInitialValues.birthDate), "yyyy-mm-dd") : "",
  }
  const { activeCentersInUserScope } = useFetchCentersInUserScope()
  const countries = useFetchCountries()
  const { id, email, assignedCenters } = useStore(ContributorStore)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  // Dynamically update browser tab title
  useTitle(`${t("menu.newCustomer")} | ${t("applicationName")}`)

  return (
    <MembersForm
      isCreate
      member={member}
      centers={activeCentersInUserScope}
      countries={countries}
      onSubmit={(values) => {
        addActiveCalls()
        execute<MemberObject>(`customers`, "POST", {}, values)
          .then((res) => {
            execute<void>(
              "fid",
              "POST",
              {},
              {
                centerId: assignedCenters[0]?.id,
                code: "DB-CreationCustomer",
                content: "Creating customer from dashboard",
                application: "dashboard",
                email: email,
                customerId: id,
              },
              {},
              "logger",
            )
            openSuccessSnackbar(
              `${t("members.snackbar.title")} ${t("generic.snackbar.success.create")}`,
              snackbarDuration,
            )
            history.push(`/members/${res.id}/edit`)
          })
          .catch(() => {
            openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
          })
          .finally(subtractActiveCalls)
      }}
    />
  )
}

export default MemberCreate
