import { Redirect, Route, Switch } from "react-router"
import Login from "../views/login/Login"
import ProtectedRoute from "./ProtectedRoute"
import Members from "../views/members/Members"
import Contributors from "../views/contributors/Contributors"
import CheckInUse from "../views/checkins/CheckInUse"
import CheckInEdit from "../views/checkins/CheckInEdit"
import CheckInCreate from "../views/checkins/CheckInCreate"
import CheckIns from "../views/checkins/CheckIns"
import ApiGateway from "../views/apigateway/ApiGateway"
import Companies from "../views/Companies"
import Centers from "../views/centers/Centers"
import CenterConfiguration from "./centers/CenterConfiguration"
import RoleView from "../views/roles/RoleView"
import CheckingApp from "../views/CheckingApp"
import ChangelogCreate from "./changelog/ChangelogCreate"
import ChangelogEdit from "./changelog/ChangelogEdit"
import Changelog from "../views/changelog/Changelog"
import Profile from "../views/profile/Profile"
import Dashboard from "../views/dashboard/Dashboard"
import PremiumDashboard from "../views/dashboard/premium/PremiumDashboard"
import Cockpit from "../views/dashboard/Cockpit"
import Home from "../views/home/Home"
import NotFound from "../views/NotFound"
import React, { FC } from "react"
import ContributorsCreate from "../views/contributors/ContributorsCreate"
import ContributorsEdit from "../views/contributors/ContributorsEdit"
import RoleCreate from "../views/roles/RoleCreate"
import RoleEdit from "../views/roles/RoleEdit"
import MemberEdit from "../views/members/MemberEdit"
import MemberCreate from "../views/members/MemberCreate"
import Exports from "../views/exports/Exports"
import PremiumCockpit from "../views/dashboard/premium/PremiumCockpit"
import ResetPassword from "../views/login/ResetPassword"
import TlpOffers from "../views/tlpOffers/TlpOffers"
import TlpOffersCreate from "../views/tlpOffers/TlpOffersCreate"
import TlpOffersEdit from "../views/tlpOffers/TlpOffersEdit"

type Props = {
  isLoggedIn: boolean
}

const CustomSwitch: FC<Props> = ({ isLoggedIn }: Props) =>
  !isLoggedIn ? (
    <Switch>
      <Route path="/resetPassword">
        <ResetPassword />
      </Route>
      <Route>
        <Login />
      </Route>
    </Switch>
  ) : (
    <Switch>
      <Route path="/login">
        <Redirect to="/home" />
      </Route>
      <Route path="/resetPassword">
        <Redirect to="/home" />
      </Route>
      <Route path="/home">
        <Home />
      </Route>
      <ProtectedRoute inLoyaltyApp right="customers.create" path="/members/create">
        <MemberCreate />
      </ProtectedRoute>
      <ProtectedRoute inLoyaltyApp right="customers.update" path="/members/:customerId/edit">
        <MemberEdit />
      </ProtectedRoute>
      <ProtectedRoute inLoyaltyApp right="customers.read" path="/members">
        <Members />
      </ProtectedRoute>
      <ProtectedRoute right="users.create" path="/contributors/create">
        <ContributorsCreate />
      </ProtectedRoute>
      <ProtectedRoute right="users.update" path="/contributors/:contributorId/edit">
        <ContributorsEdit />
      </ProtectedRoute>
      <ProtectedRoute right="users.read" path="/contributors">
        <Contributors />
      </ProtectedRoute>
      <ProtectedRoute right="cockpit.read" path="/dashboard/cockpit">
        <Cockpit />
      </ProtectedRoute>
      <ProtectedRoute right="cockpit.read" path="/dashboard/:countryCode/:centerId">
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute right="cockpit.read" path="/dashboard/:countryCode">
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute right="cockpit.read" path="/dashboard">
        <Dashboard />
      </ProtectedRoute>
      <ProtectedRoute right="cockpit.read" path="/premiumDashboard/cockpit">
        <PremiumCockpit />
      </ProtectedRoute>
      <ProtectedRoute right="cockpit.read" path="/premiumDashboard/:countryCode/:centerId">
        <PremiumDashboard />
      </ProtectedRoute>
      <ProtectedRoute right="cockpit.read" path="/premiumDashboard/:countryCode">
        <PremiumDashboard />
      </ProtectedRoute>
      <ProtectedRoute right="cockpit.read" path="/premiumDashboard">
        <PremiumDashboard />
      </ProtectedRoute>
      <ProtectedRoute inLoyaltyApp right="checkins.create" path="/checkins/center/:centerId/create">
        <CheckInCreate />
      </ProtectedRoute>
      <ProtectedRoute inLoyaltyApp right="checkins.update" path="/checkins/:checkinId/edit">
        <CheckInEdit />
      </ProtectedRoute>
      <ProtectedRoute inLoyaltyApp right="checkins.read" path="/checkins/:checkinId/use">
        <CheckInUse />
      </ProtectedRoute>
      <ProtectedRoute inLoyaltyApp right="checkins.read" path="/checkins">
        <CheckIns />
      </ProtectedRoute>
      <ProtectedRoute right="tlpoffers.create" path="/tlpOffers/create">
        <TlpOffersCreate />
      </ProtectedRoute>
      <ProtectedRoute right="tlpoffers.update" path="/tlpOffers/:tlpOfferId/edit">
        <TlpOffersEdit />
      </ProtectedRoute>
      <ProtectedRoute right="tlpoffers.read" path="/tlpOffers">
        <TlpOffers />
      </ProtectedRoute>
      <ProtectedRoute right="apigateway.read" path="/apiGateway/:tab">
        <ApiGateway />
      </ProtectedRoute>
      <ProtectedRoute right="apigateway.read" path="/apiGateway">
        <ApiGateway />
      </ProtectedRoute>
      <ProtectedRoute right="companies.read" path="/companies">
        <Companies />
      </ProtectedRoute>
      <ProtectedRoute right="exports.read" path="/exports">
        <Exports />
      </ProtectedRoute>
      <ProtectedRoute right="centers.create" path="/centers/create">
        <CenterConfiguration />
      </ProtectedRoute>
      <ProtectedRoute right="centers.update" path="/centers/:centerId/edit">
        <CenterConfiguration />
      </ProtectedRoute>
      <ProtectedRoute right="centers.read" path="/centers">
        <Centers />
      </ProtectedRoute>
      <ProtectedRoute right="roles.create" path="/roles/create">
        <RoleCreate />
      </ProtectedRoute>
      <ProtectedRoute right="roles.update" path="/roles/ROLE_SUPER_ADMIN/edit">
        <Redirect to="/roles" />
      </ProtectedRoute>
      <ProtectedRoute right="roles.create" path="/roles/:roleName/edit">
        <RoleEdit />
      </ProtectedRoute>
      <ProtectedRoute right="roles.update" path="/roles">
        <RoleView />
      </ProtectedRoute>
      <Route path="/cia">
        <CheckingApp />
      </Route>
      <ProtectedRoute right="changelogs.create" path="/changelog/create">
        <ChangelogCreate />
      </ProtectedRoute>
      <ProtectedRoute right="changelogs.update" path="/changelog/:changelogId/edit">
        <ChangelogEdit />
      </ProtectedRoute>
      <ProtectedRoute right="changelogs.read" path="/changelog">
        <Changelog />
      </ProtectedRoute>
      <Route path="/profile">
        <Profile />
      </Route>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )

export default CustomSwitch
