import React, { useState, useEffect } from "react"
import Search from "../../general/Search"
import TableComponent, { ColumnType, TableCell, TableRow } from "../../TableComponent"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import execute from "../../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import { CognitoUserObject } from "../../../api/CognitoUserObject"
import CognitoUsersActions from "./CognitoUsersActions"
import CognitoUserCreate from "./CognitoUserCreate"
import { format } from "date-fns"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"
import { useFetchCentersInUserScope } from "../../../utils/hooks/centers/useFetchCentersInUserScope"

const CognitoUsersTab: React.FC = () => {
  const { t } = useTranslation()
  const { centersInUserScopeByCountry } = useFetchCentersInUserScope()
  const [cognitoUsers, setCognitoUsers] = useState<CognitoUserObject[]>([])
  const [search, setSearch] = useState<string>("")
  const [refresh, setRefresh] = useState(0)
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const cols: (t: TFunction) => ColumnType[] = (t) => [
    { label: t("apiGateway.table.username"), columnSpecifications: "w-1/4" },
    { label: t("generic.table.email"), columnSpecifications: "w-1/4" },
    {
      label: t("apiGateway.table.status"),
      columnSpecifications: "text-center",
    },
    { label: t("apiGateway.table.updatedAt") },
    {
      label: t("generic.table.actions"),
      columnSpecifications: "text-center",
    },
  ]

  useEffect(() => {
    addActiveCalls()
    execute<CognitoUserObject[]>(`apiGateway/partners`, "GET")
      .then((res) => setCognitoUsers(res))
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  const refreshData = () => setRefresh(refresh + 1)

  return (
    <div className="max-h-full flex flex-col items-center flex-grow-1 overflow-y-auto">
      <div className="p-8 w-full">
        <div className="flex flex-col md:flex-row items-center mb-4 gap-4">
          <CognitoUserCreate refresh={refreshData} />
          <Search
            search={search}
            customStyle="md:w-auto flex-1"
            placeHolder={t("generic.forms.fields.searchPlaceholder")}
            onChangeAction={(event: any) => {
              setSearch(event.target.value.toLowerCase())
            }}
          />
        </div>
        <TableComponent columnNames={cols(t)}>
          {cognitoUsers.map(
            (item, index) =>
              (item.username.toLowerCase().includes(search) || item.email.toLowerCase().includes(search)) && (
                <TableRow key={index}>
                  <TableCell>{item.username}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell centered>{item.status}</TableCell>
                  <TableCell>{format(new Date(item.updatedAt), t("generic.format.dateTime"))}</TableCell>
                  <TableCell centered>
                    <CognitoUsersActions
                      cognitoUser={item}
                      centers={centersInUserScopeByCountry}
                      refresh={refreshData}
                    />
                  </TableCell>
                </TableRow>
              ),
          )}
        </TableComponent>
      </div>
    </div>
  )
}

export default CognitoUsersTab
