import * as React from "react"
import AddButton from "../../general/AddButton"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { useStore } from "effector-react"
import { ContributorStore } from "../../../store/ContributorStore"
import InputField from "../../general/InputField"
import execute from "../../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import CheckboxRow from "../cognitoUsers/CheckboxRow"
import { apiKeysInitialValues, apiKeysValidation } from "./ApiKeysConsts"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import CustomModal from "../../CustomModal"
import FormActionButtons from "../../general/FormActionButtons"
import Header from "../../Header"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"
import { convertToRequestFormat } from "../../../utils/ApiKeys"
import { FC, useState } from "react"

type ApiKeyCreateProps = {
  refresh: () => void
}
const ApiKeyCreate: FC<ApiKeyCreateProps> = ({ refresh }: ApiKeyCreateProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const { id, email, assignedCenters } = useStore(ContributorStore)

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <div>
      <AddButton label={t("modal.apiGateway.apiKey.add")} classNames="w-auto mr-2" onClick={() => setOpen(true)} />
      {open && (
        <CustomModal isOpen={open} onRequestClose={closeModal}>
          <div className="h-full flex flex-col w-full">
            <Header>{t("modal.apiGateway.apiKey.add")}</Header>
            <hr />
            <Formik
              initialValues={apiKeysInitialValues}
              validationSchema={apiKeysValidation}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  apikeyValue: values.apikeyValue ? values.apikeyValue : null,
                  apiAccess: values.apiAccess.reduce(convertToRequestFormat, {}),
                }
                addActiveCalls()
                execute("apiGateway/keys", "POST", {}, formData)
                  .then(() => {
                    execute<void>(
                      "fid",
                      "POST",
                      {},
                      {
                        centerId: assignedCenters[0]?.id,
                        code: "ApiKeyCreation_Dashboard'",
                        content: "Creation of an ApiKey from dashboard",
                        application: "dashboard",
                        email: email,
                        customerId: id,
                      },
                      {},
                      "logger",
                    )
                    openSuccessSnackbar(
                      `${t("apiGateway.titles.apiKey")} ${t("generic.snackbar.success.create")}`,
                      snackbarDuration,
                    )
                    refresh()
                    setOpen(false)
                  })
                  .catch(() => {
                    openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
                  })
                  .finally(subtractActiveCalls)
              }}
            >
              {({ values, setFieldValue }) => (
                <Form className="mt-2 w-full flex flex-col justify-between flex-grow">
                  <div className="w-full">
                    <InputField
                      fieldData={{
                        formKey: "name",
                        label: t("modal.apiGateway.apiKey.name"),
                        required: true,
                      }}
                      large
                    />
                    <InputField
                      fieldData={{
                        formKey: "apikeyValue",
                        label: t("modal.apiGateway.apiKey.apikeyValue"),
                        required: false,
                      }}
                      large
                    />

                    <div>
                      {values.apiAccess.map((api, index) => (
                        <div className="flex flex-row items-center md:px-20" key={api.key}>
                          <CheckboxRow
                            label={api.label}
                            checked={api.checked}
                            onClick={() => setFieldValue(`apiAccess[${index}].checked`, !api.checked)}
                          />
                          {api.key === "partner" && api.checked ? (
                            <label className="ml-10">
                              level
                              <input
                                key="partnersLevel"
                                className="w-12 ml-2 pl-1 border-1 border-solid border-grey-200"
                                type="number"
                                min="1"
                                max="4"
                                defaultValue={1}
                                onChange={(event) =>
                                  setFieldValue(`apiAccess[${index}].value`, parseInt(event.target.value))
                                }
                              />
                            </label>
                          ) : (
                            <br />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <FormActionButtons cancelOnClick={closeModal} />
                </Form>
              )}
            </Formik>
          </div>
        </CustomModal>
      )}
    </div>
  )
}

export default ApiKeyCreate
