import { TFunction } from "i18next"
import { ColumnType } from "../TableComponent"

export enum ApiGatewayTabId {
  COGNITO = "cognito",
  SWAGGER = "swagger",
  API_KEYS = "apikeys",
}
export const apiGatewayTabs = [
  { name: "apiGateway.fields.swagger", id: ApiGatewayTabId.SWAGGER, order: 0 },
  { name: "apiGateway.fields.cognito", id: ApiGatewayTabId.COGNITO, order: 1 },
  { name: "apiGateway.fields.apiKeys", id: ApiGatewayTabId.API_KEYS, order: 2 },
]

// -------------------- Swagger -------------------------

export const swaggerCols: (t: TFunction) => ColumnType[] = (t) => [{ label: t("apiGateway.table.api") }]

export type CredentialsWS = {
  [apiId: string]: {
    username: string
    password: string
  }
}

export type ApiType = {
  id: string
  label: string
  externalUrl?: string
  url?: string
}

export const swaggerRowsCols: ApiType[] = [
  {
    id: "partners",
    label: "Partners",
  },
  {
    id: "loyalty",
    label: "Loyalty",
  },
  {
    id: "pas",
    label: "PAS",
  },
  {
    id: "connect",
    label: "Westfield Connect",
  },
  {
    id: "lpe",
    label: "LPE",
    url: "lpe/swagger/",
  },
  {
    id: "dataproxy",
    label: "Data Proxy",
    url: "dataproxy/swagger/",
  },
  {
    id: "loyaltydata",
    label: "Loyalty-Data",
  },
]

// -------------------- Api Keys ------------------------

export const apisInformation = {
  partner: { label: "partners", color: "bg-tags-digital" },
  exporter: { label: "exporter", color: "bg-tags-full" },
  loyalty: { label: "loyalty", color: "bg-tags-partial" },
  dashboard: { label: "dashboard", color: "bg-tags-physical" },
  lpe: { label: "lpe", color: "bg-tags-email" },
  dataproxy: { label: "data-proxy", color: "bg-tags-origin" },
  pas: { label: "pas", color: "bg-tags-google" },
  tools: { label: "tools", color: "bg-tags-printed" },
  tasks: { label: "tasks", color: "bg-tags-pending" },
  logger: { label: "logger", color: "bg-tags-premium" },
  loyaltydata: { label: "loyaltydata", color: "bg-tags-pro" },
}
