import React from "react"
import NavBarMobile from "./mobile/NavBarMobile"
import NavBarDesktop from "./desktop/NavBarDesktop"

const NavBar: React.FC = () => {
  return (
    <div className="bg-blueGray-700 w-full mx-auto px-4 sm:px-6 lg:px-8 xl:px-2">
      <div className="hidden md:block">
        <NavBarDesktop />
      </div>
      <div className="block md:hidden">
        <NavBarMobile />
      </div>
    </div>
  )
}

export default NavBar
