import React, { useEffect } from "react"
import { Field, useField, useFormikContext } from "formik"
import FormInputLayout from "../FormInputLayout"

type CheckboxInputProps = {
  fieldData: {
    formKey: string
    label?: string
    required: boolean
    defaultValue?: boolean | string
  }
  inline?: boolean
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({ fieldData, inline }: CheckboxInputProps) => {
  const [field] = useField(fieldData.formKey)
  const formikContext = useFormikContext()

  useEffect(() => {
    if (field.value === undefined && fieldData.defaultValue !== null && formikContext.setFieldValue) {
      formikContext.setFieldValue(fieldData.formKey, fieldData.defaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Field type="checkbox" name={fieldData.formKey}>
      {({ field, meta }: any) => {
        return (
          <FormInputLayout label={fieldData.label} meta={meta} required={fieldData.required} inline={inline}>
            <div
              className={`bg-white border-2 rounded border-gray-200 w-6 h-6 flex flex-shrink-0 justify-center items-center ${
                !inline && "mr-2"
              } focus-within:border-blueGray-800`}
            >
              <input type="checkbox" className="opacity-0 absolute cursor-pointer" {...field} />
              <i
                className={`icon-check ${
                  field.value ? "block" : "hidden"
                } text-xl text-green-500 pointer-events-none fill-current`}
              />
            </div>
          </FormInputLayout>
        )
      }}
    </Field>
  )
}

export default CheckboxInput
