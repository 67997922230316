import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import execute from "../../api/api"
import ContributorsForm from "../../components/contributors/ContributorsForm"
import { initValues, mainContributorsFormValidations } from "../../components/contributors/ContributorsFormConsts"

import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { useTranslation } from "react-i18next"
import { ContributorType } from "../../api/ContributorObject"
import { useHistory } from "react-router"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"

const ContributorsEdit: React.FC = () => {
  const { t } = useTranslation()
  const { contributorId } = useParams()
  const history = useHistory()
  const [contributor, setContributor] = useState()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const validationSchema = mainContributorsFormValidations

  // Dynamically update browser tab title
  useTitle(`${t("menu.updateUser")} | ${t("applicationName")}`)

  useEffect(() => {
    addActiveCalls()
    execute<ContributorType>(`users/${contributorId}`, "GET")
      .then((res) => {
        // On défini les valeurs initiales des champs password pour la validation automatique de Formik.
        setContributor({
          ...res,
          password: "",
          password2: "",
        })
      })
      .finally(subtractActiveCalls)
  }, [contributorId])

  return (
    <>
      <ContributorsForm
        initialValues={contributor || initValues}
        validationSchema={validationSchema}
        onSubmit={(values, selectedRole) => {
          addActiveCalls()
          const assignedCenters = !selectedRole
            ? []
            : selectedRole.linkedTo === "center"
            ? [values.assignedCenters[0].id]
            : selectedRole.linkedTo === "centers"
            ? values.assignedCenters.map((center) => center.id)
            : []
          const countries = !selectedRole
            ? []
            : selectedRole.linkedTo === "countries"
            ? values.countries.map((center) => center.code)
            : []

          const formData = {
            role: values.role,
            username: values.username,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            assignedCenters,
            countries,
          }

          execute(`users/${contributorId}`, "PATCH", {}, formData)
            .then(() => {
              openSuccessSnackbar(
                `${t("contributors.snackbar.title")} ${t("generic.snackbar.success.edit")}`,
                snackbarDuration,
              )
              history.push("/contributors")
            })
            .catch(() => {
              openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
            })
            .finally(subtractActiveCalls)
        }}
      />
    </>
  )
}

export default ContributorsEdit
