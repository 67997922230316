import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import execute from "../../api/api"
import { CheckInItem, CheckinUse, CheckinWS } from "./CheckInType"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { MemberCheckinsUses, MemberType, MemberTypeWS } from "../../api/MemberObject"
import InputField from "../general/InputField"
import FormActionButtons from "../general/FormActionButtons"
import { useHistory } from "react-router-dom"
import FormInputLayout from "../FormInputLayout"
import { format } from "date-fns"
import Container from "../general/Container"
import { CheckInHistoryTable } from "../members/form/MembersFormTable"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { FormCancelButton } from "../profile/FormSubmitButton"
import { useLoyaltyApp } from "../../utils/hooks/useLoyaltyApp"
import Button from "../general/Button"
import { SetFieldValueType } from "../../utils/Types"

type CenterSearchProps = {
  checkinId: number
  checkin?: CheckinWS
  type?: "checkins" | "bookingbug"
  checkingBookingBug?: CheckInItem
}

const CheckinsBarcode: React.FC<CenterSearchProps> = ({
  checkinId,
  checkin,
  type,
  checkingBookingBug,
}: CenterSearchProps) => {
  const { t } = useTranslation()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const history = useHistory()
  const [member, setMember] = useState<MemberType>()
  const [checkinsUses, setCheckinsUses] = useState<CheckinUse[]>([])
  const currentDate = new Date()
  const currentDay = currentDate.getDay()
  const { id, email, assignedCenters } = useStore(ContributorStore)
  const isLoyaltyApp = useLoyaltyApp()

  const disabled =
    checkin &&
    (!checkin.daysOfUse?.includes(currentDay) ||
      (checkin.startDate && new Date(checkin.startDate) > currentDate) ||
      (checkin.endDate && new Date(checkin.endDate) < currentDate))

  const getMembersInformations = (customerId: number) => {
    Promise.all([
      execute<MemberTypeWS>(`customers/${customerId}`, "GET"),
      execute<MemberCheckinsUses>(`customers/${customerId}/checkinsUses`, "GET"),
    ]).then(([member, memberCheckinsUses]: [MemberTypeWS, MemberCheckinsUses]) => {
      setMember(member.customer)
      setCheckinsUses(memberCheckinsUses.services)
    })
  }

  const handleQRCode = (setValue: SetFieldValueType) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const openQRCode = window["api"] ? window["api"]["openQRCode"] : null
    openQRCode(
      function (data: any) {
        // mettre le barcode dans le champ correspondant
        setValue("barcode", data.trim())
      },
      function () {
        //console.log("QR Code : Canceled");
      },
      function (error: any) {
        // We warn the user that Camera is disabled
        console.log("QR Code : Error " + error)
      },
    )
  }

  const handleNFCCode = (setValue: SetFieldValueType) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const openNFC = window["api"] ? window["api"]["openNFC"] : null

    openNFC(
      function (data: any) {
        // mettre le barcode dans le champ correspondant
        setValue("barcode", data.trim())
      },
      function () {
        //console.log("QR Code : Canceled");
      },
      function (error: any) {
        // We warn the user that Camera is disabled
        console.log("QR Code : Error " + error)
      },
    )
  }

  return (
    <div>
      {!disabled ? (
        <Formik
          initialValues={{ barcode: "" }}
          validationSchema={Yup.object().shape({
            barcode: Yup.string().nullable().min(1).required("generic.forms.errors.required"),
          })}
          onSubmit={(values, { resetForm }) => {
            const formData =
              type === "checkins"
                ? {
                    cardNumber: values.barcode,
                    originCheckin: "dashboard",
                    checkinId: checkinId,
                  }
                : {
                    cardNumber: values.barcode,
                    category: checkingBookingBug?.category,
                    centerId: checkingBookingBug?.centerId,
                    checkinId: checkingBookingBug?.id,
                  }
            addActiveCalls()
            execute<CheckinUse>(`${type}/uses`, "POST", {}, formData)
              .then(({ customerId }) => {
                execute<void>(
                  "fid",
                  "POST",
                  {},
                  {
                    centerId: assignedCenters[0]?.id,
                    code: "CheckIn_Dashboard",
                    content: checkin?.name,
                    application: "dashboard",
                    email: email,
                    customerId: id,
                  },
                  {},
                  "logger",
                )
                openSuccessSnackbar(
                  `${t("checkins.forms.titles.use")} ${t("generic.snackbar.success.create")}`,
                  snackbarDuration,
                )
                if (type === "checkins") {
                  getMembersInformations(customerId)
                }
              })
              .catch((err) => {
                let errorMessage = snackbarErrorMessage
                if (err?.response?.data?.label) {
                  errorMessage = `checkins.forms.errors.${err.response.data.label}`
                }
                openErrorSnackbar(t(errorMessage), snackbarDuration)
              })
              .finally(subtractActiveCalls)
            resetForm()
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <InputField
                fieldData={{
                  formKey: "barcode",
                  label: "checkins.forms.fields.barcode",
                  required: true,
                }}
              />
              {isLoyaltyApp && (
                <div className="flex space-x-4 justify-center mb-8 -mt-4">
                  <Button
                    label={t("checkins.forms.fields.qrCode")}
                    onClick={() => handleQRCode(setFieldValue)}
                    classNames="w-1/2 justify-center"
                  />
                  <Button
                    label={t("checkins.forms.fields.nfc")}
                    onClick={() => handleNFCCode(setFieldValue)}
                    classNames="w-1/2 justify-center"
                  />
                </div>
              )}
              <FormActionButtons cancelOnClick={() => history.push("/checkins")} />
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <p className="text-red-500">{t("checkins.forms.errors.disabled")}</p>
          <div className="flex justify-center mt-1 md:mt-4">
            <FormCancelButton onClick={() => history.push("/checkins")} customStyle="w-16">
              {t("generic.buttons.cancel")}
            </FormCancelButton>
          </div>
        </>
      )}
      {member && (
        <>
          <hr className="my-8" />
          <div>
            <FormInputLayout label="members.snackbar.title">
              <div className="h-6 text-left">
                <p>{`${member.firstName} ${member.lastName} (${member.email})`}</p>
              </div>
            </FormInputLayout>
            <FormInputLayout label="generic.forms.fields.birthDate">
              <div className="h-6 text-left">
                <p>{format(new Date(member.birthDate!), t("generic.format.date"))}</p>
              </div>
            </FormInputLayout>
            <FormInputLayout label="members.tags.premium.label">
              <div className="h-6 text-left">
                <p>{member.isPremium ? t("generic.forms.fields.yes") : t("generic.forms.fields.no")}</p>
              </div>
            </FormInputLayout>
            {checkinsUses && (
              <Container>
                <div>
                  <h3 className="mb-4 text-center text-gray-500">{t("members.fields.historyCheckIns")}</h3>
                  <CheckInHistoryTable checkinsUses={checkinsUses} />
                </div>
              </Container>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default CheckinsBarcode
