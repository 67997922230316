import React, { FC } from "react"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { useTranslation } from "react-i18next"
import { Company } from "./CompaniesType"
import Restricted from "../Restricted"
import { ActionIcon } from "../ActionIcon"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { IconWithConfirmation } from "../modals/IconConfirmationModal"

type CompaniesActionsProps = {
  centerId: number
  company: Company
  editedCompanyId?: number
  newCompanyName: string
  onEdit: () => void
  onCancel: () => void
  refresh: () => void
}

const CompaniesActions: FC<CompaniesActionsProps> = ({
  centerId,
  company,
  editedCompanyId,
  newCompanyName,
  onEdit,
  onCancel,
  refresh,
}: CompaniesActionsProps) => {
  const { t } = useTranslation()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  return company.id === editedCompanyId ? (
    <>
      {/* Validate */}
      <ActionIcon
        icon="icon-check"
        iconColor="text-green-500"
        onClick={() => {
          if (!newCompanyName || newCompanyName.trim() === "") {
            openErrorSnackbar(t("companies.snackbar.errors.name"), snackbarDuration)
          } else {
            execute(`companies/${centerId}/${editedCompanyId}`, "PATCH", {}, { name: newCompanyName })
              .then(() => {
                openSuccessSnackbar(
                  t("companies.snackbar.title") + t("generic.snackbar.success.edit"),
                  snackbarDuration,
                )
                onCancel()
                refresh()
              })
              .catch((err) => {
                let errorMessage = snackbarErrorMessage
                if (err.response && err.response.status === 409) {
                  errorMessage = "companies.snackbar.errors.duplicate"
                }
                openErrorSnackbar(t(errorMessage), snackbarDuration)
              })
          }
        }}
      />

      {/* Cancel */}
      <ActionIcon icon="icon-cancel" iconColor="text-red-500" onClick={onCancel} />
    </>
  ) : (
    <>
      {/* Edit */}
      <Restricted right="companies.update">
        <ActionIcon icon="icon-edit" onClick={onEdit} />
      </Restricted>

      {/* Delete */}
      <Restricted right="companies.delete">
        <IconWithConfirmation
          customStyle="text-3xl"
          onConfirmationClick={() => {
            execute(`companies/${centerId}/${company.id}`, "DELETE")
              .then(() => {
                openSuccessSnackbar(
                  `${t("companies.snackbar.title")} ${t("generic.snackbar.success.delete")}`,
                  snackbarDuration,
                )
                onCancel()
                refresh()
              })
              .catch(() => {
                openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
              })
          }}
          icon="icon-delete"
          title={t("generic.buttons.delete")}
          description={t("modal.delete.description")}
        />
      </Restricted>
    </>
  )
}

export default CompaniesActions
