import { ActionIcon } from "../../ActionIcon"
import React, { useState, useEffect } from "react"
import { CognitoUserObject } from "../../../api/CognitoUserObject"
import { Field, Form, Formik } from "formik"
import execute from "../../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import { useTranslation } from "react-i18next"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import FormInputLayout from "../../FormInputLayout"
import Select from "../../Select"
import { ApiKeysTypeWS } from "../apiKeys/ApiKeysTypes"
import { ApiKeysStore, setApiKeys } from "../apiKeys/ApiKeysStore"
import { useStore } from "effector-react"
import Axios from "axios"
import FormActionButtons from "../../general/FormActionButtons"
import CustomModal from "../../CustomModal"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"

type EditCognitoUserCentersProps = {
  cognitoUser: CognitoUserObject
}

const SendEmailCognitoUser: React.FC<EditCognitoUserCentersProps> = ({ cognitoUser }: EditCognitoUserCentersProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const apiKeys: ApiKeysTypeWS[] = useStore(ApiKeysStore).apiKeys

  useEffect(() => {
    if (open) {
      addActiveCalls()
      execute<ApiKeysTypeWS[]>("apiGateway/keys", "GET")
        .then((res) => {
          setApiKeys(res)
        })
        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
        .finally(subtractActiveCalls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser, open])

  return (
    <>
      <ActionIcon icon="icon-mail" onClick={() => setOpen(true)} title={t("apiGateway.fields.tooltipsSendEmail")} />
      {open && (
        <CustomModal isOpen={open} onRequestClose={() => setOpen(false)} scrollable={false}>
          <div>
            <Formik
              enableReinitialize
              initialValues={{ selectedApiKey: "", selectedLevel: "" }}
              onSubmit={(values) => {
                const subject = "Welcome to URW Partners API"
                let body = ""

                const apiKey = apiKeys.find((element) => element.id === values.selectedApiKey)
                Axios("../quickStart.txt")
                  .then((template) => {
                    // Replace the template tags with the content
                    body = template.data
                      .replace(/§§AGURL§§/g, process.env.REACT_APP_API_GATEWAY_HOST)
                      .replace(/§§USERNAME§§/g, cognitoUser.username)
                      .replace(/§§APIKEY§§/g, apiKey?.value)
                      .replace(/§§URL§§/g, window.location.origin)
                      .replace(/&/g, "%26")
                      .replace(/\n/g, "%0D%0A")
                    window.open("mailto:" + cognitoUser.email + "?subject=" + subject + "&body=" + body, "_self")
                  })
                  .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
                //setOpen(false)
              }}
            >
              {({ setFieldValue, values }) => {
                return (
                  <Form>
                    <h3 className="mt-2">{t("modal.apiGateway.cognito.center")}</h3>
                    <hr />
                    <div className="my-4 font-semibold mt-6">
                      <Field name={"selectedApiKey"}>
                        {({ meta }: any) => (
                          <FormInputLayout label={"apiGateway.forms.fields.selectApiKeys"} inline required meta={meta}>
                            <Select
                              onChange={(event) => setFieldValue("selectedApiKey", event.target.value)}
                              values={apiKeys}
                              keyRef="id"
                              labelRef="name"
                              defaultValueDisabled
                              value={values.selectedApiKey}
                              isInput
                              translate={false}
                            />
                          </FormInputLayout>
                        )}
                      </Field>
                    </div>
                    <div className="flex flex-row justify-center">
                      <FormActionButtons cancelOnClick={() => setOpen(false)} />
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </CustomModal>
      )}
    </>
  )
}

export default SendEmailCognitoUser
