import React, { useEffect, useState } from "react"
import TableComponent, { TableCell, TableRow } from "../TableComponent"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Search from "../general/Search"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { BookingBug, CheckInItem, CheckinWS } from "./CheckInType"
import AddButton from "../general/AddButton"
import { ActionIcon } from "../ActionIcon"
import Button, { ButtonType } from "../general/Button"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import Restricted from "../Restricted"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import TableComponentMobile from "../TableComponentMobile"
import { CheckinsTabId, tableColumnsCheckins } from "./CheckinsConst"
import { DisabledIcon } from "../DisabledIcon"
import { IconWithConfirmation } from "../../components/modals/IconConfirmationModal"

const isDataRendered = (item: CheckInItem, search?: string) => {
  return !search || item.name.toLowerCase().includes(search.toLowerCase())
}

type CheckInTableProps = {
  activeTab: string
  centerId: number
  refresh: number
  setRefresh: React.Dispatch<React.SetStateAction<number>>
}

const CheckInTable: React.FC<CheckInTableProps> = ({ activeTab, centerId, refresh, setRefresh }: CheckInTableProps) => {
  const { t } = useTranslation()

  const [data, setData] = useState<CheckInItem[]>([])
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const [search, setSearch] = useState<string>("")
  const [showArchive, setShowArchive] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => setSearch(""), [activeTab])

  useEffect(() => {
    if (centerId !== -1) {
      addActiveCalls()
      setData([])
      let promise: Promise<CheckInItem[]>
      if (activeTab === CheckinsTabId.CHECKINS) {
        const path = `checkins?centerId=${centerId}${showArchive ? "&archived=true" : ""}`
        promise = execute<CheckInItem[]>(path, "GET")
      } else if (activeTab === CheckinsTabId.BOOKINGBUG && !showArchive) {
        promise = Promise.all([
          execute<CheckInItem[]>(`bookingbug/services/${centerId}`, "GET"),
          execute<CheckInItem[]>(`bookingbug/events/${centerId}`, "GET"),
        ]).then(([servicesResults, eventResults]: [CheckInItem[], CheckInItem[]]) => {
          return Promise.resolve(servicesResults.concat(eventResults))
        })
      } else {
        /* if (activeTab === 2 && showArchive) */
        promise = execute<CheckInItem[]>(`bookingbug/events/${centerId}?archived=true`, "GET")
      }

      promise
        .then((res: CheckInItem[]) => {
          setData(res)
        })
        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
        .finally(subtractActiveCalls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, centerId, showArchive, refresh])

  return (
    <div className="w-full px-4 max-h-4/5 2xl:max-h-9/12">
      <div className="flex flex-row py-4 px-6 justify-between flex-wrap md:flex-nowrap">
        <div className="flex flex-row w-full items-center">
          {activeTab === CheckinsTabId.CHECKINS && (
            <Restricted right={"checkins.create"}>
              <AddButton onClick={() => history.push(`/checkins/center/${centerId}/create`)} classNames="mr-4" />
            </Restricted>
          )}
          <Search
            search={search}
            customStyle="w-1/3 md:w-1/4"
            placeHolder={t("generic.forms.fields.searchPlaceholder")}
            onChangeAction={(event: any) => {
              setSearch(event.target.value)
            }}
          />
        </div>
        <Button
          icon={showArchive ? "icon-demand" : "icon-directory"}
          label={showArchive ? t("checkins.fields.current") : t("checkins.fields.archives")}
          onClick={() => setShowArchive(!showArchive)}
          type={showArchive ? ButtonType.PRIMARY : ButtonType.NEUTRAL}
          classNames="mt-2 md:mt-0"
        />
      </div>
      {data.length > 0 && (
        <div className="px-6 pb-2 max-h-full">
          <div className="hidden md:block mb-2">
            <TableComponent columnNames={tableColumnsCheckins.filter((col) => !col.tabs || col.tabs === activeTab)}>
              {data
                .sort((prev, next) => prev.name.localeCompare(next.name))
                .map(
                  (item, index) =>
                    isDataRendered(item, search) && (
                      <TableRow
                        key={index}
                        onClick={() =>
                          history.push(`/checkins/${item.id}/use`, {
                            checkinItem: item,
                            type: activeTab,
                          })
                        }
                      >
                        <TableCell>
                          {activeTab === CheckinsTabId.CHECKINS && (
                            <div
                              className="h-6 w-6 mr-3 rounded-full"
                              style={{
                                backgroundColor: (item as CheckinWS).colorCode,
                              }}
                            />
                          )}
                          {item.name}
                        </TableCell>
                        <TableCell centered>{item.category}</TableCell>
                        <TableCell centered>{item.usesTotal}</TableCell>
                        {activeTab === CheckinsTabId.CHECKINS ? (
                          <>
                            <TableCell centered>{(item as CheckinWS).useRate}</TableCell>
                            <TableCell centered>
                              <Restricted right={"checkins.update"}>
                                <ActionIcon
                                  icon="icon-edit"
                                  iconColor="text-black"
                                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                                    event.stopPropagation()
                                    history.push(`/checkins/${item.id}/edit`)
                                  }}
                                />
                              </Restricted>
                              <Restricted right={"checkins.delete"}>
                                {(item as CheckinWS).undeletable ? (
                                  <DisabledIcon
                                    icon="icon-delete"
                                    iconColor="text-gray-500"
                                    title={t("checkins.forms.fields.undeletable")}
                                  />
                                ) : (
                                  <IconWithConfirmation
                                    icon="icon-delete"
                                    iconColor="text-red-500"
                                    title={t("checkins.modal.title")}
                                    description={t("checkins.modal.description")}
                                    onConfirmationClick={() => {
                                      execute(`checkins/${item.id}?centerId=${centerId}`, "DELETE")
                                        .then(() => {
                                          openSuccessSnackbar(
                                            `${t("checkins.snackbar.title")} ${t("generic.snackbar.success.delete")}`,
                                            snackbarDuration,
                                          )
                                          setRefresh((prevState) => prevState + 1)
                                        })
                                        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
                                    }}
                                  />
                                )}
                              </Restricted>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell centered>{(item as BookingBug).datetime || "-"}</TableCell>
                            <TableCell centered>{(item as BookingBug).attendancesTotal}</TableCell>
                          </>
                        )}
                      </TableRow>
                    ),
                )}
            </TableComponent>
          </div>

          <div className="block md:hidden">
            {data
              .sort((prev, next) => prev.name.localeCompare(next.name))
              .map((item, index) => (
                <TableComponentMobile
                  key={index}
                  item={item}
                  centerId={centerId}
                  tabSelected={activeTab}
                  clickCell={() =>
                    history.push(`/checkins/${item.id}/use`, {
                      checkinItem: item,
                      type: activeTab,
                    })
                  }
                />
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CheckInTable
