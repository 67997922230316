import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { membersInitialValues } from "../../components/members/form/MembersFormConsts"
import { format } from "date-fns"
import { useParams } from "react-router-dom"
import execute from "../../api/api"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import MembersForm from "../../components/members/form/MembersForm"
import {
  CashbackWSType,
  EarnWSType,
  MemberCheckinsUses,
  MemberType,
  MemberTypeWS,
  TransactionsType,
} from "../../api/MemberObject"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { fr } from "date-fns/locale"
import { CheckinUse } from "../../components/checkin/CheckInType"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useFetchCountries } from "../../utils/hooks/useFetchCountries"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"
import { useFetchCentersInUserScope } from "../../utils/hooks/centers/useFetchCentersInUserScope"

const MemberEdit: React.FC = () => {
  const { t } = useTranslation()
  const { customerId } = useParams()
  const countries = useFetchCountries()
  const { activeCentersInUserScope } = useFetchCentersInUserScope()
  const [member, setMember] = useState<MemberType>()
  const [memberServices, setMemberServices] = useState<CheckinUse[]>([])
  const [transactions, setTransactions] = useState<TransactionsType[]>()
  const { id, email, assignedCenters } = useStore(ContributorStore)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  useEffect(() => {
    addActiveCalls(2)
    execute<MemberTypeWS>(`customers/${customerId}`, "GET")
      .then((resMember) => {
        // TODO : a retirer plus tard la vérification car certains customers en staging n'ont pas de birthDate et ça
        //  fait planter
        const formatedBirthDate = resMember.customer.birthDate
          ? format(new Date(resMember.customer.birthDate), "yyyy-MM-dd", {
              locale: fr,
            })
          : ""
        setMember({
          ...resMember.customer,
          newBarcode: "",
          birthDate: formatedBirthDate,
          companyName: resMember.customer.companyName || "",
        })
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  useEffect(() => {
    if (member?.isPremium) {
      addActiveCalls()
      Promise.all([
        execute<CashbackWSType>(`cashback/customer/${customerId}`, "GET", {}, {}, {}, "lpe"),
        execute<EarnWSType>(`earn/customer/${customerId}`, "GET", {}, {}, {}, "lpe"),
      ])
        .then(([cashbacksResponse, earnsResponse]: [CashbackWSType, EarnWSType]) => {
          const cashbacks = cashbacksResponse.result.cashbacks.map((cashback) => {
            cashback.date = cashback.dateCreation
            cashback.EarnCashback = "cashback"
            switch (cashback.status) {
              case "created":
                cashback.comment = "Waiting for purchase"
                break
              case "requested":
              case "validated":
                cashback.comment = "In progress"
                break
              case "expired":
                cashback.comment = "The cashback is expired"
                break
              case "deleted":
                cashback.comment = "The customer owning the cashback has been deleted"
                break
              case "received":
                cashback.comment = "The money is on the customer account"
                break
              case "refused":
              case "error":
              case "retryable":
              case "pending":
                cashback.comment = "Error sending cashback"
                break
            }
            return cashback
          })
          const earns = earnsResponse.result.earns.map((earn) => {
            earn.date = earn.dateCreation || earn.datePurchase
            earn.EarnCashback = "earn"
            return earn
          })

          setTransactions(
            cashbacks.concat(earns).sort((a, b) => {
              if (new Date(a.date) > new Date(b.date)) return -1
              else return 1
            }),
          )
        })
        .finally(subtractActiveCalls)
    }

    if (member?.type && member?.type !== "partial") {
      execute<MemberCheckinsUses>(`customers/${customerId}/checkinsUses`, "GET")
        .then((resServicesUses) => {
          if (resServicesUses.services.length > 0) {
            setMemberServices(resServicesUses.services)
          }
        })
        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
        .finally(subtractActiveCalls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, customerId])

  // Dynamically update browser tab title
  useTitle(`${t("menu.customers")} | ${t("applicationName")}`)

  return (
    <>
      {!member && (
        <MembersForm
          centers={activeCentersInUserScope}
          countries={countries}
          member={membersInitialValues}
          checkinsUses={memberServices}
          onSubmit={() => undefined}
        />
      )}
      {member && (
        <MembersForm
          centers={activeCentersInUserScope}
          countries={countries}
          member={member}
          checkinsUses={memberServices}
          transactions={transactions}
          customerId={customerId}
          onSubmit={(values) => {
            addActiveCalls()
            execute(`customers/${customerId}`, "PATCH", {}, values)
              .then(() => {
                execute<void>(
                  "fid",
                  "POST",
                  {},
                  {
                    centerId: assignedCenters[0]?.id,
                    code: "DB-ModifyCustomer",
                    content: "Modifying customer from dashboard",
                    application: "dashboard",
                    email: email,
                    customerId: id,
                  },
                  {},
                  "logger",
                )
                openSuccessSnackbar(
                  `${t("members.snackbar.title")} ${t("generic.snackbar.success.edit")}`,
                  snackbarDuration,
                )
              })
              .catch(() => {
                openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
              })
              .finally(subtractActiveCalls)
          }}
        />
      )}
    </>
  )
}

export default MemberEdit
