import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PieCharts from "./PieCharts"
import { useStore } from "effector-react"
import { execute } from "../../../api/api"
import { dataPieType } from "../../../api/ChartObject"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"

type AgePieChartProps = {
  id: string
}
const AgePieChart: React.FC<AgePieChartProps> = (props: AgePieChartProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [data, setData] = useState<dataPieType[]>([])
  const [loading, setLoading] = useState(false)

  const storeFilters = useStore(FilterStore)

  useEffect(() => {
    setLoading(true)
    execute(`dashboard/${id}/widget/ageRepartition`, "GET", {}, {}, { filters: getDashboardFilters(storeFilters) })
      .then((res: any) => {
        if (res) {
          const pieData: dataPieType[] = []
          res.forEach((element: any) => {
            pieData.push({
              name: t(`dashboard.pie.age.${element.key}`),
              y: element.doc_count,
            })
          })
          setData(pieData.reverse())
        }
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters, id])

  return <PieCharts title={t("dashboard.pie.age.title")} data={data} isSecondaryWidget={true} loading={loading} />
}

export default AgePieChart
