import * as React from "react"
import { ActionIcon } from "../ActionIcon"
import { ConfirmationModal } from "./ConfirmationModal"

type IconModalValidationProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  icon: string
  iconStyle: string
  iconTitle?: string
  title?: string
  children: React.ReactElement
  buttons: React.ReactElement
  onAfterOpen?: () => void
}

export const IconModalValidation: React.FC<IconModalValidationProps> = (props: IconModalValidationProps) => {
  const { open, setOpen, icon, iconStyle, iconTitle, title, children, buttons, onAfterOpen } = props

  return (
    <>
      <ActionIcon
        icon={icon}
        iconColor={iconStyle}
        title={iconTitle}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          setOpen(true)
        }}
      />
      <ConfirmationModal isOpen={open} setIsOpen={setOpen} title={title} buttons={buttons} onAfterOpen={onAfterOpen}>
        {children}
      </ConfirmationModal>
    </>
  )
}
