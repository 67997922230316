import React from "react"
import { useStore } from "effector-react"
import { hasRight } from "../utils/Roles"
import { ContributorStore } from "../store/ContributorStore"

type PropsType = {
  right: string
  children: any
}

const Restricted: React.FC<PropsType> = (props: PropsType) => {
  const { right, children } = props
  const { permissions } = useStore(ContributorStore)

  // Displays only if storedRights not empty
  return permissions.profile && hasRight(permissions, right) ? children : null
}

export default Restricted
