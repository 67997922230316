import React, { useState } from "react"
import { Field } from "formik"
import FormInputLayout from "../FormInputLayout"

type InputFieldProps = {
  fieldData: {
    formKey: string
    label: string
    required: boolean
  }
  large?: boolean
  key?: string
}

const InputFieldPassword: React.FC<InputFieldProps> = ({ fieldData, large = false }: InputFieldProps) => {
  const [show, setShow] = useState(false)
  const togglePasswordVisiblity = () => {
    setShow((prev) => !prev)
  }

  return (
    <Field name={fieldData.formKey}>
      {({ field, meta }: any) => (
        <FormInputLayout label={fieldData.label} required={fieldData.required} meta={meta} large={large}>
          <div className={"appearance-none w-full leading-tight flex flex-row items-center"}>
            <input
              className="appearance-none border-2 rounded w-full py-3 px-4 leading-tight focus:outline-none"
              type={show ? "text" : "password"}
              {...field}
            />
            <i
              className={`${show ? "icon-eye-off" : "icon-eye"} cursor-pointer pr-2`}
              onClick={togglePasswordVisiblity}
            />
          </div>
        </FormInputLayout>
      )}
    </Field>
  )
}

export default InputFieldPassword
