import * as Yup from "yup"
import { TFunction } from "i18next"
import { CheckinWS } from "../../components/checkin/CheckInType"

export const initialValues: CheckinWS = {
  id: -1,
  centerId: -1,
  createdAt: "",
  updatedAt: "",
  category: "service",
  name: "",
  themes: [],
  targets: [],
  useRate: "1/u",
  daysOfUse: [0, 1, 2, 3, 4, 5, 6],
  daysOfUseLabel: [],
  colorCode: "#4c6d92",
  isPremium: false,
  stores: [],
  startDate: "",
  endDate: "",
  undeletable: false,
}

export const checkInFormValidationSchema = Yup.object().shape(
  {
    category: Yup.string().required("generic.forms.errors.required"),
    name: Yup.string().required("generic.forms.errors.required"),
    themes: Yup.array().min(1, "generic.forms.errors.minValue"),
    targets: Yup.array().when("category", {
      is: "service",
      then: Yup.array().min(1, "generic.forms.errors.minValue"),
    }),
    freqQuantity: Yup.number().when("freqPeriod", {
      is: (value: string) => value !== "u",
      then: Yup.number().required("generic.forms.errors.required").positive("checkins.forms.errors.positive"),
      otherwise: Yup.number(),
    }),
    freqPeriod: Yup.string().required("generic.forms.errors.required"),
    daysOfUse: Yup.array().min(1, "generic.forms.errors.minValue"),
    isPremium: Yup.boolean(),
    colorCode: Yup.string().required("generic.forms.errors.required"),
    stores: Yup.array(),
    startDate: Yup.date().when("endDate", {
      is: (date) => date, // !undefined
      then: Yup.date().max(Yup.ref("endDate"), "generic.forms.errors.startDate"),
    }),
    endDate: Yup.date().when("startDate", {
      is: (date) => date, // !undefined
      then: Yup.date().min(Yup.ref("startDate"), "generic.forms.errors.endDate"),
    }),
    undeletable: Yup.boolean(),
  },
  [["startDate", "endDate"]],
)

export const categories = [
  { value: "service", label: "checkins.forms.values.service" },
  { value: "event", label: "checkins.forms.values.event" },
]

export const getPeriods = (t: TFunction): { key: string; label: string }[] => [
  { key: "u", label: t("checkins.forms.values.unlimited") },
  { key: "h", label: t("checkins.forms.values.hour") },
  { key: "d", label: t("checkins.forms.values.day") },
  { key: "w", label: t("checkins.forms.values.week") },
  { key: "m", label: t("checkins.forms.values.month") },
  { key: "s", label: t("checkins.forms.values.semester") },
  { key: "y", label: t("checkins.forms.values.year") },
  { key: "c", label: t("checkins.forms.values.card") },
]

export const getDays = (t: TFunction): { key: number; label: string }[] => [
  {
    key: 1,
    label: t("week.monday"),
  },
  {
    key: 2,
    label: t("week.tuesday"),
  },
  {
    key: 3,
    label: t("week.wednesday"),
  },
  {
    key: 4,
    label: t("week.thursday"),
  },
  {
    key: 5,
    label: t("week.friday"),
  },
  {
    key: 6,
    label: t("week.saturday"),
  },
  {
    key: 0,
    label: t("week.sunday"),
  },
]
