import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from "./locales/translation_en.json"
import translationFR from "./locales/translation_fr.json"
import translationES from "./locales/translation_es.json"
import translationCZ from "./locales/translation_cz.json"
import translationDA from "./locales/translation_da.json"
import translationDE from "./locales/translation_de.json"
import translationNL from "./locales/translation_nl.json"
import translationPL from "./locales/translation_pl.json"
import translationSK from "./locales/translation_sk.json"
import translationSV from "./locales/translation_sv.json"

export const language = [
  { label: "lang.en", code: "en" },
  { label: "lang.fr", code: "fr" },
  { label: "lang.es", code: "es" },
  { label: "lang.de", code: "de" },
]

/* waitingLanguage
  { label: "lang.cz", code: "cz" },
  { label: "lang.da", code: "da" },
  { label: "lang.nl", code: "nl" },
  { label: "lang.pl", code: "pl" },
  { label: "lang.sk", code: "sk" },
  { label: "lang.sv", code: "sv" },*/

const resources = {
  en: { translation: translationEN },
  fr: { translation: translationFR },
  es: { translation: translationES },
  cz: { translation: translationCZ },
  da: { translation: translationDA },
  de: { translation: translationDE },
  nl: { translation: translationNL },
  pl: { translation: translationPL },
  sk: { translation: translationSK },
  sv: { translation: translationSV },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: !!process.env.LOCAL,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    lng: localStorage.getItem("lang") || "en",
    fallbackLng: process.env.NODE_ENV === "production" ? "en" : "dev",
    resources,
  })

export default i18n
