import { ProfileType } from "../../api/ContributorObject"
import { useTranslation } from "react-i18next"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import Container from "../../components/general/Container"
import Card from "../../components/members/Card"
import ProfileForm from "../../components/profile/ProfileForm"
import { profileFields, profileValidationSchema } from "../../components/profile/ProfileFormConsts"
import { FormikValues } from "formik"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import React from "react"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"

type ProfileEditProps = {
  profileId: number
  profile: ProfileType
}

const ProfileEdit: React.FC<ProfileEditProps> = ({ profileId, profile }: ProfileEditProps) => {
  const { t } = useTranslation()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  return (
    <Container>
      <Card>
        <ProfileForm
          formTitle={t("profile.fields.titleMyProfile")}
          initialValues={profile}
          validationSchema={profileValidationSchema}
          fields={profileFields}
          buttonLabel={t("generic.buttons.update")}
          onSubmit={(formData: FormikValues) => {
            if (profileId !== -1) {
              addActiveCalls()
              const values = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phone: formData.phone ? formData.phone : null,
              }
              execute(`profile`, "PATCH", {}, values)
                .then(() => {
                  openSuccessSnackbar(`${t("profile.snackbar.titleData")} ${t("generic.snackbar.success.edit")}`)
                })
                .catch(() => {
                  openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
                })
                .finally(subtractActiveCalls)
            }
          }}
        />
      </Card>
    </Container>
  )
}

export default ProfileEdit
