import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CashbackHistoryTable, CheckInHistoryTable, LogsHistoryTable } from "./MembersFormTable"
import { LogsOrigin } from "../../../utils/Constants"
import { ReturnLogsType, LogsType, TransactionsType, MemberType } from "../../../api/MemberObject"
import { CheckinUse } from "../../checkin/CheckInType"
import execute from "../../../api/api"
import Select from "../../Select"
import DateInput from "../../general/DateInput"

type CheckInsTabProps = {
  checkinsUses?: CheckinUse[]
}
export const CheckInsTab: React.FC<CheckInsTabProps> = ({ checkinsUses }: CheckInsTabProps) => {
  const { t } = useTranslation()

  return (
    <div>
      {(!checkinsUses || checkinsUses.length === 0) && <p>{t("members.fields.noCheckIns")}</p>}
      {checkinsUses && checkinsUses.length > 0 && <CheckInHistoryTable checkinsUses={checkinsUses} />}
    </div>
  )
}

type TransactionsTabProps = {
  transactions?: TransactionsType[]
}
export const TransactionsTab: React.FC<TransactionsTabProps> = ({ transactions }: TransactionsTabProps) => {
  const { t } = useTranslation()

  return (
    <div>
      {(!transactions || transactions.length === 0) && <p>{t("members.fields.noTLP")}</p>}
      {transactions && transactions.length > 0 && (
        <div className="overflow-x-auto">
          <CashbackHistoryTable transactions={transactions} />
        </div>
      )}
    </div>
  )
}

type FilterLogsTabProps = {
  onChange: Dispatch<SetStateAction<TFiltersLogs>>
}

type TFiltersLogs = {
  origin: string
  date?: Date
  count: number
  page: number
  sort: string
}

const initFilters = { origin: "", date: undefined, count: 0, page: 1, maxPages: 0, sort: "desc" }

const FilterLogsTab: React.FC<FilterLogsTabProps> = ({ onChange }: FilterLogsTabProps) => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState<TFiltersLogs>(initFilters)

  useEffect(() => {
    onChange(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <div className="flex justify-center items-center space-x-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="flex text-center text-gray-500">{t("logs.filters.originLabel")}</p>
          <Select
            value={filters?.origin}
            values={LogsOrigin.sort((a, b) => {
              if (a.value === "" || b.value === "") {
                return -1
              } else {
                return t(a.label).localeCompare(t(b.label))
              }
            })}
            keyRef="value"
            labelRef="label"
            onChange={(event) => {
              setFilters({
                ...filters,
                origin: event.target.value,
                count: event.target.value === "" ? 0 : filters.count + 1,
              })
            }}
            large
          />
        </div>
        <div>
          <p className="flex text-center text-gray-500">{t("logs.filters.fromDateLabel")}</p>
          <DateInput
            action={(newDate) => {
              setFilters({
                ...filters,
                date: newDate === "" ? undefined : new Date(newDate.concat("T00:00:00Z")),
                count: filters.count + 1,
              })
            }}
            value={filters.date?.toISOString().substring(0, 10) || ""}
            max={new Date().toISOString().substring(0, 10)} //TODAY
            min=""
            className="h-12 w-full cursor-pointer border-2 border-gray-200 px-3 rounded"
          />
        </div>
      </div>
      {filters?.count > 0 && (
        <div className="flex justify-center items-center h-6 w-6 p-1 mt-6 bg-red-500 rounded-full">
          <i className="icon-cancel cursor-pointer text-white" onClick={() => setFilters(initFilters)} />
        </div>
      )}
    </div>
  )
}

type PageChangerLogsTabProps = {
  className?: string
  maxPages: number
  filters: TFiltersLogs
  onChange: Dispatch<SetStateAction<TFiltersLogs>>
}

const PageChangerLogsTab: React.FC<PageChangerLogsTabProps> = ({
  className,
  maxPages,
  filters,
  onChange,
}: PageChangerLogsTabProps) => {
  return (
    <div className={className}>
      {maxPages > 1 && (
        <div className="flex items-end h-14 justify-end mr-4">
          {filters.page > 1 && (
            <div className="flex justify-center items-center h-6 w-6 p-1">
              <i
                className="icon-arrow-left cursor-pointer text-black"
                onClick={() => onChange({ ...filters, page: filters.page - 1 })}
              />
            </div>
          )}
          <div className="flex justify-center items-center mx-2">{`${filters.page} / ${maxPages}`}</div>
          {filters.page < maxPages && (
            <div className="flex justify-center items-center h-6 w-6 p-1">
              <i
                className="icon-arrow-right cursor-pointer text-black"
                onClick={() => onChange({ ...filters, page: filters.page + 1 })}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

type LogsTabProps = {
  member?: MemberType
}
export const LogsTab: React.FC<LogsTabProps> = ({ member }: LogsTabProps) => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState<TFiltersLogs>(initFilters)
  const [memberLogs, setMemberLogs] = useState<LogsType[]>([])
  const [maxPages, setMaxPages] = useState<number>(0)

  useEffect(() => {
    if (member) {
      execute<ReturnLogsType>(
        "get",
        "GET",
        {},
        {},
        {
          customer_id: member.id,
          customer_email: member.email,
          fid_center_id: member.primaryCenterId,
          limit: 10,
          origin: filters?.origin,
          date: filters?.date?.getTime(),
          page: filters?.page,
          sort: filters?.sort,
        },
        "logger",
      ).then((resLogsUser) => {
        setMemberLogs(resLogsUser.logs)
        setMaxPages(resLogsUser.pages)
      })
    }
  }, [filters, member])

  return (
    <div className="flex flex-col justify-center space-y-3">
      {(memberLogs?.length > 0 || filters?.count !== 0) && <FilterLogsTab onChange={setFilters} />}
      {memberLogs && memberLogs.length === 0 && <p>{t("modal.logs.empty")}</p>}
      {memberLogs && memberLogs.length > 0 && (
        <div className="overflow-x-auto">
          <PageChangerLogsTab className="mb-2" maxPages={maxPages} filters={filters} onChange={setFilters} />
          <LogsHistoryTable logs={memberLogs} />
          <PageChangerLogsTab maxPages={maxPages} filters={filters} onChange={setFilters} />
        </div>
      )}
    </div>
  )
}
