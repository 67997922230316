import * as Yup from "yup"
import { partnerPasswordRegex, usernameRegex } from "../../../utils/Regex"

export const cognitoUsersInitialValues = {
  username: "",
  email: "",
  password: "",
}

export const cognitoUsersValidation = Yup.object().shape({
  username: Yup.string()
    .required("generic.forms.errors.required")
    .matches(usernameRegex, "generic.forms.errors.invalidUsername"),
  email: Yup.string().required("generic.forms.errors.required").email("generic.forms.errors.invalidEmail"),
  password: Yup.string().matches(partnerPasswordRegex, "generic.forms.errors.partnerPasswordValidation"),
})

export const keysLevelQuickStart = [
  { label: "dev", id: "dev" },
  { label: "level 1", id: "l1" },
  { label: "level 2", id: "l2" },
  { label: "level 3", id: "l3" },
  { label: "level 4", id: "l4" },
]
