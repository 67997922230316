import React from "react"

type ActionIconProps = {
  icon: string
  iconColor?: string
  title?: string
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

export const ActionIcon: React.FC<ActionIconProps> = ({ icon, iconColor, title, onClick }: ActionIconProps) => {
  return <i title={title} className={`mx-1 ${icon} text-2xl ${iconColor || ""} cursor-pointer`} onClick={onClick} />
}
