import { TFunction } from "i18next"
import {
  ConvertedMembersFilterType,
  FilterStore,
  MembersFilterType,
  setAges,
  setConvertedMembers,
  setDigital,
  setMembers,
  setOptin,
  setPartner,
  setPaymentMethods,
  setPremiumOrigin,
  TLPPaymentMethodType,
} from "../../store/FilterStore"
import { OptionType } from "../../components/MultipleSelect"
import {
  agesFilters,
  convertedMembersOptions,
  digitalizationFilters,
  membersCreationFilters,
  optinFilters,
  originUpgraded,
  partnersFilters,
  paymentMethodOptions,
} from "../../components/dashboard/filters/FilterConst"

type ChartOptionsType = {
  lang: {
    months: string[]
    shortMonths: string[]
    weekdays: string[]
    decimalPoint: string
    thousandsSep: string
    loading: string
  }
}

export const getChartOptions = (t: TFunction): ChartOptionsType => {
  const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
  return {
    lang: {
      months: months.map((month) => t(`month.${month}.long`)),
      shortMonths: months.map((month) => t(`month.${month}.short`)),
      weekdays: [
        t("week.sunday"),
        t("week.monday"),
        t("week.tuesday"),
        t("week.wednesday"),
        t("week.thursday"),
        t("week.friday"),
        t("week.saturday"),
      ],
      decimalPoint: ",",
      thousandsSep: " ",
      loading: t("label-loading"),
    },
  }
}

// --------------------------------------------- Dashboard filters -----------------------------------------------------

type Filter = {
  bool: {
    minimum_should_match?: number
    must: any[]
    must_not: any[]
    should: any[]
  }
}

export const getBaseFilters = (filters: FilterStore, isTLPDashboard: boolean): Filter => {
  const startDate = {
    range: {
      [isTLPDashboard ? "premiumStartDate" : "accountCreatedAt"]: {
        // As the server cannot parse empty dates, we send an undefined value, which will be skipped.
        gte: filters.date.fromDateFilter || undefined,
        lte: filters.date.toDateFilter || undefined,
      },
    },
  }

  // The genderFilter field is an array of strings which can be empty or contain "M", "F" or "O".
  const genders = filters.genderFilter.map((gender) => {
    return {
      term: {
        "gender.keyword": gender,
      },
    }
  })

  // Digital filter
  const digitalFilters = []
  if (filters.digitalFilter) {
    const bothDigital = filters.digitalFilter === "digitalPrinted"
    if (bothDigital || filters.digitalFilter === "digital") {
      digitalFilters.push({ term: { isDigital: 1 } })
    }
    if (bothDigital || filters.digitalFilter === "printed") {
      digitalFilters.push({ term: { isPrinted: 1 } })
    }
    if (filters.digitalFilter === "none") {
      digitalFilters.push({ term: { isDigital: 0 } })
      digitalFilters.push({ term: { isPrinted: 0 } })
    }
  }

  return {
    bool: {
      must: [startDate, ...digitalFilters],
      must_not: [...genders],
      should: [],
    },
  }
}

export const getDashboardFilters = (filters: FilterStore): Filter => {
  const dashboardFilters = getBaseFilters(filters, false)

  if (filters.isVip) {
    dashboardFilters.bool.must.push({ term: { isVIP: "0" } })
  }

  // In FID isVIP = isPro
  if (filters.isPro) {
    dashboardFilters.bool.must.push({ term: { isVIP: "1" } })
  }

  if (filters.memberTypeFilter === "Loyalty") {
    dashboardFilters.bool.must_not.push(
      {
        term: {
          type: {
            value: "partial",
          },
        },
      },
      {
        bool: {
          must: [
            {
              bool: {
                must: [
                  {
                    exists: {
                      field: "pasKey",
                    },
                  },
                  {
                    bool: {
                      must_not: [
                        {
                          term: {
                            "pasKey.keyword": {
                              value: "",
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              bool: {
                should: [
                  {
                    term: {
                      isPremium: {
                        value: "0",
                      },
                    },
                  },
                  {
                    bool: {
                      must_not: [
                        {
                          exists: {
                            field: "isPremium",
                          },
                        },
                      ],
                    },
                  },
                ],
                minimum_should_match: 1,
              },
            },
          ],
        },
      },
    )
  }

  if (filters.memberTypeFilter === "Newsletter") {
    dashboardFilters.bool.should.push({ term: { type: "partial" } })
    dashboardFilters.bool.should.push({
      bool: {
        must: [
          {
            bool: {
              should: [
                {
                  term: {
                    isPremium: {
                      value: "0",
                    },
                  },
                },
                {
                  bool: {
                    must_not: [
                      {
                        exists: {
                          field: "isPremium",
                        },
                      },
                    ],
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            bool: {
              must: [
                {
                  exists: {
                    field: "pasKey",
                  },
                },
                {
                  bool: {
                    must_not: [
                      {
                        term: {
                          "pasKey.keyword": {
                            value: "",
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    })
    dashboardFilters.bool.minimum_should_match = 1
  }

  // Member filter
  if (filters.membersFilter.length !== 0) {
    const tabCreateFrom: string[] = []
    filters.membersFilter.forEach((element: MembersFilterType) => {
      switch (element) {
        case "mobile":
          tabCreateFrom.push("appAndroid")
          tabCreateFrom.push("appiPhone")
          break
        case "formWeb":
          tabCreateFrom.push("sitemobile")
          tabCreateFrom.push("formWeb")
          break
        default:
          tabCreateFrom.push(element)
          break
      }
    })
    dashboardFilters.bool.must.push({
      terms: { "createdFrom.keyword": tabCreateFrom },
    })
  }

  // Optin filter
  if (filters.optinFilter !== "no") {
    const bothOptins = filters.optinFilter === "both"
    // We send the right filters corresponding to what has been selected in the Optin MultipleSelect
    if (bothOptins || filters.optinFilter === "commercial") {
      dashboardFilters.bool.must.push({ term: { optinCommercial: 1 } })
    }
    if (bothOptins || filters.optinFilter === "sms") {
      dashboardFilters.bool.must.push({ term: { optinSMS: 1 } })
    }
  } else {
    dashboardFilters.bool.must.push({ term: { optinSMS: 0 } }, { term: { optinCommercial: 0 } })
  }

  // Age Filter
  if (filters.agesFilter.length !== 0) {
    dashboardFilters.bool["minimum_should_match"] = 1

    if (filters.agesFilter.includes("20")) {
      dashboardFilters.bool.should.push({
        range: {
          birthDate: {
            gte: "now-20y",
          },
        },
      })
    }
    if (filters.agesFilter.includes("20-30")) {
      dashboardFilters.bool.should.push({
        range: {
          birthDate: {
            lt: "now-20y",
            gte: "now-30y",
          },
        },
      })
    }
    if (filters.agesFilter.includes("30-50")) {
      dashboardFilters.bool.should.push({
        range: {
          birthDate: {
            lt: "now-30y",
            gte: "now-50y",
          },
        },
      })
    }
    if (filters.agesFilter.includes("50")) {
      dashboardFilters.bool.should.push({
        range: {
          birthDate: {
            lt: "now-50y",
          },
        },
      })
    }
  }

  if (filters.membersFilter.length === 1 && filters.membersFilter.includes("Partner") && filters.partnersFilter) {
    dashboardFilters.bool.must.push({ term: { "partner.keyword": { value: filters.partnersFilter } } })
  }

  return dashboardFilters
}

export const getTLPDashboardFilters = (filters: FilterStore): Filter => {
  const dashboardTLPFilters = getBaseFilters(filters, true)

  dashboardTLPFilters.bool.must.push({ term: { isPremium: 1 } })

  // New & TLP member filter
  if (filters.premium.convertedMembers) {
    dashboardTLPFilters.bool.must.push({
      term: {
        isPremiumConverted: filters.premium.convertedMembers,
      },
    })
  }

  // Origin filter (Values returned match ONE of the criteria ("OR" logic))
  if (filters.premium.originFilter.length > 0) {
    dashboardTLPFilters.bool["minimum_should_match"] = 1
    const tabUpgradedFrom: string[] = []
    filters.premium.originFilter.forEach((element) => {
      switch (element) {
        case "mobile":
          tabUpgradedFrom.push("appAndroid")
          tabUpgradedFrom.push("appiPhone")
          break
        case "formWeb":
          tabUpgradedFrom.push("sitemobile")
          tabUpgradedFrom.push("formWeb")
          break
        case "unknown":
          tabUpgradedFrom.push("unknown")
          tabUpgradedFrom.push("blossom")
          break
        default:
          tabUpgradedFrom.push(element)
          break
      }
    })

    dashboardTLPFilters.bool.should.push({
      terms: { "premiumUpgradedFrom.keyword": tabUpgradedFrom },
    })
  }

  // Payment method filter
  if (filters.premium.paymentMethod) {
    const both = filters.premium.paymentMethod === "bankAndTR"
    if (both || filters.premium.paymentMethod === "tr") {
      dashboardTLPFilters.bool.must.push({
        term: {
          hasPremiumTicketRestaurant: 1,
        },
      })
    }
    if (both || filters.premium.paymentMethod === "bank") {
      dashboardTLPFilters.bool.must.push({
        term: {
          hasPremiumBank: 1,
        },
      })
    }
    if (filters.premium.paymentMethod === "scan") {
      dashboardTLPFilters.bool.must.push({
        term: {
          hasPremiumScan: 1,
        },
      })
    }
  }

  return dashboardTLPFilters
}

// ------------------------------------------ Classic Dashboard --------------------------------------------------

export type MultipleFilterType = {
  key: string
  values: OptionType[]
  placeholder?: string
  defaultValue?: string
  action: (value: any) => void
  isMultipleSelect: boolean
  value?: string | number
}

const membersAction = (values: MembersFilterType[]): void => {
  if (!(values.length === 1 && values.includes("Partner"))) {
    setPartner("")
  }
  setMembers(values)
}

export const dashboardMultipleFilters: MultipleFilterType[] = [
  {
    key: "members",
    values: membersCreationFilters,
    placeholder: "dashboard.filters.members.placeholder",
    defaultValue: "dashboard.filters.defaultValueAll",
    action: membersAction,
    isMultipleSelect: true,
  },
  {
    key: "partner",
    values: partnersFilters,
    placeholder: "dashboard.filters.partners.placeholder",
    defaultValue: "dashboard.filters.defaultValueAll",
    action: setPartner,
    isMultipleSelect: false,
  },
  {
    key: "optin",
    values: optinFilters,
    placeholder: "dashboard.filters.optin.placeholder",
    defaultValue: "dashboard.filters.defaultValueAll",
    action: setOptin,
    isMultipleSelect: false,
  },
  {
    key: "digitalization",
    values: digitalizationFilters,
    placeholder: "dashboard.filters.digital.placeholder",
    defaultValue: "dashboard.filters.defaultValueAll",
    action: setDigital,
    isMultipleSelect: false,
  },
  {
    key: "age",
    values: agesFilters,
    placeholder: "dashboard.filters.ages.placeholder",
    defaultValue: "dashboard.filters.defaultValueAll",
    action: setAges,
    isMultipleSelect: true,
  },
]

// --------------------------------------------- TLP Dashboard ----------------------------------------------------

export const tlpDashboardMultipleFilters = (
  convertedMembersValue: ConvertedMembersFilterType,
  paymentMethodValue: TLPPaymentMethodType,
): MultipleFilterType[] => [
  {
    key: "convertedMembers",
    placeholder: "premiumDashboard.pie.converted.placeholder",
    values: convertedMembersOptions,
    action: setConvertedMembers,
    isMultipleSelect: false,
    value: convertedMembersValue,
  },
  {
    key: "premiumOrigin",
    values: originUpgraded,
    placeholder: "premiumDashboard.pie.origin.placeholder",
    action: setPremiumOrigin,
    isMultipleSelect: true,
  },
  {
    key: "paymentMethod",
    placeholder: "premiumDashboard.pie.payment.placeholder",
    values: paymentMethodOptions,
    action: setPaymentMethods,
    isMultipleSelect: false,
    value: paymentMethodValue,
  },
]
