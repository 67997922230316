import * as Yup from "yup"
import { OptionType } from "../../components/MultipleSelect"
import { CustomerOrigin, ExportType } from "./ExportsType"

export const exportsTypes: OptionType[] = [
  {
    key: "cockpit",
    label: "Cockpit",
  },
  {
    key: "tlpCockpit",
    label: "TLP Cockpit",
  },
  {
    key: "customers",
    label: "menu.customers",
  },
  {
    key: "licensePlate",
    label: "generic.forms.fields.licencePlate",
  },
  {
    key: "checkins",
    label: "menu.checkIns",
  },
  {
    key: "centerOperations",
    label: "exports.forms.fields.customerEnrichment",
  },
]

export const exportMemberTypeValues: OptionType[] = [
  {
    key: "allMembers",
    label: "dashboard.table.allMembers",
  },
  {
    key: "loyaltyMembers",
    label: "dashboard.table.loyaltyMembers",
  },
  {
    key: "newsletterSubscribers",
    label: "dashboard.table.newsletterSubscribers",
  },
]

export const exportOptInValues: OptionType[] = [
  {
    key: "all",
    label: "generic.forms.fields.all",
  },
  {
    key: "true",
    label: "generic.forms.fields.yes",
  },
  {
    key: "false",
    label: "generic.forms.fields.no",
  },
]

export const customersOrigins: CustomerOrigin[] = [
  {
    id: ["appAndroid", "appiPhone"],
    label: "dashboard.pie.membersCreation.mobile",
  },
  {
    id: ["formWeb", "sitemobile"],
    label: "dashboard.pie.membersCreation.web",
  },
  {
    id: ["Wifi"],
    label: "dashboard.pie.membersCreation.wifi",
  },
  {
    id: ["onSite"],
    label: "dashboard.pie.membersCreation.dashboard",
  },
  {
    id: ["Partner"],
    label: "dashboard.pie.membersCreation.partner",
  },
  {
    id: ["Directories"],
    label: "dashboard.pie.membersCreation.directories",
  },
]

export const exportsFormInitialValues: ExportType = {
  type: "dashboard",
  memberType: "allMembers",
  emailAddress: "",
  startDate: "",
  endDate: "",
  centers: [],
  centersId: [],
  checkIns: [],
  checkInsId: [],
  centerOperations: [],
  centerOperationsName: [],
  origins: [],
  originsValue: [],
}

export const exportsValidationSchema = Yup.object().shape({
  type: Yup.string().required("generic.forms.errors.required"),
  centers: Yup.array().when("type", {
    is: "centerOperations",
    then: Yup.array().required("generic.forms.errors.required").min(1, "generic.forms.errors.required"),
  }),
  memberType: Yup.string().when("type", {
    is: "customers",
    then: Yup.string().required("generic.forms.errors.required"),
  }),
})
