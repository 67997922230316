import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useTitle } from "../../utils/hooks/useTitle"
import TlpOffersForm from "../../components/tlpOffers/TlpOffersForm"
import { initialValues } from "../../components/tlpOffers/TlpOfferFormConst"
import { getDays } from "../checkins/CheckInFormConsts"

const TlpOffersCreate: FC = () => {
  const { t } = useTranslation()

  // Dynamically update browser tab title
  useTitle(`${t("menu.tlpOffers")} | ${t("applicationName")}`)

  return (
    <TlpOffersForm
      isCreate
      initialValues={{
        ...initialValues,
        offerSettings: {
          ...initialValues.offerSettings,
          recurringDays: getDays(t).filter((day) => initialValues.offerSettings.recurringDays?.includes(day.key)),
        },
      }}
    />
  )
}

export default TlpOffersCreate
