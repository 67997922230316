import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Restricted from "./Restricted"
import { ActionIcon } from "./ActionIcon"
import execute from "../api/api"
import { snackbarDuration, snackbarErrorMessage } from "./snackbarStyles"
import { useHistory } from "react-router-dom"
import { useCustomSnackBar } from "../utils/hooks/useCustomSnackBar"
import { BookingBug, CheckInItem, CheckinWS } from "./checkin/CheckInType"
import { CheckinsTabId } from "./checkin/CheckinsConst"

type TableComponentMobileProps = {
  item: CheckInItem
  centerId: number
  clickCell: () => void
  tabSelected: string
}

const TableComponentMobile: React.FC<TableComponentMobileProps> = ({
  item,
  centerId,
  clickCell,
  tabSelected,
}: TableComponentMobileProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [openDetails, setOpenDetails] = useState<boolean>(false)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  return (
    <div className="my-2 border rounded py-2 text-left">
      <div className="flex flex-row items-center">
        <i
          className={`icon-right-open cursor-pointer w-1/8 text-center ${
            openDetails ? "transform -rotate-90" : "transform rotate-90"
          }`}
          onClick={() => setOpenDetails((prev) => !prev)}
        />
        <div className="cursor-pointer h-full w-5/8 flex flex-row items-center" onClick={clickCell}>
          {tabSelected === CheckinsTabId.CHECKINS && (
            <div
              className="h-4 w-4 mr-2 rounded-full"
              style={{
                backgroundColor: (item as CheckinWS).colorCode,
              }}
            />
          )}
          <label className="cursor-pointer truncate">{item.name}</label>
        </div>
        {tabSelected === CheckinsTabId.CHECKINS && (
          <div className="w-1/4 text-right">
            <Restricted right={"checkins.update"}>
              <ActionIcon
                icon="icon-edit"
                iconColor="text-black"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation()
                  history.push(`/checkins/${item.id}/edit`)
                }}
              />
            </Restricted>
            <Restricted right={"checkins.delete"}>
              <ActionIcon
                icon="icon-delete"
                iconColor="text-red-500"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation()
                  execute(`checkins/${item.id}?centerId=${centerId}`, "DELETE")
                    .then(() => {
                      openSuccessSnackbar(
                        `${t("checkins.snackbar.title")} ${t("generic.snackbar.success.delete")}`,
                        snackbarDuration,
                      )
                    })
                    .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
                }}
              />
            </Restricted>
          </div>
        )}
      </div>
      {openDetails && (
        <div className="flex flex-col space-y-2 ml-1/8 pt-2">
          <label>
            {t("checkins.table.category")} : {item.category}
          </label>
          <label>
            {t("checkins.table.bookings")} : {item.usesTotal}
          </label>

          {tabSelected === CheckinsTabId.CHECKINS ? (
            <label>
              {t("checkins.table.useRate")} : {(item as CheckinWS).useRate}
            </label>
          ) : (
            <>
              <label>
                {t("checkins.table.datetime")} = {(item as BookingBug).datetime || "-"}
              </label>
              <label>
                {t("checkins.table.participations")} = {(item as BookingBug).attendancesTotal || "-"}
              </label>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default TableComponentMobile
