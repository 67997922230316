import * as Yup from "yup"
import { format } from "date-fns"
import { versionRegex } from "../../utils/Regex"

export const changelogInitialValues = {
  id: undefined,
  date: format(new Date(), "yyyy-MM-dd"),
  version: "",
  roles: [],
  value: [""],
}

export const changelogValidation = Yup.object().shape({
  date: Yup.date().required("generic.forms.errors.required"),
  version: Yup.string()
    .required("generic.forms.errors.required")
    .matches(versionRegex, "changelog.forms.errors.invalidVersion"),
})

export const changeLogFormFields = [
  {
    formKey: "date",
    label: "changelog.forms.fields.date",
    type: "date",
    required: true,
  },
  {
    formKey: "version",
    label: "changelog.forms.fields.version",
    required: true,
  },
]
