import React from "react"
import "./Loader.css"

type PropsType = {
  customClass?: string
  text?: string
}

export const Loader: React.FC = () => (
  <div className="h-full w-full flex justify-center items-center py-8">
    <div className="h-full w-full flex justify-center items-center content-center loader" />
  </div>
)

export const LoaderComponent: React.FC<PropsType> = (props: PropsType) => {
  const { customClass, text } = props
  return (
    <div>
      <div className="fixed z-50 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="opacity-75 fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-75" />
          </div>
          <div className="flex items-center justify-center">
            <div className={`flex flex-wrap content-center mainLoader loader ${customClass}`}>{text}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoaderComponent
