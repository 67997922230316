import { useTranslation } from "react-i18next"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import Container from "../../components/general/Container"
import Card from "../../components/members/Card"
import ProfileForm from "../../components/profile/ProfileForm"
import { changePasswordFields, passwordValidationSchema } from "../../components/profile/ProfileFormConsts"
import { FormikValues } from "formik"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import React from "react"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"

type PasswordEditProps = {
  profileId: number
}
const PasswordEdit: React.FC<PasswordEditProps> = ({ profileId }: PasswordEditProps) => {
  const { t } = useTranslation()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  return (
    <Container>
      <Card>
        <ProfileForm
          formTitle={t("profile.fields.titleResetPassword")}
          initialValues={{
            oldPassword: "",
            newPassword1: "",
            newPassword2: "",
          }}
          validationSchema={passwordValidationSchema}
          fields={changePasswordFields}
          buttonLabel={t("generic.buttons.update")}
          onSubmit={(formData: FormikValues) => {
            if (profileId !== -1) {
              addActiveCalls()
              const values = {
                oldPassword: formData.oldPassword,
                newPassword: formData.newPassword1,
              }
              execute(`profile/credentials`, "PATCH", {}, values)
                .then(() => {
                  openSuccessSnackbar(`${t("profile.snackbar.titlePassword")} ${t("generic.snackbar.success.edit")}`)
                })
                .catch(() => {
                  openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
                })
                .finally(subtractActiveCalls)
            }
          }}
        />
      </Card>
    </Container>
  )
}

export default PasswordEdit
