import * as Yup from "yup"
import { passwordRegex } from "../../utils/Regex"

export const resetPasswordValidationSchema = Yup.object().shape({
  confirmationCode: Yup.string().required("generic.forms.errors.required"),
  username: Yup.string().required("generic.forms.errors.required"),
  password: Yup.string()
    .required("generic.forms.errors.required")
    .matches(passwordRegex, "generic.forms.errors.passwordValidation"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "generic.forms.errors.matchPassword")
    .required("generic.forms.errors.required"),
})
