import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PieCharts from "./PieCharts"
import { useStore } from "effector-react"
import { execute } from "../../../api/api"
import { dataPieType } from "../../../api/ChartObject"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { ColorGraphs } from "../../../utils/Constants"

const membersCreationPieChartColors = [
  ColorGraphs.green,
  ColorGraphs.blue,
  ColorGraphs.red,
  ColorGraphs.orange,
  ColorGraphs.purple,
  ColorGraphs.teal,
]

type MembersCreationPieChartProps = {
  id: string
}
const MembersCreationPieChart: React.FC<MembersCreationPieChartProps> = (props: MembersCreationPieChartProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<dataPieType[]>([])

  const storeFilters = useStore(FilterStore)
  const isPartnersFilter =
    getDashboardFilters(storeFilters)?.bool?.must[1]?.terms &&
    getDashboardFilters(storeFilters)?.bool?.must[1]?.terms["createdFrom.keyword"]?.length === 1 &&
    getDashboardFilters(storeFilters)?.bool?.must[1]?.terms["createdFrom.keyword"][0] === "Partner"

  const title = isPartnersFilter
    ? t("dashboard.pie.membersCreation.titlePartners")
    : t("dashboard.pie.membersCreation.title")
  const subtitle = t("dashboard.pie.membersCreation.subtitle")

  const getColors = (length: number): string[] => {
    const colorsArray: string[] = []
    for (let i = 0; i < length; i++) {
      colorsArray.push(Object.values(ColorGraphs)[i % Object.keys(ColorGraphs).length])
    }
    return colorsArray
  }

  useEffect(() => {
    setLoading(true)
    execute(`dashboard/${id}/widget/creationOrigins`, "GET", {}, {}, { filters: getDashboardFilters(storeFilters) })
      .then((res: any) => {
        if (res) {
          if (isPartnersFilter) {
            const elemChart: dataPieType[] = []
            // eslint-disable-next-line
            Object.keys(res).map(function (keyName: string) {
              elemChart.push({ name: t(keyName), y: res[keyName], key: keyName })
            })
            elemChart.sort((a, b) => (res[a.name] > res[b.name] ? -1 : 1))
            setData(elemChart)
          } else {
            setData([
              { name: t("dashboard.pie.membersCreation.mobile"), y: res.mobile, key: "mobile" },
              { name: t("dashboard.pie.membersCreation.web"), y: res.web, key: "web" },
              {
                name: t("dashboard.pie.membersCreation.dashboard"),
                y: res.onSite,
                key: "dashboard",
              },
              {
                name: t("dashboard.pie.membersCreation.partner"),
                y: res.Partner,
                key: "partner",
              },
              { name: t("dashboard.pie.membersCreation.wifi"), y: res.Wifi, key: "wifi" },
              {
                name: t("dashboard.pie.membersCreation.directories"),
                y: res.Directories,
                key: "directories",
              },
            ])
          }
        }
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters, id])

  return (
    <div className="col-span-1 row-span-1">
      <PieCharts
        title={title}
        data={data}
        subtitle={subtitle}
        colors={isPartnersFilter ? getColors(data.length) : membersCreationPieChartColors}
        isSecondaryWidget={false}
        loading={loading}
      />
    </div>
  )
}

export default MembersCreationPieChart
