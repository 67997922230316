export const initialMemberFilter: MemberFilter = {
  bool: {
    must: [],
    must_not: [],
    should: [],
  },
}
export type MemberFilter = {
  bool: {
    minimum_should_match?: number
    must: any[]
    must_not: any[]
    should: any[]
  }
}

export type TagFilterCondition = {
  type: "must" | "must_not" | "should"
  term?: string
  keyword?: boolean
  value: any
  filterType: "term" | "exist"
}

export type terms = {
  terms: any
}

export type term = {
  term: any
}
