import React, { useState, useEffect } from "react"
import ChangelogForm from "./ChangelogForm"
import execute from "../../api/api"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ChangelogType, ChangelogTypeWS } from "./ChangelogTypes"
import { format } from "date-fns"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { changelogInitialValues } from "./ChangelogFormConsts"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useFetchRoles } from "../../utils/hooks/useFetchRoles"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"

const ChangelogEdit: React.FC = () => {
  const { t } = useTranslation()
  const { changelogId } = useParams()
  const [changelog, setChangelog] = useState<ChangelogType>()

  const roles = useFetchRoles()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  useEffect(() => {
    addActiveCalls()
    execute<ChangelogType>(`changeLogs/${changelogId}`, "GET")
      .then((response) => {
        setChangelog({
          ...response,
          date: format(new Date(response.date), "yyyy-MM-dd"),
        })
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changelogId])

  const submitEdit = (formData: ChangelogTypeWS) => {
    addActiveCalls()
    execute(`changeLogs/${changelogId}`, "PATCH", {}, formData)
      .then(() =>
        openSuccessSnackbar(`${t("changelog.snackbar.title")} ${t("generic.snackbar.success.edit")}`, snackbarDuration),
      )
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
  }

  // Formik ne rerender pas le formulaire au changement des données initiales, donc on lui affiche le formulaire
  // initial ou celui prérempli avec les données en fonction de si on a fini de fetch les données ou pas.
  return (
    <>
      {!changelog && (
        <ChangelogForm
          header={t("changelog.forms.titles.edit")}
          values={changelogInitialValues}
          roles={roles}
          onSubmit={submitEdit}
        />
      )}
      {changelog && (
        <ChangelogForm
          header={t("changelog.forms.titles.edit")}
          values={changelog}
          roles={roles}
          onSubmit={submitEdit}
        />
      )}
    </>
  )
}

export default ChangelogEdit
