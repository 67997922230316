import * as React from "react"
import { UserRole } from "../../utils/Roles"
import execute from "../../api/api"
import { useParams } from "react-router-dom"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { useTranslation } from "react-i18next"
import RoleForm from "../../components/roles/RoleForm"
import { rightsInitialValues } from "../../components/roles/RoleFormConsts"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { Color } from "../../utils/Constants"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { FC, useEffect, useState } from "react"

const RoleEdit: FC = () => {
  const { t } = useTranslation()
  const { roleName } = useParams()
  const [role, setRole] = useState<UserRole>()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  useEffect(() => {
    addActiveCalls()
    execute<UserRole>(`users/roles/${roleName}`, "GET")
      .then((res) => {
        setRole(res)
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleName])

  return (
    <div>
      <div className={`w-screen h-2 ${Color.RIGHTS}`} />
      <div className="pt-6 pb-6">
        {!role && (
          <RoleForm
            title={t("roles.forms.titles.edit")}
            initialValues={rightsInitialValues}
            isCreate={false}
            onSubmit={() => undefined}
          />
        )}
        {role && (
          <RoleForm
            title={t("roles.forms.titles.edit")}
            initialValues={role}
            isCreate={false}
            onSubmit={(values) => {
              addActiveCalls()
              const formData = {
                name: values.name,
                label: values.label,
                linkedTo: values.linkedTo || null,
                permissions: values.permissions,
              }
              execute(`users/roles/${roleName}`, "PATCH", {}, formData)
                .then(() => {
                  openSuccessSnackbar(
                    `${t("roles.snackbar.title")} ${t("generic.snackbar.success.edit")}`,
                    snackbarDuration,
                  )
                })
                .catch(() => {
                  openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
                })
                .finally(subtractActiveCalls)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default RoleEdit
