import * as Yup from "yup"
import { TFunction } from "i18next"
import { ColumnType } from "../../TableComponent"
import { barcodeRegex, phoneNumberRegex } from "../../../utils/Regex"
import { dateMaxBirthday } from "../../../utils/Constants"
import { TabsValue } from "../../Tabs"

export const membersInitialValues = {
  gender: "",
  firstName: "",
  lastName: "",
  email: "",
  birthDate: "",
  phone: "",
  primaryCenterId: -1,
  addressZipCode: "",
  addressCity: "",
  addressStreet: "",
  licencePlate: "",
  isVIP: false,
  companyName: "",
  optinSMS: false,
  optinLoyalty: false,
  optinCommercial: false,
  type: undefined,
  id: 0,
}

export type BarcodeWS = {
  exists: boolean
  barcode: string
}

export const membersFormValidationSchema = (
  zipAddressRegex: string,
  isPartial: boolean | undefined,
): Yup.ObjectSchema =>
  Yup.object().shape({
    newBarcode: Yup.string().matches(barcodeRegex, "generic.forms.errors.barcode"),
    gender: Yup.string().required("generic.forms.errors.required"),
    firstName: isPartial ? Yup.string() : Yup.string().required("generic.forms.errors.required"),
    lastName: isPartial ? Yup.string() : Yup.string().required("generic.forms.errors.required"),
    email: Yup.string().email("generic.forms.errors.invalidEmail").required("generic.forms.errors.required"),
    phone: Yup.string().matches(phoneNumberRegex, "generic.forms.errors.invalidPhone"),
    birthDate: isPartial ? Yup.string() : Yup.string().required("generic.forms.errors.required"),
    primaryCenterId: Yup.number().required("generic.forms.errors.required").min(0, "generic.forms.errors.required"),
    addressZipCode: zipAddressRegex
      ? Yup.string().matches(new RegExp(zipAddressRegex), "generic.forms.errors.invalidZipCode")
      : Yup.string(),
    isVIP: Yup.boolean().required("generic.forms.errors.required"),
    companyName: Yup.string().when("isVIP", {
      is: true,
      then: Yup.string().required("generic.forms.errors.required"),
    }),
    optinLoyalty: Yup.boolean().required("generic.forms.errors.required"),
    optinSMS: Yup.boolean().required("generic.forms.errors.required"),
    optinCommercial: Yup.boolean().required("generic.forms.errors.required"),
  })

export const barcodeFormValidationSchema = (): Yup.ObjectSchema =>
  Yup.object().shape({
    newBarcode: Yup.string().matches(barcodeRegex, "members.forms.errors.barcodeInvalid"),
  })

export const genderFormFields = [
  { key: "M", label: "generic.forms.fields.m" },
  { key: "F", label: "generic.forms.fields.f" },
  { key: "U", label: "generic.forms.fields.undefined" },
]

export const membersFormFields = [
  {
    formKey: "firstName",
    label: "generic.forms.fields.firstname",
    required: true,
    requiredPartial: false,
  },
  {
    formKey: "lastName",
    label: "generic.forms.fields.lastname",
    required: true,
    requiredPartial: false,
  },
  {
    formKey: "email",
    label: "generic.forms.fields.email",
    required: true,
    disabled: true,
  },
  {
    formKey: "birthDate",
    label: "generic.forms.fields.birthDate",
    required: true,
    requiredPartial: false,
    type: "date",
    maxDate: dateMaxBirthday(),
  },
  {
    formKey: "phone",
    label: "generic.forms.fields.phone",
    required: false,
  },
  {
    formKey: "addressZipCode",
    label: "generic.forms.fields.zipCode",
    required: false,
  },
]

export const workNearMallOptions = [
  { key: false, label: "generic.forms.fields.no" },
  { key: true, label: "generic.forms.fields.yes" },
]

export const optinNewsFields = [
  {
    key: "optinSMS",
    label: "generic.forms.fields.sms",
  },
  {
    key: "optinCommercial",
    label: "generic.forms.fields.email",
  },
]

export const membersCheckInsCols: (t: TFunction) => ColumnType[] = (t: TFunction) => [
  { label: t("generic.table.name") },
  {
    label: t("generic.table.date"),
    columnSpecifications: "text-center",
  },
]

export const cashbackHistoryCols: (t: TFunction) => ColumnType[] = (t: TFunction) => [
  {
    label: t("generic.table.date"),
  },
  {
    label: t("members.table.type"),
  },
  {
    label: t("members.table.amount"),
    columnSpecifications: "text-center",
  },
  {
    label: t("members.table.statusPoints"),
    columnSpecifications: "text-center",
  },
  {
    label: t("members.table.comment"),
  },
]

export const membersLogsCols: (t: TFunction) => ColumnType[] = (t: TFunction) => [
  {
    label: t("generic.table.date"),
  },
  {
    label: t("logs.table.origin"),
  },
  {
    label: t("logs.table.logCode"),
  },
  {
    label: t("logs.table.ipAddress"),
  },
  {
    label: t("logs.table.userAgent"),
  },
  {
    label: t("logs.table.information"),
  },
  {
    label: t("generic.table.actions"),
  },
]

export enum idTabsMembersAction {
  CHECKINS = "checkins",
  TLP = "tlp",
  LOGS = "logs",
}

export const membersActionTabs: TabsValue[] = [
  { name: "menu.checkIns", id: idTabsMembersAction.CHECKINS, displayUk: false, displayPartial: false, order: 0 },
  {
    name: "tlpOffers.history",
    id: idTabsMembersAction.TLP.toString(),
    displayUk: true,
    displayPartial: false,
    order: 1,
  },
  {
    name: "logs.title",
    id: idTabsMembersAction.LOGS.toString(),
    displayUk: false,
    displayPartial: true,
    order: 2,
    restrictedRole: "customerslogs.read",
  },
]
