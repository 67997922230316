import React from "react"
import CheckInForm from "./CheckInForm"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { getDays, initialValues } from "./CheckInFormConsts"
import { CheckInItem, CheckinTargetType, CheckinThemeType } from "../../components/checkin/CheckInType"
import { FormikValues } from "formik"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"

const CheckInCreate: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { centerId } = useParams<{ centerId: string }>()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  // Dynamically update browser tab title
  useTitle(`${t("menu.checkIns")} | ${t("applicationName")}`)

  return (
    <CheckInForm
      title={t("checkins.forms.titles.create")}
      initialValues={{
        ...initialValues,
        themes: [],
        targets: [],
        freqQuantity: (initialValues.useRate && parseInt(initialValues.useRate[0])) || 1,
        freqPeriod: (initialValues.useRate && initialValues.useRate[2].toLowerCase()) || "u",
        daysOfUse: getDays(t).filter((day) => initialValues.daysOfUse?.includes(day.key)),
        stores: [],
      }}
      centerId={parseInt(centerId, 10)}
      onSubmit={(formData: FormikValues) => {
        addActiveCalls()
        execute<CheckInItem>(
          `checkins?centerId=${centerId}`,
          "POST",
          {},
          {
            ...formData,
            centerId,
            daysOfUse: formData.daysOfUse.map((day: { key: number; label: string }) => day.key),
            themes: formData.themes.map((theme: CheckinThemeType) => theme.value),
            targets: formData.targets.map((target: CheckinTargetType) => target.value),
          },
        )
          .then((createdCheckin) => {
            openSuccessSnackbar(
              `${t("checkins.snackbar.title")} ${t("generic.snackbar.success.create")}`,
              snackbarDuration,
            )
            history.push(`/checkins/${createdCheckin.id}/edit`)
          })
          .catch(() => {
            openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
          })
          .finally(subtractActiveCalls)
      }}
    />
  )
}

export default CheckInCreate
