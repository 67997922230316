import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import Button, { ButtonType } from "../components/general/Button"
import { useTitle } from "../utils/hooks/useTitle"

const NotFound: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  // Dynamically update browser tab title
  useTitle(`${t("notFound.fields.name")} | ${t("applicationName")}`)

  return (
    <div className="h-screen w-screen bg-gray-100 flex justify-center pt-10">
      <div className="container flex flex-row justify-center px-5 text-gray-700">
        <div className="max-w-2xl">
          <h1 className="text-8xl md:text-6xl font-dark font-bold my-4">404</h1>
          <p className="text-2xl md:text-3xl font-light leading-normal my-4">{t("notFound.fields.title")}</p>
          <p className="text-lg mb-8">{t("notFound.fields.subTitle")}</p>

          <div className="flex justify-center">
            <Button label={t("notFound.buttons.previousPage")} onClick={() => history.goBack()} classNames="mr-5" />
            <Button label={t("menu.home")} onClick={() => history.push("/home")} type={ButtonType.NEUTRAL} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
