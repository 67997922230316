import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useTitle } from "../../utils/hooks/useTitle"
import TlpOffersForm from "../../components/tlpOffers/TlpOffersForm"
import { getDays } from "../checkins/CheckInFormConsts"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import { TlpOfferType, TlpOfferWSType } from "../../components/tlpOffers/TlpOffersTypes"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { useParams } from "react-router"
import { format } from "date-fns"

const TlpOffersEdit: FC = () => {
  const { t } = useTranslation()
  const { tlpOfferId } = useParams()
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const [tlpOffer, setTlpOffer] = useState<TlpOfferType>()
  const [initialValues, setInitialValues] = useState()

  useEffect(() => {
    addActiveCalls()
    execute<TlpOfferWSType>(`offers/${tlpOfferId}`, "GET", {}, {}, {}, "lpe")
      .then((response) => {
        const {
          centerId,
          name,
          currency,
          offerType,
          startDate,
          endDate,
          storesList,
          allStores,
          recurringDays,
          status,
          canActivate,
          ...others
        } = response.result.offer
        const values = {
          generalSettings: {
            centerId,
            name,
            currency,
          },
          offerSettings: {
            offerType,
            startDate: startDate ? format(new Date(startDate), "yyyy-MM-dd") : "",
            endDate: endDate ? format(new Date(endDate), "yyyy-MM-dd") : "",
            storesList: storesList
              ? storesList.map((store) => {
                  return {
                    key: store.uniqueStoreId,
                    label: store.genericName,
                  }
                })
              : [],
            allStores,
            recurringDays: getDays(t).filter((day) => recurringDays?.includes(day.key)),
          },
          rewardSettings: { ...others },
          status,
          canActivate,
        }
        setInitialValues(values)
        setTlpOffer(response.result.offer)
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tlpOfferId])

  // Dynamically update browser tab title
  useTitle(`${t("menu.tlpOffers")} | ${t("applicationName")}`)

  if (!initialValues) {
    return <div />
  } else {
    return <TlpOffersForm isCreate={false} initialValues={initialValues} initialTlpOfferData={tlpOffer} />
  }
}

export default TlpOffersEdit
