import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PieCharts from "../pieCharts/PieCharts"
import { execute } from "../../../api/api"
import { useStore } from "effector-react"
import { DataType, PremiumObjectiveObject } from "../../../api/PremiumObject"
import { FilterStore } from "../../../store/FilterStore"
import { getTLPDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { ColorGraphs } from "../../../utils/Constants"

type ConvertedPremiumProps = {
  id: string
}
const ConvertedPremium: FC<ConvertedPremiumProps> = (props: ConvertedPremiumProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [data, setData] = useState<DataType[]>([])
  const [loading, setLoading] = useState(false)

  const storeFilters = useStore(FilterStore)

  useEffect(() => {
    setLoading(true)
    execute<PremiumObjectiveObject>(
      `dashboard/${id}/widget/premium/converted`,
      "GET",
      {},
      {},
      { filters: getTLPDashboardFilters(storeFilters) },
    )
      .then((res) => {
        if (res) {
          setData([
            {
              name: t("premiumDashboard.pie.converted.converted"),
              y: res.convertedCustomers,
            },
            {
              name: t("premiumDashboard.pie.converted.new"),
              y: res.newCustomers,
            },
          ])
        }
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeFilters, id])

  return (
    <PieCharts
      title={t("premiumDashboard.pie.converted.title")}
      subtitle={t("premiumDashboard.pie.converted.subtitle")}
      data={data}
      isSecondaryWidget={false}
      loading={loading}
      colors={[ColorGraphs.green, ColorGraphs.orange]}
    />
  )
}

export default ConvertedPremium
