import * as React from "react"
import { useTranslation } from "react-i18next"
import Container from "../../general/Container"
import Card from "../Card"
import { Field } from "formik"
import RadioOption from "../../general/RadioOption"
import { optinNewsFields } from "./MembersFormConsts"
import { SetFieldValueType } from "../../../utils/Types"
import FormInputInline from "../../FormInputInline"

type AgreementSectionProps = {
  setFieldValue: SetFieldValueType
  values: any
}
export const AgreementSection: React.FC<AgreementSectionProps> = ({ setFieldValue, values }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Card>
        <div className="">
          <Field name="optinLoyalty">
            {({ meta }: any) => (
              <FormInputInline largeLeft meta={meta} label="members.forms.fields.labelOptinLoyalty">
                <div className="h-10 flex flex-row justify-center md:justify-start">
                  <RadioOption
                    label={t("generic.forms.fields.email")}
                    isActive={values.optinLoyalty}
                    onClick={() => setFieldValue("optinLoyalty", !values.optinLoyalty)}
                    meta={meta}
                  />
                </div>
              </FormInputInline>
            )}
          </Field>
          <FormInputInline largeLeft label="members.forms.fields.labelOptinSMSCommercial">
            <div className="h-10 w-full flex flex-row justify-center md:justify-start space-x-4">
              {optinNewsFields.map((optin) => (
                <Field key={optin.key} name={optin.key}>
                  {() => {
                    const isDisabled =
                      !values.optinLoyalty ||
                      (optin.key === "optinSMS" && !values.phone) ||
                      (optin.key === "optinCommercial" && !values.email)
                    return (
                      <RadioOption
                        key={optin.key}
                        label={t(optin.label)}
                        isActive={!isDisabled && values[optin.key]}
                        isDisabled={isDisabled}
                        onClick={() => !isDisabled && setFieldValue(optin.key, !values[optin.key])}
                      />
                    )
                  }}
                </Field>
              ))}
            </div>
          </FormInputInline>
        </div>
      </Card>
    </Container>
  )
}
