import React, { FC, useEffect, useState } from "react"
import CompaniesTable from "../components/companies/CompaniesTable"
import CenterSearch from "../components/companies/CenterSearch"
import { CentersSideMenu } from "../components/general/SideMenu"
import execute from "../api/api"
import { CentersInfoCount } from "../components/centers/CentersType"
import { Company } from "../components/companies/CompaniesType"
import { useTranslation } from "react-i18next"
import { Color } from "../utils/Constants"
import { addActiveCalls, subtractActiveCalls } from "../store/LoadingStore"
import { useTitle } from "../utils/hooks/useTitle"
import { useFetchCentersInUserScope } from "../utils/hooks/centers/useFetchCentersInUserScope"

const Companies: FC = () => {
  const { vipCentersInUserScope } = useFetchCentersInUserScope()
  const [refresh, setRefresh] = useState(0)
  const [searchCenter, setSearchCenter] = useState<string>()
  const [centersCompaniesCount, setCentersCompaniesCount] = useState<CentersInfoCount>({})
  const [centerId, setCenterId] = useState(-1)
  const [companies, setCompanies] = useState<Company[]>([])
  const { t } = useTranslation()

  // Dynamically update browser tab title
  useTitle(`${t("menu.companies")} | ${t("applicationName")}`)

  useEffect(() => {
    // We set the first center as the selected center when we fetch them
    if (vipCentersInUserScope.length > 0) {
      setCenterId(vipCentersInUserScope[0].id)
    }
  }, [vipCentersInUserScope])

  useEffect(() => {
    execute<Company[]>("companies", "GET").then((companies) => {
      const centersCompaniesCount: CentersInfoCount = {}
      companies.forEach((company) => {
        if (centersCompaniesCount[company.centerId]) {
          centersCompaniesCount[company.centerId]++
        } else {
          centersCompaniesCount[company.centerId] = 1
        }
      })
      setCentersCompaniesCount(centersCompaniesCount)
    })
  }, [refresh])

  useEffect(() => {
    if (centerId !== -1) {
      addActiveCalls()
      execute<Company[]>(`companies/${centerId}`, "GET")
        .then((resCompanies) => setCompanies(resCompanies))
        .finally(subtractActiveCalls)
    }
  }, [centerId, refresh])

  return (
    <>
      <div className={`w-full h-2 ${Color.COMPANIES}`} />
      <div className="h-auto flex flex-col xl:flex-row divide-x divide-x-4 ">
        <>
          {vipCentersInUserScope.length > 1 && (
            <CentersSideMenu
              centerId={centerId}
              centers={vipCentersInUserScope}
              centersInfoCount={centersCompaniesCount}
              searchCenter={searchCenter}
              setSearchCenter={setSearchCenter}
              setCenterId={setCenterId}
            />
          )}
          {vipCentersInUserScope.length > 1 && (
            <CenterSearch centers={vipCentersInUserScope} centerId={centerId} setCenterId={setCenterId} />
          )}
        </>
        {centerId && (
          <div className="flex flex-col flex-grow-1 h-full">
            <CompaniesTable centerId={centerId} companies={companies} refresh={() => setRefresh(refresh + 1)} />
          </div>
        )}
      </div>
    </>
  )
}

export default Companies
