import React, { FC } from "react"
import { tagsConnexion, tagsOrigins, tagsOthers, tagsStatus, tagsType, tagsTypology } from "../../utils/Constants"
import { CentersWS } from "../centers/CentersType"
import MultipleSelect from "../MultipleSelect"
import Select from "../Select"
import { useTranslation } from "react-i18next"
import { useStore } from "effector-react"
import {
  FilterMemberStore,
  resetFiltersMemberStore,
  setConnexion,
  setOrigin,
  setOthers,
  setStatus,
  setType,
  setTypology,
} from "../../store/FilterMemberStore"
import CentersByCountryDropdown from "../centers/CentersByCountryDropdown"

type PropsType = {
  centersByCountry: CentersWS[]
  selectedCenters: CentersWS
  setSelectedCenters: (center: CentersWS) => void
}

const MembersFiltersTags: FC<PropsType> = ({ centersByCountry, selectedCenters, setSelectedCenters }: PropsType) => {
  const { t } = useTranslation()
  const activeFilter = useStore(FilterMemberStore)

  const resetFilters = () => {
    resetFiltersMemberStore()
  }

  return (
    <div className="space-x-3">
      <div className="flex items-center justify-center flex-wrap gap-2 md:gap-4">
        <CentersByCountryDropdown
          centersByCountry={centersByCountry}
          selectedCenters={selectedCenters}
          setSelectedCenters={setSelectedCenters}
        />
        <div className="w-full md:w-64">
          <p className="text-xs text-left pl-2 pb-1 cursor-default">{t("members.tags.titleGroup.status")}</p>
          <Select
            value={activeFilter.status}
            onChange={(event) => setStatus(event.target.value)}
            values={tagsStatus}
            defaultValue={t("generic.forms.selectDefaultValue")}
            defaultValueDisabled
          />
        </div>

        <div className="w-full md:w-64">
          <p className="text-xs text-left pl-2 pb-1 cursor-default">{t("members.tags.titleGroup.type")}</p>
          <Select
            value={activeFilter.type}
            onChange={(event) => setType(event.target.value)}
            values={tagsType}
            defaultValue={t("generic.forms.selectDefaultValue")}
            defaultValueDisabled
          />
        </div>

        <div className="w-full md:w-64">
          <p className="text-xs text-left pl-2 pb-1 cursor-default">{t("members.tags.titleGroup.typology")}</p>
          <Select
            value={activeFilter.typology}
            onChange={(event) => setTypology(event.target.value)}
            values={tagsTypology}
            defaultValue={t("generic.forms.selectDefaultValue")}
            defaultValueDisabled
          />
        </div>

        <div className="w-full md:w-64">
          <p className="text-xs text-left pl-2 pb-1 cursor-default">{t("members.tags.titleGroup.connexion")}</p>
          <Select
            value={activeFilter.connexion}
            onChange={(event) => {
              setConnexion(event.target.value)
            }}
            values={tagsConnexion}
            defaultValue={t("generic.forms.selectDefaultValue")}
            defaultValueDisabled
          />
        </div>
        <div className="w-full md:w-64">
          <p className="text-xs text-left pl-2 pb-1 cursor-default">{t("members.tags.titleGroup.origin")}</p>
          <div className="flex flex-row items-center">
            <MultipleSelect
              options={tagsOrigins}
              placeholder={t("generic.forms.selectDefaultValue")}
              values={activeFilter.origin}
              colorRef="color"
              iconRef="icon"
              field="origin"
              setFieldValue={(event, newValues) => setOrigin(newValues)}
              className="bg-white border-2"
            />
          </div>
        </div>
        <div className="w-full md:w-64">
          <p className="text-xs text-left pl-2 pb-1 cursor-default">{t("members.tags.titleGroup.others")}</p>
          <div className="flex flex-row items-center">
            <MultipleSelect
              options={tagsOthers}
              placeholder={t("generic.forms.selectDefaultValue")}
              values={activeFilter.others}
              colorRef="color"
              iconRef="icon"
              field="others"
              setFieldValue={(event, newValues) => setOthers(newValues)}
              className="bg-white border-2"
            />
            {activeFilter.unlessOneFilter && (
              <div className="p-1 bg-red-500 rounded-full ml-2">
                <i className="icon-cancel cursor-pointer text-white" onClick={resetFilters} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MembersFiltersTags
