import { useTranslation } from "react-i18next"
import { useEffect } from "react"

export const useTitle = (title: string): void => {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = title
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])
}
