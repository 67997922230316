import React, { FC, useEffect, useState } from "react"
import ObjectiveCharts from "./ObjectiveCharts"
import { useTranslation } from "react-i18next"
import { useStore } from "effector-react"
import { execute } from "../../../api/api"
import { getQuarter } from "date-fns"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { numberWithThousandsSeparator } from "../../../utils/Functions"

export type MoonObjective = {
  annualObjective: number
  periodCustomers: number
  quarterCustomers: number
  quarterObjective: number
}

export const InitialMoonObjective = {
  annualObjective: 0,
  periodCustomers: 0,
  quarterCustomers: 0,
  quarterObjective: 0,
}

type MoonChartsProps = {
  id: string
}
const MoonCharts: FC<MoonChartsProps> = (props: MoonChartsProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<MoonObjective>(InitialMoonObjective)

  const storeFilters = useStore(FilterStore)

  useEffect(() => {
    setLoading(true)
    execute<MoonObjective>(
      `dashboard/${id}/widget/moonObjective`,
      "GET",
      {},
      {},
      { filters: getDashboardFilters(storeFilters) },
    )
      .then((res) => {
        if (res) {
          setData(res)
        }
      })
      .finally(() => setLoading(false))
  }, [storeFilters, id])

  const percentToPrint = () => {
    if (
      data.quarterCustomers === undefined ||
      data.quarterObjective === undefined ||
      (data.quarterCustomers === 0 && data.quarterObjective === 0)
    ) {
      return 0.0
    }
    return (Math.round(((data.quarterCustomers * 100) / data.quarterObjective) * 100) / 100).toFixed(2)
  }

  return (
    <ObjectiveCharts
      title={t("dashboard.fields.moon.title")}
      subtitle={t("dashboard.fields.moon.objective", {
        value: numberWithThousandsSeparator(data.annualObjective),
      })}
      membersDone={t("dashboard.fields.moon.done", {
        value: numberWithThousandsSeparator(data.periodCustomers),
      })}
      objective={t("dashboard.fields.moon.qObjective", {
        qValue: getQuarter(new Date()),
      })}
      objective50Percent={t("dashboard.fields.moon.percentObjectiveWithNumber", {
        valuePercent: "50",
        valueMembers: numberWithThousandsSeparator(Math.round(data.quarterObjective / 2)),
      })}
      objectivePercent={
        data.quarterObjective >= 0
          ? t("dashboard.fields.moon.percentObjectiveWithNumber", {
              valuePercent: percentToPrint(),
              valueMembers: numberWithThousandsSeparator(data.quarterCustomers),
            })
          : t("dashboard.fields.moon.percentObjectiveWithNumberWithoutObjective", {
              qValue: getQuarter(new Date()),
              valueMembers: numberWithThousandsSeparator(data.quarterCustomers),
            })
      }
      valueObjectivePercent={Number(percentToPrint())}
      loading={loading}
      objectiveTotalNumbers={t("dashboard.fields.moon.objectiveNumber", {
        value: numberWithThousandsSeparator(data.quarterObjective),
      })}
    />
  )
}

export default MoonCharts
