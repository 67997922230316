import React from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"
import CustomModal from "../../CustomModal"
import { FormCancelButton } from "../../profile/FormSubmitButton"
import { LogsType } from "../../../api/MemberObject"

type DisplayLogModalProps = {
  log: LogsType
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DisplayLogModal: React.FC<DisplayLogModalProps> = (props: DisplayLogModalProps) => {
  const { log, open, setOpen } = props
  const { t } = useTranslation()
  const closeModal = () => {
    setOpen(false)
  }

  return (
    <CustomModal isOpen={open} onRequestClose={closeModal}>
      <h3 className="mb-4 text-center font-bold">{t("logs.table.information")}</h3>
      <div className="h-1 border-t-1 border-blueGray-200 mb-2" />
      <div className="lg:pl-8 pt-4 pb-8 h-full overflow-auto">
        <div>
          {t("generic.table.date")} : {format(new Date(log.date), t("generic.format.dateMinuteTime"))}
        </div>
        {Object.entries(log).map(function ([key, value], index) {
          key = key.replace(/(_\w)/g, (k) => k[1].toUpperCase()) // transforming snake_case to camelCase
          if (key.toString() !== "date")
            return (
              <div key={index}>
                {t(`logs.table.${key}`)} : {value}
              </div>
            )
          else return null
        })}
      </div>
      <div className="h-1 border-t-1 border-blueGray-200 m-2" />
      <div className="flex justify-end">
        <FormCancelButton onClick={closeModal}>{t("generic.buttons.cancel")}</FormCancelButton>
      </div>
    </CustomModal>
  )
}

export default DisplayLogModal
