import React, { FC, useEffect, useState } from "react"
import MembersFiltersTags from "../../components/members/MembersFiltersTags"
import MembersCard from "../../components/members/MembersCard"
import SearchBar from "../../components/SearchBar"
import { useHistory } from "react-router-dom"
import { execute } from "../../api/api"
import { MemberObject } from "../../api/MemberObject"
import { useTranslation } from "react-i18next"
import { initialMemberFilter, MemberFilter } from "./FilterObject"
import ReactTooltip from "react-tooltip"
import { Color, Tag, tagsConnexion } from "../../utils/Constants"
import { numberWithThousandsSeparator } from "../../utils/Functions"
import { useStore } from "effector-react"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useTitle } from "../../utils/hooks/useTitle"
import { FilterMemberStore } from "../../store/FilterMemberStore"
import { CentersWS } from "../../components/centers/CentersType"
import { useFetchCentersInUserScope } from "../../utils/hooks/centers/useFetchCentersInUserScope"

const PAGE_SIZE = 100

const getFilter = (activeMembersFilter: FilterMemberStore, selectedCenters: CentersWS) => {
  // On doit passer par JSON.parse(JSON.stringify(...)) car on souhaite une copie entière de l'objet pour ne récupérer
  // aucune référence. Le problème du {...obj} est qu'on récupère un nouvel objet mais les références de ses champs sont
  // toujours passées. Donc si const a = {foo: []} et que const b = {...a}, le champ "foo" de b et de a seront le même
  // tableau = push dans l'un fera push dans l'autre. Pareil pour les objets en propriété.
  const membersFilters: MemberFilter = JSON.parse(JSON.stringify(initialMemberFilter))
  // Origin filter
  if (activeMembersFilter.origin.length !== 0) {
    const tabCreateFrom: string[] = []
    activeMembersFilter.origin.forEach((element: Tag) => {
      element.filter?.forEach((element) => tabCreateFrom.push(element.value))
    })
    membersFilters.bool.must.push({
      terms: { "createdFrom.keyword": tabCreateFrom },
    })
  }

  // Others filter
  if (activeMembersFilter.others.length !== 0) {
    activeMembersFilter.others.forEach((element: Tag) => {
      element.filter?.forEach((element) =>
        membersFilters.bool[element.type].push({
          term: { [element.term!]: element.value },
        }),
      )
    })
  }

  // Status filter
  if (activeMembersFilter.status) {
    membersFilters.bool.must.push({
      term: { "type.keyword": activeMembersFilter.status },
    })
  }

  // Type filter
  if (activeMembersFilter.type) {
    switch (activeMembersFilter.type) {
      case "digital":
        membersFilters.bool.must.push({
          term: { isDigital: 1 },
        })
        break
      case "physical":
        membersFilters.bool.must.push({
          term: { isDigital: 0 },
        })
        break
    }
  }

  // Connexion filter
  if (activeMembersFilter.connexion) {
    if (activeMembersFilter.connexion === "unknown") {
      tagsConnexion
        .find((element) => element.key === "unknown")
        ?.filter?.forEach((elementFilter) =>
          membersFilters.bool.must_not.push({
            exists: { field: elementFilter.value },
          }),
        )
    } else {
      const field: Tag | undefined = tagsConnexion.find((element) => element.key === activeMembersFilter.connexion)
      if (field) {
        membersFilters.bool.must.push({
          exists: { field: field.filter![0].value },
        })
      } else {
        membersFilters.bool.must.push({
          exists: { field: activeMembersFilter.connexion },
        })
      }
    }
  }

  // Member Type filter
  if (activeMembersFilter.typology) {
    switch (activeMembersFilter.typology) {
      case "loyaltyMember":
        membersFilters.bool.should.push({
          bool: { must_not: [{ term: { type: "partial" } }, { exists: { field: "pasKey" } }] },
        })
        membersFilters.bool.should.push({
          bool: { must: [{ exists: { field: "pasKey" } }, { term: { isPremium: 1 } }] },
        })
        membersFilters.bool.minimum_should_match = 1
        break
      case "newsletterSubscriber":
        membersFilters.bool.should.push({ term: { type: "partial" } })
        membersFilters.bool.should.push({
          bool: { must: { exists: { field: "pasKey" } }, must_not: { term: { isPremium: 1 } } },
        })
        membersFilters.bool.minimum_should_match = 1
        break
    }
  }

  if (selectedCenters.centers.length > 0) {
    membersFilters.bool.must.push({
      terms: { centerId: selectedCenters.centers.map((center) => center.id.toString()) },
    })
  }

  return membersFilters
}

type FetchMembersProps = {
  from: number
  size: number
  filters: any
  input: string
}

type FetchMembersResult = {
  customers: MemberObject[]
  total: number
}

const fetchMembers = async ({ from, size, filters, input }: FetchMembersProps): Promise<FetchMembersResult> => {
  const res = await execute<FetchMembersResult>(
    `customers/search`,
    "GET",
    {},
    {},
    {
      size,
      page: from,
      filters,
      input,
    },
  )
  return res
}

const Members: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [nbMembers, setNbMembers] = useState(0)
  const [search, setSearchString] = useState<string>("")
  const [members, setMembers] = useState<MemberObject[]>([])
  const [deletedCustomerId, setDeletedCustomerId] = useState<number>()
  const activeMembersFilter = useStore(FilterMemberStore)
  const { activeCentersInUserScope, activeCentersInUserScopeByCountry } = useFetchCentersInUserScope()
  const [selectedCenters, setSelectedCenters] = useState<CentersWS>({ country: "", centers: [] })
  const [newMembersLength, setNewMembersLength] = useState(0)

  const refreshData = (deletedCustomerId: number) => {
    setDeletedCustomerId(deletedCustomerId)
  }

  const loadMembers = (from: number) => {
    addActiveCalls()
    const filters = getFilter(activeMembersFilter, selectedCenters)
    return fetchMembers({ from: from, size: PAGE_SIZE, filters, input: search })
      .then((res) => {
        if (res) {
          setNewMembersLength(res.customers.length)
          setMembers((members) => (from === 0 ? res.customers : members.concat(res.customers)))
          setNbMembers(res.total)
        }
      })
      .finally(() => {
        subtractActiveCalls()
      })
  }

  useEffect(() => {
    let isLoading = false
    function handleScroll() {
      if (
        !isLoading &&
        !(window.innerHeight + document.documentElement.scrollTop !== document.scrollingElement?.scrollHeight)
      ) {
        isLoading = true
        if (newMembersLength >= 100) {
          loadMembers(members.length).then(() => {
            isLoading = false
          })
        }
        isLoading = false
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [members, search, activeMembersFilter])

  // Dynamically update browser tab title
  useTitle(`${t("menu.customers")} | ${t("applicationName")}`)

  useEffect(() => {
    loadMembers(0)
  }, [search, activeMembersFilter, selectedCenters])

  return (
    <div>
      <div className="w-full h-16">
        <SearchBar
          barColor={Color.MEMBERS}
          searchAction={(searchInput: string) => {
            setSearchString(searchInput)
          }}
          resource="customers"
          addAction={() => history.push("/members/create")}
        />
      </div>
      <div className="p-8">
        <div className="bg-white p-5 rounded">
          <MembersFiltersTags
            centersByCountry={activeCentersInUserScopeByCountry}
            selectedCenters={selectedCenters}
            setSelectedCenters={setSelectedCenters}
          />
          <div className="mt-6 font-bold text-xl">
            {t("members.fields.total", {
              value: numberWithThousandsSeparator(nbMembers),
            })}
          </div>
        </div>
        <div className="flex justify-end items-center my-4">
          <ReactTooltip place="left" type="info" effect="solid" />
          <span
            className="icon icon-info text-blue-500 text-4xl cursor-pointer"
            data-tip={`${t("generic.fields.dateFormatLabel")} ${t("generic.format.date")}`}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {members.length === 0 ? (
            <div />
          ) : (
            members.map((member, index) => {
              // Because of the ES cache, data can be fetched before being refreshed. Therefore, we clean it with Javascript.
              if (member.id === deletedCustomerId) {
                return <></>
              }
              return (
                <MembersCard key={index} member={member} refreshData={refreshData} centers={activeCentersInUserScope} />
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}

export default Members
