import { useSnackbar as useRSSnackBar } from "react-simple-snackbar"
import { errorSnackbarStyle, successSnackbarStyle } from "../../components/snackbarStyles"

export const useCustomSnackBar = (type?: "error" | "success"): any[] => {
  const [successSnackbar] = useRSSnackBar({ style: successSnackbarStyle })
  const [errorSnackbar] = useRSSnackBar({ style: errorSnackbarStyle })

  switch (type) {
    case "error":
      return [errorSnackbar, null]
    case "success":
      return [successSnackbar, null]
    default:
      return [successSnackbar, errorSnackbar]
  }
}
