import * as React from "react"
import { useTranslation } from "react-i18next"
import { Field } from "formik"
import FormInputLayout from "../FormInputLayout"
import Select from "../Select"
import { CountryObjectWS } from "../../api/CountryObject"
import { FC } from "react"

type CountrySelectorProps = {
  countries: CountryObjectWS[]
  value?: string
  onChange: (event: any) => void
}
export const CountrySelector: FC<CountrySelectorProps> = ({ countries, value, onChange }: CountrySelectorProps) => {
  const { t } = useTranslation()

  return (
    <Field name="countryCode">
      {({ meta }: any) => (
        <FormInputLayout required meta={meta} label="centers.forms.fields.countryCode">
          <Select
            disabled={countries.length <= 1}
            value={value}
            onChange={onChange}
            large
            values={countries}
            keyRef="code"
            labelRef="code"
            defaultValue={t(`centers.forms.values.defaultValueCountry`)}
            defaultValueDisabled
            complementTranslation="country."
          />
        </FormInputLayout>
      )}
    </Field>
  )
}
