import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import { UserRole } from "../Roles"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { useTranslation } from "react-i18next"
import { useCustomSnackBar } from "./useCustomSnackBar"
import { useEffect, useState } from "react"

type SortFunctionType = (role1: UserRole, role2: UserRole) => number
const sortByLabel = (a: UserRole, b: UserRole) => a.label.localeCompare(b.label)

export const useFetchRoles = (sortFunction: SortFunctionType = sortByLabel): UserRole[] => {
  const { t } = useTranslation()
  const [roles, setRoles] = useState<UserRole[]>([])
  const [openErrorSnackbar] = useCustomSnackBar("error")

  useEffect(() => {
    addActiveCalls()
    execute<UserRole[]>("users/roles", "GET")
      .then((resRoles) => {
        const mappedRoles = resRoles.sort(sortFunction)
        setRoles(mappedRoles)
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return roles
}
