import React, { FC } from "react"
import CustomModal from "../CustomModal"
import { useTranslation } from "react-i18next"
import Header from "../Header"
import { Form, Formik } from "formik"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import InputField from "../general/InputField"
import FormActionButtons from "../general/FormActionButtons"
import { newRightInitialValue, newRightValidationSchema } from "./RoleFormConsts"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"

type RoleAddRightModalProps = {
  open: boolean
  setOpen: (newValue: boolean) => void
}

const RoleAddRightModal: FC<RoleAddRightModalProps> = ({ open, setOpen }: RoleAddRightModalProps) => {
  const { t } = useTranslation()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <div>
      <CustomModal isOpen={open} onRequestClose={closeModal}>
        <Header>{t("roles.forms.titles.addRight")}</Header>
        <hr />
        <Formik
          initialValues={newRightInitialValue}
          validationSchema={newRightValidationSchema}
          onSubmit={(values) => {
            addActiveCalls()
            execute(`users/roles/addRight`, "POST", {}, values)
              .then(() => {
                openSuccessSnackbar(
                  `${t("roles.snackbar.right")} ${t("generic.snackbar.success.create")}`,
                  snackbarDuration,
                )
                setOpen(false)
              })
              .catch(() => {
                openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
              })
              .finally(subtractActiveCalls)
          }}
        >
          <Form className="mt-8 text-left">
            <InputField
              isAutoFocus={true}
              fieldData={{
                formKey: "name",
                label: "generic.forms.fields.name",
                required: true,
              }}
            />
            <FormActionButtons cancelOnClick={closeModal} />
          </Form>
        </Formik>
      </CustomModal>
    </div>
  )
}

export default RoleAddRightModal
