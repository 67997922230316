import React, { FC } from "react"
import { useStore } from "effector-react"
import { useTranslation } from "react-i18next"
import { hasRight } from "../utils/Roles"
import { ContributorStore } from "../store/ContributorStore"

const getTab = (key: string, tabLength: number, style: string, label: string, onClick: () => void) => (
  <div
    key={key}
    className={`h-8 mx-2 rounded px-2 py-6 text-lg cursor-pointer flex flex-col justify-center cursor-pointer w-1/${tabLength} ${style}`}
    onClick={onClick}
  >
    {label}
  </div>
)

export type TabsValue = {
  name: string
  id: string
  order: number
  restrictedRole?: string
  displayUk?: boolean
  displayPartial?: boolean
}

const getTabStyle = (isSelectedTab: boolean) => {
  return isSelectedTab ? "bg-blueGray-700 text-white" : "bg-blueGray-100 bg-opacity-50 text-blueGray-700"
}

type TabsProps = {
  tabs: TabsValue[]
  selectedTab: string
  setSelectedTab: (value: string) => void
  className?: string
  isUKMember?: boolean
  isPartialMember?: boolean
}
export const Tabs: FC<TabsProps> = ({
  selectedTab,
  setSelectedTab,
  tabs,
  className,
  isUKMember,
  isPartialMember,
}: TabsProps) => {
  const { t } = useTranslation()
  const { permissions } = useStore(ContributorStore)
  const displayableTabs = tabs.filter(
    (tab) =>
      (!tab.restrictedRole || hasRight(permissions, tab.restrictedRole)) &&
      (!isUKMember || tab.displayUk) &&
      (!isPartialMember || tab.displayPartial),
  )

  return (
    <div className={className || "h-16 w-full flex flex-row"}>
      {displayableTabs.length === 1 ? (
        <div className="flex w-full items-center justify-center">
          <div className="flex px-10 py-3 bg-blueGray-700 text-white items-center justify-center rounded">
            {t(displayableTabs[0]?.name)}
          </div>
        </div>
      ) : (
        <>
          {displayableTabs.map((tab) =>
            getTab(tab?.id, displayableTabs.length, getTabStyle(selectedTab === tab?.id), t(tab?.name), () =>
              setSelectedTab(tab.id),
            ),
          )}
        </>
      )}
    </div>
  )
}
