import React, { FC, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Color } from "../../utils/Constants"
import Container from "../general/Container"
import Card from "../members/Card"
import { Field, Form, Formik, FormikValues } from "formik"
import InputField from "../general/InputField"
import {
  OfferTypeType,
  OfferTypeWSType,
  RewardSettingsItemType,
  RewardSettingsWSType,
  StoresWSType,
  StoreType,
  TlpCentersWSType,
  TlpCenterType,
  TlpOfferWSType,
} from "./TlpOffersTypes"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import Select from "../Select"
import FormInputLayout from "../FormInputLayout"
import { CenterSelector } from "../centers/CenterSelector"
import { generalSettingsValidationSchema, getMinDate, offerSettingsValidationSchema } from "./TlpOfferFormConst"
import FormDateInput from "../general/FormDateInput"
import DaysMultipleSelect from "../general/DaysMultipleSelect"
import FormActionButtons from "../general/FormActionButtons"
import MultipleSelect, { OptionType } from "../MultipleSelect"
import BlocTitle from "./BlocTitle"
import ShowMoreButton from "./ShowMoreButton"
import RewardSettings from "./RewardSettings"
import CheckboxInput from "../general/CheckboxInput"
import ChangeStatusButton from "./ChangeStatusButton"
import { useFetchCentersInUserScope } from "../../utils/hooks/centers/useFetchCentersInUserScope"

type TlpOfferFormProps = {
  isCreate: boolean
  initialValues: FormikValues
  initialTlpOfferData?: FormikValues
}

const TlpOffersForm: FC<TlpOfferFormProps> = ({ isCreate, initialValues, initialTlpOfferData }: TlpOfferFormProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const { activePremiumCentersInUserScope } = useFetchCentersInUserScope()
  const [tlpOfferData, setTlpOfferData] = useState()
  const bottom = useRef<HTMLDivElement>(null)
  const top = useRef<HTMLDivElement>(null)
  const { id, email, assignedCenters } = useStore(ContributorStore)

  const [showOfferSettingsBloc, setShowOfferSettingsBloc] = useState<boolean>(!isCreate)
  const [showMoreOfferSettings, setShowMoreOfferSettings] = useState<boolean>(false)
  const [showRewardSettingsBloc, setShowRewardSettingsBloc] = useState<boolean>(!isCreate)
  const [showMoreRewardSettings, setShowMoreRewardSettings] = useState<boolean>(false)
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)
  const [hasMoreSettings, setHasMoreSettings] = useState<boolean>(false)

  const [stores, setStores] = useState<OptionType[]>([])
  const [offerTypes, setOfferTypes] = useState<OptionType[]>([])
  const [rewardSettingsItems, setRewardSettingsItems] = useState<RewardSettingsItemType[]>([])
  const [tlpCenters, setTlpCenters] = useState<TlpCenterType[]>([])

  const [centerId, setCenterId] = useState<string>(!isCreate && initialValues.generalSettings.centerId)
  const [currency, setCurrency] = useState<string>(initialValues.generalSettings.currency)
  const [offerType, setOfferType] = useState<string>(!isCreate && initialValues.offerSettings.offerType)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [offerStatus, setOfferStatus] = useState<string>()
  const [canActivate, setCanActivate] = useState<boolean>()

  useEffect(() => {
    addActiveCalls(2)
    execute<OfferTypeWSType>(`ui/enums/OfferItemTypeEnum`, "GET", {}, {}, { origin: "fid" }, "lpe")
      .then((res) => {
        if (res?.result) {
          setOfferTypes(
            res.result.map((offerType: OfferTypeType) => ({
              key: offerType.label,
              label: offerType.label,
            })),
          )
        }
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)

    execute<TlpCentersWSType>(`center`, "GET", {}, {}, {}, "lpe")
      .then((res) => {
        if (res?.result?.centers) {
          setTlpCenters(
            res.result.centers.map((center: TlpCenterType) => ({
              centerId: center.centerId,
              cashbackCurrency: center.cashbackCurrency,
            })),
          )
        }
      })
      .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
      .finally(subtractActiveCalls)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (centerId) {
      addActiveCalls()
      execute<StoresWSType>(`center/stores/${centerId}`, "GET", {}, {}, { origin: "fid" }, "lpe")
        .then((res) => {
          if (res?.result?.stores) {
            setStores(
              res.result.stores
                .map((store: StoreType) => ({
                  key: store.uniqueStoreId,
                  label: store.genericName,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            )
          }
        })
        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
        .finally(subtractActiveCalls)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centerId])

  useEffect(() => {
    if (offerType && centerId) {
      addActiveCalls()
      execute<RewardSettingsWSType>(
        `offers/builder/reward`,
        "GET",
        {},
        {},
        { offerType: offerType, centerId: centerId },
        "lpe",
      )
        .then((res) => {
          if (res?.result?.items) {
            setRewardSettingsItems(res.result.items)
            // Show "more settings" section only if the items list contain at least one hidden item
            setHasMoreSettings(res.result.items.some((item) => item.hidden))
          }
        })
        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
        .finally(subtractActiveCalls)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerType, centerId])

  return (
    <>
      <div className={`w-screen h-2 ${Color.TLPOFFERS}`} />
      <div className="pt-6">
        <div className="pb-8">
          <h3>{isCreate ? t("tlpOffers.headers.creation") : t("tlpOffers.headers.editing")}</h3>
        </div>

        <div ref={top} />

        {!isCreate && (
          <Container>
            <Card removePadding>
              <BlocTitle title={t("tlpOffers.headers.updateStatus")} />

              <div className="p-8">
                <div className="mb-6 w-full md:w-1/2 m-auto">
                  <p className="font-bold text-gray-500 inline">{t("tlpOffers.fields.status")} : </p>
                  <p className="inline">{offerStatus || initialValues.status}</p>
                </div>

                {errorMessage && <div className="p-8" dangerouslySetInnerHTML={{ __html: errorMessage }} />}

                <ChangeStatusButton
                  canActivate={canActivate !== undefined ? canActivate : initialValues.canActivate}
                  values={{ ...initialTlpOfferData, ...tlpOfferData }}
                  offerId={initialTlpOfferData?.id}
                  setErrorMessage={setErrorMessage}
                  setOfferStatus={setOfferStatus}
                  setCanActivate={setCanActivate}
                />
              </div>
            </Card>
          </Container>
        )}

        <Formik
          initialValues={initialValues.generalSettings}
          validationSchema={generalSettingsValidationSchema}
          onSubmit={(values) => {
            setTlpOfferData({
              ...initialTlpOfferData,
              ...tlpOfferData,
              ...values,
            })
            setShowOfferSettingsBloc(true)
            setCenterId(values.centerId)
            openSuccessSnackbar(`${t("tlpOffers.snackbar.validateGeneralSettings")}`, snackbarDuration)
            !isCreate && setShowSaveButton(true)
            bottom.current?.scrollIntoView()
          }}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Container>
                  <Card removePadding>
                    <BlocTitle title={t("tlpOffers.headers.generalSettings")} />

                    <div className="p-6">
                      <CenterSelector
                        isCreate={isCreate}
                        canUpdate
                        centers={activePremiumCentersInUserScope}
                        value={values.centerId}
                        fieldName="centerId"
                        label="tlpOffers.fields.center"
                        onChange={(event) => {
                          const center = tlpCenters.find((center) => center.centerId === event.target.value)
                          if (center) {
                            setFieldValue("centerId", event.target.value)
                            setCurrency(center.cashbackCurrency)
                          }
                        }}
                      />

                      <div className="mb-6 w-full md:w-1/2 m-auto text-left">
                        <p className="font-bold text-gray-500 inline">{t("tlpOffers.forms.fields.currency")} : </p>
                        <p className="inline">{currency}</p>
                      </div>

                      <InputField
                        fieldData={{
                          formKey: "name",
                          label: "tlpOffers.fields.offerName",
                          required: true,
                        }}
                      />

                      <FormActionButtons validateButtonLabel="tlpOffers.buttons.confirm" />
                    </div>
                  </Card>
                </Container>
              </Form>
            )
          }}
        </Formik>

        {showOfferSettingsBloc && (
          <Formik
            initialValues={initialValues.offerSettings}
            validationSchema={offerSettingsValidationSchema}
            onSubmit={(values) => {
              setTlpOfferData({
                ...initialTlpOfferData,
                ...tlpOfferData,
                ...values,
                recurringDays: values.recurringDays.map((day: any) => day.key),
                storesList: values.allStores
                  ? []
                  : values.storesList.map((store: any) => {
                      return {
                        uniqueStoreId: store.key,
                        genericName: store.label,
                      }
                    }),
              })
              setOfferType(values.offerType)
              setShowRewardSettingsBloc(true)
              openSuccessSnackbar(`${t("tlpOffers.snackbar.validateOfferSettings")}`, snackbarDuration)
              !isCreate && setShowSaveButton(true)
              bottom.current?.scrollIntoView()
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Container>
                    <Card removePadding>
                      <BlocTitle title={t("tlpOffers.headers.offerSettings")} />

                      <div className="p-6">
                        <Field name="offerType">
                          {({ meta }: any) => (
                            <FormInputLayout label="tlpOffers.fields.offerType" required meta={meta}>
                              <Select
                                onChange={(event) => setFieldValue("offerType", event.target.value)}
                                meta={meta}
                                value={values.offerType}
                                large
                                values={offerTypes}
                                defaultValue={t("generic.forms.selectDefaultValue")}
                                defaultValueDisabled
                              />
                            </FormInputLayout>
                          )}
                        </Field>

                        <FormDateInput
                          fieldData={{
                            formKey: "startDate",
                            label: "tlpOffers.forms.fields.startDate",
                            placeholder: t("generic.format.datePlaceholder"),
                            required: true,
                            minDate: getMinDate(),
                          }}
                        />

                        <FormDateInput
                          fieldData={{
                            formKey: "endDate",
                            label: "tlpOffers.forms.fields.endDate",
                            placeholder: t("generic.format.datePlaceholder"),
                            required: false,
                            minDate: getMinDate(),
                          }}
                        />

                        <ShowMoreButton showMore={showMoreOfferSettings} setShowMore={setShowMoreOfferSettings} />

                        {showMoreOfferSettings && (
                          <div>
                            <CheckboxInput
                              fieldData={{
                                formKey: "allStores",
                                required: false,
                                label: "tlpOffers.forms.fields.allStores",
                              }}
                            />

                            {!values.allStores && (
                              <Field name="storesList">
                                {({ field, meta }: any) => (
                                  <FormInputLayout label="tlpOffers.forms.fields.stores" required meta={meta}>
                                    <MultipleSelect
                                      options={stores}
                                      values={field.value}
                                      field="storesList"
                                      setFieldValue={setFieldValue}
                                      meta={meta}
                                      large
                                    />
                                  </FormInputLayout>
                                )}
                              </Field>
                            )}

                            <DaysMultipleSelect
                              setFieldValue={setFieldValue}
                              label="tlpOffers.forms.fields.days"
                              formKey="recurringDays"
                            />
                          </div>
                        )}

                        <FormActionButtons validateButtonLabel="tlpOffers.buttons.confirm" />
                      </div>
                    </Card>
                  </Container>
                </Form>
              )
            }}
          </Formik>
        )}

        {showRewardSettingsBloc && (
          <Formik
            initialValues={initialValues.rewardSettings}
            onSubmit={(values) => {
              setTlpOfferData({
                ...initialTlpOfferData,
                ...tlpOfferData,
                ...values,
              })
              openSuccessSnackbar(`${t("tlpOffers.snackbar.validateRewardSettings")}`, snackbarDuration)
              setShowSaveButton(true)
              bottom.current?.scrollIntoView()
            }}
          >
            {({ values }) => {
              return (
                <Form>
                  <Container>
                    <Card removePadding>
                      <BlocTitle title={t("tlpOffers.headers.rewardSettings")} />

                      <div className="p-8">
                        <RewardSettings rewardSettingsItems={rewardSettingsItems} values={values} />

                        {hasMoreSettings && (
                          <div>
                            <ShowMoreButton
                              showMore={showMoreRewardSettings}
                              setShowMore={setShowMoreRewardSettings}
                              fullSize
                            />

                            {showMoreRewardSettings && (
                              <RewardSettings
                                rewardSettingsItems={rewardSettingsItems}
                                values={values}
                                showHiddenParams
                              />
                            )}
                          </div>
                        )}

                        <FormActionButtons validateButtonLabel="tlpOffers.buttons.confirm" />
                      </div>
                    </Card>
                  </Container>
                </Form>
              )
            }}
          </Formik>
        )}

        {showSaveButton && (
          <Container>
            <Card>
              <FormActionButtons
                validateButtonLabel="tlpOffers.buttons.save"
                cancelButtonLabel="tlpOffers.buttons.cancel"
                validateOnClick={() => {
                  addActiveCalls()
                  if (isCreate) {
                    execute<TlpOfferWSType>(`offers`, "POST", {}, tlpOfferData, {}, "lpe")
                      .then((response) => {
                        execute<void>(
                          "fid",
                          "POST",
                          {},
                          {
                            centerId: assignedCenters[0]?.id,
                            code: "TlpOfferCreation_Dashboard",
                            content: "Creation of a Tlp Offer from dashboard",
                            application: "dashboard",
                            email: email,
                            customerId: id,
                          },
                          {},
                          "logger",
                        )
                        openSuccessSnackbar(
                          `${t("tlpOffers.snackbar.title")} ${t("generic.snackbar.success.create")}`,
                          snackbarDuration,
                        )
                        history.push(`/tlpOffers/${response.result.offer.id}/edit`)
                      })
                      .catch(() => {
                        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
                      })
                      .finally(subtractActiveCalls)
                  } else {
                    execute<TlpOfferWSType>(
                      `offers/${initialTlpOfferData?.id}`,
                      "PUT",
                      {},
                      { ...initialTlpOfferData, ...tlpOfferData },
                      {},
                      "lpe",
                    )
                      .then((response) => {
                        openSuccessSnackbar(
                          `${t("tlpOffers.snackbar.title")} ${t("generic.snackbar.success.edit")}`,
                          snackbarDuration,
                        )
                        setErrorMessage(response.result.errorMessage)
                      })
                      .catch((err) => {
                        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
                        setErrorMessage(err.response.data.message)
                      })
                      .finally(() => {
                        subtractActiveCalls()
                        top.current?.scrollIntoView()
                      })
                  }
                }}
                cancelOnClick={() => history.push("/tlpOffers")}
              />
            </Card>
          </Container>
        )}

        <div ref={bottom} />
      </div>
    </>
  )
}

export default TlpOffersForm
