import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import BarCharts from "./BarCharts"
import { useStore } from "effector-react"
import { execute } from "../../../api/api"
import { dataPieType } from "../../../api/ChartObject"
import { formatDataWithSlider } from "./UtilsBarChart"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"
import { Loader } from "../../Loader"

type CheckinsType = {
  name: string
  total: number
}

type CheckInsBarChartProps = {
  id: string
}
const CheckInsBarChart: React.FC<CheckInsBarChartProps> = (props: CheckInsBarChartProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<dataPieType[]>([])
  const [value, setValue] = useState(5) // valeur initial du filtre
  const [dataWithSlider, setDataWithSlider] = useState<dataPieType[]>([])

  const storeFilters = useStore(FilterStore)

  const inputProps = {
    value: value,
    min: 1,
    max: 15,
    onChange: (e: any) => setValue(e.target.value),
  }

  useEffect(() => {
    setLoading(true)
    execute<CheckinsType[]>(
      `dashboard/${id}/widget/topServices`,
      "GET",
      {},
      {},
      {
        filters: getDashboardFilters(storeFilters),
      },
    )
      .then((res) => {
        if (res) {
          setData(
            res.map((element) => {
              return { name: element.name, y: element.total }
            }),
          )
        }
      })
      .finally(() => setLoading(false))
  }, [storeFilters, id])

  useEffect(() => {
    setDataWithSlider(formatDataWithSlider(data, value, t))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, value]) //data as dependency because the first time we have no info in graph

  return loading ? (
    <Loader />
  ) : (
    <>
      {data.length > 0 && (
        <BarCharts
          title={t("dashboard.bar.checkIns.title")}
          data={dataWithSlider}
          headerText={t("dashboard.bar.checkIns.header")}
          xAxisLabel={t("dashboard.bar.checkIns.xAxis")}
          yAxisLabel={t("dashboard.bar.checkIns.yAxis")}
          inputProps={inputProps}
        />
      )}
    </>
  )
}

export default CheckInsBarChart
