import { FilterStore, setFromDate, setToDate } from "../../store/FilterStore"
import React from "react"
import { useStore } from "effector-react"
import { useTranslation } from "react-i18next"
import DateInput from "../../components/general/DateInput"

const HeaderDateFilter: React.FC = () => {
  const { t } = useTranslation()
  const filterStore = useStore(FilterStore)

  return (
    <div className="flex flex-col lg:flex-row items-center ">
      <div className="whitespace-no-wrap font-bold m-2">{t("dashboard.filters.date.from")}</div>
      <DateInput
        action={(newDate) => {
          setFromDate(newDate)
        }}
        value={filterStore.date.fromDateFilter}
        max={filterStore.date.toDateFilter}
        min="2007-06-01"
      />
      <div className="whitespace-no-wrap font-bold m-2">{t("dashboard.filters.date.to")}</div>
      <DateInput
        action={(newDate) => {
          setToDate(newDate)
        }}
        value={filterStore.date.toDateFilter}
        max={new Date().toISOString().substring(0, 10)} //TODAY
        min={filterStore.date.fromDateFilter}
      />
    </div>
  )
}

export default HeaderDateFilter
