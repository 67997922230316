import React, { FC } from "react"
import TableComponent, { TableCell, TableRow } from "../TableComponent"
import { ApiType, swaggerCols, swaggerRowsCols } from "./ApiGatewayConsts"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { BaseURL } from "../../api/api"

const renderApi = (t: TFunction, api: ApiType) => {
  return (
    <TableRow
      key={api.label}
      onClick={() => {
        const url = api.url
          ? (BaseURL + "").replace("loyalty.", "loyalty-preprod.") + api.url // Redirects to preprod for prod env
          : window.location.origin + "/swagger/" + api.id + "/index.html"
        const win = window.open(url, "_blank")
        win && win.focus()
      }}
    >
      <TableCell>{api.label}</TableCell>
    </TableRow>
  )
}

const renderTable = (t: TFunction, apis: ApiType[]) => (
  <TableComponent columnNames={swaggerCols(t)}>{apis.map((api: ApiType) => renderApi(t, api))}</TableComponent>
)

const SwaggerTab: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="w-full">
      <div className="p-8 flex flex-col justify-around overflow-auto">
        <div className="mb-4 w-full">{renderTable(t, swaggerRowsCols)}</div>
      </div>
    </div>
  )
}

export default SwaggerTab
