import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Color } from "../../utils/Constants"
import Container from "../../components/general/Container"
import Card from "../../components/members/Card"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { Formik } from "formik"
import { exportsFormInitialValues, exportsValidationSchema } from "./ExportsConst"
import { CustomerOrigin, ExportCheckType, ExportType } from "./ExportsType"
import FormInputLayout from "../../components/FormInputLayout"
import { useStore } from "effector-react"
import { FilterStore } from "../../store/FilterStore"
import { ContributorStore } from "../../store/ContributorStore"
import Button, { ButtonType } from "../../components/general/Button"
import axios from "axios"
import ExportsForm from "../../components/exports/ExportsForm"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"
import { useFetchCentersInUserScope } from "../../utils/hooks/centers/useFetchCentersInUserScope"

/*Check the export status used in ...*/
const checkExportStatus = (myExport: ExportCheckType, setExport: (state: ExportCheckType) => void) => {
  execute<ExportCheckType>(`exports/check?id=${myExport.id}&type=${myExport.type}`, "GET").then((exportRes) => {
    setExport(exportRes)
  })
}

const Exports: FC = () => {
  const { t } = useTranslation()
  const filters = useStore(FilterStore)
  const profile = useStore(ContributorStore)
  const { activeCentersInUserScope } = useFetchCentersInUserScope()
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const [myExport, setExport] = useState<ExportCheckType>()
  const [startCheckInterval, setStartCheckInterval] = useState(false)
  const [intervalCheck, setIntervalCheck] = useState<NodeJS.Timeout | undefined>()

  const downloadExport = () => {
    addActiveCalls()
    execute<{ url: string }>(myExport!.download!, "GET")
      .then(({ url }) => {
        return axios.request({
          url,
          responseType: "blob", // to get the download dialog,
        })
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        const filename = myExport!.filename ? myExport!.filename : "export.csv"
        link.setAttribute("download", filename)
        document.body.appendChild(link)
        link.click()
        setExport(undefined)
        setStartCheckInterval(false)
      })
      .catch((err) => {
        let errorMessage = snackbarErrorMessage
        if (err?.response?.data?.label) {
          errorMessage = err.response.data.label
        }
        openErrorSnackbar(t(errorMessage), snackbarDuration)
      })
      .finally(subtractActiveCalls)
  }

  // Dynamically update browser tab title
  useTitle(`${t("menu.exports")} | ${t("applicationName")}`)

  useEffect(() => {
    if (myExport?.status !== "RUNNING" && intervalCheck) {
      clearInterval(intervalCheck)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myExport])

  useEffect(() => {
    if (startCheckInterval) {
      const interval = setInterval(() => {
        if (myExport?.status === "RUNNING") {
          checkExportStatus(myExport, setExport)
        } else {
          clearInterval(interval)
        }
      }, 5000)
      setIntervalCheck(interval)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCheckInterval])

  return (
    <>
      <div className={`w-full h-2 ${Color.EXPORTS}`} />
      <div className="pt-6">
        <Container>
          <Card>
            <h3 className="mb-4">{t("exports.forms.title")}</h3>
            {myExport?.status && (
              <>
                <hr className="mb-8" />
                <div className="rounded p-2 bg-yellow-50 mb-2">
                  <label>{t("exports.fields.takeTime")}</label>
                </div>
                <div>
                  <FormInputLayout label="exports.forms.fields.status">
                    <div className="flex">
                      {myExport?.status}
                      {myExport?.status === "RUNNING" && (
                        <div className="animate-spin ml-2">
                          <i className="icon-loading" />
                        </div>
                      )}
                    </div>
                  </FormInputLayout>
                  {myExport?.filename && (
                    <FormInputLayout label="exports.forms.fields.filename">
                      <p>{myExport.filename}</p>
                    </FormInputLayout>
                  )}
                  {myExport?.download && (
                    <div className="flex justify-center">
                      <Button
                        type={ButtonType.SUCCESS}
                        label={t("exports.buttons.download")}
                        onClick={downloadExport}
                      />
                    </div>
                  )}
                </div>
                <hr className="my-8" />
              </>
            )}
            <Formik
              initialValues={{
                ...exportsFormInitialValues,
                startDate: filters.date.fromDateFilter,
                endDate: filters.date.toDateFilter,
              }}
              validationSchema={exportsValidationSchema}
              onSubmit={(values: ExportType) => {
                /**
                 * Converts formData:
                 *   - retrieves logged in user email
                 *   - converts MultipleSelect data
                 *   - removes unnecessary export properties to lighten the sent request
                 */
                const formData = {
                  ...values,
                  emailAddress: profile.email,
                  centers: undefined,
                  centersId: values.type !== "dashboard" ? values.centers.map((center) => center.id) : [],
                  origins:
                    values.type === "customers"
                      ? values.origins?.reduce(
                          (originsValue: string[], origin: CustomerOrigin) => [...originsValue, ...origin.id],
                          [],
                        )
                      : undefined,
                  optinCommercial: values.type === "customers" ? values.optinCommercial : undefined,
                  optinSMS: values.type === "customers" ? values.optinSMS : undefined,
                  checkIns: undefined,
                  checkInsId: values.type === "checkins" ? values.checkIns?.map((checkIn) => checkIn.id) : undefined,
                  centerOperations: undefined,
                  centerOperationsName:
                    values.type === "centerOperations"
                      ? values.centerOperations?.map((centerOperation) => centerOperation.gameName)
                      : undefined,
                }
                if (formData.type !== "customers") {
                  delete formData.origins
                  delete formData.optinCommercial
                  delete formData.optinSMS
                }

                setExport(undefined)
                setStartCheckInterval(false)
                addActiveCalls()
                execute<ExportCheckType>("exports/require", "POST", {}, formData)
                  .then((exportRes) => {
                    setExport(exportRes)
                    setStartCheckInterval(true)
                  })
                  .catch((err) => {
                    let errorMessage = snackbarErrorMessage
                    if (err?.response?.data?.label) {
                      errorMessage = err.response.data.label
                    }
                    openErrorSnackbar(t(errorMessage), snackbarDuration)
                  })
                  .finally(subtractActiveCalls)
              }}
            >
              {(props) => <ExportsForm centers={activeCentersInUserScope} {...props} />}
            </Formik>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Exports
