import axios from "axios"
import credentialsManager from "../utils/CredentialsManager"
import { LoginType } from "./LoginObject"
import { setUser, unsetUser } from "../store/ContributorStore"
import { hasJwtExpired } from "../utils/Jwt"

const MAX_TIMEOUT = 30_000 // 30s

type CODE = "network" | "server" | "client" | "session"

export const BaseURL = process.env.REACT_APP_API_GATEWAY_HOST

class ApiError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(message: string, code: CODE, data?: any) {
    super(message)
  }
}

type ApiLoginType = {
  token: string
}

type csrfType = {
  csrfToken: string
}

type LuxApiResponse = {
  data: Array<{ store_id: number; name: string }>
  meta: { page: number; page_count: number }
}

const requestApiGatewayJwt = (): Promise<string> => {
  const apiGatewayJwtWS = request<ApiLoginType>(
    "login",
    "POST",
    {},
    {
      username: credentialsManager.getApiGatewayUsername(),
      password: credentialsManager.getApiGatewayPassword(),
    },
  )
  return apiGatewayJwtWS.then((res: ApiLoginType) => {
    if (res.token) {
      credentialsManager.setApiGatewayToken(res.token)
    }
    return Promise.resolve(res.token)
  })
}

const automaticLogin = (headers: { [key: string]: string }, refreshToken: string): Promise<string> => {
  const automaticLoginWS = request<LoginType>(`dashboard/login/automatic`, "POST", headers, {
    token: refreshToken,
  })
  return automaticLoginWS.then((res: LoginType) => {
    if (res.tokens && res.tokens.accessToken) {
      const accessToken = res.tokens.accessToken
      credentialsManager.setDashboardAccessToken(accessToken)
      setUser(res.user)
      return Promise.resolve(accessToken)
    } else {
      return Promise.reject()
    }
  })
}

const requestCsrfToken = (headers: { [key: string]: string }): Promise<string> => {
  const csrfWS = request<csrfType>(`dashboard/csrf/token`, "GET", headers, {})
  return csrfWS.then((res: csrfType) => {
    if (res.csrfToken) {
      credentialsManager.setCsrfToken(res.csrfToken)
    }
    return Promise.resolve(res.csrfToken)
  })
}

const getApiHeaders = (omitDashboardJwt = false): Promise<{ [key: string]: string }> => {
  let apiGatewayJwtWS

  // Check if API Gateway token is undefined or expired
  const apiGatewayToken = credentialsManager.getApiGatewayToken()
  if (!apiGatewayToken || hasJwtExpired(apiGatewayToken)) {
    apiGatewayJwtWS = requestApiGatewayJwt()
  } else {
    apiGatewayJwtWS = Promise.resolve(apiGatewayToken)
  }
  return apiGatewayJwtWS.then((apiGatewayJwt: string): Promise<{ [key: string]: string }> => {
    const apiHeaders: { [key: string]: string } = {
      Authorization: apiGatewayJwt,
      "x-api-key": credentialsManager.getApiGatewayKey(),
    }
    if (omitDashboardJwt) {
      return Promise.resolve(apiHeaders)
    } else {
      let dashboardJwtWS

      // Check if Dashboard token is undefined or expired
      const accessToken = credentialsManager.getDashboardAccessToken()
      if (!accessToken || hasJwtExpired(accessToken)) {
        const refreshToken = credentialsManager.getDashboardRefreshToken()
        if (refreshToken) {
          dashboardJwtWS = automaticLogin(apiHeaders, refreshToken)
        } else {
          dashboardJwtWS = Promise.reject()
        }
      } else {
        dashboardJwtWS = Promise.resolve(accessToken)
      }
      return dashboardJwtWS
        .then((dashboardJwt: string) => {
          apiHeaders["x-access-token"] = dashboardJwt
          return Promise.resolve(apiHeaders)
        })
        .catch(() => {
          credentialsManager.eraseDashboardAccessToken()
          credentialsManager.eraseDashboardRefreshToken()
          unsetUser()
          return Promise.resolve(apiHeaders)
        })
    }
  })
}

const getCsrfHeaders = (apiHeaders: { [key: string]: string }): Promise<{ [key: string]: string | null }> => {
  let csrfWS

  // Check if csrf token is defined
  if (!credentialsManager.getCsrfToken()) {
    csrfWS = requestCsrfToken(apiHeaders)
  } else {
    csrfWS = Promise.resolve(credentialsManager.getCsrfToken())
  }
  return csrfWS.then((csrfToken: string | null) => {
    return {
      "xsrf-token": csrfToken,
    }
  })
}

const request = <T>(
  path: string,
  method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH",
  headers: { [key: string]: string } = {},
  body?: any,
  params?: any,
  withCredentials = true,
): Promise<T> => {
  const heads = {
    "content-type": "application/json",
    ...headers,
  }
  // cancel Token used for Timeout
  const cancelToken = axios.CancelToken.source()
  setTimeout(cancelToken.cancel, MAX_TIMEOUT)
  return axios
    .request<T>({
      cancelToken: cancelToken.token,
      url: `${BaseURL}${path}`,
      method: method || (body ? "POST" : "GET"),
      headers: heads,
      data: body ? JSON.stringify(body) : undefined,
      params: params,
      timeout: MAX_TIMEOUT,
      withCredentials: withCredentials,
    })
    .then((res) => {
      if (res.status === 401 || res.status === 403) {
        // Authentication or right error
        throw new ApiError("Expired session ?", "session", res.data)
      }
      if (res.status >= 400 && res.status < 500) {
        // Query Error
        throw new ApiError("Unable to query " + path + " : " + res.status + " / " + res.statusText, "client", res.data)
      }
      if (res.status !== 200 && res.status !== 201 && res.status !== 204) {
        // Technical error (Internal Server Error, ...)
        throw new ApiError("Unable to query " + path + " : " + res.status + " / " + res.statusText, "server", res.data)
      }
      return res.data
    })
}

const executeWithErrorHandling = <T>(
  path: string,
  method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH",
  headers: { [key: string]: string } = {},
  body?: { [key: string]: any },
  params?: { [key: string]: any },
  withCredentials = true,
): Promise<T> => {
  return request(path, method, headers, body, params, withCredentials)
    .then((res: any) => {
      return Promise.resolve(res)
    })
    .catch((err: any) => {
      // if Unauthorized, then we resolve as we will be automatically redirect to login page
      const res = err.response
      if (res && res.status === 401) {
        return Promise.resolve()
      } else {
        return Promise.reject(err)
      }
    })
}

export const execute = <T>(
  path: string,
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  headers: { [key: string]: string } = {},
  body?: { [key: string]: any },
  params?: { [key: string]: any },
  basePath = "dashboard",
): Promise<T> => {
  const dashboardPath = `${basePath}/${path}`
  const isLoginCall = path === "login"
  const isLoggerCall = basePath === "logger"
  const apiHeadersWS = getApiHeaders(isLoginCall || isLoggerCall)
  return apiHeadersWS.then((apiHeaders: any) => {
    headers = Object.assign(headers, apiHeaders)
    if (!isLoginCall && !isLoggerCall && method !== "GET") {
      const csrfHeadersWS = getCsrfHeaders(apiHeaders)
      return csrfHeadersWS.then((csrfHeaders: any) => {
        headers = Object.assign(headers, csrfHeaders)
        return executeWithErrorHandling(dashboardPath, method, headers, body, params)
      })
    } else {
      return executeWithErrorHandling(dashboardPath, method, headers, body, params, !(basePath === "logger"))
    }
  })
}

export const luxApi = (path: string, queryParams: { [key: string]: string | number }): Promise<LuxApiResponse> => {
  return axios
    .get(`${process.env.REACT_APP_URL_TO_LUX}${path}`, {
      params: {
        api_key: process.env.REACT_APP_LUX_API_KEY,
        ...queryParams,
      },
    })
    .then((data) => data.data)
    .catch((err) => err)
}

export default execute
