import { createEvent, createStore } from "effector"
import { Tag } from "../utils/Constants"

export type TypeFilterType = "digital" | "physical" | ""
export type ConnexionFilterType = "googleId" | "cognitoId" | "facebookId" | "unknown" | ""
export type StatusFilterType = "partial" | "full" | "pending" | ""
export type TypologyFilterType = "loyaltyMember" | "newsletterSubscriber" | ""

export type FilterMemberStore = {
  unlessOneFilter: boolean
  origin: Tag[]
  status: StatusFilterType
  type: TypeFilterType
  others: Tag[]
  connexion: ConnexionFilterType
  typology: TypologyFilterType
}

export const FilterMemberStoreInitial: FilterMemberStore = {
  unlessOneFilter: false,
  origin: [],
  status: "",
  type: "",
  others: [],
  connexion: "",
  typology: "",
}

export const setOrigin = createEvent<Tag[]>("SET_ORIGIN")
export const setStatus = createEvent<StatusFilterType>("SET_STATUS")
export const setType = createEvent<TypeFilterType>("SET_TYPE")
export const setOthers = createEvent<Tag[]>("SET_OTHERS")
export const setConnexion = createEvent<ConnexionFilterType>("SET_CONNEXION")
export const setTypology = createEvent<TypologyFilterType>("SET_TYPOLOGY")

export const resetFiltersMemberStore = createEvent("RESET")

export const FilterMemberStore = createStore<FilterMemberStore>(FilterMemberStoreInitial)
  .on(setOrigin, (state: FilterMemberStore, payload: Tag[]) => {
    return { ...state, origin: payload, unlessOneFilter: true }
  })
  .on(setStatus, (state: FilterMemberStore, payload: StatusFilterType) => {
    return { ...state, status: payload, unlessOneFilter: true }
  })
  .on(setType, (state: FilterMemberStore, payload: TypeFilterType) => {
    return { ...state, type: payload, unlessOneFilter: true }
  })
  .on(setOthers, (state: FilterMemberStore, payload: Tag[]) => {
    return { ...state, others: payload, unlessOneFilter: true }
  })
  .on(setConnexion, (state: FilterMemberStore, payload: ConnexionFilterType) => {
    return { ...state, connexion: payload, unlessOneFilter: true }
  })
  .on(setTypology, (state: FilterMemberStore, payload: TypologyFilterType) => {
    return { ...state, typology: payload, unlessOneFilter: true }
  })
  .reset(resetFiltersMemberStore)
