import React, { FC } from "react"
import { Loader } from "../../Loader"

type PropsType = {
  title: string
  subtitle?: string
  value: string
  bottomText?: string
  loading: boolean
}

const PercentCharts: FC<PropsType> = (props: PropsType) => {
  const { title, subtitle, value, bottomText, loading } = props

  return loading ? (
    <Loader />
  ) : (
    <div className="bg-white flex flex-col justify-between w-full pt-4">
      <div className="text-3xl">{title}</div>
      <div className="flex flex-col justify-center h-full">
        <div className="text-xl">{subtitle}</div>
        <div className="text-2xl">{value}</div>
      </div>
      {bottomText && <div className="bg-blueGray-100 b-0 w-full">{bottomText}</div>}
    </div>
  )
}

export default PercentCharts
