import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PercentCharts from "./PercentCharts"
import { useStore } from "effector-react"
import { execute } from "../../../api/api"
import { FilterStore } from "../../../store/FilterStore"
import { getDashboardFilters } from "../../../views/dashboard/ChartsUtils"

type EmailValidity = {
  objective: string
  period: string
}

const initialEmailValidity: EmailValidity = { objective: "0%", period: "0%" }

type EmailValidityPercentProps = {
  id: string
}
const EmailValidityPercent: FC<EmailValidityPercentProps> = (props: EmailValidityPercentProps) => {
  const { id } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<EmailValidity>(initialEmailValidity)

  const storeFilters = useStore(FilterStore)

  useEffect(() => {
    setLoading(true)
    execute(`dashboard/${id}/widget/emailValidity`, "GET", {}, {}, { filters: getDashboardFilters(storeFilters) })
      .then((res: any) => {
        if (res) {
          setData({
            objective: res.emailObjective,
            period: res.periodCustomers,
          })
        }
      })
      .finally(() => setLoading(false))
  }, [storeFilters, id])

  return (
    <PercentCharts
      title={t("dashboard.fields.emailValidity.title")}
      subtitle={t("dashboard.fields.emailValidity.subtitle", {
        value: data.objective,
      })}
      value={t("dashboard.fields.emailValidity.value", { value: data.period })}
      bottomText={t("dashboard.fields.emailValidity.bottomText")}
      loading={loading}
    />
  )
}

export default EmailValidityPercent
