import React, { useState, useRef } from "react"
import { SetFieldValueType } from "../utils/Types"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { useOutsideAlerter } from "../utils/Components"

const getSelectedOption = (
  t: TFunction,
  item: any,
  keyRef: string,
  labelRef: string,
  onCancel: () => void,
  colorRef?: string,
  iconRef?: string,
) => (
  <div
    key={item[keyRef]}
    className={`flex justify-center items-center m-1 font-medium py-1 px-2 ${
      colorRef ? `${item[colorRef]} text-white` : "bg-white text-gray-700"
    } rounded-lg border border-teal-300 w-full`}
  >
    {iconRef && (
      <div>
        <i className={item[iconRef]} />
      </div>
    )}
    <div className="text-xs font-normal w-full truncate">{t(item[labelRef])}</div>
    <div className="flex flex-auto flex-row-reverse">
      <i className="icon-cancel cursor-pointer" onClick={onCancel} />
    </div>
  </div>
)

const getOption = (
  t: TFunction,
  option: any,
  keyRef: string,
  labelRef: string,
  onClick: () => void,
  selected: boolean,
  onCancel?: () => void,
) => (
  <div
    key={option[keyRef]}
    className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
    onClick={selected ? onCancel : onClick}
  >
    <div className={`flex w-full p-2 hover:bg-blueGray-50 ${selected ? "bg-blueGray-50" : ""}`}>
      <div className="w-full items-center flex text-left">
        <div className="mx-2 leading-6 w-3/4">{t(option[labelRef])}</div>
        {selected && <i className="icon-cancel cursor-pointer w-1/4 text-right" onClick={onCancel} />}
      </div>
    </div>
  </div>
)

export type OptionType = { key: string | number; label: string }
type MultipleSelectProps = {
  options: any[]
  placeholder?: string
  keyRef?: string
  labelRef?: string
  values: any[]
  field: string
  setFieldValue: SetFieldValueType
  meta?: any
  className?: string
  colorRef?: string
  iconRef?: string
  large?: boolean
}

const MultipleSelect: React.FC<MultipleSelectProps> = ({
  options,
  placeholder,
  keyRef = "key",
  labelRef = "label",
  values,
  field,
  setFieldValue,
  meta,
  className,
  colorRef,
  iconRef,
  large,
}: MultipleSelectProps) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>("")
  const [focus, setFocus] = useState<boolean>(false)
  const refDropdown = useRef(null)
  const refInput = useRef(null)

  const addOption = (option: any) => () => {
    const newSelected = [...values, option]
    setFieldValue(field, newSelected)
    setSearch("")
  }

  const deleteOption = (item: any) => {
    const selectedValues = values.filter((value) => value[keyRef] !== item[keyRef])
    setFieldValue(field, selectedValues)
  }

  useOutsideAlerter(refInput, setFocus, "mousedown", refDropdown)

  return (
    <div className={`w-full ${large ? "md:w-full" : "md:w-64"} flex flex-col items-center`}>
      <div className="w-full flex flex-col items-center relative">
        <div className="w-full">
          <div
            ref={refInput}
            onFocus={() => setFocus(true)}
            className={`p-1 flex  ${meta && meta.error && meta.touched ? "border-red-500" : "border-gray-200"}  ${
              className ? className : "border-2 rounded my-2"
            } rounded`}
          >
            <div className="flex flex-auto flex-nowrap h-9 overflow-hidden content-center w-full">
              {values.map((item) =>
                getSelectedOption(t, item, keyRef, labelRef, () => deleteOption(item), colorRef, iconRef),
              )}
              <div className="flex-1">
                <input
                  placeholder={values.length === 0 ? placeholder : ""}
                  value={search}
                  className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                  onChange={(event) => setSearch(event.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-row">
              <div className="text-gray-500 w-8 py-1 pl-2 pr-1 border-r flex items-center border-gray-200">
                {values.length}
              </div>
              <div className="text-gray-300 w-8 py-1 pl-2 pr-1 flex items-center">
                <i className="icon-down cursor-pointer text-black text-lg" onClick={() => setFocus(true)} />
              </div>
            </div>
          </div>
        </div>
        <div
          ref={refDropdown}
          className={`absolute shadow bg-white z-40 w-full lef-0 rounded overflow-y-auto ${focus ? "" : "hidden"}`}
          style={{
            maxHeight: 200,
            top: "100%",
          }}
        >
          <div className="flex flex-col w-full" ref={refDropdown}>
            {options.map((option) => {
              const isSelected = values.find((selectedOption) => selectedOption[keyRef] === option[keyRef])
              return (
                t(option[labelRef]).toLowerCase().includes(search.toLowerCase()) &&
                getOption(t, option, keyRef, labelRef, addOption(option), isSelected, () => deleteOption(option))
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultipleSelect
