import React from "react"
import execute from "../../api/api"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import ContributorsForm from "../../components/contributors/ContributorsForm"
import { initValues, mainContributorsFormValidations } from "../../components/contributors/ContributorsFormConsts"
import { snackbarDuration, snackbarErrorMessage } from "../../components/snackbarStyles"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"

const ContributorsCreate: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  // Dynamically update browser tab title
  useTitle(`${t("menu.newUser")} | ${t("applicationName")}`)

  return (
    <ContributorsForm
      isCreate
      initialValues={initValues}
      validationSchema={mainContributorsFormValidations}
      onSubmit={(values, selectedRole) => {
        addActiveCalls()
        const formData = {
          ...values,
          assignedCenters: !selectedRole
            ? []
            : selectedRole.linkedTo === "center"
            ? [values.assignedCenters[0].id]
            : selectedRole.linkedTo === "centers"
            ? values.assignedCenters.map((center) => center.id)
            : [],
          countries: !selectedRole
            ? []
            : selectedRole.linkedTo === "countries"
            ? values.countries.map((center) => center.code)
            : [],
        }

        execute("users", "POST", {}, formData)
          .then(() => {
            openSuccessSnackbar(
              `${t("contributors.snackbar.title")} ${t("generic.snackbar.success.create")}`,
              snackbarDuration,
            )
            history.push("/contributors")
          })
          .catch((err) =>
            openErrorSnackbar(
              t("generic.forms.errors." + err?.response?.data?.label || snackbarErrorMessage),
              snackbarDuration,
            ),
          )
          .finally(subtractActiveCalls)
      }}
    />
  )
}

export default ContributorsCreate
