import { CenterTypeWS } from "../../centers/CentersType"
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Company } from "../../companies/CompaniesType"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import { execute } from "../../../api/api"
import Container from "../../general/Container"
import Card from "../Card"
import { Field } from "formik"
import { workNearMallOptions } from "./MembersFormConsts"
import RadioOption from "../../general/RadioOption"
import Select from "../../Select"
import { SetFieldValueType } from "../../../utils/Types"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import FormInputInline from "../../FormInputInline"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"

type WorkNearMallSectionProps = {
  primaryCenter: CenterTypeWS
  isVIP: boolean
  setFieldValue: SetFieldValueType
}
export const WorkNearMallSection: React.FC<WorkNearMallSectionProps> = ({
  primaryCenter,
  isVIP,
  setFieldValue,
}: WorkNearMallSectionProps) => {
  const { t } = useTranslation()
  // On utilise ce state dans le cas où l'utilisateur change continuellement le "isVIP" de "oui" à "non" et inversement.
  // Comme ça, on n'effectue pas le fetch des companies à chaque changement de "isVIP".
  const [prevPrimaryCenterId, setPrevPrimaryCenterId] = useState<number>()
  const [companies, setCompanies] = useState<Company[]>([])
  const [openErrorSnackbar] = useCustomSnackBar("error")

  useEffect(() => {
    if (isVIP && primaryCenter.id !== prevPrimaryCenterId) {
      addActiveCalls()
      execute<Company[]>(`companies/${primaryCenter.id}`, "GET")
        .then((resCompanies) => {
          setCompanies(resCompanies)
          setPrevPrimaryCenterId(primaryCenter.id)
        })
        .catch(() => {
          openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
        })
        .finally(subtractActiveCalls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVIP, prevPrimaryCenterId, primaryCenter])

  return (
    <Container>
      <Card>
        <div className="pr-0 lg:pl-32">
          <Field name="isVIP">
            {({ field, meta }: any) => (
              <div>
                <FormInputInline label="members.forms.fields.workNearMall" required meta={meta}>
                  <div className="h-10 w-full flex flex-row justify-center md:justify-start space-x-4">
                    {workNearMallOptions.map((option) => (
                      <RadioOption
                        key={option.label}
                        label={t(option.label)}
                        isActive={option.key === field.value}
                        onClick={() => setFieldValue("isVIP", option.key)}
                        meta={meta}
                      />
                    ))}
                  </div>
                </FormInputInline>
                {field.value && primaryCenter?.hasVIP && (
                  <Field name="companyName">
                    {({ field: fieldCompanyName, meta }: any) => (
                      <FormInputInline label="members.forms.fields.selectCompany" required meta={meta}>
                        <Select
                          value={fieldCompanyName.value}
                          onChange={(event) => setFieldValue("companyName", event.target.value)}
                          values={companies}
                          keyRef="id"
                          valueRef="name"
                          labelRef="name"
                          defaultValue={t("members.fields.disabledCompany")}
                          defaultValueDisabled
                        />
                      </FormInputInline>
                    )}
                  </Field>
                )}
              </div>
            )}
          </Field>
        </div>
      </Card>
    </Container>
  )
}
