import React, { FC, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { CentersWS, CenterTypeWS } from "./CentersType"
import FlagIcon from "../../styles/customIcons/FlagIcon"
import { useOutsideAlerter } from "../../utils/Components"

type CountryCentersDropDownProps = {
  countryCenters: CentersWS
  setSelectedCenters: (center: CentersWS) => void
  setOpen: (open: boolean) => void
}

const CountryCentersDropDown: FC<CountryCentersDropDownProps> = ({
  countryCenters,
  setSelectedCenters,
  setOpen,
}: CountryCentersDropDownProps) => {
  const { t } = useTranslation()
  const [centers, setCenters] = useState<CenterTypeWS[]>([])
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const refCountry = useRef(null)
  useOutsideAlerter(refCountry, setIsSubMenuOpen, "click")

  return (
    <div className="ml-3 md-3 cursor-pointer hover:rounded hover:bg-gray-100 text-left">
      <div
        key={countryCenters.country}
        className="flex flex-row justify-between items-center"
        ref={refCountry}
        onClick={() => {
          setCenters(countryCenters.centers)
          setIsSubMenuOpen((prev) => !prev)
        }}
      >
        <div className="block px-4 py-2 text-gray-700">
          <FlagIcon country={countryCenters.country} />
          {t(`country.${countryCenters.country}`)}
        </div>
        <i
          className={`icon-down cursor-pointer text-black text-lg ${
            isSubMenuOpen ? "transform rotate-180" : "transform rotate-0"
          }`}
        />
      </div>
      {isSubMenuOpen && (
        <div className="py-1 bg-white" role="menu">
          <div
            key="all"
            onClick={() => {
              setSelectedCenters({ country: centers[0]?.countryCode, centers: centers })
              setOpen(false)
            }}
          >
            <div
              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
              role="menuitem"
              key="all"
            >
              {t("generic.forms.fields.allCenters")}
            </div>
          </div>
          {centers.map((center) => (
            <div
              key={center.name}
              onClick={() => {
                setSelectedCenters({ country: "", centers: [center] })
                setOpen(false)
              }}
            >
              <div
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                role="menuitem"
                key={center.name}
              >
                {center.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

type PropsType = {
  centersByCountry: CentersWS[]
  selectedCenters: CentersWS
  setSelectedCenters: (center: CentersWS) => void
}

const CentersByCountryMobileDropDown: FC<PropsType> = ({
  centersByCountry,
  selectedCenters,
  setSelectedCenters,
}: PropsType) => {
  const [isOpen, setOpen] = useState(false)
  const { t } = useTranslation()

  const refSelectElement = useRef(null)
  useOutsideAlerter(refSelectElement, setOpen, "click")

  return (
    <div className="origin-left relative cursor-pointer" ref={refSelectElement}>
      <div className={`py-1 px-1 w-full bg-blueGray-50 rounded bg-white border-2 border-gray-200 py-2 block md:hidden`}>
        <div onClick={() => setOpen((prev) => !prev)} className="flex flex-row font-medium items-center m-auto">
          <div className="w-full text-left pl-3">
            {selectedCenters.country.length > 0 && <FlagIcon country={selectedCenters.country} />}
            {selectedCenters.centers.length === 0
              ? t("dashboard.filters.country.all")
              : selectedCenters.country.length > 0
              ? t("generic.forms.fields.allCenters")
              : selectedCenters.centers[0].name}
          </div>
          <div className="text-right">
            <i className="icon-down cursor-pointer m-auto text-black text-lg" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="origin-top-left absolute left-0 mt-2 w-full rounded shadow-md z-20">
          <div className="py-1 rounded-md bg-white shadow-xs" role="menu">
            <div key="all" className="ml-3 md-3 cursor-pointer text-left">
              <div>
                <div
                  onClick={() => {
                    setOpen(!isOpen)
                    setSelectedCenters({ country: "", centers: [] })
                  }}
                  className="block py-2 pl-6 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  role="menuitem"
                  key="all"
                >
                  {t("dashboard.filters.country.all")}
                </div>
              </div>
            </div>
            {centersByCountry.map((countryCenters, index) => (
              <CountryCentersDropDown
                key={index}
                countryCenters={countryCenters}
                setSelectedCenters={setSelectedCenters}
                setOpen={setOpen}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default CentersByCountryMobileDropDown
