import { createEvent, createStore } from "effector"
import { ApiKeysTypeWS } from "./ApiKeysTypes"

export type ApiKeysStoreType = {
  apiKeys: ApiKeysTypeWS[]
}

export const ApiKeysStoreInitial: ApiKeysStoreType = {
  apiKeys: [],
}

export const setApiKeys = createEvent<ApiKeysTypeWS[]>("SET_API_KEYS")

export const ApiKeysStore = createStore<ApiKeysStoreType>(ApiKeysStoreInitial).on(
  setApiKeys,
  (state: ApiKeysStoreType, payload: ApiKeysTypeWS[]) => {
    return { apiKeys: payload }
  },
)
