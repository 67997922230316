import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Highcharts from "highcharts"
import { IconType } from "../DashboardConstant"
import ConvertedPremium from "../../../components/dashboard/premiumCharts/ConvertedPremium"
import PaymentMethodGraph from "../../../components/dashboard/premiumCharts/PaymentMethodGraph"
import VerticalBarCharts from "../../../components/dashboard/verticalBarChart/VerticalBarChart"
import InfoCharts from "./InfoCharts"
import { getChartOptions, getTLPDashboardFilters, tlpDashboardMultipleFilters } from "../ChartsUtils"
import HeaderDateFilter from "../HeaderDateFilter"
import Icon from "../../../components/dashboard/Icon"
import { useStore } from "effector-react"
import { execute } from "../../../api/api"
import { FilterStore, resetFiltersStore, setAllDate } from "../../../store/FilterStore"
import { Filter, getIconsFilters, resetFilter } from "../Dashboard"
import ConversionDate from "./ConversionDate"
import { GenderStatsType } from "../DashboardTypes"
import { Color } from "../../../utils/Constants"
import BreadcrumbCountries from "../../../components/dashboard/BreadcrumbCountries"
import { useParams } from "react-router-dom"
import OriginPremiumGraph from "../../../components/dashboard/premiumCharts/OriginPremiumGraph"
import { useTitle } from "../../../utils/hooks/useTitle"
import { useFetchAllCenters } from "../../../utils/hooks/centers/useFetchAllCenters"

const PremiumDashboard: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { countryCode, centerId } = useParams<{
    countryCode: string
    centerId: string
  }>()
  const requestsId = centerId || countryCode || "all"

  const [genderStats, setGenderStats] = useState<GenderStatsType>()
  const filtersStore = useStore(FilterStore)
  const { allCenters, allActivePremiumCentersByCountry } = useFetchAllCenters()

  const iconFilters = getIconsFilters(filtersStore, () => resetFilter(), false, genderStats)
  const selectedCenter = allCenters.find((center) => center.id.toString() === centerId)
  const tlpMultipleFilters = tlpDashboardMultipleFilters(
    filtersStore.premium.convertedMembers,
    filtersStore.premium.paymentMethod,
  )

  useEffect(() => {
    Highcharts.setOptions(getChartOptions(t))
  }, [i18n.language, t])

  useEffect(() => {
    const dateFilter = filtersStore.date
    resetFiltersStore()
    setAllDate(dateFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetching gender data
  useEffect(() => {
    execute<GenderStatsType>(
      `dashboard/${requestsId}/widget/gender`,
      "GET",
      {},
      {},
      { filters: getTLPDashboardFilters(filtersStore) },
    ).then((res) => setGenderStats(res))
  }, [filtersStore, requestsId])

  // Dynamically update browser tab title
  useTitle(`${t("menu.premiumDashboard")} | ${t("applicationName")}`)

  return (
    <div className="bg-blueGray-50">
      <div className={`w-full h-2 ${Color.PREMIUM}`} />
      <BreadcrumbCountries
        premium
        countries={allActivePremiumCentersByCountry}
        countryCode={countryCode}
        centerId={centerId}
      />
      <div className="flex flex-col justify-center bg-white py-2 mx-8 rounded-sm ">
        <div className="flex flex-col lg:flex-row justify-center">
          <HeaderDateFilter />
          <div className="hidden lg:flex flex-row text-xl">
            {iconFilters.map((icon: IconType, index) => (
              <Icon
                key={index}
                label={icon.label}
                title={t(icon.title)}
                onClick={icon.action}
                value={icon.value}
                isClicked={icon.isClicked}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-center">
          {tlpMultipleFilters.map((multipleFilter, index) => (
            <Filter multipleFilter={multipleFilter} t={t} key={`premium${index}`} storeFilters={filtersStore} />
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 p-8" key="premiumDashboard">
        {selectedCenter && <ConversionDate center={selectedCenter} />}
        <InfoCharts id={requestsId} />
        <VerticalBarCharts id={requestsId} className="lg:col-span-2 row-span-1" />
        <ConvertedPremium id={requestsId} />
        {/* PremiumNewGraph */}
        <OriginPremiumGraph
          id={requestsId}
          wsEndpoint="upgradedNew"
          subtitle={t("premiumDashboard.pie.origin.subtitle.new")}
        />
        {/* PremiumConvertedGraph */}
        <OriginPremiumGraph
          id={requestsId}
          wsEndpoint="upgradedConverted"
          subtitle={t("premiumDashboard.pie.origin.subtitle.converted")}
        />
        <PaymentMethodGraph id={requestsId} />
      </div>
    </div>
  )
}

export default PremiumDashboard
