import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { CentersWS, CenterTypeWS } from "../../../components/centers/CentersType"
import { useCustomSnackBar } from "../useCustomSnackBar"
import { useStore } from "effector-react"
import { ContributorStore } from "../../../store/ContributorStore"
import { hasRight } from "../../Roles"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import { execute } from "../../../api/api"
import { CountryObject } from "../../../api/CenterObject"
import { snackbarDuration, snackbarErrorMessage } from "../../../components/snackbarStyles"
import {
  getActiveCenters,
  getActiveCentersByCountry,
  getActivePremiumCentersByCountry,
  getCentersList,
} from "../../Centers"

export const useFetchAllCenters = (): {
  allCenters: CenterTypeWS[]
  allActiveCenters: CenterTypeWS[]
  allActiveCentersByCountry: CentersWS[]
  allActivePremiumCentersByCountry: CentersWS[]
} => {
  const { t } = useTranslation()
  const [openErrorSnackbar] = useCustomSnackBar("error")
  const { permissions } = useStore(ContributorStore)

  const [allCenters, setAllCenters] = useState<CenterTypeWS[]>([])
  const [allActiveCenters, setAllActiveCenters] = useState<CenterTypeWS[]>([])
  const [allActiveCentersByCountry, setAllActiveCentersByCountry] = useState<CentersWS[]>([])
  const [allActivePremiumCentersByCountry, setAllActivePremiumCentersByCountry] = useState<CentersWS[]>([])

  useEffect(() => {
    if (hasRight(permissions, "dashboard.read")) {
      addActiveCalls()
      execute<CountryObject[]>("dashboard/centers", "GET")
        .then((res) => {
          if (res) {
            setAllCenters(getCentersList(res))
            setAllActiveCenters(getActiveCenters(res))
            setAllActiveCentersByCountry(getActiveCentersByCountry(res))
            setAllActivePremiumCentersByCountry(getActivePremiumCentersByCountry(res))
          }
        })
        .catch(() => openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration))
        .finally(subtractActiveCalls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { allCenters, allActiveCenters, allActiveCentersByCountry, allActivePremiumCentersByCountry }
}
