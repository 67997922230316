import { t } from "i18next"
import React, { useState } from "react"
import { MemberObject } from "../../api/MemberObject"
import { ActionIcon } from "../ActionIcon"
import CustomModal from "../CustomModal"
import ClaimForm from "./ClaimForm"
import { initialValues } from "./ClaimFormConsts"

type ClaimModalProps = {
  member: MemberObject
}

export const ClaimModal: React.FC<ClaimModalProps> = ({ member }: ClaimModalProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const initialEmail = { ...initialValues, phone: member.phone ? member.phone : undefined }

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <ActionIcon
        icon="icon-chat-empty"
        iconColor="text-3xl text-tags-premium"
        title={t("claim.titles.icon")}
        onClick={() => setOpenModal(true)}
      />
      {openModal && (
        <CustomModal isOpen={openModal}>
          <ClaimForm initialValues={initialEmail} member={member} closeModal={closeModal} />
        </CustomModal>
      )}
    </>
  )
}
