import React, { FC } from "react"
import { ButtonType } from "../general/Button"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import execute from "../../api/api"
import { TlpOfferWSType } from "./TlpOffersTypes"
import { snackbarDuration, snackbarErrorMessage } from "../snackbarStyles"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import Button from "../general/Button"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTranslation } from "react-i18next"
import { FormikValues } from "formik"
import { Status } from "./TlpOfferFormConst"

type ChangeStatusButtonProps = {
  canActivate: boolean
  values?: FormikValues
  offerId: string
  setErrorMessage: (message: string) => void
  setOfferStatus: (status: string) => void
  setCanActivate: (value: boolean) => void
}

const ChangeStatusButton: FC<ChangeStatusButtonProps> = ({
  canActivate,
  values,
  offerId,
  setErrorMessage,
  setOfferStatus,
  setCanActivate,
}: ChangeStatusButtonProps) => {
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const { id, email, assignedCenters } = useStore(ContributorStore)
  const { t } = useTranslation()

  return (
    <Button
      label={canActivate ? t("tlpOffers.buttons.activate") : t("tlpOffers.buttons.deactivate")}
      type={canActivate ? ButtonType.SUCCESS : ButtonType.NEUTRAL}
      classNames="m-auto"
      onClick={() => {
        addActiveCalls()
        execute<TlpOfferWSType>(
          `offers/${offerId}`,
          "PUT",
          {},
          { ...values, status: canActivate ? Status.ACTIVE : Status.INACTIVE },
          {},
          "lpe",
        )
          .then((response) => {
            execute<void>(
              "fid",
              "POST",
              {},
              {
                centerId: assignedCenters[0]?.id,
                code: "TlpOfferModification_Dashboard",
                content: "Modification of a Tlp Offer from dashboard",
                application: "dashboard",
                email: email,
                customerId: id,
              },
              {},
              "logger",
            )
            openSuccessSnackbar(
              `${t("tlpOffers.snackbar.title")} ${t("generic.snackbar.success.edit")}`,
              snackbarDuration,
            )
            setOfferStatus(response.result.offer.status)
            setCanActivate(response.result.offer.canActivate)
            setErrorMessage("")
          })
          .catch((err) => {
            openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
            setErrorMessage(err.response.data.message)
          })
          .finally(subtractActiveCalls)
      }}
    />
  )
}

export default ChangeStatusButton
