import React from "react"

type ChipProps = {
  key: string
  title?: string
  label: string
  color?: string
  icon?: string
  onDelete?: (event: any) => void
  style?: string
  onClick?: (event: any) => void
}

const Chip: React.FC<ChipProps> = ({ title, label, color, icon, onDelete, onClick, style }: ChipProps) => {
  return (
    <span
      className={`${color} mx-1 no-wrap inline-block rounded-lg px-3 py-1 text-sm font-semibold my-1 text-white cursor-default ${style}`}
      title={title}
      onClick={onClick}
    >
      {icon !== "" ? <i className={`mx-auto mr-1 text-sm ${icon}`} /> : ""}
      {label}
      {onDelete && (
        <span className="pl-4 cursor-pointer" onClick={onDelete}>
          &times;
        </span>
      )}
    </span>
  )
}

export default Chip
