import { createEvent, createStore } from "effector"

export type Credentials = {
  apiGatewayUsername: string
  apiGatewayPassword: string
  apiGatewayJwt: string
  apiGatewayKey: string
  dashboardAccessJwt: string
  dashboardRefreshJwt: string
  csrfToken: string
}

export const CredentialsInitial: Credentials = {
  apiGatewayUsername: "orchestrator", // todo: use the right user (by env)
  apiGatewayPassword: "Azerty123!",
  apiGatewayJwt: "",
  apiGatewayKey: "Nihk04fYYOmuGXN7vxSu52RYY5d7Cfb8HW8qoZC2",
  dashboardAccessJwt: "",
  dashboardRefreshJwt: "",
  csrfToken: "",
}

export const setApiGatewayJwt = createEvent<string>("SET_API_GATEWAY_JWT")
export const setApiGatewayKey = createEvent<string>("SET_API_GATEWAY_KEY")
export const setDashboardAccessJwt = createEvent<string>("SET_DASHBOARD_ACCESS_JWT")
export const setDashboardRefreshJwt = createEvent<string>("SET_DASHBOARD_REFRESH_JWT")
export const setCSRFToken = createEvent<string>("SET_CSRF_TOKEN")
export const resetCredentialsStore = createEvent("RESET")

export const CredentialsStore = createStore<Credentials>(CredentialsInitial)
  .on(setApiGatewayJwt, (state: Credentials, payload: string) => {
    return { ...state, apiGatewayJwt: payload }
  })
  .on(setApiGatewayKey, (state: Credentials, payload: string) => {
    return { ...state, apiGatewayKey: payload }
  })
  .on(setDashboardAccessJwt, (state: Credentials, payload: string) => {
    return { ...state, dashboardAccessJwt: payload }
  })
  .on(setDashboardRefreshJwt, (state: Credentials, payload: string) => {
    return { ...state, dashboardRefreshJwt: payload }
  })
  .on(setCSRFToken, (state: Credentials, payload: string) => {
    return { ...state, csrfToken: payload }
  })
  .reset(resetCredentialsStore)
