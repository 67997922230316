import jwt_decode from "jwt-decode"

export type decodedJwtType = {
  id: number
  username?: string
  role?: string
  type: "dashboard" | "refresh"
  iat: number
  exp?: number
  iss?: string
}

export const hasJwtExpired = (jwt: string | null): boolean => {
  if (!jwt) {
    return true
  }
  const decodedJwt: decodedJwtType = jwt_decode(jwt)

  // Check if token is expired (minus 1 second for call delay)
  return (decodedJwt.exp! - 1) * 1000 < Date.now()
}
