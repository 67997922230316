import React, { FC, useState } from "react"
import MenuItemMobile from "./MenuItemMobile"
import NavBarParameters from "../NavBarParameters"
import { useMenu } from "../../../utils/hooks/useMenu"

const NavBarMobile: FC = () => {
  const [openMenuMobile, setOpenMenuMobile] = useState<boolean>(false)
  const permittedMenu = useMenu()

  return (
    <nav className="bg-blueGray-700 w-full mx-auto px-4 sm:px-6 lg:px-8 xl:px-2">
      <div className="flex flex-row items-center">
        <div className="block md:hidden h-full flex items-center">
          <button
            onClick={() => setOpenMenuMobile((prev) => !prev)}
            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
          >
            <svg className=" h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <div className="flex flex-row-reverse flex-grow">
          <NavBarParameters />
        </div>
      </div>
      {openMenuMobile && (
        <div>
          {permittedMenu.map((menuItem) => (
            <MenuItemMobile menuItem={menuItem} setMenuOpened={setOpenMenuMobile} key={`navItem${menuItem.label}`} />
          ))}
        </div>
      )}
    </nav>
  )
}

export default NavBarMobile
