import React, { FC, useEffect, useState, useMemo } from "react"
import Container from "../../components/general/Container"
import Card from "../../components/members/Card"
import { FastField, Field, Form, Formik, FormikValues } from "formik"
import FormInputLayout from "../../components/FormInputLayout"
import { useTranslation } from "react-i18next"
import Select from "../../components/Select"
import { useHistory } from "react-router-dom"
import MultipleSelect, { OptionType } from "../../components/MultipleSelect"
import { categories, checkInFormValidationSchema, getPeriods } from "./CheckInFormConsts"
import FormDateInput from "../../components/general/FormDateInput"
import FormActionButtons from "../../components/general/FormActionButtons"
import CheckboxInput from "../../components/general/CheckboxInput"
import { Checkin, CheckinTargetType, CheckinThemeType } from "../../components/checkin/CheckInType"
import InputField from "../../components/general/InputField"
import execute, { luxApi } from "../../api/api"
import { Color } from "../../utils/Constants"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import ColorPicker from "../../components/general/ColorPicker"
import DaysMultipleSelect from "../../components/general/DaysMultipleSelect"
import { useStore } from "effector-react"
import { ContributorStore } from "../../store/ContributorStore"
import { useFetchAllCenters } from "../../utils/hooks/centers/useFetchAllCenters"

type CheckInsFormProps = {
  title: string
  initialValues: Checkin
  centerId: number
  onSubmit: (formData: FormikValues) => void
}

const CheckInForm: FC<CheckInsFormProps> = ({ title, initialValues, centerId, onSubmit }: CheckInsFormProps) => {
  const user = useStore(ContributorStore)
  const { t } = useTranslation()
  const history = useHistory()
  const [stores, setStores] = useState<OptionType[]>([])
  const [servicesThemes, setServicesThemes] = useState<CheckinThemeType[]>([])
  const [eventsThemes, setEventsThemes] = useState<CheckinThemeType[]>([])
  const [targets, setTargets] = useState<CheckinTargetType[]>([])
  const { allCenters } = useFetchAllCenters()
  const currentCenterData = useMemo(() => allCenters.find((center) => center.id === centerId), [allCenters, centerId])

  useEffect(() => {
    addActiveCalls(2)
    getStores()

    async function getStores() {
      const stores: OptionType[] = []
      const centerIdOrProtocol = centerId > 4999 ? currentCenterData?.protocol : centerId
      const getPageStore = async (centerIdOrProtocol: number | string, page: number): Promise<void> => {
        try {
          const { data, meta } = await luxApi("stores", {
            fields: "store_id,name",
            centre_id: centerIdOrProtocol,
            page: page,
            per_page: 200,
          })
          stores.push(
            ...data.map((store: { store_id: number; name: string }) => ({
              key: store.store_id,
              label: store.name,
            })),
          )
          if (meta.page < meta["page_count"]) {
            await getPageStore(centerIdOrProtocol, meta.page + 1)
          }
        } finally {
          subtractActiveCalls()
        }
        setStores(stores)
      }

      if (!centerIdOrProtocol) {
        return
      }

      await getPageStore(centerIdOrProtocol, 1)
    }

    execute<CheckinThemeType[]>(`checkins/themes`, "GET")
      .then((res) => {
        setServicesThemes(
          res
            .filter((theme) => theme.category === "service")
            .map((theme) => {
              return {
                ...theme,
                label: `checkins.forms.values.themes.${theme.value}`,
              }
            }),
        )
        setEventsThemes(
          res
            .filter((theme) => theme.category === "event")
            .map((theme) => {
              return {
                ...theme,
                label: `checkins.forms.values.themes.${theme.value}`,
              }
            }),
        )
      })
      .finally(subtractActiveCalls)

    execute<CheckinTargetType[]>(`checkins/targets`, "GET")
      .then((res) => {
        setTargets(
          res.map((target) => {
            return {
              ...target,
              label: `checkins.forms.values.targets.${target.value}`,
            }
          }),
        )
      })
      .finally(subtractActiveCalls)
  }, [centerId, currentCenterData])

  return (
    <div>
      <div className={`w-screen h-2 ${Color.CHECKINS}`} />
      <div className="pt-6">
        <Container>
          <Card>
            <h3 className="mb-4">{title}</h3>
            <Formik
              initialValues={{
                ...initialValues,
                targets:
                  initialValues.targets && initialValues.targets.length > 0
                    ? initialValues.targets
                    : targets.filter((target) => target.value === "n/a"),
              }}
              enableReinitialize
              validationSchema={checkInFormValidationSchema}
              onSubmit={(values) => {
                const formData = {
                  ...values,
                  useRate: `${values.freqQuantity}/${values.freqPeriod.toUpperCase()}`,
                  freqQuantity: undefined,
                  freqPeriod: undefined,
                  stores: values.stores.map((store) => {
                    return {
                      id: store.key,
                      name: store.label,
                    }
                  }),
                  startDate: values.startDate ? values.startDate : undefined,
                  endDate: values.endDate ? values.endDate : undefined,
                }
                onSubmit(formData)
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <Field name="category">
                    {({ meta }: any) => (
                      <FormInputLayout label="checkins.forms.fields.category" required meta={meta}>
                        <Select
                          onChange={(event) => setFieldValue("category", event.target.value)}
                          meta={meta}
                          value={values.category}
                          large
                          values={categories}
                          keyRef="value"
                          defaultValue={`--- ${t("checkins.forms.values.defaultValueCategory")} ---`}
                          defaultValueDisabled
                        />
                      </FormInputLayout>
                    )}
                  </Field>

                  {values.category && (
                    <div>
                      <Field name="themes">
                        {({ field, meta }: any) => {
                          return (
                            <FormInputLayout label="checkins.forms.fields.themes" required meta={meta}>
                              <MultipleSelect
                                options={values.category === "service" ? servicesThemes : eventsThemes}
                                keyRef="value"
                                values={field.value}
                                field="themes"
                                setFieldValue={setFieldValue}
                                meta={meta}
                                large
                              />
                            </FormInputLayout>
                          )
                        }}
                      </Field>
                      <Field name="targets">
                        {({ field, meta }: any) => (
                          <FormInputLayout label="checkins.forms.fields.targets" required meta={meta}>
                            <MultipleSelect
                              options={targets}
                              keyRef="value"
                              values={field.value}
                              field="targets"
                              setFieldValue={setFieldValue}
                              meta={meta}
                              large
                            />
                          </FormInputLayout>
                        )}
                      </Field>
                    </div>
                  )}

                  <InputField
                    fieldData={{
                      formKey: "name",
                      label: "generic.forms.fields.name",
                      required: true,
                    }}
                  />

                  <Field name="freqQuantity">
                    {({ field, meta }: any) => (
                      <FormInputLayout required meta={meta} label="checkins.forms.fields.freq">
                        {values.freqPeriod && values.freqPeriod !== "u" && (
                          <>
                            <input
                              type="number"
                              min={1}
                              className={`bg-gray-200 appearance-none border-2 w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-blueGray-800
                ${
                  meta && meta.touched && meta.error ? " border-red-500 text-red-700" : " border-gray-200 text-gray-700"
                }
              `}
                              {...field}
                            />

                            <span className="mx-4 text-2xl">{"/"}</span>
                          </>
                        )}
                        <Select
                          value={values.freqPeriod}
                          onChange={(event) => setFieldValue("freqPeriod", event.target.value)}
                          large
                          values={getPeriods(t)}
                          defaultValueDisabled
                        />
                      </FormInputLayout>
                    )}
                  </Field>

                  <DaysMultipleSelect
                    setFieldValue={setFieldValue}
                    label="checkins.forms.fields.days"
                    required
                    formKey="daysOfUse"
                  />

                  <CheckboxInput
                    fieldData={{
                      formKey: "isPremium",
                      required: false,
                      label: "checkins.forms.fields.isPremium",
                    }}
                  />

                  <Field name="stores">
                    {({ field, meta }: any) => (
                      <FormInputLayout label="checkins.forms.fields.stores" meta={meta}>
                        <MultipleSelect
                          options={stores}
                          values={field.value}
                          field="stores"
                          setFieldValue={setFieldValue}
                          meta={meta}
                          large
                        />
                      </FormInputLayout>
                    )}
                  </Field>

                  <FormDateInput
                    fieldData={{
                      formKey: "startDate",
                      label: "checkins.forms.fields.from",
                      placeholder: t("generic.format.datePlaceholder"),
                      required: false,
                    }}
                  />
                  <FormDateInput
                    fieldData={{
                      formKey: "endDate",
                      label: "checkins.forms.fields.to",
                      placeholder: t("generic.format.datePlaceholder"),
                      required: false,
                    }}
                  />
                  <FastField name="colorCode">
                    {({ field, meta }: any) => (
                      <ColorPicker value={field.value} meta={meta} setFieldValue={setFieldValue} />
                    )}
                  </FastField>
                  {(user.role === "ROLE_SUPER_ADMIN" ||
                    user.role === "ROLE_ADMIN" ||
                    user.role === "ROLE_AFTER_SALES_SERVICE") && (
                    <CheckboxInput
                      fieldData={{
                        formKey: "undeletable",
                        required: false,
                        label: "checkins.forms.fields.undeletable",
                      }}
                    />
                  )}

                  <FormActionButtons cancelOnClick={() => history.push("/checkins")} />
                </Form>
              )}
            </Formik>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default CheckInForm
