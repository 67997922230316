import React, { CSSProperties, FC } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik, FormikValues } from "formik"
import { execute } from "../../api/api"
import { LoginType } from "../../api/LoginObject"
import { addActiveCalls, subtractActiveCalls } from "../../store/LoadingStore"
import { useCustomSnackBar } from "../../utils/hooks/useCustomSnackBar"
import { useTitle } from "../../utils/hooks/useTitle"
import { useHistory, useLocation } from "react-router-dom"
import { resetPasswordValidationSchema } from "../../components/login/LoginFormConsts"
import InputFieldLogin from "../../components/general/InputFieldLogin"
import InputFieldLoginPassword from "../../components/general/InputFieldLoginPassword"
import credentialsManager from "../../utils/CredentialsManager"
import { setUser } from "../../store/ContributorStore"

const ResetPassword: FC = () => {
  const { t } = useTranslation()
  const location: any = useLocation()
  const history = useHistory()

  // Dynamically update browser tab title
  useTitle(`${t("menu.resetPassword")} | ${t("applicationName")}`)

  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  const loginStyle: CSSProperties = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("/images/login_background.jpg")`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: "center",
    backgroundSize: `cover`,
  }

  const automaticLogin = (username: string, password: string) => {
    addActiveCalls()
    const body = {
      username: username,
      password: password,
    }
    execute<LoginType>("login", "POST", {}, body)
      .then((res: LoginType) => {
        if (res) {
          credentialsManager.setDashboardAccessToken(res.tokens.accessToken)
          setUser(res.user)
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          history.push("/")
          openErrorSnackbar(t("login.errors.credentials"))
        }
      })
      .finally(subtractActiveCalls)
  }

  const handleSubmit = (values: FormikValues): Promise<void> => {
    addActiveCalls()
    const body = {
      username: values.username,
      password: values.password,
      confirmationCode: values.confirmationCode,
    }
    const loginWS = execute<LoginType>("resetPassword", "POST", {}, body)
    return loginWS
      .then(() => {
        openSuccessSnackbar(t("login.snackbar.successResetPassword"))
        automaticLogin(values.username, values.password)
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) openErrorSnackbar(t("login.errors.invalidCode"))
          else openErrorSnackbar(t("generic.snackbar.error"))
        }
      })
      .finally(subtractActiveCalls)
  }

  return (
    <div className="h-screen font-sans bg-cover" style={loginStyle}>
      <div className="container mx-auto h-full flex flex-1 justify-center items-center">
        <div className="w-full max-w-md">
          <div className="leading-loose">
            <Formik
              initialValues={{ username: location.state?.username, password: "", rememberMe: false }}
              validationSchema={resetPasswordValidationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="m-4 p-3 bg-white rounded shadow-xl">
                <p className="text-2xl pb-4">{t("login.buttons.resetPassword")}</p>
                <div>
                  <InputFieldLogin
                    fieldData={{
                      formKey: "username",
                      label: t("generic.forms.fields.username"),
                      required: true,
                    }}
                  />
                  <InputFieldLogin
                    fieldData={{
                      formKey: "confirmationCode",
                      label: t("login.forms.fields.confirmationCode"),
                      required: true,
                    }}
                  />
                  <InputFieldLoginPassword
                    fieldData={{
                      formKey: "password",
                      label: t("generic.forms.fields.password"),
                      required: true,
                    }}
                  />
                  <InputFieldLoginPassword
                    fieldData={{
                      formKey: "confirmPassword",
                      label: t("login.forms.fields.confirmPassword"),
                      required: true,
                    }}
                  />
                  <div className="mt-2 items-center flex flex-col">
                    <button
                      className="px-3 py-1 text-white font-light tracking-wider bg-gray-900 hover:bg-gray-800 rounded"
                      type="submit"
                    >
                      {t("login.buttons.resetPassword")}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
