import { TagFilterCondition } from "../views/members/FilterObject"
import { menuElement } from "./Types"

export enum Color {
  HOME = "bg-transparent",
  MEMBERS = "bg-menu-blue",
  CONTRIBUTORS = "bg-menu-darkRed",
  DASHBOARD = "bg-menu-purple",
  PREMIUM = "bg-menu-darkGreen",
  CHECKINS = "bg-menu-lightBlue",
  TLPOFFERS = "bg-menu-yellow",
  APIGATEWAY = "bg-menu-lightRed",
  COMPANIES = "bg-menu-orange",
  EXPORTS = "bg-menu-red",
  CENTERS = "bg-menu-green",
  RIGHTS = "bg-menu-brown",
  CIA = "bg-menu-darkBlue",
  CHANGELOG = "bg-menu-pink",
  PROFILE = "bg-menu-base",
  TLPSUPPORT = "bg-menu-green",
}

export enum ColorGraphs {
  green = "#90ed7d",
  blue = "#7cb5ec",
  red = "#f15c80",
  orange = "#f7a35c",
  purple = "#8c42f4",
  teal = "#00bfb8",
  yellow = "#FFDF00",
  magenta = "#ff00ff",
  tangerine = "#E78A61",
  blueOrchid = "#1F45FC",
  black = "#434348",
  darkBlack = "#000000",
  lime = "#00FF00",
  platinum = "#E5E4E2",
}

export enum ColorDetailsTOE {
  red = "bg-detailsCashback-red",
  purple = "bg-detailsCashback-purple",
  teal = "bg-detailsCashback-teal",
  default = "bg-gray-400",
}

export const dateMiniForm = "1920-01-01"

export const dateMaxBirthday = (): string => {
  const dateBirthday = new Date()
  dateBirthday.setFullYear(dateBirthday.getFullYear() - 16)
  dateBirthday.setDate(dateBirthday.getDate() - 1)
  return dateBirthday.toISOString().substring(0, 10)
}

export const menu: menuElement[] = [
  {
    path: "/home",
    icon: "icon-home",
    label: "menu.home",
    color: Color.HOME,
    right: true,
    shouldShowInNav: true,
    shouldShowInHome: false,
    inLoyaltyApp: true,
  },
  {
    path: "/members",
    icon: "icon-member-card",
    label: "menu.customers",
    color: Color.MEMBERS,
    right: "customers.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: true,
  },
  {
    path: "/contributors",
    icon: "icon-organization",
    label: "menu.users",
    color: Color.CONTRIBUTORS,
    right: "users.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
  {
    path: "/dashboard/cockpit",
    icon: "icon-chart",
    label: "menu.dashboard",
    color: Color.DASHBOARD,
    right: "cockpit.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    basePath: "/dashboard",
    inLoyaltyApp: false,
  },
  {
    path: "/premiumDashboard/cockpit",
    icon: "icon-credit-card",
    label: "menu.premiumDashboard",
    color: Color.PREMIUM,
    right: "cockpit.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    basePath: "/premiumDashboard",
    inLoyaltyApp: false,
  },
  {
    path: "/checkins",
    icon: "icon-loyalty-card",
    label: "menu.checkIns",
    color: Color.CHECKINS,
    right: "checkins.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: true,
  },
  {
    path: "/tlpOffers",
    icon: "icon-tag",
    label: "menu.tlpOffers",
    color: Color.TLPOFFERS,
    right: "tlpoffers.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
  {
    path: "/apiGateway",
    icon: "icon-cloud",
    label: "menu.apiGateway",
    color: Color.APIGATEWAY,
    right: "apigateway.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
  {
    path: "/companies",
    icon: "icon-suitcase",
    label: "menu.companies",
    color: Color.COMPANIES,
    right: "companies.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
  {
    path: "/exports",
    icon: "icon-export",
    label: "menu.exports",
    color: Color.EXPORTS,
    right: "exports.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
  {
    path: "/centers",
    icon: "icon-cart",
    label: "menu.centers",
    color: Color.CENTERS,
    right: "centers.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
  {
    path: "/roles",
    icon: "icon-key",
    label: "menu.roles",
    color: Color.RIGHTS,
    right: "roles.update",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
  {
    path: "https://docs.google.com/spreadsheets/d/1yOx1bKrm8KcuXDAKBOcFhs-Su6NzT_pVe2AreL4_wW8/edit#gid=1866606146",
    icon: "icon-chat-empty",
    label: "menu.tlpSupport",
    color: Color.TLPSUPPORT,
    right: "tlpsupport.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
  {
    path: "/cia",
    icon: "icon-qr-code-scanner",
    label: "menu.app",
    color: Color.CIA,
    right: true,
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
  {
    path: "/changelog",
    icon: "icon-megaphone",
    label: "menu.changelog",
    color: Color.CHANGELOG,
    right: "changelogs.read",
    shouldShowInNav: true,
    shouldShowInHome: true,
    inLoyaltyApp: false,
  },
]

export type Tag = {
  title: string
  label: string
  key: string
  color: string
  icon: string
  filter?: TagFilterCondition[]
}

type Tags = {
  [key: string]: Tag
}

export const tags: Tags = {
  pending: {
    title: "members.tags.pending.title",
    label: "members.tags.pending.label",
    key: "pending",
    color: "bg-tags-pending",
    icon: "",
    filter: [
      {
        type: "must",
        term: "type",
        keyword: true,
        value: "pending",
        filterType: "term",
      },
    ],
  },
  partial: {
    title: "members.tags.partial.title",
    label: "members.tags.partial.label",
    key: "partial",
    color: "bg-tags-partial",
    icon: "",
    filter: [
      {
        type: "must",
        term: "type",
        keyword: true,
        value: "partial",
        filterType: "term",
      },
    ],
  },
  full: {
    title: "members.tags.full.title",
    label: "members.tags.full.label",
    key: "full",
    color: "bg-tags-full",
    icon: "",
    filter: [
      {
        type: "must",
        term: "type",
        keyword: true,
        value: "full",
        filterType: "term",
      },
    ],
  },
  loyaltyMembers: {
    title: "members.tags.loyaltyMembers.title",
    label: "members.tags.loyaltyMembers.title",
    key: "loyaltyMember",
    color: "bg-tags-loyaltyMember",
    icon: "",
    filter: [
      {
        type: "should",
        term: "memberType",
        keyword: true,
        value: "loyaltyMember",
        filterType: "term",
      },
    ],
  },
  newsletterSubscribers: {
    title: "members.tags.newsletterSubscribers.title",
    label: "members.tags.newsletterSubscribers.title",
    key: "newsletterSubscriber",
    color: "bg-tags-newsletterSubscriber",
    icon: "",
    filter: [
      {
        type: "should",
        term: "memberType",
        keyword: true,
        value: "newsletterSubscriber",
        filterType: "term",
      },
    ],
  },
  physical: {
    title: "members.tags.physical.title",
    label: "members.tags.physical.label",
    key: "physical",
    color: "bg-tags-physical",
    icon: "",
    filter: [
      {
        type: "must",
        term: "isDigital",
        keyword: false,
        value: 0,
        filterType: "term",
      },
    ],
  },
  digital: {
    title: "members.tags.digital.title",
    label: "members.tags.digital.label",
    key: "digital",
    color: "bg-tags-digital",
    icon: "",
    filter: [
      {
        type: "must",
        term: "isDigital",
        keyword: false,
        value: 1,
        filterType: "term",
      },
    ],
  },
  premium: {
    title: "members.tags.premium.title",
    label: "members.tags.premium.label",
    key: "TLP",
    color: "bg-tags-premium",
    icon: "",
    filter: [
      {
        type: "must",
        term: "isPremium",
        keyword: false,
        value: 1,
        filterType: "term",
      },
    ],
  },
  printed: {
    title: "members.tags.printed.title",
    label: "members.tags.printed.label",
    key: "printed",
    color: "bg-tags-printed",
    icon: "",
    filter: [
      {
        type: "must",
        term: "isPrinted",
        keyword: false,
        value: 1,
        filterType: "term",
      },
    ],
  },
  pro: {
    title: "members.tags.pro.title",
    label: "members.tags.pro.label",
    key: "pro",
    color: "bg-tags-pro",
    icon: "",
    filter: [
      {
        type: "must",
        term: "isVIP",
        keyword: false,
        value: 1,
        filterType: "term",
      },
    ],
  },
  hostess: {
    title: "members.tags.from.title",
    label: "members.tags.from.label.hostess",
    key: "hostess",
    color: "bg-tags-origin",
    icon: "icon-cart",
    filter: [
      {
        type: "should",
        term: "createdFrom",
        keyword: true,
        value: "onSite",
        filterType: "term",
      },
    ],
  },
  web: {
    title: "members.tags.from.title",
    label: "members.tags.from.label.web",
    key: "web",
    color: "bg-tags-origin",
    icon: "icon-website",
    filter: [
      {
        type: "should",
        term: "createdFrom",
        keyword: true,
        value: "formWeb",
        filterType: "term",
      },
      {
        type: "should",
        term: "createdFrom",
        keyword: true,
        value: "sitemobile",
        filterType: "term",
      },
    ],
  },
  ios: {
    title: "members.tags.from.title",
    label: "members.tags.from.label.ios",
    key: "iphone",
    color: "bg-tags-origin",
    icon: "icon-apple",
    filter: [
      {
        type: "should",
        term: "createdFrom",
        keyword: true,
        value: "appiPhone",
        filterType: "term",
      },
    ],
  },
  android: {
    title: "members.tags.from.title",
    label: "members.tags.from.label.android",
    key: "android",
    color: "bg-tags-origin",
    icon: "icon-android",
    filter: [
      {
        type: "should",
        term: "createdFrom",
        keyword: true,
        value: "appAndroid",
        filterType: "term",
      },
    ],
  },
  wifi: {
    title: "members.tags.from.title",
    label: "members.tags.from.label.wifi",
    key: "wifi",
    color: "bg-tags-origin",
    icon: "icon-wifi",
    filter: [
      {
        type: "should",
        term: "createdFrom",
        keyword: true,
        value: "Wifi",
        filterType: "term",
      },
    ],
  },
  partner: {
    title: "members.tags.from.title",
    label: "members.tags.from.label.partner",
    key: "partner",
    color: "bg-tags-origin",
    icon: "icon-partner",
    filter: [
      {
        type: "should",
        term: "createdFrom",
        keyword: true,
        value: "Partner",
        filterType: "term",
      },
    ],
  },
  directories: {
    title: "members.tags.from.title",
    label: "members.tags.from.label.directories",
    key: "directories",
    color: "bg-tags-origin",
    icon: "icon-directory",
    filter: [
      {
        type: "should",
        term: "createdFrom",
        keyword: true,
        value: "Directories",
        filterType: "term",
      },
    ],
  },
  email: {
    title: "members.tags.email.title",
    label: "members.tags.email.label",
    key: "email",
    color: "bg-tags-email",
    icon: "",
    filter: [
      {
        type: "must",
        keyword: false,
        value: "cognitoId",
        filterType: "exist",
      },
    ],
  },
  google: {
    title: "members.tags.google.title",
    label: "members.tags.google.label",
    key: "google",
    color: "bg-tags-google",
    icon: "",
    filter: [
      {
        type: "must",
        keyword: false,
        value: "googleId",
        filterType: "exist",
      },
    ],
  },
  facebook: {
    title: "members.tags.facebook.title",
    label: "members.tags.facebook.label",
    key: "facebook",
    color: "bg-tags-facebook",
    icon: "",
    filter: [
      {
        type: "must",
        keyword: false,
        value: "facebookId",
        filterType: "exist",
      },
    ],
  },
  unknown: {
    title: "members.tags.unknow.title",
    label: "members.tags.unknow.label",
    key: "unknown",
    color: "bg-tags-none",
    icon: "",
    filter: [
      {
        type: "must_not",
        keyword: false,
        value: "googleId",
        filterType: "exist",
      },
      {
        type: "must_not",
        keyword: false,
        value: "facebookId",
        filterType: "exist",
      },
      {
        type: "must_not",
        keyword: false,
        value: "cognitoId",
        filterType: "exist",
      },
    ],
  },
}

export const tagsStatus: Tag[] = [tags.pending, tags.partial, tags.full]

export const tagsType: Tag[] = [tags.physical, tags.digital]

export const tagsTypology: Tag[] = [tags.loyaltyMembers, tags.newsletterSubscribers]

export const tagsConnexion: Tag[] = [tags.email, tags.google, tags.facebook, tags.unknown]

export const tagsOrigins: Tag[] = [
  tags.hostess,
  tags.web,
  tags.ios,
  tags.android,
  tags.wifi,
  tags.partner,
  tags.directories,
]

export const tagsOthers: Tag[] = [tags.premium, tags.printed, tags.pro]

export const LogsOrigin = [
  {
    label: "logs.filters.defaultOriginValue",
    value: "",
  },
  {
    label: "Android",
    value: "android",
  },
  {
    label: "ios",
    value: "ios",
  },
  {
    label: "Web",
    value: "web",
  },
  {
    label: "Data-Proxy",
    value: "proxy",
  },
  {
    label: "FID",
    value: "fid",
  },
]

export enum originMemberEdit {
  TLP = "tlp",
  CHECKINS = "checkins",
  LOGS = "logs",
  EDITION = "edition",
}
