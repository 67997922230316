import React, { FC } from "react"
import Button, { ButtonType } from "../../components/general/Button"
import { setFromDate, setToDate } from "../../store/FilterStore"
import { format } from "date-fns"
import HeaderDateFilter from "./HeaderDateFilter"
import { useTranslation } from "react-i18next"

const CockpitDateFilter: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col lg:flex-row items-center bg-white justify-center bg-white py-3 mx-3 rounded-sm">
      <div className="flex flex-row">
        <Button
          label={t("dashboard.filters.date.yesterday")}
          onClick={() => {
            const yesterday = new Date()
            yesterday.setDate(yesterday.getDate() - 1)
            setFromDate(format(yesterday, "yyyy-MM-dd"))
            setToDate(format(yesterday, "yyyy-MM-dd"))
          }}
          type={ButtonType.NEUTRAL}
          classNames="mr-3"
        />

        <Button
          label={t("dashboard.filters.date.today")}
          onClick={() => {
            setFromDate(format(new Date(), "yyyy-MM-dd"))
            setToDate(format(new Date(), "yyyy-MM-dd"))
          }}
          type={ButtonType.NEUTRAL}
          classNames="mr-6"
        />
      </div>
      <HeaderDateFilter />
    </div>
  )
}

export default CockpitDateFilter
