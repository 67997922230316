import * as React from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Container from "../../general/Container"
import Card from "../Card"
import InputField from "../../general/InputField"
import execute from "../../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import { barcodeFormValidationSchema, BarcodeWS } from "./MembersFormConsts"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"
import { Form, Formik } from "formik"
import FormSubmitButton, { FormCancelButton } from "../../profile/FormSubmitButton"
import { MemberType } from "../../../api/MemberObject"

type BarCodeSectionProps = {
  barcodeId: string
  customerId: number
}
export const BarCodeSection: React.FC<BarCodeSectionProps> = ({ barcodeId, customerId }: BarCodeSectionProps) => {
  const { t } = useTranslation()
  const [editBarcode, setEditBarcode] = useState(false)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()
  const [barcode, setBarcode] = useState(barcodeId)

  const requestAlreadyUsedBarcode = (newBarcode: string): Promise<boolean> => {
    const barcodeWS = execute<BarcodeWS>(`customers/barcode/${newBarcode}`, "GET", {})

    return barcodeWS
      .then((response: BarcodeWS) => {
        return Promise.resolve(response.exists)
      })
      .catch(() => {
        openErrorSnackbar(t("members.forms.errors.barcodeInvalid"), snackbarDuration)
        return Promise.reject()
      })
  }

  const updateNewBarcode = (newBarcode: string) => {
    const body = {
      barcode: newBarcode,
    }
    execute<MemberType>(`customers/${customerId}`, "PATCH", {}, body)
      .then((response) => {
        setBarcode(response.barcode || "")
        setEditBarcode(false)
        openSuccessSnackbar(`${t("members.snackbar.title")} ${t("generic.snackbar.success.edit")}`, snackbarDuration)
      })
      .catch(() => {
        openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
      })
  }

  return (
    <div>
      <Container>
        <Card>
          <div className="px-0 md:px-20 lg:px-40">
            <div className="flex justify-center">
              <img
                height={200}
                width={450}
                src={`https://barcode.tec-it.com/barcode.ashx?code=EAN13&dpi=200&data=${barcode}`}
                alt="barcode"
              />
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                className="py-2 px-3 text-white border-1 border-transparent rounded bg-blueGray-500"
                onClick={() => setEditBarcode(true)}
              >
                {t("generic.buttons.update")}
              </button>
            </div>
          </div>
        </Card>
      </Container>
      {editBarcode && (
        <Container>
          <Card>
            <div className="h-full flex flex-col justify-between">
              <h3 className="mb-4">{t("members.forms.titles.barcode")}</h3>
              <Formik
                initialValues={{ newBarcode: "" }}
                validationSchema={barcodeFormValidationSchema}
                onSubmit={(values) => {
                  requestAlreadyUsedBarcode(values.newBarcode).then((alreadyUseBarcode) => {
                    if (!alreadyUseBarcode) {
                      updateNewBarcode(values.newBarcode)
                    } else {
                      openErrorSnackbar(t("members.forms.errors.barcodeAlreadyUsed"), snackbarDuration)
                    }
                  })
                }}
              >
                <Form>
                  <InputField
                    fieldData={{
                      formKey: "newBarcode",
                      label: "members.forms.fields.barcode",
                      required: true,
                    }}
                  />
                  <div className="flex justify-center space-x-3">
                    <FormCancelButton onClick={() => setEditBarcode(false)}>
                      {t("generic.buttons.cancel")}
                    </FormCancelButton>
                    <FormSubmitButton>{t("generic.buttons.update")}</FormSubmitButton>
                  </div>
                </Form>
              </Formik>
            </div>
          </Card>
        </Container>
      )}
    </div>
  )
}
