import React, { FC } from "react"
import { Field } from "formik"
import FormInputLayout from "../FormInputLayout"

type TextAreaProps = {
  fieldData: {
    formKey: string
    label?: string
    required?: boolean
  }
  isAutoFocus?: boolean
  large?: boolean
  inline?: boolean
  rows?: string
}

const TextArea: FC<TextAreaProps> = ({ fieldData, isAutoFocus, large, inline, rows }: TextAreaProps) => {
  return (
    <Field key={fieldData.formKey} name={fieldData.formKey}>
      {({ field, meta }: any) => (
        <FormInputLayout
          label={fieldData.label}
          required={!!fieldData.required}
          meta={meta}
          large={large}
          inline={inline}
        >
          <textarea
            rows={rows}
            autoFocus={isAutoFocus}
            className={
              "appearance-none border-2 rounded w-full py-3 px-4 leading-tight focus:outline-none focus:border-blueGray-600" +
              (meta && meta.touched && meta.error ? " border-red-500 text-red-700" : " border-gray-200 text-gray-700")
            }
            type="text"
            {...field}
          />
        </FormInputLayout>
      )}
    </Field>
  )
}

export default TextArea
