import { HighchartSerie } from "../verticalBarChart/ConstPremiumEvolution"
import { ColorGraphs } from "../../../utils/Constants"

export type objectKeyString = "mobile" | "web" | "onSite" | "Partner" | "Wifi" | "Directories"

export type subGraphType = {
  cumulativeTotal: number
  cumulativeValue: number
  date: number
  day: string
  percent: number
  total: number
  value: number
}
export type WSGraph = {
  date: number
  Directories: subGraphType
  Partner: subGraphType
  Wifi: subGraphType
  mobile: subGraphType
  onSite: subGraphType
  web: subGraphType
}

export type DataSeries = {
  date: number[]
  mobile: {
    customers: number[]
    percentages: number[]
  }
  web: {
    customers: number[]
    percentages: number[]
  }
  onSite: {
    customers: number[]
    percentages: number[]
  }
  Partner: {
    customers: number[]
    percentages: number[]
  }
  Wifi: {
    customers: number[]
    percentages: number[]
  }
  Directories: {
    customers: number[]
    percentages: number[]
  }
}

export const initialDataSeriesPartners = {
  date: [],
}

export const initialDataSeries: DataSeries = {
  date: [],
  mobile: {
    customers: [],
    percentages: [],
  },
  web: {
    customers: [],
    percentages: [],
  },
  onSite: {
    customers: [],
    percentages: [],
  },
  Partner: {
    customers: [],
    percentages: [],
  },
  Wifi: {
    customers: [],
    percentages: [],
  },
  Directories: {
    customers: [],
    percentages: [],
  },
}

export const dataSubTypeInitialState: dataSubType = {
  customers: 0,
  percentages: 0,
}

export const dataCustomersStockDirectiveInitialState: DataCustomersStockDirective = {
  date: "",
  mobile: dataSubTypeInitialState,
  web: dataSubTypeInitialState,
  onSite: dataSubTypeInitialState,
  Partner: dataSubTypeInitialState,
  Wifi: dataSubTypeInitialState,
  Directories: dataSubTypeInitialState,
}

export const dataCustomersStockDirectiveInitialStatePartners = {
  date: "",
}

export type dataSubType = {
  customers: number
  percentages: number
}

export type DataCustomersStockDirective = {
  date: string
  mobile: dataSubType
  web: dataSubType
  onSite: dataSubType
  Partner: dataSubType
  Wifi: dataSubType
  Directories: dataSubType
}

export const getSeriesForHighchart = (titleSeries: string[], seriesData: DataSeries): HighchartSerie[] => {
  return [
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[0],
      data: seriesData.onSite ? seriesData.onSite.customers : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.red,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[1],
      data: seriesData.mobile ? seriesData.mobile.customers : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.green,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[2],
      data: seriesData.web ? seriesData.web.customers : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.blue,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[3],
      data: seriesData.Partner ? seriesData.Partner.customers : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.orange,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[4],
      data: seriesData.Wifi ? seriesData.Wifi.customers : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.purple,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 0,
      type: "column",
      name: titleSeries[5],
      data: seriesData.Directories ? seriesData.Directories.customers : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.teal,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 1,
      type: "area",
      name: titleSeries[0],
      data: seriesData.onSite ? seriesData.onSite.percentages : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.red,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 1,
      type: "area",
      name: titleSeries[1],
      data: seriesData.mobile ? seriesData.mobile.percentages : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.green,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 1,
      type: "area",
      name: titleSeries[2],
      data: seriesData.web ? seriesData.web.percentages : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.blue,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 1,
      type: "area",
      name: titleSeries[3],
      data: seriesData.Partner ? seriesData.Partner.percentages : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.orange,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 1,
      type: "area",
      name: titleSeries[4],
      data: seriesData.Wifi ? seriesData.Wifi.percentages : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.purple,
      showInLegend: false,
      turboThreshold: 0,
    },
    {
      yAxis: 1,
      type: "area",
      name: titleSeries[5],
      data: seriesData.Directories ? seriesData.Directories.percentages : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      color: ColorGraphs.teal,
      showInLegend: false,
      turboThreshold: 0,
    },
  ]
}

const sortTitleSeries = (
  titleSeries: string[],
  seriesData: { [k: string]: { customers: number[]; percentages: number[] } },
): string[] => {
  return titleSeries.sort((a, b) =>
    Object.values(seriesData[a].customers).reduce((c, d) => c + d, 0) >
    Object.values(seriesData[b].customers).reduce((c, d) => c + d, 0)
      ? -1
      : 1,
  )
}

export const getSeriesForHighchartPartners = (
  titleSeries: string[],
  seriesData: { [k: string]: { customers: number[]; percentages: number[] } },
): any => {
  const SeriesForHighchart = []
  titleSeries = sortTitleSeries(titleSeries, seriesData)
  for (let i = 0; i < titleSeries.length; i++) {
    SeriesForHighchart.push({
      yAxis: 0,
      type: "column",
      name: titleSeries[i],
      color: Object.values(ColorGraphs)[i % Object.keys(ColorGraphs).length],
      data: seriesData[titleSeries[i]] ? seriesData[titleSeries[i]].customers : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      showInLegend: false,
      turboThreshold: 0,
    })
  }
  for (let i = 0; i < titleSeries.length; i++) {
    SeriesForHighchart.push({
      yAxis: 1,
      type: "area",
      name: titleSeries[i],
      color: Object.values(ColorGraphs)[i % Object.keys(ColorGraphs).length],
      data: seriesData[titleSeries[i]] ? seriesData[titleSeries[i]].percentages : null,
      marker: {
        enabled: false,
      },
      shadow: false,
      showInLegend: false,
      turboThreshold: 0,
    })
  }

  return SeriesForHighchart
}
