import { dataPieType } from "../../../api/ChartObject"
import { TFunction } from "i18next"

export const formatDataWithSlider = (data: dataPieType[], value: number, t: TFunction): dataPieType[] => {
  const lastElem: dataPieType = { name: t("dashboard.bar.others"), y: 0 }
  const newData: dataPieType[] = []
  data.forEach((element, index) => {
    if (index < value) {
      newData.push(element)
    } else {
      lastElem.y = lastElem.y + element.y
    }
    index++
  })
  if (lastElem.y !== 0) {
    newData.push(lastElem)
  }
  return newData
}
