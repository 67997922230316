import * as React from "react"
import AddButton from "../../general/AddButton"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import InputField from "../../general/InputField"
import { useStore } from "effector-react"
import { ContributorStore } from "../../../store/ContributorStore"
import execute from "../../../api/api"
import { snackbarDuration, snackbarErrorMessage } from "../../snackbarStyles"
import { cognitoUsersInitialValues, cognitoUsersValidation } from "./CognitoUsersConsts"
import { addActiveCalls, subtractActiveCalls } from "../../../store/LoadingStore"
import CustomModal from "../../CustomModal"
import FormActionButtons from "../../general/FormActionButtons"
import Header from "../../Header"
import { useCustomSnackBar } from "../../../utils/hooks/useCustomSnackBar"
import { FC, useState } from "react"

type CognitoUserCreateProps = {
  refresh: () => void
}
const CognitoUserCreate: FC<CognitoUserCreateProps> = ({ refresh }: CognitoUserCreateProps) => {
  const { t } = useTranslation()
  const { id, email, assignedCenters } = useStore(ContributorStore)
  const [open, setOpen] = useState(false)
  const [openSuccessSnackbar, openErrorSnackbar] = useCustomSnackBar()

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <div>
      <AddButton label={t("modal.apiGateway.cognito.add")} classNames="w-auto mr-2" onClick={() => setOpen(true)} />
      {open && (
        <CustomModal isOpen={open} onRequestClose={closeModal} className="md:w-1/3">
          <Formik
            initialValues={cognitoUsersInitialValues}
            validationSchema={cognitoUsersValidation}
            onSubmit={(values) => {
              addActiveCalls()
              execute(
                "apiGateway/partners",
                "POST",
                {},
                {
                  ...values,
                  // Deletes password if empty as there is a regex on back side if not empty
                  password: values.password.length > 0 ? values.password : undefined,
                },
              )
                .then(() => {
                  execute<void>(
                    "fid",
                    "POST",
                    {},
                    {
                      centerId: assignedCenters[0]?.id,
                      code: "PartnerCreation_Dashboard'",
                      content: "Creating partner from dashboard",
                      application: "dashboard",
                      email: email,
                      customerId: id,
                    },
                    {},
                    "logger",
                  )
                  openSuccessSnackbar(
                    `${t("apiGateway.titles.cognitoUser")} ${t("generic.snackbar.success.create")}`,
                    snackbarDuration,
                  )
                  refresh()
                  setOpen(false)
                })
                .catch(() => {
                  openErrorSnackbar(t(snackbarErrorMessage), snackbarDuration)
                })
                .finally(subtractActiveCalls)
            }}
          >
            <Form className="h-full flex flex-col">
              <Header>{t("modal.apiGateway.cognito.add")}</Header>
              <hr />
              <div className="mt-4 md:mt-8 flex flex-col flex-grow justify-between">
                <div>
                  <InputField
                    fieldData={{
                      formKey: "username",
                      label: t("generic.forms.fields.username"),
                      required: true,
                    }}
                    large
                  />
                  <InputField
                    fieldData={{
                      formKey: "email",
                      label: t("generic.forms.fields.email"),
                      required: true,
                    }}
                    large
                  />
                  <InputField
                    fieldData={{
                      formKey: "password",
                      label: t("generic.forms.fields.password"),
                      type: "password",
                    }}
                    large
                  />
                </div>
                <FormActionButtons cancelOnClick={closeModal} />
              </div>
            </Form>
          </Formik>
        </CustomModal>
      )}
    </div>
  )
}

export default CognitoUserCreate
